import React, { useEffect, useState, useRef } from 'react';
import {
  Linking,
  Platform,
  Pressable,
  StatusBar,
  StyleSheet,
  Text,
  View,
  useColorScheme
} from 'react-native';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  createNavigationContainerRef,
  NavigationContainer,
  useNavigation
} from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';

import { track } from '@amplitude/analytics-react-native';

import { Helmet } from 'react-helmet-async';

import Onboarding from '../../onboarding/containers/OnboardingContainer';
import OnboardingAnswers from '../../onboarding/containers/OnboardingAnswersContainer';
import GettingStartedTour from '../../onboarding/components/GettingStartedTour';

import Feed from '../../feed/containers/FeedViewContainer';
import PostMake from '../../feed/containers/PostMakeContainer';
import Notifications from '../../feed/containers/NotificationsContainer';

import RecipeDetail from '../../recipes/containers/RecipeDetailContainer';
import RecipeEditor from '../../recipes/containers/RecipeEditorContainer';
import StoryShare from '../../recipes/components/StoryShare';

import HouseholdHome from '../../household/containers/HouseholdHomeContainer';
import HouseholdSwitcher from '../../household/containers/HouseholdSwitcherContainer';
import HouseholdInvite from '../../household/containers/HouseholdInviteContainer';
import HouseholdJoin from '../../household/containers/HouseholdJoinContainer';

import UniversalSearch from '../../search/containers/UniversalSearchContainer';
import WizardViewContainer from '../../search/containers/WizardViewContainer';

import AccountSearch from '../../account/containers/AccountSearchContainer';
import Profile from '../../account/containers/ProfileContainer';
import SettingsContainer from '../../account/containers/SettingsContainer';

import ChallengesOverview from '../../challenges/containers/ChallengesOverviewContainer'
import ChallengeDetail from '../../challenges/containers/ChallengeDetailContainer'

import LoginSignUpScreen from '../../auth/components/LoginSignUpScreen';
import ResetPasswordContainer from '../../auth/containers/ResetPasswordContainer';
import VerifyEMailContainer from '../../auth/containers/VerifyEMailContainer';

import LabellingToolContainer from '../../labelling_tool/containers/LabellingToolContainer';
import BigTextMaker from '../../big_text_maker/components/BigTextMaker';

import About from './About';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import NotificationContainer from '../../notifications/containers/NotificationContainer';
import ErrorScreenContainer from '../containers/ErrorScreenContainer';
import {
  FeedUpdateIndicator,
  HouseholdUpdateIndicator,
  NoUpdateIndicator
} from '../containers/TabBarIconUpdateIndicator';

import FeedIcon from '../../assets/Feed';
import Filter from '../../assets/Filter';
import Fridge from '../../assets/Fridge';
import Heart from '../../assets/Heart';
import Household from '../../assets/Household';
import Home from '../../assets/Home';
import MagnifyingGlass from '../../assets/MagnifyingGlass';
import MealPlan from '../../assets/MealPlan';
import Pot from '../../assets/Pot';
import ProfileIcon from '../../assets/Profile';
import Seedling from '../../assets/Seedling';
import User from '../../assets/User';

import {
  ADMIN_PATH,
  ADMIN_SCREENS,
  ApiState,
  APP_TABS,
  AppState,
  AuthState,
  CHALLENGES_PATH,
  CHALLENGES_SCREENS,
  ConnectionState,
  ErrorScreenType,
  FEED_PATH,
  FEED_SCREENS,
  HOUSEHOLD_PATH,
  HOUSEHOLD_SCREENS,
  MEAL_PLANNING_PATH,
  MISC_SCREENS,
  MODAL_SCREENS,
  PROFILE_PATH,
  PROFILE_SCREENS,
  RECIPE_PATH,
  ROOT_SCREENS,
  SEARCH_PATH,
  SEARCH_SCREENS,
  USER_PATH,
  USER_SCREENS,
} from '../../common/constants/';
import {
  Small,
  useAppDimensions,
  XSmall
} from '../../utils/';
import { BlurView } from '../../utils/BlurView';
import Theme, { useTheme } from '../../utils/Theme';
import useNotificationSetup from '../../hooks/useNotificationSetup';

export const navigation_ref = createNavigationContainerRef();
const RootStack = createStackNavigator();
const Tab = createBottomTabNavigator();
const SearchStack = createStackNavigator();
const FeedStack = createStackNavigator();
const ChallengesStack = createStackNavigator();
const HouseholdStack = createStackNavigator();
const ProfileStack = createStackNavigator();
const AdminStack = createStackNavigator();

export const BackgroundPrimary = ({ children }) => {
  const theme = useTheme();

  return (
    <View
      style={[
        styles.appBodyFull,
        theme.general
      ]}
    >
      { children }
    </View>
  );
};

const UnguardedView = ({ children }) => {
  const scheme = useColorScheme();
  const theme = Theme(scheme);

  return (
    <View style={{...StyleSheet.absoluteFill, ...theme.general}}>
      <StatusBar
        barStyle={scheme === "light" ? "dark-content" : "light-content"}
        backgroundColor={theme.general.backgroundColor}
      />
        <View style={{...StyleSheet.absoluteFill, ...theme.general}}>
          { children }
        </View>
      <NotificationContainer />
    </View>
  );
};
const GuardedView = ({ children }) => {
  const [ getting_started_tour_visible, setGettingStartedTourVisible ] = useState(false);

  return (
    <UnguardedView>
      { children }
      <Onboarding
        onComplete={() => {
          console.log('onComplete');
          setGettingStartedTourVisible(true);
        }}
      />
      <OnboardingAnswers />
      <GettingStartedTour
        visible={getting_started_tour_visible}
        onRequestClose={() => setGettingStartedTourVisible(false)}
      />
    </UnguardedView>
  )
};

const FeedView = () => {
  const { t } = useTranslation('common');
  return (
    <GuardedView>
      <Helmet>
        <title>{ t('app.metaTags.feed.title') }</title>
        <meta propery='og:title' content={t('app.metaTags.feed.title')}/>
        <meta propery='og:description' content={t('app.metaTags.feed.description')}/>
      </Helmet>
      <Feed />
    </GuardedView>
  )
};
const PostMakeView = () => (
  <GuardedView>
    <PostMake />
  </GuardedView>
);
const NotificationsView = () => (
  <GuardedView>
    <Notifications />
  </GuardedView>
);
const ChallengesOverviewView = () => (
  <GuardedView>
    <ChallengesOverview />
  </GuardedView>
);
const HouseholdHomeView = () => {
  const { t } = useTranslation('common');
  return (
    <GuardedView>
      <Helmet>
        <title>{ t('app.metaTags.household.title') }</title>
        <meta propery='og:title' content={t('app.metaTags.household.title')}/>
        <meta propery='og:description' content={t('app.metaTags.household.description')}/>
      </Helmet>
      <HouseholdHome />
    </GuardedView>
  )
};
const RecipeEditorView = () => (
  <GuardedView>
    <RecipeEditor />
  </GuardedView>
);
const HouseholdSwitcherView = () => (
  <GuardedView>
    <HouseholdSwitcher />
  </GuardedView>
);
const HouseholdInviteView = () => (
  <GuardedView>
    <HouseholdInvite />
  </GuardedView>
);
const HouseholdJoinView = () => (
  <GuardedView>
    <HouseholdJoin />
  </GuardedView>
);
const UniversalSearchView = () => {
  const { t } = useTranslation('common');
  return (
    <GuardedView>
      <Helmet>
        <title>{ t('app.metaTags.search.title') }</title>
        <meta propery='og:title' content={t('app.metaTags.search.title')}/>
        <meta propery='og:description' content={t('app.metaTags.search.description')}/>
      </Helmet>
      <UniversalSearch />
    </GuardedView>
  )
};
const WizardView = () => (
  <GuardedView>
    <WizardViewContainer />
  </GuardedView>
);
const SettingsView = () => (
  <GuardedView>
    <SettingsContainer />
  </GuardedView>
);
const ProfileView = () => (
  <GuardedView>
    <Profile />
  </GuardedView>
);
const AccountSearchView = () => (
  <GuardedView>
    <AccountSearch />
  </GuardedView>
);
const LabellingToolView = () => (
  <GuardedView>
    <LabellingToolContainer />
  </GuardedView>
);
const BigTextMakerView = () => (
  <GuardedView>
    <BigTextMaker />
  </GuardedView>
);
const LoginSignUpView = () => (
  <UnguardedView>
    <LoginSignUpScreen />
  </UnguardedView>
);
const ResetPasswordView = () => (
  <UnguardedView>
    <ResetPasswordContainer />
  </UnguardedView>
);
const VerifyEMailView = () => (
  <UnguardedView>
    <VerifyEMailContainer />
  </UnguardedView>
);
const TermsAndConditionsView = () => (
  <UnguardedView>
    <TermsAndConditions />
  </UnguardedView>
);
const AboutView = () => (
  <UnguardedView>
    <About />
  </UnguardedView>
);
const PrivacyPolicyView = () => (
  <UnguardedView>
    <PrivacyPolicy />
  </UnguardedView>
);

const fillStack = (stack, screens, initial_route_name) => {
  const { t } = useTranslation("common");

  return (
    <stack.Navigator
      initialRouteName={initial_route_name}
      screenOptions={{ headerShown: false }}
    >
      {
        screens.map(({ name, component }, idx) => (
          <stack.Screen
            key={idx}
            name={name}
            component={component}
            options={{
              title: t("app.name"),
            }}
          />
        ))
      }
    </stack.Navigator>
  );
};

const RootScreens = () => {
  const theme = useTheme();
  const { t } = useTranslation("common");

  const screens = [
    { name: ROOT_SCREENS.app.name, component: AppTabs },
    { name: MISC_SCREENS.about.name, component: AboutView },
    { name: MISC_SCREENS.termsAndConditions.name, component: TermsAndConditionsView },
    { name: MISC_SCREENS.privacyPolicy.name, component: PrivacyPolicyView },
  ];
  const modal_screens = [
    { name: MODAL_SCREENS.recipeDetail.name, component: RecipeDetail },
    { name: MODAL_SCREENS.challengeDetail.name, component: ChallengeDetail },
    { name: MODAL_SCREENS.storyShare.name, component:  StoryShare },
  ];

  const make_screens = ({ name, component }, idx) => (
    <RootStack.Screen
      key={idx}
      name={name}
      component={component}
      options={{
        title: t("app.name"),
      }}
    />
  );

  return (
    <RootStack.Navigator
      initialRouteName={ROOT_SCREENS.app.name}
      screenOptions={{ headerShown: false }}
    >
      <RootStack.Group>
        {
          screens.map(make_screens)
        }
      </RootStack.Group>
      <RootStack.Group
        screenOptions={{
          presentation: 'modal',
          cardStyle: theme.general
        }}
      >
        {
          modal_screens.map(make_screens)
        }
      </RootStack.Group>
    </RootStack.Navigator>
  );
};

const FeedScreen = () => {
  const screens = [
    { name: FEED_SCREENS.home.name, component: FeedView },
    { name: FEED_SCREENS.postMake.name, component: PostMakeView },
    { name: FEED_SCREENS.notifications.name, component: NotificationsView },
  ];

  return fillStack(FeedStack, screens, FEED_SCREENS.home.name);
};

const ChallengesScreen = () => {
  const screens = [
    { name: CHALLENGES_SCREENS.overview.name, component: ChallengesOverviewView },
  ];

  return fillStack(ChallengesStack, screens, CHALLENGES_SCREENS.overview.name);
};

const HouseholdScreen = () => {
  const screens = [
    { name: HOUSEHOLD_SCREENS.householdHome.name, component: HouseholdHomeView },
    { name: HOUSEHOLD_SCREENS.householdSwitcher.name, component: HouseholdSwitcherView },
    { name: HOUSEHOLD_SCREENS.householdInvite.name, component: HouseholdInviteView },
    { name: HOUSEHOLD_SCREENS.householdJoin.name, component: HouseholdJoinView },
    { name: HOUSEHOLD_SCREENS.recipeEditor.name, component: RecipeEditorView },
  ];

  return fillStack(HouseholdStack, screens, HOUSEHOLD_SCREENS.householdHome.name);
};

const SearchScreen = () => {
  const screens = [
    {
      name: SEARCH_SCREENS.universalSearch.name,
      component: UniversalSearchView,
    },
    { name: SEARCH_SCREENS.recipeSearch.name, component: WizardView },
  ];

  return fillStack(SearchStack, screens, SEARCH_SCREENS.universalSearch.name);
};

const ProfileScreen = () => {
  const screens = [
    { name: USER_SCREENS.account.name, component: ProfileView },
    { name: PROFILE_SCREENS.loginSignUp.name, component: LoginSignUpView },
    { name: PROFILE_SCREENS.resetPassword.name, component: ResetPasswordView },
    { name: PROFILE_SCREENS.verifyEMail.name, component: VerifyEMailView },
    { name: PROFILE_SCREENS.settings.name, component: SettingsView },
    { name: PROFILE_SCREENS.search.name, component: AccountSearchView },
  ];

  return fillStack(ProfileStack, screens, USER_SCREENS.account.name);
};

const AdminScreen = () => {
  const screens = [
    { name: ADMIN_SCREENS.labelling.name, component: LabellingToolView },
    { name: ADMIN_SCREENS.bigTextMaker.name, component: BigTextMakerView },
  ];

  return fillStack(AdminStack, screens, ADMIN_SCREENS.bigTextMaker.name);
};

const AppTabs = () => {
  const navigation = useNavigation();

  const { t } = useTranslation("common");
  const theme = useTheme();
  const scheme = useColorScheme();

  let screens = [
    {
      name: APP_TABS.feed.name,
      component: FeedScreen,
      icon: <FeedIcon color={theme.general.color} />,
      active_icon: <FeedIcon color={theme.tabBarIconActiveColor} filled />,
      update_indicator: <FeedUpdateIndicator />
    },
    {
      name: APP_TABS.search.name,
      component: SearchScreen,
      icon: <MagnifyingGlass color={theme.general.color} />,
      active_icon: <MagnifyingGlass color={theme.tabBarIconActiveColor} filled />,
      update_indicator: <NoUpdateIndicator />
    },
    {
      name: APP_TABS.challenges.name,
      component: ChallengesScreen,
      icon: <Seedling color={theme.general.color}/>,
      active_icon: <Seedling color={theme.tabBarIconActiveColor} filled/>,
      update_indicator: <NoUpdateIndicator />
    },
    {
      name: APP_TABS.household.name,
      component: HouseholdScreen,
      icon: <Fridge color={theme.general.color} />,
      active_icon: <Fridge color={theme.tabBarIconActiveColor} filled />,
      update_indicator: <HouseholdUpdateIndicator />
    },
    {
      name: APP_TABS.profile.name,
      component: ProfileScreen,
      icon: <User color={theme.general.color} />,
      active_icon: <User color={theme.general.color} />,
      update_indicator: <NoUpdateIndicator />,
      hide: true
    },
    {
      name: APP_TABS.admin.name,
      component: AdminScreen,
      icon: <Filter color={theme.general.color} />,
      active_icon: <Filter color={theme.general.color} />,
      update_indicator: <NoUpdateIndicator />,
      hide: true
    }
  ];

  return (
    <Tab.Navigator
      initialRouteName={APP_TABS.feed.name}
      screenOptions={({ route }) => ({
        headerShown: false,
        hiddenTabs: [APP_TABS.admin.name],
        tabBarHideOnKeyboard: true,
        tabBarStyle: {
          position: 'absolute',
          borderTopWidth: 0
        },
        tabBarBackground: () => (
          <BlurView
            style={StyleSheet.absoluteFill}
            reducedTransparencyFallbackColor={theme.general.backgroundColor}
            overlayColor=""
            blurType={scheme}
            blurAmount={20}
          />
        )
      })}
    >
      {screens.map(
        (
          { name, component, icon, active_icon, update_indicator, hide },
          idx
        ) => (
          <Tab.Screen
            key={idx}
            name={name}
            component={component}
            options={{
              tabBarIcon: ({ focused, size }) => (
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: focused ? 1.0 : 0.5
                  }}
                >
                  <View
                    style={{
                      height: size,
                      width: size,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    { focused ? active_icon : icon }
                  </View>
                  { update_indicator }
                  <XSmall
                    style={{
                      opacity: 1.0,
                      color: focused ? theme.tabBarIconActiveColor : theme.general.color
                    }}
                    numberOfLines={1}
                  >
                    {t(`app.${name.toLowerCase()}`)}
                  </XSmall>
                </View>
              ),
              title: t("app.name"),
              tabBarShowLabel: false,
              unMountOnBlur: true,
              tabBarButton: (props) => {
                if (hide) {
                  return null;
                } else {
                  return (
                    <Pressable {...props}/>
                  );
                }
              },
            }}
          />
        )
      )}
    </Tab.Navigator>
  );
};

const Main = () => {
  const route_name_ref = useRef();

  const root_screens_config = {};
  const app_screens_config = {};
  const feed_screens_config = {};
  const search_screens_config = {};
  const challenges_screens_config = {};
  const household_screens_config = {};
  const profile_screens_config = {};
  const admin_screens_config = {};

  Object.values(ROOT_SCREENS).forEach(
    ({ name, path }) => (root_screens_config[name] = path)
  );
  Object.values(MISC_SCREENS).forEach(
    ({ name, path }) => (root_screens_config[name] = path)
  );
  Object.values(MODAL_SCREENS).forEach(
    ({ name, path, path_prefix, is_dynamic, param_name }) => {
      let prefix = `app`;
      if (path_prefix) {
        prefix = `${prefix}/${path_prefix}`;
      }
      if (is_dynamic) {
        root_screens_config[name] = `${prefix}/:${param_name}?`;
      } else {
        root_screens_config[name] = `${prefix}/${path}`;
      }
    }
  );
  Object.values(APP_TABS).forEach(
    ({ name, path }) => (app_screens_config[name] = `app/${path}`)
  );
  Object.values(FEED_SCREENS).forEach(
    ({ name, path }) => (feed_screens_config[name] = `app/${FEED_PATH}/${path}`)
  );
  Object.values(SEARCH_SCREENS).forEach(
    ({ name, path }) =>
      (search_screens_config[name] = `app/${SEARCH_PATH}/${path}`)
  );
  Object.values(HOUSEHOLD_SCREENS).forEach(
    ({ name, path }) =>
      (household_screens_config[name] = `app/${HOUSEHOLD_PATH}/${path}`)
  );
  Object.values(PROFILE_SCREENS).forEach(
    ({ name, path }) => {
      profile_screens_config[name] = `app/${PROFILE_PATH}/${path}`;
    }
  );
  Object.values(USER_SCREENS).forEach(
    ({ name, path, is_dynamic, param_name }) => {
      if (is_dynamic) {
        profile_screens_config[name] = `app/${USER_PATH}/:${param_name}?`;
      } else {
        profile_screens_config[name] = `app/${USER_PATH}/${path}`;
      }
    }
  );
  Object.values(CHALLENGES_SCREENS).forEach(
    ({ name, path, is_dynamic, param_name }) => {
      if (is_dynamic) {
        challenges_screens_config[name] = `app/${CHALLENGES_PATH}/:${param_name}?`;
      } else {
        challenges_screens_config[name] = `app/${CHALLENGES_PATH}/${path}`;
      }
    }
  );
  Object.values(ADMIN_SCREENS).forEach(
    ({ name, path }) =>
      (admin_screens_config[name] = `app/${ADMIN_PATH}/${path}`)
  );

  root_screens_config.App = { screens: app_screens_config };
  root_screens_config.App.screens.Feed = { screens: feed_screens_config };
  root_screens_config.App.screens.Search = { screens: search_screens_config };
  root_screens_config.App.screens.Challenges = {
    screens: challenges_screens_config
  };
  root_screens_config.App.screens.Household = {
    screens: household_screens_config,
  };
  root_screens_config.App.screens.Profile = {
    screens: profile_screens_config,
  };
  root_screens_config.App.screens.Admin = { screens: admin_screens_config };

  const linking = {
    enabled: true,
    prefixes: ["https://eat.savori.app", "savori://"],
    config: {
      screens: root_screens_config,
    },
  };

  useNotificationSetup();

  return (
    <NavigationContainer
      linking={linking}
      ref={navigation_ref}
      onReady={() => {
        if (navigation_ref.getCurrentRoute()) {
          const current_route_name = navigation_ref.getCurrentRoute().name;
          route_name_ref.current = current_route_name;
          track(current_route_name);
        }
      }}
      onStateChange={() => {
        const previous_route_name = route_name_ref.current;
        if (navigation_ref.getCurrentRoute()) {
          const current_route_name = navigation_ref.getCurrentRoute().name;
          if (previous_route_name !== current_route_name) {
            track(current_route_name);
          }
          route_name_ref.current = current_route_name;
        }
      }}
    >
      <RootScreens/>
    </NavigationContainer>
  );
};

const App = ({
  api_state,
  app_state,
  user_language
}) => {
  const theme = useTheme();

  if (user_language && user_language !== i18n.language) {
    i18n.changeLanguage(user_language);
  }

  switch (api_state.state) {
    case ApiState.OK:
      break;
    default:
      const error_screen_type = new Map();
      error_screen_type.set(
        ApiState.NEW_VERSION_AVAILABLE,
        ErrorScreenType.NEW_VERSION_AVAILABLE
      );
      error_screen_type.set(
        ApiState.NEEDS_UPDATE,
        ErrorScreenType.NEEDS_UPDATE
      );
      error_screen_type.set(ApiState.ERROR, ErrorScreenType.OTHER);
      return (
        <ErrorScreenContainer
          error_screen_type={error_screen_type.get(api_state.state)}
          message={api_state.message}
          dismissable={api_state.dismissable}
        />
      );
  }

  switch (app_state) {
    case AppState.INITIALIZING:
      return (
        <BackgroundPrimary>
          <View style={{width: '50%'}}>
            <Pot color={theme.general.color} background_color={theme.general.backgroundColor}/>
          </View>
        </BackgroundPrimary>
      );
    case AppState.INITIALIZATION_FAILED:
      return (
        <ErrorScreenContainer
          error_screen_type={ErrorScreenType.NO_CONNECTION}
        />
      );
    default:
      return (
        <Main />
      );
  }
};

const styles = StyleSheet.create({
  appBodyFull: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
});

export default App;
