import React from 'react';
import { Linking, View, ScrollView, StyleSheet } from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import {
  H1,
  HiddenEmail,
  HidingHeaderScrollView,
  Body2
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import { padding } from '../../common/components/CommonStyles';
import { Title } from '../../header/components/Header';

const About = () => {
  const navigation = useNavigation();

  const { t } = useTranslation('about', 'common');

  const theme = useTheme();

  // TODO add:
  // react-native-async
  // react-navigation
  // ...

  const attributions = [
    {
      attributionKey: "artwork",
      name: "Paul Riedmüller",
      url: "http://www.paulriedmueller.com/"
    },
    {
      attributionKey: "technicalAdvisor",
      name: "Andreas Riedmüller",
      url: "https://www.userbrain.com/"
    },
    {
      attributionKey: "testing",
      name: "Thomas Oberhauser"
    }
  ];

  const open_src_projects = [
    {
      name: "react",
      url: "https://reactjs.org/"
    },
    {
      name: "react-native",
      url: "https://reactnative.dev/"
    },
    {
      name: "react-native-web",
      url: "https://necolas.github.io/react-native-web/"
    },
    {
      name: "django",
      url: "https://www.djangoproject.com/"
    },
    {
      name: "django REST framework",
      url: "https://www.django-rest-framework.org/"
    },
    {
      name: "recipe-scrapers (modified)",
      url: "https://github.com/hhursev/recipe-scrapers"
    },
    {
      name: "ingreedy-py (modified)",
      url: "https://github.com/openculinary/ingreedy-py"
    },
    {
      name: "jwt-decode",
      url: "https://github.com/auth0/jwt-decode"
    },
    {
      name: "i18next",
      url: "https://github.com/i18next/i18next"
    },
    {
      name: "react-i18next",
      url: "https://github.com/i18next/react-i18next"
    }
  ];

  return (
    <HidingHeaderScrollView
      contentContainerStyle={{padding}}
      header={(
        <Title
          goBack={() => navigation.goBack()}
          title_string={t('common:common.about')}
        />
      )}
      body={(
        <>
          <H1 style={theme.general}>
            { t('title') }
          </H1>
          <Body2 style={theme.general}>
            { t('aboutText1') }
          </Body2>
          <Body2 style={theme.general}>
            { t('aboutText2_0') }
          </Body2>
          <Body2 style={theme.general}>
            { `${t('aboutText2_1')} `  }
            { `${t('aboutText2_2')} `  }
            { `${t('aboutText2_3')} `  }
            { `${t('aboutText2_4')} `  }
            { `${t('aboutText2_5')} `  }
            { `${t('aboutText2_6')} `  }
          </Body2>
          <Body2 style={theme.general}>
            { `${t('aboutText3_1')} ` }
            { `${t('aboutText3_2')} ` }
          </Body2>
          <Body2 style={theme.general}>
            { `${t('aboutText4_1')} ` }
            { `${t('aboutText4_2')} ` }
            <HiddenEmail email="tim@savori.app"/>
          </Body2>
          <Body2 style={theme.general}>
            { '\n' }
          </Body2>
          <H1 style={theme.general}>
            { t('attributionsTitle') }
          </H1>
          {
            attributions.map((attribution, idx) => (
              <React.Fragment key={idx}>
                <Body2 onPress={() => attribution.url ? Linking.openURL(attribution.url) : null}>
                  <Body2 style={theme.general}>
                    { `${attribution.name} / ` }
                  </Body2>
                  <Body2 style={theme.general}>
                    { t(attribution.attributionKey) }
                  </Body2>
                </Body2>
              </React.Fragment>
            ))
          }
          <Body2 style={theme.general}>
            { '\n' }
          </Body2>
          <H1 style={theme.general}>
            { t('openSourceTitle') }
          </H1>
          <Body2 style={theme.general}>
            { t('openSourceSubtitle') }
          </Body2>
          {
            open_src_projects.map((project, idx) => (
              <Body2 key={idx} style={theme.general} onPress={() => Linking.openURL(project.url)}>
                {project.name}
              </Body2>
            ))
          }
        </>
      )}
    />
  );
}

export default About;
