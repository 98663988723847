import React, { useState } from 'react';
import { TextInput, Slider, StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { useTranslation } from 'react-i18next';

import Button from '../../common/components/Button';
import common_styles, { textField } from '../../common/components/CommonStyles';
import { Body2, Small, XSmall, useAppWidth } from '../../utils/';
import { useTheme } from '../../utils/Theme';
import {
  APP_TABS,
  ADMIN_SCREENS
} from '../../common/constants/';

const FONT_SIZE_TITLE_MULTIPLICATOR = 0.8;
const FONT_SIZE_TAGLINE_MULTIPLICATOR = 0.5;

const BigTextMaker = () => {
  const [ title, setTitle ] = useState('How to waste less food?');
  const [ text, setText ] = useState("Know what you've got.\nBuy what you need.\nEat what you have.\nRepeat.");
  const [ font_size, setFontSize ] = useState(15);

  const width = useAppWidth();

  const navigation = useNavigation();

  const { t } = useTranslation('common');

  const theme = useTheme();

  return (
    <>
      <View style={common_styles.buttonContainer}>
        <Button
          style={[theme.button, {height: 60}]}
          onPress={() => navigation.navigate(
            APP_TABS.admin.name,
            { screen: ADMIN_SCREENS.labelling.name }
          )}
        >
          <Body2 style={theme.button}>
            {t('admin.labelling')}
          </Body2>
        </Button>
      </View>
      <View style={styles.inputContainer}>
        <TextInput value={title} onChangeText={setTitle} style={[textField, theme.textField]} />
        <TextInput value={text} onChangeText={setText} style={[textField, theme.textField]} multiline={true} />
        <TextInput value={font_size} onChangeText={setFontSize} style={[textField, theme.textField]} keyboardType = 'numeric'/>
      </View>
      <View style={[styles.background, {height: width, width}]}>
        <View/>
        <View style={styles.bigTextContainer}>
          <Body2 style={[theme.general, {fontSize: font_size * FONT_SIZE_TITLE_MULTIPLICATOR}]}>{title}</Body2>
          { text.split('\n').map((line, index) => <Body2 style={[theme.general, {fontSize: Number(font_size)}]} key={index}>{line}</Body2>) }
        </View>
        <View style={styles.logoWithTagLineContainer}>
        <Body2 style={[theme.general, {fontSize: Number(font_size)}]}>
          { t('app.name') }
        </Body2>
        <Body2 style={[theme.general, {fontSize: font_size * FONT_SIZE_TAGLINE_MULTIPLICATOR}]}>
          { t('app.tagline') }
        </Body2>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  background: {
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  bigTextContainer: {
    flexDirection: 'column'
  },
  logoWithTagLineContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
});

export default BigTextMaker;
