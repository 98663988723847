import React, { useEffect } from 'react';

import { useNavigation, useRoute } from '@react-navigation/native';

import {
  USER_SCREENS,
  APP_TABS
} from '../../common/constants/';

const VerifyEMail = ({ verifyEMailRequest }) => {
  const navigation = useNavigation();
  const route = useRoute();

  useEffect(() => {
    if (route.params && route.params.token && route.params.uuid) {
      verifyEMailRequest(route.params.token, route.params.uuid);
    }
    navigation.navigate(
      APP_TABS.profile.name,
      {screen: USER_SCREENS.account.name}
    );
  }, [route]);

  return null;
}

export default VerifyEMail;
