import React from 'react';
import { Linking, View } from 'react-native';

import { BulletListItem, H1 as H1orig, H2Serif as H2orig, H3 as H3orig, Body1, Body2, HiddenEmail } from '../../utils/';
import { useTheme } from '../../utils/Theme';

export const H1 = ({children}) => {
  const theme = useTheme();
  return (
    <H1orig style={theme.general}>
      { children }
    </H1orig>
  );
}
export const H2 = ({children}) => {
  const theme = useTheme();
  return (
    <H2orig style={theme.general}>
      { children }
    </H2orig>
  );
}
export const H3 = ({children}) => {
  const theme = useTheme();
  return (
    <H3orig style={theme.general}>
      { children }
    </H3orig>
  );
}
export const P = ({children}) => {
  const theme = useTheme();
  return (
    <View>
      <Body2 style={theme.general}>
        { children }
        { '\n' }
      </Body2>
    </View>
  );
}
export const Span = ({children}) => {
  const theme = useTheme();
  return (
    <Body2 style={theme.general}>
      { children }
    </Body2>
  );
}
export const Strong = ({children}) => {
  const theme = useTheme();
  return (
    <Body1 style={theme.general}>
      { children }
    </Body1>
  );
}
export const Ul = ({children}) => {
  const theme = useTheme();
  return (
    <View>
      <Body2 style={theme.general}>
        { children }
      </Body2>
    </View>
  )
};
export const Li = ({children}) => {
  const theme = useTheme();
  return (
    <BulletListItem style={theme.general}>
      <Body2 style={theme.general}>
        { children }
      </Body2>
    </BulletListItem>
  );
}
export const A = ({children, href}) => {
  const theme = useTheme();
  return (
    <Body2
      style={theme.general}
      onPress={() => Linking.openURL(href)}
    >
      { children }
    </Body2>
  );
}
