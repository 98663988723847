import React, {
  useEffect,
  useState
} from 'react';
import {
  ImageBackground,
  KeyboardAvoidingView,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import {
  useNavigation
} from '@react-navigation/native';

import IngredientsList from '../../common/components/IngredientsList';
import Ingredient from '../../common/components/Ingredient';
import Masonry from '../../common/components/Masonry';
import RecipeCompact from '../../recipes/components/RecipeCompact';
import common_styles, { padding } from '../../common/components/CommonStyles';

import Bowl from '../../assets/bowl.webp';
import Breakfast from '../../assets/breakfast.webp';
//import Indian from '../../assets/indian.webp';
import LinearGradient from '../../assets/LinearGradient';
import MagnifyingGlass from '../../assets/MagnifyingGlass';
import OnePot from '../../assets/one_pot.webp';
import Pasta from '../../assets/pasta.webp';
import Pizza from '../../assets/pizza.webp';
import Ramen from '../../assets/ramen.webp';
import Salad from '../../assets/healthy.webp';
import Soup from '../../assets/soup.webp';
import Tacos from '../../assets/tacos.webp';
import ZeroWaste from '../../assets/zero_waste.webp';

import { useTheme } from '../../utils/Theme';
import {
  HidingHeaderScrollView,
  SmallUppercase,
  SearchBar,
  Title
} from '../../utils/';

import {
  APP_TABS,
  COLORS,
  MIN_RECIPE_WIDTH,
  MIN_RECIPE_COLS,
  PROFILE_SCREENS,
  MODAL_SCREENS,
  SEARCH_SCREENS
} from '../../common/constants/';

import { Account } from '../../account/components/AccountSearch';

const SearchState = Object.freeze({"ACTIVE": 0, "INACTIVE": 1});

const QuickSearchShortcut = ({
  title_string,
  image,
  onPress
}) => {
  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const theme = useTheme();

  return (
    <TouchableOpacity
      style={[common_styles.card, styles.quickSearchShortcut]}
      onPress={onPress}
    >
      <ImageBackground
        style={{height: '100%'}}
        source={image}
      >
        <View style={{
          ...StyleSheet.absoluteFill
        }}>
          <LinearGradient/>
          <View
            style={{
              ...StyleSheet.absoluteFill,
              backgroundColor: 'black',
              opacity: 0.37
            }}
          />
          <View
            style={{
              ...StyleSheet.absoluteFill,
              padding,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Title
              style={{
                color: COLORS.YELLOW,
                textAlign: 'center'
              }}
              numberOfLines={2}
            >
              { title_string }
            </Title>
          </View>
        </View>

      </ImageBackground>
    </TouchableOpacity>
  );
};

const UniversalSearch = ({
  universal_search_results,
  universalSearch,
  addFollow,
  deleteFollow
}) => {
  const [ search_state, setSearchState ] = useState(SearchState.INACTIVE);
  const [ search_phrase, setSearchPhrase ] = useState('');

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const theme = useTheme();

  const have_ingredients = (universal_search_results.ingredients.length > 0);
  const have_accounts = (universal_search_results.accounts.length > 0);
  const have_recipes = (universal_search_results.recipes.length > 0);
  const have_suggestions = (universal_search_results.suggestions.length > 0);

  useEffect(() => {
    if (search_phrase) {
      setSearchState(SearchState.ACTIVE);
    } else {
      setSearchState(SearchState.INACTIVE);
    }
  }, [search_phrase]);

  let body = null;
  if (search_state === SearchState.ACTIVE) {
    body = (
      <>
        {
          have_ingredients ? (
            <View style={styles.sectionTitle}>
              <SmallUppercase style={[theme.general, styles.sectionTitleLabel]}>{t('search.ingredientsSectionTitle')}</SmallUppercase>
            </View>
          ) : null
        }
        {
          <IngredientsList>
            {
              universal_search_results.ingredients.map((ingredient, index) => (
                <Ingredient
                  key={index}
                  ingredient={ingredient}
                  ingredientAction={() => navigation.navigate(
                    APP_TABS.search.name,
                    {
                      screen: SEARCH_SCREENS.recipeSearch.name,
                      params: {
                        ingredients: [ingredient]
                      }
                    }
                  )}
                />
              ))
            }
          </IngredientsList>
        }
        {
          have_recipes ? (
            <View style={styles.sectionTitle}>
              <SmallUppercase
                style={[theme.general, styles.sectionTitleLabel]}
              >
                { t('search.recipesSectionTitle') }
              </SmallUppercase>
              <TouchableOpacity
                style={[
                  common_styles.smallButton,
                  theme.secondary
                ]}
                onPress={() =>
                  navigation.navigate(
                    APP_TABS.search.name,
                    {
                      screen: SEARCH_SCREENS.recipeSearch.name,
                      params: { search_phrase }
                    }
                  )
                }
              >
                <SmallUppercase>
                  { t('search.moreButton') }
                </SmallUppercase>
              </TouchableOpacity>
            </View>
          ) : null
        }
        {
          universal_search_results.recipes.map((recipe, index) => (
            <RecipeCompact
              key={index}
              recipe={recipe}
              delete_enabled={false}
              onPress={() => navigation.navigate(
                MODAL_SCREENS.recipeDetail.name,
                {
                  uuid: recipe.uuid,
                  nav_origin: { name: SEARCH_SCREENS.universalSearch.name }
                }
              )}
              style={{marginVertical: 0.5 * padding}}
            />
          ))
        }
        {
          have_accounts ? (
            <View style={styles.sectionTitle}>
              <SmallUppercase style={[theme.general, styles.sectionTitleLabel]}>{t('search.accountsSectionTitle')}</SmallUppercase>
              <TouchableOpacity
                style={[
                  common_styles.smallButton,
                  theme.secondary
                ]}
                onPress={() =>
                  navigation.navigate(
                    APP_TABS.profile.name,
                    {
                      screen: PROFILE_SCREENS.search.name,
                      params: { search_phrase }
                    }
                  )
                }
              >
                <SmallUppercase>
                  { t('search.moreButton') }
                </SmallUppercase>
              </TouchableOpacity>
            </View>
          ) : null
        }
        {
          universal_search_results.accounts.map((account, index) => (
            <Account
              key={index}
              {...account}
              addFollow={addFollow}
              deleteFollow={deleteFollow}
            />
          ))
        }
        {/*
          have_suggestions ? (
            <View style={styles.sectionTitle}>
              <SmallUppercase style={[theme.general, styles.sectionTitleLabel]}>{t('search.suggestionsSectionTitle')}</SmallUppercase>
            </View>
          ) : null */
        }
        {/*
          universal_search_results.suggestions.map((suggestion, index) => (
            <Pressable
              key={index}
              style={styles.suggestion}
              onPress={() => console.log('TODO')}
            >
              <View style={common_styles.roundButton}>
                <View style={common_styles.roundButtonIcon}>
                  <MagnifyingGlass color={theme.general.color}/>
                </View>
              </View>
              <Body2 style={theme.general}>
                { suggestion }
              </Body2>
            </Pressable>
          ))*/
        }
      </>
    );
  } else {
    const shortcuts = [
      {
        title_string: t('search.quickSearchShortcuts.saladTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'salad'
            }
          }
        ),
        image: Salad,
      },
      {
        title_string: t('search.quickSearchShortcuts.soupTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'soup'
            }
          }
        ),
        image: Soup,
      },
      {
        title_string: t('search.quickSearchShortcuts.bowlTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'bowl'
            }
          }
        ),
        image: Bowl,
      },
      {
        title_string: t('search.quickSearchShortcuts.onePotTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'one pot'
            }
          }
        ),
        image: OnePot,
      },
      {
        title_string: t('search.quickSearchShortcuts.zeroWasteTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'zero waste'
            }
          }
        ),
        image: ZeroWaste,
      },
      {
        title_string: t('search.quickSearchShortcuts.pizzaTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'pizza'
            }
          }
        ),
        image: Pizza,
      },
      {
        title_string: t('search.quickSearchShortcuts.pastaTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'pasta'
            }
          }
        ),
        image: Pasta,
      },
      {
        title_string: t('search.quickSearchShortcuts.breakfastTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'breakfast'
            }
          }
        ),
        image: Breakfast,
      },
      {
        title_string: t('search.quickSearchShortcuts.ramenTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'ramen'
            }
          }
        ),
        image: Ramen,
      },
      {
        title_string: t('search.quickSearchShortcuts.tacosTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'tacos'
            }
          }
        ),
        image: Tacos,
      },
      /*{
        title_string: t('search.quickSearchShortcuts.indianTitle'),
        onPress: () => navigation.navigate(
          APP_TABS.search.name,
          {
            screen: SEARCH_SCREENS.recipeSearch.name,
            params: {
              search_phrase: 'indian'  // TODO
            }
          }
        ),
        image: Indian,
      }*/
    ];

    body = (
      <Masonry
        min_width={MIN_RECIPE_WIDTH}
        min_num_cols={MIN_RECIPE_COLS}
      >
        {
          shortcuts.map((shortcut, index) => (
            <QuickSearchShortcut
              key={index}
              {...shortcut}
            />
          ))
        }
      </Masonry>
    );
  }

  return (
    <HidingHeaderScrollView
      header={
        <View style={{padding}}>
          <SearchBar
            keyboardShouldPersistTaps='handled'
            search_phrase={search_phrase}
            setSearchPhrase={setSearchPhrase}
            doSearch={search_phrase => universalSearch(search_phrase)}
            placeholder={t('search.universalSearchPlaceholder')}
            contentContainerStyle={{padding}}
          />
        </View>
      }
      body={
        <View style={{ padding }}>
          { body }
        </View>
      }
    />
  );
};

const styles = StyleSheet.create({
  sectionTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: padding
  },
  sectionTitleLabel: {
  },
  suggestion: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  quickSearchShortcut: {
    overflow: 'hidden',
    height: 190,
    padding: 0,
    margin: 0,
    marginVertical: 0.5 * padding
  }
})

export default UniversalSearch;
