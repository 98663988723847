import React, {
  forwardRef,
  useEffect,
  useState
} from 'react';
import {
  Animated,
  Keyboard,
  KeyboardAvoidingView,
  Linking,
  Platform,
  RefreshControl,
  ScrollView,
  Share,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  useColorScheme,
  useWindowDimensions,
  View
} from 'react-native';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next'; 

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import Clipboard from '@react-native-clipboard/clipboard';

import { track } from '@amplitude/analytics-react-native';

import Burger from '../assets/Filter';
import Cross from '../assets/Cross';
import MagnifyingGlass from '../assets/MagnifyingGlass';
import Plus from '../assets/Plus';
import { ArrowLeft } from '../assets/Arrow';
import common_styles, {
  fontFamily,
  padding,
  roundedBorder,
  textField,
  text_styles
} from '../common/components/CommonStyles';
import Button from '../common/components/Button';
import {
  APP_TABS,
  COLORS,
  HOUSEHOLD_SCREENS,
  MAX_APP_WIDTH,
  USER_PATH,
  ROOT_SCREENS
} from '../common/constants/';
import {
  BASE_URL
} from '../common/constants/urls';

import { useTheme } from './Theme';
import { BlurView } from './BlurView';

export const Title = (props) => (<Text {...props} style={[text_styles.title, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const TitleHandwritten = (props) => (<Text {...props} style={[text_styles.title, text_styles.handWritten, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const H1 = (props) => (<Text {...props} style={[text_styles.h1, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const H2Sans = (props) => (<Text {...props} style={[text_styles.h2Sans, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const H2Serif = (props) => (<Text {...props} style={[text_styles.h2Serif, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const H3 = (props) => (<Text {...props} style={[text_styles.h3, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const Body1 = (props) => (<Text {...props} style={[text_styles.body1, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const Body2 = (props) => (<Text {...props} style={[text_styles.body2, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const SmallUppercase = (props) => (<Text {...props} style={[text_styles.smallUppercase, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const XSmall = (props) => (<Text {...props} style={[text_styles.xSmall, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const Small = (props) => (<Text {...props} style={[text_styles.small, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)
export const ButtonText = (props) => (<Text {...props} style={[text_styles.button, props.style, text_styles.noBackgroundColor]}>{ props.children }</Text>)

/*
export const StrikeThrough = (props) => {
  const [ width, setWidth ] = useState(0);
  const [ height, setHeight ] = useState(0);

  console.log('width, height', width, height)

  return (
    <H1
      style={{flexDirection: 'row', alignItems: 'baseline', backgroundColor: 'yellow', alignSelf: 'baseline'}}
      onLayout={({nativeEvent}) => {
        // onLayout is only called if this is not a nested Text node! Otherwise, need to go through onTextLayout in top level Text node.
        console.log('onlayout', nativeEvent)
        setWidth(nativeEvent.layout.width);
        setHeight(nativeEvent.layout.height);
      }}
      onTextLayout={(event) => {
        console.log('onTextlayout', event)
        //setWidth(nativeEvent.layout.width);
        //setHeight(nativeEvent.layout.height);
      }}
    >
      <H1 {...props}>
        {props.children}
      </H1>
      <View style={{...StyleSheet.absoluteFill, height, width}}>
        <Strike01 {...props}/>
      </View>
    </H1>
  );
}
*/

export const jsonEqual = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};


// Durstenfeld shuffle
// From https://stackoverflow.com/a/12646864 (otim: added 'return array')
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const truncateString = (input_str, max_num_chars) => {
  return (input_str.length > max_num_chars) ? input_str.substring(0, max_num_chars-1) + '...' : input_str;
}

export const getEmojisFromRecipe = (recipe) => {
  let emojis = [];
  if (!recipe) {
    return emojis;
  } else if (recipe && recipe.ingredients_with_properties) {
    emojis = recipe.ingredients_with_properties.filter(
      ing => (ing.ingredient && ing.ingredient.emoji)
    ).map(
      ing => ing.ingredient.emoji
    );
  } else {
    console.log('getEmojiFromRecipe: recipe does not have ingredients');
  }
  if (emojis.length === 0) {
    emojis.push('😋');
  }
  return emojis;
}

export const HiddenEmail = ({email, style}) => {
  const [ revealed, setRevealed ] = useState(false);

  const { t } = useTranslation('common');

  if (revealed) {
    const url = `mailto:${email}`;
    return (
      <Body2 style={style} onPress={() => {
        Linking.canOpenURL(url).then(supported => {
          if (!supported) {
            Linking.openURL(url).catch(err => {
              console.error(err);
            })
          } else {
            console.error('unsupported url', url);
          }}).catch(err => {
            console.error('cannot open url. error: ', err);
          })
        }
      }>
        { email }
      </Body2>
    );
  } else {
    return (
      <Body1 style={style} onPress={() => setRevealed(true)}>{t('common.clickToReveal')}</Body1>
    );
  }
}

export const BulletListItem = (props) => {
  return (
    <View style={ bullet_styles.row }>
      <View style={ bullet_styles.bullet }>
        <Text style={props.style}>{'\u2022' + " "}</Text>
      </View>
      <View style={ bullet_styles.bulletText }>
        <Text>
          { props.children }
        </Text>
      </View>
    </View>
  );
}
const bullet_styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  bullet: {
    flexBasis: 10,
    flexGrow: 0,
    flexShrink: 0
  },
  bulletText: {
    flexGrow: 1,
    flexShrink: 1
  }
});

export const Alert = ({ text }) => (
  <View style={alert_styles.alert}>
    <Body2 style={alert_styles.alertText}>
      { text }
    </Body2>
  </View>
);

const alert_styles = StyleSheet.create({
  alert: {
    ...roundedBorder,
    backgroundColor: '#fdf2f5',
    borderColor: '#dd0000',
    borderWidth: 1.5
  },
  alertText: {
    color: '#dd0000'
  }
});

export const Br = () => (
  <Small>
    { '\n' }
  </Small>
);
export const VSkipTiny = () => (
  <View style={{height: 3}}/>
);
export const VSkipSmall = () => (
  <View style={{height: 7}}/>
);
export const VSkipMedium = () => (
  <View style={{height: 10}}/>
);
export const VSkipLarge = () => (
  <View style={{height: 15}}/>
);
export const VSkipHuge = () => (
  <View style={{height: 25}}/>
);

export const RoundButtonBase = (props) => {
  const theme = useTheme();

  return (
    <TouchableOpacity
      {...props}
      style={[
        common_styles.roundButton,
        theme.general,
        props.style
      ]}
      onPress={props.onPress}
    >
      { props.children }
    </TouchableOpacity>
  );
};

export const RoundButton = (props) => {
  const theme = useTheme();

  return (
    <RoundButtonBase {...props}>
      <View style={common_styles.roundButtonIcon}>
        { props.children }
      </View>
    </RoundButtonBase>
  );
};

export const CloseButton = (props) => {
  const theme = useTheme();

  return (
    <RoundButton {...props}>
      <Cross color={theme.general.color}/>
    </RoundButton>
  );
};

export const BackButton = (props) => {
  const theme = useTheme();

  return (
    <RoundButton {...props}>
      <ArrowLeft color={theme.general.color}/>
    </RoundButton>
  );
};

export const BurgerButton = (props) => {
  const theme = useTheme();

  return (
    <RoundButton {...props}>
      <Burger color={theme.general.color}/>
    </RoundButton>
  );
};

export const SquareButton = (props) => {
  const theme = useTheme();

  return (
    <TouchableOpacity
      {...props}
      style={[
        common_styles.squareButton,
        { borderColor: theme.general.color },
        props.style
      ]}
      onPress={props.onPress}
    >
      <View style={common_styles.roundButtonIcon}>
        { props.children }
      </View>
    </TouchableOpacity>
  );
};

export const PlusButton = (props) => {
  const theme = useTheme();

  return (
    <SquareButton {...props}>
      <Plus color={theme.general.color}/>
    </SquareButton>
  );
};

export const FollowInviteButton = ({username, ...props}) => {
  const { t } = useTranslation(['common', 'recipes']);

  const url = `${BASE_URL}/${ROOT_SCREENS.app.path}/${USER_PATH}/${username}/`;
  const title = t('feed.inviteTextTitle');
  const message = t('feed.inviteText', {link: url});
  const share_content = {
    title,
    url,
    message
  };
  const share_options = {
    dialogTitle: title,
    subject: title,
    //tintColor: theme.general.color
  };

  return (
    <Button
      {...props}
      onPress={
        Platform.select({
          web: () => {
            track('copy_invite_link', {
              category: 'follow_invite'
            });
            Clipboard.setString(message);
          },
          default: async () => {
            track('invite_share_started', {
              category: 'follow_invite'
            });
            const result = await Share.share(share_content, share_options);
            if (result.action === Share.sharedAction) {
              console.log('shared with activityType', result.activityType);
              track('invite_share_completed', {
                category: 'follow_invite'
              });
            } else if (result.action === Share.dismissedAction) {
              console.log('share dismissed', result);
              track('invite_share_aborted', {
                category: 'follow_invite'
              });
            }
          }
        })
      }
    >
      <ButtonText {...props}>
        {
          Platform.select({
            web: t('feed.copyInviteLink'),
            default: t('feed.shareInviteLink')
          })
        }
      </ButtonText>
    </Button>
  );
};

export const HouseholdInviteButton = ({household_uuid, ...props}) => {
  const { t } = useTranslation(['common', 'recipes']);

  const url = `${BASE_URL}/${ROOT_SCREENS.app.path}/${APP_TABS.household.path}/${HOUSEHOLD_SCREENS.householdJoin.path}?uuid=${household_uuid}`;
  const title = t('householdInvite.inviteTextTitle');
  const message = t('householdInvite.inviteText', {link: url});
  const share_content = {
    title,
    url,
    message
  };
  const share_options = {
    dialogTitle: title,
    subject: title,
    //tintColor: theme.general.color
  };

  return (
    <Button
      {...props}
      onPress={
        Platform.select({
          web: () => {
            track('copy_invite_link', {
              category: 'household_invite'
            });
            Clipboard.setString(message)
          },
          default: async () => {
            track('invite_share_started', {
              category: 'household_invite'
            });
            const result = await Share.share(share_content, share_options);
            if (result.action === Share.sharedAction) {
              console.log('shared with activityType', result.activityType);
              track('invite_share_completed', {
                category: 'household_invite'
              });
            } else if (result.action === Share.dismissedAction) {
              console.log('share dismissed', result);
              track('invite_share_aborted', {
                category: 'household_invite'
              });
            }
          }
        })
      }
    >
      <ButtonText {...props}>
        {
          Platform.select({
            web: t('householdInvite.copyInviteLink'),
            default: t('householdInvite.shareInviteLink')
          })
        }
      </ButtonText>
    </Button>
  );
};

export const SearchBar = forwardRef(({
  search_phrase,
  setSearchPhrase,
  doSearch,
  ...props
}, ref) => {
  const theme = useTheme();

  return (
    <View
      style={[
        theme.textField,
        common_styles.searchBar,
        props.style
      ]}
    >
      <View
        style={[
          common_styles.inlineIcon,
          { marginRight: padding }
        ]}
      >
        <MagnifyingGlass color={theme.general.color}/>
      </View>
      <TextInput
        placeholderTextColor={theme.textFieldPlaceholder.color}
        value={search_phrase}
        onChangeText={(text) => {
          setSearchPhrase(text);
          if (doSearch) doSearch(text);
        }}
        {...props}
        style={[
          theme.general,
          theme.textField,
          {
            flex: 1,
            padding: 0
          },
          props.style
        ]}
        ref={ref}
      />
      <TouchableOpacity
        style={[
          common_styles.inlineIcon,
          { marginLeft: padding }
        ]}
        onPress={() => {
          setSearchPhrase('')
          if (doSearch) doSearch('');
        }}
      >
        <Cross color={theme.general.color}/>
      </TouchableOpacity>
    </View>
  );
})

export const PageIndicator = ({
  pages,
  active_index,
  color,
  ...props
}) => {
  const theme = useTheme();

  return (
    <View style={{flexDirection: 'row'}}>
      {
        pages.map((page, index) => (
          <View
            key={index}
            style={[
              page_indicator_styles.dot,
              { backgroundColor: color },
              (index === active_index)
                ? page_indicator_styles.active
                : page_indicator_styles.inactive
            ]}
          />
        ))
      }
    </View>
  );
};

const DOT_DIAMETER = 6;
export const PAGE_INDICATOR_HEIGHT = 2 * DOT_DIAMETER;
const page_indicator_styles = StyleSheet.create({
  dot: {
    margin: 0.5 * DOT_DIAMETER,
    borderRadius: 0.5 * DOT_DIAMETER,
    width: DOT_DIAMETER,
    height: DOT_DIAMETER
  },
  inactive: {
    opacity: 0.37
  },
  active: {
    width: 3 * DOT_DIAMETER,
  }
})

export const BottomStickyView = ({children, ...props}) => {
  const tab_bar_height = useBottomTabBarHeight();

  return (
    <View
      style={[
        bottom_sticky_view_styles.container,
        {
          bottom: useIsKeyboardVisible() ? 0 : tab_bar_height
        },
        props.style
      ]}
      {...props}
    >
     { children }
    </View>
  );
}

const bottom_sticky_view_styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
});


export const HidingHeaderScrollView = forwardRef(
(
  {
    header,
    body,
    onRefresh,
    contentContainerStyle,
    ...props
  },
  ref
) => {
  const [ refreshing, setRefreshing ] = useState(false);
  const [ header_height, setHeaderHeight ] = useState(0);

  const theme = useTheme();
  const scheme = useColorScheme();

  const { top, bottom } = useSafeAreaInsets();
  let bottom_tab_bar_height = 0;
  try {
    bottom_tab_bar_height = useBottomTabBarHeight();
  } catch (error) {
    bottom_tab_bar_height = bottom;
  }

  const minScroll = 100;

  const scrollY = new Animated.Value(0);

  const clampedScrollY = scrollY.interpolate({
    inputRange: [minScroll, minScroll + 1],
    outputRange: [0, 1],
    extrapolateLeft: 'clamp',
  });

  const minusScrollY = Animated.multiply(clampedScrollY, -1);

  const translateY = Animated.diffClamp(
    minusScrollY,
    -header_height,
    0,
  );

  const opacity = translateY.interpolate({
    inputRange: [-header_height, 0],
    outputRange: [0.0, 1],
    extrapolate: 'clamp',
  });

  const onScroll = (e) => {
    scrollY.setValue(e.nativeEvent.contentOffset.y);
  };

  return (
    <KeyboardAvoidingView
      behavior='height'
      style={StyleSheet.absoluteFill}
    >
      <Animated.View
        style={{
          transform: [{translateY: translateY}],
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          elevation: 4,
          zIndex: 1,
          overflow: 'hidden'
        }}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setHeaderHeight(height - top);
        }}
      >
        <BlurView
          style={StyleSheet.absoluteFill}
          reducedTransparencyFallbackColor={theme.general.backgroundColor}
          overlayColor=""
          blurType={scheme}
          blurAmount={20}
        />
        <Animated.View
          style={[
            {
              ...theme.general,
              opacity,
              paddingTop: top,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: COLORS.LIGHT_GRAY,

            },
            common_styles.borderBottom
          ]}
        >
          { header }
        </Animated.View>
      </Animated.View>
      <ScrollView
        ref={ref}
        style={StyleSheet.absoluteFill}
        onScroll={onScroll}
        scrollEventThrottle={16}
        contentContainerStyle={[
          {
            paddingTop: padding + top + header_height,
            paddingBottom: padding + (useIsKeyboardVisible() ? 0 : bottom_tab_bar_height)
          },
          contentContainerStyle
        ]}
        refreshControl={
          onRefresh ? (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                onRefresh(() => setRefreshing(false));
              }}
              tintColor={theme.general.color}
              progressViewOffset={top + header_height}
            />
          ) : null
        }
        {...props}
      >
        { body }
      </ScrollView>
    </KeyboardAvoidingView>
  );
});


export const useAppWidth = () => {
  let { width } = useWindowDimensions();

  width = Math.min(width, MAX_APP_WIDTH);

  return width;
}

export const useAppDimensions = () => {
  let { height, width } = useWindowDimensions();

  width = Math.min(width, MAX_APP_WIDTH);

  const { top } = useSafeAreaInsets();

  try {
    height = height - top - useBottomTabBarHeight();
  } catch (error) {
    //console.error('error in useAppDimensions. error:', error);
    height = height - top;
  }

  return { height, width };
}


export const useIsKeyboardVisible = () => {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardVisible(true);
      },
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false);
      },
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  return isKeyboardVisible;
};


export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const relativeTime = (datetime) => {
  const { t } = useTranslation(['common']);

  const current = new Date();

  const ms_per_minute = 60 * 1000;
  const ms_per_hour = ms_per_minute * 60;
  const ms_per_day = ms_per_hour * 24;
  const ms_per_week = ms_per_day * 7;
  const ms_per_month = ms_per_day * 30;
  const ms_per_year = ms_per_day * 365;

  const elapsed = current - new Date(datetime);

  if (elapsed < 0) {
    console.error('Encountered negative relative time! Returning empty string');
    return '';
  } else if (elapsed < ms_per_minute) {
    return t('relativeTime.justNow');
  } else if (elapsed < ms_per_hour) {
    const minutes = Math.round(elapsed/ms_per_minute);
    return t('relativeTime.minutesAgo', {count: minutes});
  } else if (elapsed < ms_per_day ) {
    const hours = Math.round(elapsed/ms_per_hour);
    return t('relativeTime.hoursAgo', {count: hours});
  } else if (elapsed < ms_per_week ) {
    const days = Math.round(elapsed/ms_per_day);
    return t('relativeTime.daysAgo', {count: days});
  } else if (elapsed < ms_per_month ) {
    const weeks = Math.round(elapsed/ms_per_week);
    return t('relativeTime.weeksAgo', {count: weeks});
  } else if (elapsed < ms_per_year ) {
    const months = Math.round(elapsed/ms_per_month);
    return  t('relativeTime.monthsAgo', {count: months});
  } else {
    const years = Math.round(elapsed/ms_per_year);
    return t('relativeTime.yearsAgo', {count: years});
  }
}

export const parseDuration = (
  duration_string,
  i18n_prefix = ''
) => {
  const { t } = useTranslation(['common']);

  // TODO '[DD] [HH:[MM:]]ss[.uuuuuu]'
  const duration = parseFloat(duration_string);

  const s_per_second = 1;
  const s_per_minute = 60;
  const s_per_hour = s_per_minute * 60;
  const s_per_day = s_per_hour * 24;
  const s_per_week = s_per_day * 7;
  const s_per_month = s_per_day * 30;
  const s_per_year = s_per_day * 365;

  if (!duration || (duration < 0)) {
    return t(i18n_prefix ? `${i18n_prefix}.duration.unknown` : 'duration.unknown');
  } else if (duration > s_per_year) {
    const years = Math.round(duration/s_per_year);
    return t(i18n_prefix ? `${i18n_prefix}.duration.years` : 'duration.years', {count: years});
  } else if (duration > s_per_month) {
    const months = Math.round(duration/s_per_month);
    return t(i18n_prefix ? `${i18n_prefix}.duration.months` : 'duration.months', {count: months});
  } else if (duration > s_per_day) {
    const days = Math.round(duration/s_per_day);
    return t(i18n_prefix ? `${i18n_prefix}.duration.days` : 'duration.days', {count: days});
  } else if (duration > s_per_hour) {
    const hours = Math.round(duration/s_per_hour);
    return t(i18n_prefix ? `${i18n_prefix}.duration.hours` : 'duration.hours', {count: hours});
  } else if (duration > s_per_minute) {
    const minutes = Math.round(duration/s_per_minute);
    return t(i18n_prefix ? `${i18n_prefix}.duration.minutes` : 'duration.minutes', {count: minutes});
  } else if (duration > s_per_second) {
    const seconds = Math.round(duration/s_per_second);
    return t(i18n_prefix ? `${i18n_prefix}.duration.seconds` : 'duration.seconds', {count: seconds});
  }
}
