import { connect } from 'react-redux';

import { resetPasswordRequest, setPasswordRequest } from '../../actions';
import ResetPassword from '../components/ResetPassword';

const mapStateToProps = state => ({
  auth_state: state.auth_state
})

const mapDispatchToProps = {
  resetPasswordRequest,
  setPasswordRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)
