import React from 'react';

import Svg, { G, Path } from "react-native-svg";

const PaperPlane = ({color, stroke_width = 22}) => (
  <Svg width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <G id="Layer5" transform="matrix(1,0,0,1,-2.85634,0.24197)">
        <Path d="M61.818,40.517C46.881,40.657 34.799,52.826 34.799,67.795L34.799,157.891C34.799,172.947 47.022,185.171 62.079,185.171L116.638,185.171C131.322,185.171 143.312,173.543 143.895,158.999L143.634,159.001L89.075,159.001C74.019,159.001 61.795,146.777 61.795,131.721L61.795,41.625C61.795,41.253 61.803,40.883 61.818,40.517Z" style={{fill: 'none', stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}/>
        <G transform="matrix(0.717912,0,0,1.10092,43.3518,-46.1923)">
            <Path d="M177.685,79.768C177.685,66.092 160.658,54.989 139.686,54.989L63.689,54.989C42.717,54.989 25.69,66.092 25.69,79.768L25.69,161.605C25.69,175.281 42.717,186.384 63.689,186.384L139.686,186.384C160.658,186.384 177.685,175.281 177.685,161.605L177.685,79.768Z" style={{fill: 'none', stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}/>
        </G>
    </G>
  </Svg>
);

export default PaperPlane;
