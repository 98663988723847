import React from 'react';
import { AppRegistry } from "react-native";

import './i18n';
import './index.css';

import AppRoot from './app/components/AppRoot';

const appName = "savori";

AppRegistry.registerComponent(appName, () => AppRoot);
AppRegistry.runApplication(appName, {
  // Mount the react-native app in the "root" div of index.html
  rootTag: document.getElementById('root'),
});
