import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import Brand from '../../assets/Brand';
import {
  BackButton,
  Body2,
  BurgerButton,
  CloseButton,
  H2Sans,
  Title as TitleFont,
  PlusButton,
  useAppWidth
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import common_styles, { padding } from '../../common/components/CommonStyles';

import { COLORS, HEADER_HEIGHT, ROOT_SCREENS } from '../../common/constants/';

const HeaderIcon = ({ image }) => (
  <View style={styles.authButtonIcon}>
    { image }
  </View>
)

const Header = () => {
  const width = useAppWidth();

  const navigation = useNavigation();

  const theme = useTheme();

  return (
    <View style={[theme.general, styles.header]}>
      <TouchableOpacity
        style={styles.headerBrand}
        onPress={() => {
          navigation.navigate(ROOT_SCREENS.app.name);  // TODO navigate to APP_TABS.home.name
        }}
      >
        <Brand color={theme.general.color}/>
      </TouchableOpacity>
    </View>
  );
}

export const TitleBase = ({title_string, children, ...props}) => {
  const theme = useTheme();

  return (
    <View {...props} style={[styles.title, props.style]}>
      {
        children[0]
      }

      <View style={{flex: 1}}>
        <TitleFont style={theme.general} numberOfLines={1}>
          { title_string }
        </TitleFont>
      </View>

      {
        children.slice(1).map(child => child)
      }
    </View>
  );
}

export const TitleWClose = ({title_string, close, ...props}) => {
  const theme = useTheme();

  return (
    <View {...props} style={[styles.title, props.style]}>
      <View style={{flex: 1}}>
        <TitleFont style={theme.general} numberOfLines={1}>
          { title_string }
        </TitleFont>
      </View>

      <CloseButton
        style={theme.secondary}
        onPress={close}
      />
    </View>
  );
}

export const Title = ({goBack, ...props}) => {
  return (
    <TitleBase {...props}>
      <BackButton
        onPress={goBack}
      />
      <BackButton style={{opacity: 0}} disabled/>
    </TitleBase>
  );
}

export const TitleWBurger = ({goBack, onPressBurger, ...props}) => {
  return (
    <TitleBase {...props}>
      <BackButton
        onPress={goBack}
      />
      <BurgerButton onPress={onPressBurger} />
    </TitleBase>
  );
}
export const TitleWPlus = ({goBack, onPressPlus, ...props}) => {
  return (
    <TitleBase {...props}>
      <BackButton
        onPress={goBack}
      />
      <PlusButton
        onPress={onPressPlus}
        style={{margin: padding}}
      />
    </TitleBase>
  );
}
export const TitleWAction = ({action_string, goBack, goForward, action_disabled, ...props}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <TitleBase {...props}>
      <BackButton onPress={goBack} />
      <TouchableOpacity onPress={goForward} disabled={action_disabled} style={{padding}}>
        <H2Sans style={[theme.general, action_disabled ? {opacity: 0.4} : null]}>{ action_string }</H2Sans>
      </TouchableOpacity>
    </TitleBase>
  );
}

const styles = StyleSheet.create({
  header: {
    height: HEADER_HEIGHT,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerBrand: {
    flex: 1,
    alignItems: 'center'
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

export default Header;
