import React from 'react';
import { Pressable, Text, View } from 'react-native';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import styles from '../../common/components/IngredientStyles';
import common_styles from '../../common/components/CommonStyles';

import Tile from './Tile';
import { BigLetter, BigEmoji } from './misc';
import { XSmall } from '../../utils/';
import { useTheme } from '../../utils/Theme';
import { INGREDIENT_MAX_LENGTH } from '../../common/constants/';

import Checkmark from '../../assets/Checkmark';

export const ING_STATE = Object.freeze({
  "ACTIVE": 0,
  "UNKNOWN": 1,
  "INACTIVE": 2
});

//  active, id, emoji=null, amount=null, amount_max=null, amount_str=null, unit=null, variant=null, substitute=null
const IngredientBase = ({
  ingredient,
  state,
  ingredientAction,
  locked,
  selectable = false,
  selected
}) => {

  const { t } = useTranslation('recipes')

  const theme = useTheme();

  const unmatched = (!ingredient.id);

  let icon = (<BigLetter style={theme.cardBody} name={ingredient.name}/>);
  if (ingredient.emoji) {
    icon = (<BigEmoji emoji={ingredient.emoji} label={ingredient.name}/>);
  }

  let subtitle = '';
  if (ingredient.variant) {
    subtitle = t('useVariant') + ' ' + ingredient.variant.name;
  } else if (ingredient.substitute) {
    subtitle = t('substituteWith') + ' ' + ingredient.substitute.name;
  }

  let quantity = '';
  if (ingredient.amount_str) {
    quantity = `${ingredient.amount_str}`;
  }
  if (ingredient.unit) {
    if (ingredient.amount_max) {
      quantity = quantity + ' ' + t(`units.${ingredient.unit}`, {count: ingredient.amount_max});
    } else if (ingredient.amount) {
      quantity = quantity + ' ' + t(`units.${ingredient.unit}`, {count: ingredient.amount});
    }
  }

  let unselected_symbol = '';
  if (locked) {
    unselected_symbol = '🔒';
  }
  if (unmatched) {
    unselected_symbol = '❔';
  }
  let status_badge = (
    <View style={styles.dot}>
      <Text>
        { unselected_symbol }
      </Text>
    </View>
  );
  if (selectable && !selected) {
    status_badge = (
      <View style={[styles.dot, styles.dottedOutline]}>
        <Text>
          { unselected_symbol }
        </Text>
      </View>
    );
  } else if (selectable && selected) {
    status_badge = (
      <View style={[styles.dot, styles.greenBg]}>
        <Checkmark color={theme.activeIngredient.color}/>
      </View>
    );
  }

  return (
    <Pressable
      style={[
        styles.ingredientBase,
        (selectable && selected) ? theme.cardSelected : theme.card
      ]}
      onPress={() => {
        ingredientAction(ingredient)
      }}
    >
        <Tile>
          { status_badge }
          <View style={styles.header}>
            { icon }
          </View>
          <View style={styles.ingredientBody}>
            <XSmall style={[theme.cardBody, styles.ingredientText]} numberOfLines={1}>
              { quantity }
            </XSmall>
            <XSmall style={[theme.cardBody, styles.ingredientText, {opacity: 1.0}]} numberOfLines={2}>
              { ingredient.name }
            </XSmall>
            <XSmall style={[theme.cardBody, styles.ingredientText]} numberOfLines={1}>
              { subtitle }
            </XSmall>
          </View>
        </Tile>
    </Pressable>
  );
}

export const ActiveIngredient = ({ ingredient, deleteIngredient, locked = false }) => (
  <IngredientBase
    state={ING_STATE.ACTIVE}
    locked={locked}
    ingredient={ingredient}
    ingredientAction={deleteIngredient}
  />
)

export const UnknownIngredient = ({ ingredient, addIngredient, locked = false }) => (
  <IngredientBase
    state={ING_STATE.UNKNOWN}
    locked={locked}
    ingredient={ingredient}
    ingredientAction={addIngredient}
  />
)

export const InactiveIngredient = ({ ingredient, addIngredient, locked = false }) => (
  <IngredientBase
    state={ING_STATE.INACTIVE}
    locked={locked}
    ingredient={ingredient}
    ingredientAction={addIngredient}
  />
);

export default IngredientBase;
