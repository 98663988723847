import React, {
  useEffect,
  useState
} from 'react';
import {
  Image,
  ImageBackground,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import {
  useNavigation,
  useRoute
} from '@react-navigation/native';

import { TitleWBurger } from '../../header/components/Header';
import Button from '../../common/components/Button';
import common_styles, { padding } from '../../common/components/CommonStyles';

import { useTheme } from '../../utils/Theme';
import {
  Body1,
  Body2,
  ButtonText,
  CloseButton,
  FollowInviteButton,
  H1,
  H3,
  parseDuration,
  Small,
  Title,
  VSkipLarge,
  XSmall,
} from '../../utils/';

import {
  CHALLENGES_SCREENS,
  COLORS
} from '../../common/constants/';
import {
  MEDIA_PREFIX
} from '../../common/constants/urls';

const ChallengeDetail = ({
  challengeLoad,
  challengeEnroll,
  challengeLeave
}) => {
  const [ signup_button_enabled, setSignupButtonEnabled ] = useState(true);
  const [ uuid_state, setUuid ] = useState('');
  const [ title_string_state, setTitleString ] = useState('');
  const [ images_state, setImages ] = useState([]);
  const [ description_state, setDescription ] = useState(null);
  const [ duration_state, setDuration ] = useState(null);
  const [ period_state, setPeriod ] = useState(null);
  const [ is_open_end_state, setIsOpenEnd ] = useState(false);
  const [ is_subscribed_state, setIsSubscribed ] = useState(false);
  const [ has_completed_state, setHasCompleted ] = useState(false);

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const route = useRoute();

  const theme = useTheme();

  useEffect(() => {
    if (route.params && route.params.uuid) {
      challengeLoad(
        route.params.uuid,
        ({
          uuid,
          title,
          images,
          description,
          duration,
          period,
          is_open_end,
          has_completed,
          is_subscribed,
          ...other
        }) => {
          setUuid(uuid);
          setTitleString(title);
          setImages(images);
          setDescription(description);
          setDuration(duration);
          setPeriod(period);
          setIsOpenEnd(is_open_end);
          setIsSubscribed(is_subscribed);
          setHasCompleted(has_completed);
        }
      );
    }
  }, [route.params]);

  const enroll_button = (
    <Button
      style={theme.button}
      onPress={() => {
        setSignupButtonEnabled(false);
        challengeEnroll(
          uuid_state,
          /*success_callback*/() => setIsSubscribed(true),
          /*failure_callback*/() => {},
          /*final_callback*/() => setSignupButtonEnabled(true)
        );
      }}
      disabled={!signup_button_enabled}
    >
      <Body1 style={theme.button}>
        { t('challenges.signUp') }
      </Body1>
    </Button>
  );
  const leave_button = (
    <Button
      style={theme.button}
      onPress={() => {
        setSignupButtonEnabled(false);
        challengeLeave(
          uuid_state,
          /*success_callback*/() => setIsSubscribed(false),
          /*failure_callback*/() => {},
          /*final_callback*/() => setSignupButtonEnabled(true)
        );
      }}
      disabled={!signup_button_enabled}
    >
      <Body1 style={theme.button}>
        { t('challenges.signOut') }
      </Body1>
    </Button>
  );

  const button = (
    <View style={[common_styles.buttonContainer, {padding}]}>
      {
        is_subscribed_state ? leave_button : enroll_button
      }
    </View>
  );

  return (
    <>
      <ImageBackground
        source={images_state.length ? {uri: `data:image/png;base64,${images_state[0].image_placeholder}`} : null}
        style={styles.header}
      >
        <View
          style={{
            ...StyleSheet.absoluteFill,
            backgroundColor: 'black',
            opacity: 0.37
          }}
        />
      </ImageBackground>
      <ScrollView
        style={StyleSheet.absoluteFill}
        contentContainerStyle={theme.general}
      >
        <ImageBackground
          defaultSource={images_state.length ? {uri: `data:image/png;base64,${images_state[0].image_placeholder}`} : null}
          source={images_state.length ? {uri: `${MEDIA_PREFIX}${images_state[0].image}`} : null}
          style={styles.header}
        >
          <View
            style={{
              ...StyleSheet.absoluteFill,
              backgroundColor: 'black',
              opacity: 0.37
            }}
          />
          <CloseButton
            onPress={() => {
              navigation.goBack()
            }}
            style={{position: 'absolute', top: 15, right: 15}}
          />
          <Title style={[
            theme.general,
              {
                color: COLORS.YELLOW,
                backgroundColor: null
              }
            ]}
          >
            { title_string_state }
          </Title>
        </ImageBackground>
        <View style={styles.body}>
          <XSmall style={theme.general}>
            { t('challenges.durationLabel') }
          </XSmall>
          <Body2 style={theme.general}>
            {
              (!is_open_end_state && duration_state) ?
                parseDuration(duration_state) :
                t('challenges.durationOpenEnd', { // This is just to silence a warning about different order of hooks during rendering.
                  duration: parseDuration(duration_state)
                })
            }
          </Body2>
          <VSkipLarge/>
          <XSmall style={theme.general}>
            { t('challenges.descriptionLabel') }
          </XSmall>
          <Body2 style={theme.general}>
            { description_state }
          </Body2>
          <VSkipLarge/>
          <XSmall style={theme.general}>
            {
              (!is_open_end_state && duration_state) ?
                t('challenges.smallPrintWDuration', {
                  period: parseDuration(period_state),
                  duration: parseDuration(duration_state)
                }) :
                t('challenges.smallPrintOpenEnd', { // This is just to silence a warning about different order of hooks during rendering.
                  period: parseDuration(period_state),
                  duration: parseDuration(duration_state)
                })
            }
          </XSmall>
        </View>
        { button }
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    height: 300,
    alignItems: 'center',
    justifyContent: 'center'
  },
  body: {
    padding
  }
})

export default ChallengeDetail;
