import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';

import {
  useNavigation,
  useRoute
} from '@react-navigation/native';

import Button from '../../common/components/Button';
import IngredientsList from '../../common/components/IngredientsList';
// import { IngredientPicker } from '../../recipes/components/RecipeEditorBase';  // TODO move ingredient to dedicated file. onPick to dismiss picker.
import DietPickerContainer from '../../common/containers/DietPickerContainer';
import SplitView, { HANDLE_CONTAINER_HEIGHT } from '../../common/components/SplitView';

import { TitleBase } from '../../header/components/Header';

import Recipes from '../../recipes/containers/RecipesContainer';

import Cross from '../../assets/Cross';
import Filter from '../../assets/Filter';
import Plus from '../../assets/Plus';

import common_styles, {
  borderRadius,
  castShadow,
  padding
} from '../../common/components/CommonStyles';
import { BlurView } from '../../utils/BlurView';
import { useTheme } from '../../utils/Theme';
import {
  arraysEqual,
  BackButton,
  Body2,
  ButtonText,
  H1,
  HidingHeaderScrollView,
  RoundButton,
  RoundButtonBase,
  SearchBar,
  Small,
  Title
} from '../../utils/';
import {
  APP_TABS,
  SEARCH_SCREENS
} from '../../common/constants/';

import { WizardIngredient1, WizardIngredient2, WizardIngredient3 } from '../containers/WizardIngredientContainer';

const SEARCH_MODE = Object.freeze({"REQ_INGS": 0, "SEARCH_PHRASE": 1});
const RecipeSearchState = Object.freeze({"LOADING": 0, "IDLE": 1});

const WizardIngredientsList = () => (
  <IngredientsList>
    <WizardIngredient1/>
    <WizardIngredient2/>
    <WizardIngredient3/>
  </IngredientsList>
)
const RequiredIngredient = ({ ingredient, onPress }) => {

  const theme = useTheme();

  return (
    <View style={[common_styles.smallIng, theme.secondary]}>
      <Body2 style={theme.secondary}>{ ingredient.emoji }</Body2>
      <Body2 style={theme.secondary}>{ ingredient.name }</Body2>
      <Pressable
        style={common_styles.inlineIcon}
        onPress={onPress}
      >
        <Cross color={theme.secondary.color}/>
      </Pressable>
    </View>
  );
}
const PlusIngredient = ({ onPress }) => {

  const theme = useTheme();

  return (
    <Pressable
      style={[
        common_styles.smallIng,
        theme.secondary,
        common_styles.squareButton,
        { height: null, margin: 0, borderWidth: 0 }
      ]}
      onPress={onPress}
    >
      <View style={[common_styles.roundButtonIcon, {padding: 0, margin: 0}]}>
        <Plus color={theme.secondary.color}/>
      </View>
    </Pressable>
  );
}

const WizardView = ({
  required_ingredients,
  always_active_ingredients,
  active_ingredients,
  inactive_ingredients,
  query,
  result,
  diet,
  addActiveIngredient,
  addRequiredIngredient,
  deleteRequiredIngredient,
  recipeWizardQuery
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [ show_diet_picker, setShowDietPicker ] = useState(false);
  // const [ show_ingredient_picker, setShowIngredientPicker ] = useState(false);
  const [ search_phrase, setSearchPhrase ] = useState('');
  const [ mode, setMode ] = useState(SEARCH_MODE.SEARCH_PHRASE);
  const [ recipe_search_state, setRecipeSearchState ] = useState(RecipeSearchState.IDLE);

  const [panel_natural_height, setPanelNaturalHeight] = useState(-1);
  const [panel_collapsed_height, setPanelCollapsedHeight] = useState(-1);

  const theme = useTheme();

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const route = useRoute();

  const { top } = useSafeAreaInsets();
  const tab_bar_height = useBottomTabBarHeight();

  const onRefresh = () => {
    setRefreshing(true);
    recipeWizardQuery(
      /*offset*/0,
      search_phrase,
      /*final_callback*/() => setRefreshing(false)
    );
  };

  useEffect(() => {
    if (route.params && route.params.ingredients && (route.params.ingredients.length > 0)) {
      setMode(SEARCH_MODE.REQ_INGS);
      setSearchPhrase('');
      route.params.ingredients.forEach(ing => {
        // Pop ingredient from params to avoid adding them over and over.
        navigation.setParams({ingredients: route.params.ingredients.filter(new_ing => new_ing.id !== ing.id)});
        // Add ingredient.
        addRequiredIngredient(ing);
      });
    } else if (route.params && route.params.search_phrase) {
      setMode(SEARCH_MODE.SEARCH_PHRASE);
      // TODO clear req ings
      console.log('setting search_phrase', route.params.search_phrase)
      setSearchPhrase(route.params.search_phrase);
      setRecipeSearchState(RecipeSearchState.LOADING);
      recipeWizardQuery(
        /*offset*/0,
        search_phrase,
        /*final_callback*/() => setRecipeSearchState(RecipeSearchState.IDLE)
      );
    } else if (!required_ingredients || (required_ingredients.length === 0)) {  // This needs to be an else because with the params use case, we initially don't have any ingredients but we don't want to navigate back.
      setMode(SEARCH_MODE.SEARCH_PHRASE);
    }
  }, [route.params, required_ingredients]);

  useEffect(() => {
    const all_active_ingredient_ids = [...new Set([...active_ingredients.map(ing => ing.id) , ...always_active_ingredients.map(ing => ing.id)])];
    if (mode === SEARCH_MODE.REQ_INGS) {
      if (!arraysEqual(result.query.required_ingredients, query.required_ingredients.map(ingredient => ingredient.id)) ||
          !arraysEqual(result.query.active_ingredients, all_active_ingredient_ids) ||
          !arraysEqual(result.query.inactive_ingredients, inactive_ingredients.map(ingredient => ingredient.id)) ||
          diet !== result.query.diet) {
        setRecipeSearchState(RecipeSearchState.LOADING);
        recipeWizardQuery(
          /*offset*/0,
          search_phrase,
          /*final_callback*/() => setRecipeSearchState(RecipeSearchState.IDLE)
        );
      }
    } else {
      if ((search_phrase !== result.query.search_phrase) ||
          !arraysEqual(result.query.active_ingredients, all_active_ingredient_ids) ||
          !arraysEqual(result.query.inactive_ingredients, inactive_ingredients.map(ingredient => ingredient.id)) ||
          (diet !== result.query.diet)) {
        setRecipeSearchState(RecipeSearchState.LOADING);
        recipeWizardQuery(
          /*offset*/0,
          search_phrase,
          /*final_callback*/() => setRecipeSearchState(RecipeSearchState.IDLE)
        );
      }
    }
  }, [
    mode,
    always_active_ingredients,
    active_ingredients,
    inactive_ingredients,
    query,
    result,
    diet,
    recipeWizardQuery,
    search_phrase
  ]);

  const header = (
    <>
      <View
        style={[
          styles.header,
          common_styles.borderBottom,
        ]}
      >
        <TitleBase
          title_string={t('wizard.wizardTitle')}
        >
          <BackButton onPress={() => {
            navigation.navigate(APP_TABS.search.name, {screen: SEARCH_SCREENS.universalSearch.name});
            required_ingredients.forEach(ing => deleteRequiredIngredient(ing));
          }}/>
          <RoundButton style={theme.secondary} onPress={() => setShowDietPicker(true)}>
            <Filter color={theme.secondary.color}/>
          </RoundButton>
        </TitleBase>
        {
          (mode === SEARCH_MODE.REQ_INGS) ? (
            <>
              <View style={styles.reqIngsNote}>
                <Small style={theme.general}>
                  { t('wizard.showRecipesIncluding') }
                </Small>
              </View>
              <ScrollView horizontal>
                <View style={styles.reqIngs}>
                  {/*<PlusIngredient onPress={() => setShowIngredientPicker(true)} />*/}
                  {
                    required_ingredients ? required_ingredients.map((ingredient, idx) => (
                      <RequiredIngredient
                        key={idx}
                        ingredient={ingredient}
                        onPress={() => {
                          deleteRequiredIngredient(ingredient);
                        }}
                      />
                    )) : null
                  }
                </View>
              </ScrollView>
            </>
          ) : (
            <View style={{marginTop: padding}}>
              <SearchBar
                search_phrase={search_phrase}
                setSearchPhrase={(new_search_phrase) => {
                  setSearchPhrase(new_search_phrase);
                  navigation.setParams({search_phrase: new_search_phrase});
                }}
                doSearch={() => {}}
                placeholder={t('search.universalSearchPlaceholder')}
              />
            </View>
          )
        }
      </View>
    </>
  );

  const top_component = (
    <>
      <Recipes/>
      {
        result.recipes.can_load_more ? (
          <View style={common_styles.buttonContainer}>
            <Button
              style={theme.button}
              onPress={() => recipeWizardQuery(
                /*offset*/result.recipes.recipes.length,
                search_phrase,
                /*final_callback*/() => setRecipeSearchState(RecipeSearchState.IDLE)
              )}
            >
              <ButtonText style={theme.button}>
                { t('wizard.loadMore') }
              </ButtonText>
            </Button>
          </View>
        ) : null
      }
    </>
  );

  let bottom_component = (
    <View
      onLayout={({nativeEvent}) => {
        let { height } = nativeEvent.layout;
        height = height + HANDLE_CONTAINER_HEIGHT;
        if (panel_natural_height < 0){
          setPanelNaturalHeight(height);
        }
      }}
    >
      <H1
        style={[theme.general, {paddingHorizontal: padding}]}
        onLayout={({nativeEvent}) => {
          let { height } = nativeEvent.layout;
          height = height + HANDLE_CONTAINER_HEIGHT;
          if (panel_collapsed_height < 0){
            setPanelCollapsedHeight(height + padding);
          }
        }}
      >
        { t('wizard.whatElseYouGot') }
      </H1>
      <View style={{'width': "100%", padding}}>
        <WizardIngredientsList/>
      </View>
    </View>
  );

  return (
    <View style={{flex: 1, paddingTop: top, paddingBottom: tab_bar_height}}>
      { header }
      <SplitView
        topComponent={top_component}
        bottomComponent={bottom_component}
        bottomPanelNaturalHeight={panel_natural_height}
        bottomPanelCollapsedHeight={HANDLE_CONTAINER_HEIGHT/* panel_collapsed_height */}
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
      <DietPickerContainer
        visible={show_diet_picker}
        onRequestClose={() => setShowDietPicker(false)}
      />
      {/*
        <IngredientPicker
          ingredients={required_ingredients}
          show={show_ingredient_picker}
          setShow={setShowIngredientPicker}
          addIngredient={addRequiredIngredient} // This is not working correctly. addReqIng seems to expect a different layout than IngPicker's addIngredient
          deleteIngredient={deleteRequiredIngredient}
        />
      */}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    padding
  },
  roundButton: {
    position: 'absolute',
    right: 15,
    ...castShadow,
  },
  reqIngs: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  reqIngsNote: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 0.5 * padding
  },
  contentContainer: {
    minHeight: 100
  },
});

export default WizardView;
