import React from 'react';

import Svg, { Circle, Path, G } from "react-native-svg";

const AddUser = ({color = 'white', stroke_width = 13.0}) => (
  <Svg width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <G id="Layer3">
      <G transform="matrix(0.846757,0,0,0.846757,15.6887,-0.886076)">
        <Path
          d="M41.653,191.085C41.653,191.085 41.073,146.131 100,146.131C158.927,146.131 157.483,191.085 157.483,191.085L41.653,191.085Z"
          style={{fill: color}}
        />
        <Circle cx="99.568" cy="95.069" r="47.865"
          style={{fill: color}}
        />
      </G>
    </G>
  </Svg>
);

export default AddUser;
