import { connect } from 'react-redux';

import { initialize, reconnect } from '../../actions';

import ErrorScreen from '../components/ErrorScreen';

const mapDispatchToProps = {
  initialize,
  reconnect
}

export default connect(
  null,
  mapDispatchToProps
)(ErrorScreen)
