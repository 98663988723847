import React from 'react';

import Svg, { G, Path } from 'react-native-svg';

const NoConnection = ({color = 'white'}) => (
    <Svg width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{fill: 'none', strokeLinecap: 'round'}}>
        <G>
            <Path d="M39.139,155.433C32.065,155.293 7.919,145.133 8.855,121.097C9.975,92.335 38.021,86.752 38.021,86.752C38.021,86.752 36.285,51.812 73.978,44.775C102.043,39.536 117.673,64.501 117.673,64.501C117.673,64.501 130.256,56.226 146.047,65.681C156.96,72.215 157.525,87.002 157.525,87.002C157.525,87.002 191.41,87.234 190.995,121.829C190.733,143.681 171.49,155.798 160.646,155.838C134.724,155.934 65.199,155.947 39.139,155.433Z" style={{stroke: color, strokeWidth: 6.8}}/>
            <G transform="matrix(1,0,0,1,0.31171,-3.56591)">
                <Path d="M78.042,139.478C78.042,139.478 85.429,128.778 100,128.899C113.215,129.009 121.162,140.087 121.162,140.087" style={{stroke: color, strokeWidth: 6.8}}/>
            </G>
            <Path d="M111.33,102.818L127.5,86.661" style={{stroke: color, strokeWidth: 6.8}}/>
            <G transform="matrix(1,0,0,1,-39.2595,0)">
                <Path d="M111.33,102.818L127.5,86.661" style={{stroke: color, strokeWidth: 6.8}}/>
            </G>
            <G transform="matrix(0.00936539,0.999956,-0.999956,0.00936539,173.841,-25.6275)">
                <Path d="M111.33,102.818L127.5,86.661" style={{stroke: color, strokeWidth: 6.8}}/>
            </G>
            <G transform="matrix(0.00936539,0.999956,-0.999956,0.00936539,212.963,-25.6275)">
                <Path d="M111.33,102.818L127.5,86.661" style={{stroke: color, strokeWidth: 6.8}}/>
            </G>
        </G>
    </Svg>
);

export default NoConnection;
