import React, { useState } from 'react';
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TextInput,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Button from '../../common/components/Button';
import IngredientPickerContainer from '../../ingredients/containers/IngredientPickerContainer';
import SearchBar from '../../ingredients/containers/SearchBar';
import { TitleWAction } from '../../header/components/Header';

import common_styles, {
  castShadow,
  margin,
  padding,
  roundedBorder,
  textField
} from '../../common/components/CommonStyles';
import {
  Alert,
  BackButton,
  Br,
  Body2,
  H1,
  Small,
  useAppWidth
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import { ArrowLeft } from '../../assets/Arrow';
import Cross from '../../assets/Cross';

import { RecipeType } from '../../common/constants/';

const Ingredient = ({ ingredient, onPress }) => {

  const theme = useTheme();

  if (!ingredient) return null;

  return (
    <View style={[common_styles.smallIng, theme.secondary]}>
      <Body2>{ingredient.emoji}</Body2>
      <Body2 style={theme.secondary}>{ ingredient.name }</Body2>
      <Pressable
        style={{height: 12, width: 12, alignItems: 'center', margin: 3}}
        onPress={onPress}
      >
        <Cross color={theme.secondary.color}/>
      </Pressable>
    </View>
  );
}
const PlusIngredient = ({ onPress }) => {

  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <Pressable
      style={[common_styles.smallIng, theme.secondary]}
      onPress={onPress}
    >
      <Body2 style={theme.secondary}>
        { `+ ${t('recipeEditor.addIngredients')}` }
      </Body2>
    </Pressable>
  );
}

export const IngredientPicker = ({
  ingredients,
  show,
  setShow,
  addIngredient,
  deleteIngredient
}) => {
  //const { keyboard_height, setKeyboardHeight } = useState(0);

  const width = useAppWidth();
  const { bottom, top } = useSafeAreaInsets();

  const { t } = useTranslation(['common', 'recipes']);

  const theme = useTheme();

  /*
  useEffect(() => {
    const show_subscription = Keyboard.addListener('keyboardDidShow', (e) => {
      console.log('Keyboard Shown', e.endCoordinates.height);
      setKeyboardHeight(e.endCoordinates.height);
    });
    const hide_subscription = Keyboard.addListener('keyboardDidHide', (e) => {
      console.log('Keyboard Hidden', e.endCoordinates.height);
      setKeyboardHeight(0);
    });
    return () => {
      show_subscription.remove();
      hide_subscription.remove();
    };
  }, []);
  */

  return (
    <Modal
      animationType={Platform.OS === "web" ? "none" : "fade"}
      transparent
      visible={show}
      onRequestClose={() => setShow(false)}
    >
      <KeyboardAvoidingView behavior='height'>
        <View style={common_styles.modalContainer}>
          <Pressable style={common_styles.modalBackground} onPress={() => setShow(false)}/>
          <View
            style={[
              theme.general,
              common_styles.modal,
              styles.ingredientPicker,
              {
                width,
                top,
                bottom: 0,
                paddingBottom: bottom,
                paddingHorizontal: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              },
            ]}
          >
            <View style={{width: '100%'}}>
              <TitleWAction
                action_string={t('common.done')}
                style={{paddingHorizontal: padding}}
                title_string={t(`recipeEditor.addIngredients`)}
                goBack={() => setShow(false)}
                goForward={() => setShow(false)}
              />

              <View style={styles.addedIngredientsContainer}>
                <Small style={[theme.general, {paddingHorizontal: padding}]}>{t('recipeEditor.addedIngredients', {count: ingredients.length})}</Small>
                <ScrollView
                  horizontal
                  contentContainerStyle={styles.addedIngredients}
                >
                  {
                    (ingredients.length !== 0) ? ingredients.map((ing, index) => (
                      <Ingredient
                        key={index}
                        ingredient={ing?.ingredient}
                        onPress={() => deleteIngredient(ing)}
                      />
                    )) : (
                      <View style={[common_styles.smallIng, theme.secondary]}>
                        <Body2 style={theme.secondary}>{t('recipeEditor.noIngredient')}</Body2>
                      </View>
                    )
                  }
                </ScrollView>
              </View>

              <View style={{paddingHorizontal: padding}}>
                <SearchBar auto_focus/>
              </View>
            </View>
            <ScrollView
              style={{width: '100%', padding}}
              keyboardShouldPersistTaps='handled'
            >
              <IngredientPickerContainer
                addIngredient={(ingredient) => addIngredient({ingredient})}
                deleteIngredient={(ingredient) => addIngredient({ingredient})}
              />
            </ScrollView>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}

const RecipeEditorBase = ({
  title_string,
  meal_name,
  setMealName,
  show_empty_meal_name_warning,
  validate,
  notes,
  setNotes,
  ingredients,
  deleteIngredient,
  show_ingredient_picker,
  setShowIngredientPicker
}) => {
  const [ selected_recipe_type, setSelectedRecipeType ] = useState(RecipeType.CUSTOM);

  const { t } = useTranslation(['common', 'recipes']);

  const width = useAppWidth();

  const theme = useTheme();

  return (
    <>
      <Small style={[theme.general, styles.label]}>
        { t(`recipeEditor.nameLabel`) }
      </Small>
      <TextInput
        style={[textField, theme.textField]}
        placeholder={t(`recipeEditor.namePlaceholder`)}
        placeholderTextColor={theme.textFieldPlaceholder.color}
        value={meal_name}
        onChangeText={value => {
          setMealName(value);
          validate();
        }}
      />
      {
        show_empty_meal_name_warning ? (
          <Alert text={t('recipeEditor.emptyMealNameWarning')} />
        ) : null
      }

      <Br/>

      <Small style={[theme.general, styles.label]}>
        { t(`recipeEditor.ingredientsLabel`) }
      </Small>
      <View style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: width - 2 * padding
      }}>
        {
          ingredients ? ingredients.map((ing, idx) => (
            <Ingredient
              key={idx}
              ingredient={ing?.ingredient}
              onPress={() => deleteIngredient(ing)}
            />
          )) : null
        }
        <PlusIngredient onPress={() => setShowIngredientPicker(true)} />
      </View>

      <Br/>

      <Small style={[theme.general, styles.label]}>{t(`recipeEditor.notesLabel`)}</Small>
      <TextInput
        style={[textField, theme.textField, {height: 100}]}
        placeholder={t(`recipeEditor.notesPlaceholder`)}
        placeholderTextColor={theme.textFieldPlaceholder.color}
        value={notes}
        onChangeText={value => setNotes(value)}
        multiline
      />
    </>
  );
}

const styles = StyleSheet.create({
  ingredientPicker: {
    padding,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: (Platform.OS === 'web') ? 'fixed' : 'absolute',
    ...castShadow
  },
  addedIngredientsContainer: {
    paddingVertical: padding
  },
  addedIngredients: {
    alignItems: 'center',
    paddingHorizontal: padding,
    paddingVertical: 0.25 * padding
  },
  label: {
    marginBottom: 0.5 * padding
  }
});

export default RecipeEditorBase;
