import { connect } from 'react-redux';

import TabBarIconUpdateIndicator from '../components/TabBarIconUpdateIndicator';

const noUpdateProps = state => ({
  has_updates: false
});
const feedProps = state => ({
  has_updates: state.update_flags.feed || state.update_flags.notifications
});
const householdProps = state => ({
  has_updates: Boolean(Object.values(state.update_flags.households).reduce(
    (has_updates, household) => has_updates |= (household.meal_plan || household.ingredients),
    false
  ))
});

export const NoUpdateIndicator = connect(
  noUpdateProps,
  null
)(TabBarIconUpdateIndicator)
export const FeedUpdateIndicator = connect(
  feedProps,
  null
)(TabBarIconUpdateIndicator);
export const HouseholdUpdateIndicator = connect(
  householdProps,
  null
)(TabBarIconUpdateIndicator)
