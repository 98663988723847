import React from 'react';

import Masonry from './Masonry';

import {
  MIN_INGREDIENT_WIDTH,
  MIN_INGREDIENT_COLS
} from '../constants/';

const IngredientsList = ({ children }) => (
  <Masonry
    min_width={MIN_INGREDIENT_WIDTH}
    min_num_cols={MIN_INGREDIENT_COLS}
  >
    { children}
  </Masonry>
)

export default IngredientsList;
