import { connect } from 'react-redux';

import {
  storeActiveHousehold
} from '../../actions';

import HouseholdSwitcher from '../components/HouseholdSwitcher';

const mapStateToProps = state => {
  const uuid = state.active_household_uuid;

  return {
    households: state.profile.households.map(household => {
      return {...household, active: (household.uuid === uuid)}
    })
  }
}
const mapDispatchToProps = {
  storeActiveHousehold
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HouseholdSwitcher)
