import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import IngredientsList from '../../common/components/IngredientsList';
import { ActiveIngredient, InactiveIngredient } from '../../common/components/Ingredient';
import common_styles from '../../common/components/CommonStyles';

import Cross from '../../assets/Cross';

import { Body1 } from '../../utils';
import { useTheme } from '../../utils/Theme';

const IngredientSearchState = Object.freeze({"INACTIVE": 0, "ACTIVE_LOADING": 1, "ACTIVE_IDLE": 2});

const UnsortedIngredientsList = ({ ingredients, addIngredient, deleteIngredient }) => {
  const ingredients_list = ingredients.map((ingredient, index) => {
    if (ingredient.active) {
      return (
        <ActiveIngredient key={index} ingredient={ingredient} deleteIngredient={deleteIngredient} />
      );
    } else {
      return (
        <InactiveIngredient key={index} ingredient={ingredient} addIngredient={addIngredient} />
      );
    }
  });

  return (
    <IngredientsList>
      {
        ingredients_list
      }
    </IngredientsList>
  );
}

const IngredientPicker = ({
  active_ingredients,
  suggested,
  current_search_phrase,
  results,
  loadSuggestedIngredients,
  ingredientSearch,
  setIngredientSearchResults,
  addIngredient,
  deleteIngredient,
  setIngredientsSearchPhrase,
  onPick,
  mode
}) => {
  const [search_state, setSearchState] = useState(IngredientSearchState.INACTIVE);

  const active_ingredient_ids = active_ingredients.map(ing => ing.id);
  const ingredients_search_results =
    results.ingredients.map(ingredient => {
      return {
        ...ingredient,
        active: active_ingredient_ids.includes(ingredient.id)
      }
    });

  //const active_ingredient_ids = [...new Set([...required_ingredient_ids, ...query.active_ingredients.map(ing => ing.id)])];

  const { t } = useTranslation('common');

  const theme = useTheme();

  useEffect(() => {
    if (!current_search_phrase) {
      setSearchState(IngredientSearchState.INACTIVE);
      if (current_search_phrase !== results.search_phrase) {
        // Reset search results.
        setIngredientSearchResults(current_search_phrase, []);
      }
      //if (!suggested.length) {
      //  loadSuggestedIngredients(query.required_ingredients.map(ing => ing.id));
      //}
    } else {
      setSearchState(IngredientSearchState.ACTIVE_IDLE);
      if (current_search_phrase !== results.search_phrase) {
        setSearchState(IngredientSearchState.ACTIVE_LOADING);
        ingredientSearch(current_search_phrase);
      }
    }
  }, [/* suggested,  */current_search_phrase, results.search_phrase, results.ingredients, setIngredientSearchResults, ingredientSearch/*, query.required_ingredients , loadSuggestedIngredients */]);

  let body = null;
  if (search_state === IngredientSearchState.INACTIVE) {
    // pass
  } else if (search_state === IngredientSearchState.ACTIVE_LOADING) {
    body = (<Body1 style={theme.general}>{t('common.loading')}</Body1>);
  } else if (search_state === IngredientSearchState.ACTIVE_IDLE && !ingredients_search_results.length) {
    body = (<Body1 style={theme.general}>{t('ingredients.nothingFound')}</Body1>);
  } else {
    body = (
      <UnsortedIngredientsList
        ingredients={ingredients_search_results}
        addIngredient={(ingredient) => {
          addIngredient(ingredient);
          setIngredientsSearchPhrase('');
          if (onPick) onPick();
        }}
        deleteIngredient={(ingredient) => {
          deleteIngredient(ingredient);
          setIngredientsSearchPhrase('');
          if (onPick) onPick();
        }}
      />
    );
  }

  return body;
}

export default IngredientPicker
