import React from 'react';
import {
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { Title } from '../../header/components/Header';
import { HidingHeaderScrollView } from '../../utils/';

import LoginSignUp from '../containers/LoginSignUpContainer';

import styles from './AuthStyles';

const LoginSignUpScreen = () => {
  const navigation = useNavigation();

  const { t } = useTranslation('common');

  return (
    <HidingHeaderScrollView
      contentContainerStyle={styles.auth}
      header={(
        <Title
          goBack={() => navigation.goBack()}
          title_string={t('auth.loginSignUpTitle')}
        />
      )}
      body={(
        <LoginSignUp />
      )}
    />
  )
};

export default LoginSignUpScreen;
