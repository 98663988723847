import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import i18n from 'i18next';

import { reset } from '@amplitude/analytics-react-native';

import { handlePostLogin } from './';
import apiRequest from './common';
import {
  storageRemoveItem,
  storageSetStringItem,
} from './utils';

import {
  clearAuth,
  initialize,
  loginSuccess,
  pushNotification,
  setProfile,
  refreshTokenRequest,
  refreshTokenSuccess,
  logOut
} from '../../actions/';
import * as types from '../../actions/types';

import {
  LOCAL_STORAGE_ID_ACTIVE_HOUSEHOLD_UUID,
  LOCAL_STORAGE_ID_HIDDEN_MAKES,
  NotificationSeverity,
  REFRESH_TOKEN_ID,
  S_TO_MS,
} from '../constants/';
import { API_PREFIX } from '../constants/urls';

import jwtDecode from 'jwt-decode';

export const TokenState = Object.freeze({
  "ACTIVE": 0,
  "NEED_REFRESH": 1,
  "EXPIRED": 2
});

export const getTokenState = auth => {
  if (auth.access && (jwtDecode(auth.access).exp * S_TO_MS > (new Date()).getTime())) {
    return TokenState.ACTIVE;
  } else if (auth.refresh && (jwtDecode(auth.refresh).exp * S_TO_MS > (new Date()).getTime())) {
    return TokenState.NEED_REFRESH;
  } else {
    return TokenState.EXPIRED;
  }
}

function* handleSignUpRequest(action) {
  const {success, data, errors} = yield apiRequest(`${API_PREFIX}/api/v0/auth/create/`, 'POST', action.account_info, false, true);
  if (success) {
    yield handleLoginRequest({payload: action.account_info});
    if (action.success_callback) action.success_callback();
} else {
    yield put(clearAuth());
    for (let error of errors) {
      if (action.failure_callback) action.failure_callback(error);
    }
  }
  if (action.final_callback) action.final_callback();
}

function* handleLoginRequest(action) {
  const {success, data, errors} = yield apiRequest(`${API_PREFIX}/api/v0/auth/token/obtain/`, 'POST', action.payload, false, true);
  if (success) {
    yield call(storageSetStringItem, REFRESH_TOKEN_ID, data.refresh);
    yield put(loginSuccess(data));
    yield handlePostLogin();
    const message = i18n.t('common:inAppNotifications.youAreNowLoggedIn');
    yield put(pushNotification(NotificationSeverity.INFO, message, 3));
    if (action.success_callback) action.success_callback();
  } else {
    yield put(clearAuth());  // Needed?
    console.log('login errors', errors);
    for (let error of errors) {
      console.log('before failure callback', error);
      if (action.failure_callback) action.failure_callback(error);
      console.log('after failure callback');
    }
  }
  if (action.final_callback) action.final_callback();
}

export function* refreshTokenIfNeeded() {
  let { auth } = yield select(state => state);
  let token_state = getTokenState(auth);
  if (token_state === TokenState.NEED_REFRESH) {
    const success = yield handleTokenRefreshRequest(refreshTokenRequest(auth.refresh));
    return success;
  } else {
    return (token_state === TokenState.ACTIVE);
  }
}

export function* handleTokenRefreshRequest(action) {
  const {success, data} = yield apiRequest(`${API_PREFIX}/api/v0/auth/token/refresh/`, 'POST', {refresh: action.token}, false);
  if (success) {
    yield put(refreshTokenSuccess(data));
  }
  return success;
}

export function* handleResetPasswordRequest({username}) {
  const {success, data} = yield apiRequest(`${API_PREFIX}/api/v0/auth/reset/`, 'POST', {username}, false);
  return success;
}

export function* handleSetPasswordRequest({token, uuid, password}) {
  const {success, data, errors} = yield apiRequest(`${API_PREFIX}/api/v0/auth/reset/`, 'PATCH', {token, uuid, password}, false, true);
  if (success) {
    const message = i18n.t('common:inAppNotifications.passwordChangeSuccess');
    yield put(pushNotification(NotificationSeverity.INFO, message, 3));
    yield call(storageSetStringItem, REFRESH_TOKEN_ID, data.refresh);
    yield put(loginSuccess(data));
    yield handlePostLogin();
  } else {
    const message = i18n.t('common:inAppNotifications.passwordChangeFailed');
    yield put(pushNotification(NotificationSeverity.ERROR, message, 3));
  }
  return success;
}

export function* handleVerifyEMailRequest({token, uuid}) {
  const {success, data, errors} = yield apiRequest(`${API_PREFIX}/api/v0/auth/verify/`, 'POST', {token, uuid}, false, true);
  if (success) {
    const message = i18n.t('common:inAppNotifications.emailVerifySuccess');
    yield put(pushNotification(NotificationSeverity.INFO, message, 3));
    yield call(storageSetStringItem, REFRESH_TOKEN_ID, data.refresh);
    yield put(loginSuccess(data));
    yield handlePostLogin();
  } else {
    const message = i18n.t('common:inAppNotifications.emailVerifyFailed');
    yield put(pushNotification(NotificationSeverity.INFO, message, 3));
  }
  return success;
}

export function* handleJoinHouseholdRequest({uuid}) {
  const {success, data, errors} = yield apiRequest(`${API_PREFIX}/api/v0/household/join/`, 'POST', {uuid}, true, true);
  if (success) {
    const message = i18n.t('common:inAppNotifications.joinHouseholdSuccess');
    yield put(pushNotification(NotificationSeverity.INFO, message, 3));
    yield handlePostLogin();
  } else {
    const message = i18n.t('common:inAppNotifications.joinHouseholdFailed');
    yield put(pushNotification(NotificationSeverity.INFO, message, 3));
  }
  return success;
}

export function* handleLogOut() {
  yield call(storageRemoveItem, REFRESH_TOKEN_ID);
  yield call(storageRemoveItem, LOCAL_STORAGE_ID_ACTIVE_HOUSEHOLD_UUID);
  yield call(storageRemoveItem, LOCAL_STORAGE_ID_HIDDEN_MAKES);
  reset();
  yield put(initialize());
  const message = i18n.t('common:inAppNotifications.youAreNowLoggedOut');
  yield put(pushNotification(NotificationSeverity.INFO, message, 3));
}

export default function* watchAuthRequests() {
  yield all([
    takeLatest(types.SIGN_UP_REQUEST, handleSignUpRequest),
    takeLatest(types.LOGIN_REQUEST, handleLoginRequest),
    takeLatest(types.LOG_OUT, handleLogOut),
    takeLatest(types.TOKEN_REQUEST, handleTokenRefreshRequest),
    takeLatest(types.RESET_PASSWORD_REQUEST, handleResetPasswordRequest),
    takeLatest(types.SET_PASSWORD_REQUEST, handleSetPasswordRequest),
    takeLatest(types.VERIFY_EMAIL_REQUEST, handleVerifyEMailRequest),
    takeLatest(types.JOIN_HOUSEHOLD_REQUEST, handleJoinHouseholdRequest),
  ]);
}
