import React, { lazy } from 'react';
import { ScrollView, View } from 'react-native';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { padding } from '../../common/components/CommonStyles';
import { Title } from '../../header/components/Header';

import { HidingHeaderScrollView } from '../../utils/';

import PrivacyPolicyEn from './PrivacyPolicyEn';
import PrivacyPolicyDe from './PrivacyPolicyDe';

const PrivacyPolicyBody = () => {
  const lang_code = i18n.language.substring(0, 2)
  switch (lang_code) {
    case 'de': {
      return ( <PrivacyPolicyDe/> );
    }
    default:
    case 'en': {
      if (lang_code !== 'en') {
        console.error(`Unknown language "${i18n.language}"! Falling back to English`);
      }
      return ( <PrivacyPolicyEn/> );
    }
  }
}

const PrivacyPolicy = () => {
  const navigation = useNavigation();

  const { t } = useTranslation('common');

  return (
    <HidingHeaderScrollView
      contentContainerStyle={{padding}}
      header={(
        <Title
          goBack={() => navigation.goBack()}
          title_string={t('common.privacyPolicy')}
        />
      )}
      body={(
        <PrivacyPolicyBody/>
      )}
    />
  )
}

export default PrivacyPolicy;
