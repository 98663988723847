import React from 'react';

import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

//<?xml version="1.0" encoding="UTF-8" standalone="no"?>
//<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
//<Svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;">
const FridgeCheck = ({color = 'white'}) => (
  <Svg width="100%" height="100%" viewBox="0 0 110 100" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{'fillRule':'evenodd','clipRule':'evenodd','strokeLinecap':'round','strokeLinejoin':'round','strokeMiterlimit':10}}>
    <Defs>
      <ClipPath id="_clip1">
        <Path d="M117.737,-5.904L117.737,107.695L-4.932,107.695L-4.932,-5.904L117.737,-5.904ZM80.073,1C95.94,1 108.822,13.882 108.822,29.749C108.822,45.616 95.94,58.498 80.073,58.498C64.206,58.498 51.324,45.616 51.324,29.749C51.324,13.882 64.206,1 80.073,1Z"/>
      </ClipPath>
    </Defs>
    <Path d="M117.737,-5.904L117.737,107.695L-4.932,107.695L-4.932,-5.904L117.737,-5.904ZM80.073,1C95.94,1 108.822,13.882 108.822,29.749C108.822,45.616 95.94,58.498 80.073,58.498C64.206,58.498 51.324,45.616 51.324,29.749C51.324,13.882 64.206,1 80.073,1Z" style={{'fill':'none'}}/>
    <G clipPath="url(#_clip1)">
        <G id="Artboard1" transform="matrix(1.51253,0,0,1.51253,-242.468,-554.058)">
            <G transform="matrix(1,0,0,1,197.791,368.049)">
                <Path d="M0,62.639L-29.021,62.639C-32.214,62.639 -34.826,60.027 -34.826,56.834L-34.826,5.805C-34.826,2.612 -32.214,0 -29.021,0L0,0C3.192,0 5.805,2.612 5.805,5.805L5.805,56.834C5.805,60.027 3.192,62.639 0,62.639Z" style={{'fill':'none','stroke': color, 'strokeWidth':'2.6px'}}/>
            </G>
            <G transform="matrix(1,0,0,1,196.823,430.688)">
                <Path d="M0,-62.639L9.305,-62.639C12.497,-62.639 15.109,-60.027 15.109,-56.835L15.109,-5.805C15.109,-2.613 12.497,0 9.305,0L0,0" style={{'fill':'none','stroke': color, 'strokeWidth':'2.6px'}}/>
            </G>
            <G transform="matrix(1,0,0,1,162.965,385.382)">
                <Path d="M0,0L40.631,0" style={{'fill':'none','stroke': color, 'strokeWidth':'2.6px'}}/>
            </G>
            <G transform="matrix(1,0,0,1,166.996,380.061)">
                <Path d="M0,-5.321L0,0L2.096,0" style={{'fill':'none','stroke': color, 'strokeWidth':'2.16px'}}/>
            </G>
            <G transform="matrix(1,0,0,1,166.996,391.669)">
                <Path d="M0,12.737L0,0L2.096,0" style={{'fill':'none','stroke': color, 'strokeWidth':'2.6px'}}/>
            </G>
            <G id="Layer1" transform="matrix(1.22013,0,0,1.22013,127.951,335.173)">
                <G transform="matrix(0,1.1554,1.1554,0,56.8974,58.9898)">
                    <Path d="M-9.835,-9.835L9.835,-9.835" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.1554,0,0,1.1554,41.4566,51.8136)">
                    <Path d="M0,-2.219L3.529,0L7.025,-2.219" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.1554,0,0,1.1554,49.5733,66.6478)">
                    <Path d="M0,2.219L-3.53,0L-7.025,2.219" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.00061,0.577694,0.577694,-1.00061,39.8518,49.1481)">
                    <Path d="M-1.318,-4.918L18.353,-4.918" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.1554,0,0,1.1554,35.0603,50.6224)">
                    <Path d="M0,6.084L3.687,4.137L3.513,0" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.1554,0,0,1.1554,56.4064,67.6322)">
                    <Path d="M0,-6.084L-3.688,-4.137L-3.513,0" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.00061,-0.577694,-0.577694,-1.00061,39.8518,68.8304)">
                    <Path d="M-1.318,4.918L18.353,4.918" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.1554,0,0,1.1554,39.1377,60.3624)">
                    <Path d="M0,6.084L0.157,1.917L-3.512,0" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
                <G transform="matrix(1.1554,0,0,1.1554,52.3671,57.4092)">
                    <Path d="M0,-6.083L-0.157,-1.917L3.513,0" style={{'fill':'none','stroke': color, 'strokeWidth':'1.41px'}}/>
                </G>
            </G>
        </G>
    </G>
    <G id="check">
        <Path d="M80.073,1C95.94,1 108.822,13.882 108.822,29.749C108.822,45.616 95.94,58.498 80.073,58.498C64.206,58.498 51.324,45.616 51.324,29.749C51.324,13.882 64.206,1 80.073,1ZM95.428,14.723C97.544,14.948 97.706,15.601 98.21,16.4C99.007,17.662 99.061,19.107 98.012,20.547L76.556,48.271C76.118,48.804 75.95,48.913 75.622,49.119C74.298,49.952 72.742,49.97 71.299,48.769L60.988,39.665C58.973,37.779 59.553,37.023 59.944,35.827C60.66,33.636 63.713,32.61 65.618,34.104C65.673,34.148 65.726,34.194 65.779,34.239L73.195,40.787L92.421,15.954C93.194,15.118 94.279,14.668 95.428,14.723Z" style={{'fill':color}}/>
    </G>
  </Svg>
)

export default FridgeCheck;
