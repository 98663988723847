import React from 'react';

import Svg, { Circle } from "react-native-svg";

const Dot = ({color = 'white'}) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    version="1.1"
    id="svg5"
    //style={{backgroundColor: 'blue'}}
  >
    <Circle
       style={{stroke:'none',fill:color}}
       cx="10"
       cy="10"
       r="10" />
  </Svg>
);

export default Dot;
