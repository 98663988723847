import { connect } from 'react-redux';

import { createSelectorCreator, defaultMemoize } from 'reselect';
import { jsonEqual } from '../../utils/';

import App from '../components/App';

const createSelector = createSelectorCreator(
  defaultMemoize,
  jsonEqual
);

const selectApiState = createSelector(state => state.api_state, a => a);

const mapStateToProps = state => ({
  api_state: selectApiState(state),
  app_state: state.app_state,
  user_language: state.profile.userprofile.language,
  user_uuid: state.profile.userprofile.uuid
});

export default connect(
  mapStateToProps
)(App)
