import { connect } from 'react-redux';

import {
  challengeSearch,
} from '../../actions';
import ChallengesOverview from '../components/ChallengesOverview';

const mapDispatchToProps = {
  challengeSearch
}

export default connect(
  null,
  mapDispatchToProps
)(ChallengesOverview)
