import React, {
  useEffect,
  useState
} from 'react';
import {
  Image,
  ImageBackground,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTranslation } from 'react-i18next';
import {
  useNavigation,
  useRoute
} from '@react-navigation/native';

import Button from '../../common/components/Button';
import { Picker } from '../../common/components/Picker';
import { TitleWClose } from '../../header/components/Header';

import { Tag } from '../../recipes/components/TagPicker';

import common_styles, {
  borderRadius,
  padding,
  textField
} from '../../common/components/CommonStyles';
import {
  Body1,
  Body2,
  ButtonText,
  getEmojisFromRecipe,
  H1,
  H3,
  relativeTime,
  RoundButton,
  Small,
  useAppWidth,
  VSkipSmall,
  VSkipTiny,
  XSmall
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import Bookmark from '../../assets/Bookmark';
import DotDotDot from '../../assets/DotDotDot';
import Heart from '../../assets/Heart';
import LinearGradient from '../../assets/LinearGradient';
import RecipeImagePlaceholder from '../../assets/RecipeImagePlaceholder';
import User from '../../assets/User';
import { ArrowRight } from '../../assets/Arrow';

import {
  APP_TABS,
  COLORS,
  MAKE_MAX_TAGS,
  NotificationSeverity,
  MODAL_SCREENS,
  ReportType,
  USER_SCREENS
} from '../../common/constants/';
import {
  MEDIA_PREFIX
} from '../../common/constants/urls';

const RecipeShareDescriptionWTimeStamp = ({
  share_type,
  date
}) => {
  const theme = useTheme();

  const { t } = useTranslation('common');

  switch(share_type) {
    case 'make':
      return (
        <XSmall style={theme.general}>
          { t('feed.madeThisRelativeTime', {relative_time: relativeTime(date)}) }
        </XSmall>
      );
    default:
    case 'share':
      return (
        <XSmall style={theme.general}>
          { t('feed.sharedThisRelativeTime', {relative_time: relativeTime(date)}) }
        </XSmall>
      );
  }
}

export const RecipeShare = ({
  uuid,
  authors,
  date,
  num_reactions,
  have_reacted,
  recipe_share,
  addBookmark,
  deleteBookmark,
  addReaction,
  deleteReaction,
  showEntryActionPicker
}) => {
  const {
    images,
    recipes,
    used_up_ingredients,
    caption,
    tags,
    share_type
  } = recipe_share;

  const [ have_bookmarked_state, setHaveBookmarkedState ] = useState((recipes.length !== 0) ? recipes[0].have_bookmarked : false);
  const [ num_bookmarks_state, setNumBookmarksState ] = useState((recipes.length !== 0) ? recipes[0].num_bookmarks : 0);
  const [ have_reacted_state, setHaveReactedState ] = useState(have_reacted);
  const [ num_reactions_state, setNumReactionsState ] = useState(num_reactions);
  const [ recipe_placeholder_uri, setRecipePlaceholderUri ] = useState('')

  const { t } = useTranslation(['common', 'recipes']);
  const theme = useTheme();
  const navigation = useNavigation();
  const route = useRoute();

  let width = useAppWidth();
  width = width - 2 * padding;

  let image = '';
  let image_placeholder_uri = '';
  let image_width = 0;
  let image_height = 0;

  if (images.length !== 0) {
    image = images[0].image;
    const { image_placeholder } = images[0];
    image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
    image_width = images[0].width;
    image_height = images[0].height;
  }

  useEffect(() => {
    if (recipes.length !== 0) {
      setHaveBookmarkedState(recipes[0].have_bookmarked);
      setNumBookmarksState(recipes[0].num_bookmarks);
    }
    setHaveReactedState(have_reacted);
    setNumReactionsState(num_reactions);
  }, [recipes, have_reacted, num_reactions]);

  const image_action_panel = (
    <View style={styles.makeImageActionsContainer}>
      <View style={styles.makeImageAction}>
        {
          have_reacted_state ? (
            <TouchableOpacity
              style={styles.makeImageActionIcon}
              onPress={() => {
                deleteReaction(uuid);
                setHaveReactedState(false);
                setNumReactionsState(num_reactions_state => num_reactions_state - 1);
              }}
            >
              <View style={common_styles.roundButtonIcon}>
                <Heart color={have_reacted_state ? COLORS.RED : 'white'} filled={have_reacted_state}/>
              </View>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.makeImageActionIcon}
              onPress={() => {
                addReaction(uuid, '❤️');
                setHaveReactedState(true);
                setNumReactionsState(num_reactions_state => num_reactions_state + 1);
              }}
            >
              <View style={common_styles.roundButtonIcon}>
                <Heart color={'white'} filled={have_reacted_state}/>
              </View>
            </TouchableOpacity>
          )
        }
        {
          num_reactions_state ? (
            <Body2 style={{color: 'white'}}>{num_reactions_state}</Body2>
          ) : null
        }
      </View>
      <View style={styles.makeImageAction}>
        {
          (recipes.length !== 0) ? (
            <>
              {
                num_bookmarks_state ? (
                  <Body2 style={{color: 'white'}}>{num_bookmarks_state}</Body2>
                ) : null
              }
              {
                have_bookmarked_state ? (
                  <TouchableOpacity
                    style={styles.makeImageActionIcon}
                    onPress={() => {
                      deleteBookmark(recipes[0].uuid);
                      setHaveBookmarkedState(false);
                      setNumBookmarksState(num_bookmarks_state => num_bookmarks_state - 1);
                    }}
                  >
                    <View style={common_styles.roundButtonIcon}>
                      <Bookmark color={'white'} filled={have_bookmarked_state}/>
                    </View>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={styles.makeImageActionIcon}
                    onPress={() => {
                      addBookmark(recipes[0].uuid);
                      setHaveBookmarkedState(true);
                      setNumBookmarksState(num_bookmarks_state => num_bookmarks_state + 1);
                    }}
                  >
                    <View style={common_styles.roundButtonIcon}>
                      <Bookmark color={'white'} filled={have_bookmarked_state}/>
                    </View>
                  </TouchableOpacity>
                )
              }
            </>
          ) : null
        }
      </View>
    </View>
  );

  const tags_component = (
    <View style={styles.makeTagsContainer}>
      {
        tags.slice(0, MAKE_MAX_TAGS).map((tag, index) => (
          <Tag
            key={index}
            tag={tag}
            disabled
          />
        ))
      }
      {
        (tags.length > MAKE_MAX_TAGS) ? (
          <Tag
            tag={{tag: '...'}}
            disabled
          />
        ) : null
      }
    </View>
  );

  let emojis = [];
  if (recipes.length !== 0) {
    emojis = getEmojisFromRecipe(recipes[0]);
  } else {
    emojis = getEmojisFromRecipe();
  }

  let avatar_image = '';
  let avatar_image_placeholder_uri = '';

  if (authors.length && authors[0].userprofile.image && authors[0].userprofile.image.image) {
    avatar_image = authors[0].userprofile.image.image_50;
    const avatar_image_placeholder = authors[0].userprofile.image.image_placeholder;
    avatar_image_placeholder_uri = {uri: `data:image/png;base64,${avatar_image_placeholder}`};
  }

  return (
    <>
      <View style={styles.makeHeader}>
        {
          avatar_image ? (
            <TouchableOpacity
              onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: authors[0].username}})}
            >
              <Image
                style={common_styles.roundButton}
                defaultSource={avatar_image_placeholder_uri}
                source={{uri: `${MEDIA_PREFIX}${avatar_image}`}}
              />
            </TouchableOpacity>
          ) : (
            <RoundButton
              style={theme.secondary}
              onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: authors[0].username}})}
            >
              <User color={theme.general.color}/>
            </RoundButton>
          )
        }
        <View style={{flex: 1, paddingHorizontal: padding}}>
          <TouchableOpacity
            style={styles.makeHeaderUser}
            onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: authors[0].username}})}
          >
            <H3 style={theme.general}>
              { (authors.length > 0) ? authors[0].username : null }
            </H3>
            {
              (authors.length > 0) && authors[0].userprofile.is_bot ? (
                <View style={[common_styles.smallIng, theme.secondary]}>
                  <H3 style={theme.secondary}>
                    { t('profile.botLabel') }
                  </H3>
                </View>
              ) : null
            }
          </TouchableOpacity>
          <RecipeShareDescriptionWTimeStamp
            share_type={share_type}
            date={date}
          />
        </View>
        <RoundButton
          style={theme.general}
          onPress={showEntryActionPicker}
        >
          <DotDotDot color={theme.general.color}/>
        </RoundButton>
      </View>
      {
        (used_up_ingredients.length > 0) ?
          <ScrollView
            horizontal
            style={styles.makeUsedUpIngredientsContainer}
            contentContainerStyle={styles.makeUsedUpIngredients}
          >
            <Small style={theme.general}>{t('feed.usedUpIngredients', {count: used_up_ingredients.length})}</Small>
            {
              used_up_ingredients.map((ing, index) => (
                <View style={[common_styles.smallIng, theme.secondary]} key={index}>
                  <Small>{ing.ingredient.emoji}</Small>
                  <Small style={theme.secondary}>{ing.ingredient.name}</Small>
                </View>
              ))
            }
          </ScrollView> : null
      }
      <View
        style={styles.makeImageContainer}
      >
        {
          (images.length !== 0 && images[0].image) ? (
            <ImageBackground
              source={{uri: `${MEDIA_PREFIX}${image}`}}
              defaultSource={image_placeholder_uri}
              style={[
                styles.makeImage,
                {
                  width: width + 1,
                  height: image_height ? (image_height / image_width * (width + 1)) : 0
                }
              ]}
            >
              <Pressable
                style={{...StyleSheet.absoluteFill}}
                onPress={() =>  {
                  if (recipes.length) {
                    navigation.navigate(
                      MODAL_SCREENS.recipeDetail.name,
                      {
                        uuid: recipes[0].uuid,
                        nav_origin: route
                      }
                    );
                  } else {
                    return;
                  }
                }}
              >
                { tags_component }
                <View style={{...StyleSheet.absoluteFill, top: image_height ? (image_height / image_width * (width + 1) - 64) : 0, bottom: 0, height: 64}}>
                  <LinearGradient/>
                  { image_action_panel }
                </View>
              </Pressable>
            </ImageBackground>
          ) : (
            <View
              style={[
                styles.makeImage,
                {
                  width,
                  height: width
                }
              ]}
            >
              <RecipeImagePlaceholder
                ref={ref => {
                  if (ref && ref.toDataURL && !recipe_placeholder_uri) {
                    ref.toDataURL(
                      base64 => {
                        setRecipePlaceholderUri(`data:image/png;base64,${base64}`);
                      }
                    );
                  }
                }}
                emoji={emojis[0]}
              />
              <Pressable
                style={{...StyleSheet.absoluteFill}}
                onPress={() =>  {
                  if (recipes.length) {
                    navigation.navigate(
                      MODAL_SCREENS.recipeDetail.name,
                      {
                        uuid: recipes[0].uuid,
                        nav_origin: route
                      }
                    );
                  } else {
                    return;
                  }
                }}
              >
                { tags_component }
                <View style={{...StyleSheet.absoluteFill, top: width - 64, bottom: 0, height: 64}}>
                  <LinearGradient/>
                  { image_action_panel }
                </View>
              </Pressable>
            </View>
          )
        }
      </View>
      {
        (recipes.length !== 0) ? (
          <TouchableOpacity
            onPress={() => navigation.navigate(
              MODAL_SCREENS.recipeDetail.name,
              {
                uuid: recipes[0].uuid,
                nav_origin: route
              }
            )}
            style={[styles.recipeCaption, theme.secondary]}
          >
            <View style={styles.recipeCaptionText}>
              <Body2 style={theme.secondary}>{recipes[0].instance.name}</Body2>
              {
                recipes[0].author ? (
                  <XSmall style={theme.secondary}>{`${t(`recipes:recipeType.${recipes[0].recipe_type}`)} ${t('recipes:byAuthor')} ${recipes[0].author.username}`}</XSmall>
                ) : null
              }
              {
                recipes[0].source ? (
                  <XSmall style={theme.secondary}>{recipes[0].source.name}</XSmall>
                ) : null
              }
            </View>
            <View style={common_styles.inlineIcon}>
              <ArrowRight color={theme.secondary.color}/>
            </View>
          </TouchableOpacity>
        ) : null
      }
      {
        caption ? (
          <>
            <VSkipTiny />
            <View style={styles.makeBody}>
              <Body2>
                <Body1 style={theme.general}>{(authors.length > 0) ? authors[0].username : null}  </Body1>
                <Body2 style={theme.general}>{caption}</Body2>
              </Body2>
            </View>
          </>
        ) : null
      }
      <VSkipSmall/>
    </>
  );
}

const ReportSubmitModal = ({
  visible,
  onRequestClose,
  report_entry_uuid,
  report_type,
  submitReport
}) => {
  const [ submit_enabled, setSubmitEnabled ] = useState(true);
  const [ comment, setComment ] = useState('');

  const { bottom, top } = useSafeAreaInsets();

  const { t } = useTranslation('common');

  const theme = useTheme();

  return (
    <Modal
      animationType={Platform.OS === "web" ? "none" : "fade"}
      transparent
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <KeyboardAvoidingView behavior='height'>
        <View style={common_styles.modalContainer}>
          <Pressable style={common_styles.modalBackground} onPress={onRequestClose}/>
          <View
            style={[
              common_styles.modal,
              theme.general,
              {
                width: '100%',
                bottom: 0,
                position: (Platform.OS === 'web') ? 'fixed' : 'absolute',
                paddingBottom: bottom,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }
            ]}
          >
           <TitleWClose
              title_string={t('feed.reportCommentTitle')}
              close={onRequestClose}
            />
            <XSmall style={theme.general}>{t('feed.reportCommentLabel')}</XSmall>
            <TextInput
              style={[textField, theme.textField, {height: 200}]}
              placeholder={t(`feed.reportCommentPlaceholder`)}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={comment}
              onChangeText={value => setComment(value)}
              multiline
              autoFocus
            />
            <View style={common_styles.buttonContainer}>
              <Button
                style={theme.button}
                onPress={() => {
                  setSubmitEnabled(false);
                  submitReport(
                    report_entry_uuid,
                    report_type,
                    comment,
                    /*final_callback*/() => {
                      setSubmitEnabled(true);
                      onRequestClose()
                    }
                  );
                }}
                disabled={!submit_enabled}
              >
                <ButtonText style={theme.button}>{t('feed.submitReport')}</ButtonText>
              </Button>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}

const Feed = ({
  is_superuser,
  username,
  user_uuid,
  entries,
  hidden_entries,
  can_load_more,
  addBookmark,
  deleteBookmark,
  addReaction,
  deleteRecipeShare,
  deleteReaction,
  hideEntry,
  loadFeed,
  loadRecipe,
  pushNotification,
  submitReport
}) => {
  const [ show_entry_action_picker, setShowEntryActionPicker ] = useState(false);

  const [ show_report_type_picker, setShowReportTypePicker ] = useState(false);
  const [ show_report_submit_modal, setShowReportSubmitModal ] = useState(false);
  const [ report_type, setReportType ] = useState(ReportType.DISLIKE);
  const [ selected_entry, setSelectedEntry ] = useState(undefined);

  const { t } = useTranslation(['common', 'recipes']);
  const theme = useTheme();
  const width = useAppWidth();
  const navigation = useNavigation();

  const hidden_entries_set = new Set(hidden_entries);

  return (
    <>
      {
        entries?.filter(entry => !hidden_entries_set.has(entry.uuid)).map((entry, index) => {
          if (entry.recipe_share) {
            return (
              <RecipeShare
                key={index}
                {...entry}
                addBookmark={addBookmark}
                deleteBookmark={deleteBookmark}
                addReaction={addReaction}
                deleteReaction={deleteReaction}
                showEntryActionPicker={() => {
                  setSelectedEntry(entry);
                  setShowEntryActionPicker(true);
                }}
              />
            );
          } else {
            return null
          }
        }
        )
      }
      {
        can_load_more ? (
          <View style={{padding}}>
            <Button
              style={theme.button}
              onPress={() => loadFeed(entries.length)}
            >
              <ButtonText style={theme.button}>{t('feed.loadMore')}</ButtonText>
            </Button>
          </View>
        ) : null
      }
      <Picker
        options={[
          {
            label: t('feed.report'),
            action: () => setShowReportTypePicker(true)
          },
          {
            label: t('feed.hide'),
            action: () => hideEntry(selected_entry.uuid)
          },
          ...(is_superuser || (selected_entry && selected_entry.authors.map(user => user.userprofile.uuid).includes(user_uuid))) ?
          [
            {
              label: t('feed.deleteEntry'),
              action: () => {
                deleteRecipeShare(selected_entry.uuid)
              },
              style: {color: COLORS.RED}
            }
          ] : [],
          {
            label: t('feed.shareStory'),
            action: () => {
              if (Platform.OS === 'web') {
                pushNotification(
                  NotificationSeverity.WARNING,
                  t('feed.shareStoryNotAvailable'),
                  3
                );
              } else {
                navigation.navigate(
                  MODAL_SCREENS.storyShare.name,
                  {
                    image: selected_entry?.recipe_share.images[0]
                  }
                )
              }
            },
            disabled: (selected_entry?.recipe_share.images.length === 0)
          }
        ]}
        visible={show_entry_action_picker}
        onRequestClose={() => setShowEntryActionPicker(false)}
      />
      <Picker
        options={Object.values(ReportType).map(report_type => ({
          label: t(`common.reportTypes.${report_type}`),
          action: () => {
            setReportType(report_type);
            setShowReportSubmitModal(true);
          }
        }))}
        visible={show_report_type_picker}
        onRequestClose={() => setShowReportTypePicker(false)}
      />
      <ReportSubmitModal
        visible={show_report_submit_modal}
        onRequestClose={() => setShowReportSubmitModal(false)}
        report_entry_uuid={selected_entry?.uuid}
        report_type={report_type}
        submitReport={submitReport}
      />
    </>
  );
}

const styles = StyleSheet.create({
  makeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding,
    paddingBottom: 0.5 * padding
  },
  makeHeaderUser: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  makeUsedUpIngredientsContainer: {
  },
  makeUsedUpIngredients: {
    alignItems: 'center',
    paddingHorizontal: padding,
    paddingVertical: 0.25 * padding
  },
  makeImageContainer: {
    paddingHorizontal: padding
  },
  makeImage: {
    justifyContent: 'flex-end',
    borderRadius,
    overflow: 'hidden',
  },
  makeTagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding
  },
  makeImageActionsContainer: {
    ...StyleSheet.absoluteFill,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  makeImageAction: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  makeImageActionIcon: {
    padding
  },
  recipeCaption: {
    borderRadius,
    marginTop: 0.25 * padding,
    marginHorizontal: padding,
    padding: 0.5 * padding,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  recipeCaptionText: {
    flex: 1
  },
  makeBody: {
    paddingTop: 0.25 * padding,
    paddingHorizontal: padding
  }
});

export default Feed;
