import AsyncStorage from '@react-native-async-storage/async-storage';

export const storageGetStringItem = async function (key) {
  return await AsyncStorage.getItem(key);
}

export const storageSetStringItem = async (key, value) => {
  await AsyncStorage.setItem(key, value);
}

export const storageGetItem = async function (key) {
  const json_value = await AsyncStorage.getItem(key);
  return (json_value !== null) ? JSON.parse(json_value) : null
}

export const storageSetItem = async (key, value) => {
  try {
    const json_value = JSON.stringify(value)
    await AsyncStorage.setItem(key, json_value);
  } catch (error) {
    console.error('Error in setItem', error, 'key', key, 'value', value);
  }
}

export const storageRemoveItem = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
    console.error('Error in removeItem', error, 'key', key);
  }
}
