import React from 'react';

import Svg, { Circle as SvgCircle, Rect } from 'react-native-svg';

const Circle = ({color = 'white', stroke_width=5, filled = false}) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <SvgCircle
       style={{stroke:color, strokeWidth:stroke_width, fill:'none'}}
       cx="50"
       cy="50"
       r={`${50 - stroke_width}`} />
    {
      filled ? (
        <SvgCircle
           style={{fill:color}}
           cx="50"
           cy="50"
           r={`${50 - 2 * stroke_width}`} />
      ) : null
    }
  </Svg>
);

export default Circle;
