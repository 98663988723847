import React, {
  useCallback,
  useEffect,
  useState
} from 'react';

import {
  Picker,
  Pressable,
  Switch,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';

import {
  Trans,
  useTranslation
} from 'react-i18next';

import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';

import { track } from '@amplitude/analytics-react-native';

import Eye from '../../assets/Eye';
import EyeOff from '../../assets/EyeOff';

import Button from '../../common/components/Button';
import auth_styles from '../../auth/components/AuthStyles';
import common_styles, {
  noShadow,
  padding,
  text_styles_raw,
  textField
} from '../../common/components/CommonStyles';
import {
  Alert,
  Br,
  ButtonText,
  Body2,
  Small,
  XSmall,
  VSkipHuge,
  VSkipMedium,
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import ResetPasswordContainer from '../containers/ResetPasswordContainer';

import {
  APP_TABS,
  AuthState,
  DIETS,
  LANGUAGES,
  MISC_SCREENS,
  USER_SCREENS
} from '../../common/constants/';

import styles from './AuthStyles';

export const Mode = Object.freeze({
  'SIGN_UP': 0,
  'LOGIN': 1,
  'RESET_PASSWORD': 2,
  'USERNAME_SENT': 3
});

const LoginSignUp = ({
  auth_state,
  loginRequest,
  resetPasswordRequest,
  signUpRequest,
  initial_mode,
  onboarding_answers,
  onSignUpSubmit,
  onSignUpSuccess,
  onSignUpFailure
}) => {
  const [mode, setMode] = useState(initial_mode ? initial_mode : Mode.SIGN_UP);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subscribed, setSubscribed] = useState(true);
  const [household_uuid, setHouseholdUUID] = useState('');
  const [alerts, setAlerts] = useState([]);
  const [buttons_enabled, setButtonsEnabled] = useState(true);
  const [conceal_pw, setConcealPw] = useState(true);

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const route = useRoute();
  const is_focused = useIsFocused();

  const theme = useTheme();

  useEffect(() => {
    if (is_focused) {
      if (auth_state === AuthState.LOGGED_IN) {
        navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name});
      }
      if (route.params && route.params.uuid) {
        setHouseholdUUID(route.params.uuid);
      }
      if (initial_mode) {
        setMode(initial_mode);
      }
    }
  }, [auth_state, route, is_focused, initial_mode]);

  const onSignUpFailureCb = useCallback((alert) => {
    if (onSignUpFailure) onSignUpFailure();
    setAlerts([...alerts, alert]);
  });
  const pushAlert = useCallback((alert) => {
    setAlerts([...alerts, alert]);
  });

  const small_print = (
    <Trans i18nKey='smallPrint' t={t}>
      <XSmall style={[theme.general, styles.label, {fontSize: text_styles_raw.small.fontSize}]}>
        By using the app, you are accepting the <XSmall style={{textDecorationLine: 'underline', fontSize: text_styles_raw.small.fontSize}} onPress={() => {navigation.navigate(MISC_SCREENS.termsAndConditions.name)}}>terms and conditions</XSmall>, as well as our <XSmall style={{textDecorationLine: 'underline', fontSize: text_styles_raw.small.fontSize}} onPress={() => navigation.navigate(MISC_SCREENS.privacyPolicy.name)}>privacy policy</XSmall>.
      </XSmall>
    </Trans>
  );

  switch (mode) {
    case Mode.SIGN_UP:
      return (
        <>
          <VSkipHuge/>

          <Small style={[theme.general, styles.label]}>
            {t('auth.username')}
          </Small>
          <VSkipMedium/>
          <TextInput
            autoCorrect={false}
            autoComplete="username-new"
            textContentType="username"
            placeholder={t('auth.username')}
            placeholderTextColor={theme.textFieldPlaceholder.color}
            value={username}
            onChangeText={text => setUsername(text)}
            style={[textField, theme.textField]}
            autoCapitalize="none"
          />

          <VSkipHuge/>

          <Small style={[theme.general, styles.label]}>
            {t('profile.settings.eMail')}
          </Small>
          <VSkipMedium/>
          <TextInput
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="email"
            textContentType="emailAddress"
            placeholder={t('profile.settings.eMail')}
            placeholderTextColor={theme.textFieldPlaceholder.color}
            value={email}
            onChangeText={text => setEmail(text)}
            style={[textField, theme.textField]}
          />

          <VSkipHuge/>

          <Small style={[theme.general, styles.label]}>
            {t('auth.password')}
          </Small>
          <VSkipMedium/>
          <View
            style={[
              theme.textField,
              common_styles.searchBar,
              noShadow
            ]}
          >
            <TextInput
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="password-new"
              textContentType="oneTimeCode" //"newPassword"
              placeholder={t('auth.password')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={password}
              onChangeText={text => setPassword(text)}
              secureTextEntry={conceal_pw}
              style={[
                theme.textField,
                { flex: 1, padding: 0 }
              ]}
            />
            <TouchableOpacity
              style={[
                common_styles.inlineIcon,
                { marginLeft: padding }
              ]}
              onPress={() => setConcealPw(!conceal_pw)}
            >
              {
                conceal_pw ? (
                  <Eye color={theme.textField.color}/>
                ) : (
                  <EyeOff color={theme.textField.color}/>
                )
              }
            </TouchableOpacity>
          </View>

          <VSkipHuge/>

          <View style={auth_styles.switchContainer}>
            <Body2 style={[theme.general, styles.label]}>
              { `${t('profile.settings.subscribe')} (${t('profile.settings.noSpam')})` }
            </Body2>
            <Switch
              value={subscribed}
              onValueChange={() => setSubscribed(!subscribed)}
              thumbColor={theme.switchThumbColor}
              trackColor={theme.switchTrackColor}
            />
          </View>

          <VSkipHuge/>

          {
            alerts.map((alert, index) => (<Alert key={index} text={alert}/>))
          }

          <VSkipMedium/>

          { small_print }

          <VSkipMedium/>

          <View style={common_styles.buttonContainer}>
            <Button
              style={theme.button}
              onPress={() => {
                if (onSignUpSubmit) onSignUpSubmit();
                setAlerts([]);
                setButtonsEnabled(false);
                track('sign_up', {
                  category: 'auth'
                });
                let payload = {
                  username,
                  email,
                  password,
                  userprofile: {subscribed}
                };
                if (household_uuid) {
                  payload = {...payload, household: {uuid: household_uuid}};
                }
                if (onboarding_answers) {
                  payload = {...payload, onboarding_answers};
                  payload.userprofile = {...payload.userprofile, diet: onboarding_answers.diet};
                  delete payload.onboarding_answers.diet;
                }
                signUpRequest(
                  payload,
                  onSignUpSuccess,
                  onSignUpFailureCb,
                  () => {
                    setButtonsEnabled(true)
                  }
                );
              }}
              disabled={!buttons_enabled}
            >
              <ButtonText style={theme.button}>
                {t('auth.signUp')}
              </ButtonText>
            </Button>
          </View>
          <VSkipMedium/>
          <View style={common_styles.buttonContainer}>
            <Button
              style={[theme.secondaryButton]}
              onPress={() => {
                setAlerts([]);
                setMode(Mode.LOGIN);
              }}
            >
              <ButtonText style={[theme.secondaryButton, {fontSize: 17}]}>
                {t('auth.logInButton')}
              </ButtonText>
            </Button>
          </View>
        </>
      );
    case Mode.LOGIN:
      return (
        <>
          <VSkipHuge/>

          <Small style={[theme.general, styles.label]}>
            {t('auth.usernameOrEmail')}
          </Small>
          <VSkipMedium/>
          <TextInput
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={t('auth.usernameOrEmail')}
            placeholderTextColor={theme.textFieldPlaceholder.color}
            value={username}
            onChangeText={text => setUsername(text)}
            style={[textField, theme.textField]}
            autoComplete="username"
            textContentType="username"
          />

          <VSkipHuge/>

          <Small style={[theme.general, styles.label]}>
            {t('auth.password')}
          </Small>
          <VSkipMedium/>
          <View
            style={[
              theme.textField,
              common_styles.searchBar,
              noShadow
            ]}
          >
            <TextInput
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="password"
              textContentType="password"
              placeholder={t('auth.password')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={password}
              onChangeText={text => setPassword(text)}
              secureTextEntry={conceal_pw}
              style={[
                theme.textField,
                { flex: 1, padding: 0 }
              ]}
            />
            <TouchableOpacity
              style={[
                common_styles.inlineIcon,
                { marginLeft: padding }
              ]}
              onPress={() => setConcealPw(!conceal_pw)}
            >
              {
                conceal_pw ? (
                  <Eye color={theme.textField.color}/>
                ) : (
                  <EyeOff color={theme.textField.color}/>
                )
              }
            </TouchableOpacity>
          </View>

          <VSkipHuge/>

          {
            alerts.map((alert, index) => (<Alert key={index} text={alert}/>))
          }

          <VSkipMedium/>

          { small_print }

          <VSkipMedium/>

          <View style={common_styles.buttonContainer}>
            <Button
              style={theme.button}
              onPress={() => {
                setAlerts([]);
                setButtonsEnabled(false);
                track('log_in', {
                  category: 'auth'
                });
                let payload = {
                  username,
                  password
                };
                if (household_uuid) {
                  payload = {...payload, household: {uuid: household_uuid}};
                }
                loginRequest(
                  payload,
                  () => {},
                  pushAlert,
                  () => setButtonsEnabled(true)
                );
              }}
              disabled={!buttons_enabled}
            >
              <ButtonText style={theme.button}>
                { t('auth.logIn') }
              </ButtonText>
            </Button>
          </View>
          <VSkipMedium/>

          <View style={common_styles.buttonContainer}>
            <Button
              style={theme.secondaryButton}
              onPress={() => {
                setAlerts([]);
                setMode(Mode.SIGN_UP)
              }}
            >
              <ButtonText style={[theme.secondaryButton, {fontSize: 17}]}>
                { t('auth.registerButton') }
              </ButtonText>
            </Button>
          </View>
          <VSkipMedium/>

          <View style={[common_styles.buttonContainer, {justifyContent: 'center', alignItems: 'center'}]}>
            <Button
              style={theme.secondaryButton}
              onPress={() => {
                setAlerts([]);
                setMode(Mode.RESET_PASSWORD)
              }}
            >
              <ButtonText style={theme.secondaryButton}>
                { t('auth.forgotPasswordButton') }
              </ButtonText>
            </Button>
          </View>
        </>
      );
    case Mode.RESET_PASSWORD:
      return (
        <>
          <VSkipHuge/>

          <Small style={[theme.general, styles.label]}>
            {t('auth.usernameOrEmail')}
          </Small>
          <VSkipMedium/>
          <TextInput
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="username"
            textContentType="username"
            placeholder={t('auth.usernameOrEmail')}
            placeholderTextColor={theme.textFieldPlaceholder.color}
            value={username}
            onChangeText={text => setUsername(text)}
            style={[textField, theme.textField]}
          />

          <VSkipHuge/>

          <View style={common_styles.buttonContainer}>
            <Button
              style={theme.button}
              onPress={() => {
                track('reset_password_request', {
                  category: 'auth'
                });
                resetPasswordRequest(username);
                setMode(Mode.USERNAME_SENT);
              }}
            >
              <ButtonText style={theme.button}>
                { t('auth.forgotPassword') }
              </ButtonText>
            </Button>
          </View>
          <VSkipMedium/>
          <View style={common_styles.buttonContainer}>
            <Button
              style={[theme.secondaryButton]}
              onPress={() => {
                setAlerts([]);
                setMode(Mode.SIGN_UP)
              }}
            >
              <ButtonText style={[theme.secondaryButton, {fontSize: 17}]}>
                { t('auth.registerButton') }
              </ButtonText>
            </Button>
          </View>
        </>
      );
    case Mode.USERNAME_SENT:
      return (
        <>
          <VSkipHuge/>

          <Body2 style={theme.general}>
            { t('auth.forgotPasswordConfirmation') }
          </Body2>

          <VSkipHuge/>

          <View style={common_styles.buttonContainer}>
            <Button
              style={theme.button}
              onPress={() => {
                setMode(Mode.LOGIN);
              }}
            >
              <ButtonText style={theme.button}>
                { t('auth.logIn') }
              </ButtonText>
            </Button>
          </View>
        </>
      );
  }
};

export default LoginSignUp;
