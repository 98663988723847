import React from 'react';
import { TouchableOpacity } from 'react-native';

import common_styles from './CommonStyles';

const Button = (props) => {

  if (props.disabled === true) {
    return (
      <TouchableOpacity {...props} style={[common_styles.button, props.style, {opacity: 0.5}]}>
        { props.children }
      </TouchableOpacity>
    );
  } else {
    return (
      <TouchableOpacity {...props} style={[common_styles.button, props.style]}>
        { props.children }
      </TouchableOpacity>
    );

  }
}

export default Button;
