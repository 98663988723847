import { connect } from 'react-redux';

import { putIngredient } from '../../actions';

import IngredientEditor from '../components/IngredientEditor';

const mapDispatchToProps = ({
  putIngredient
})

export default connect(
  null,
  mapDispatchToProps
)(IngredientEditor)
