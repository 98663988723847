import { connect } from 'react-redux';

import {
  addRecipe,
  updateRecipe
} from '../../actions';

import RecipeEditor from '../components/RecipeEditor';

const mapDispatchToProps = {
  addRecipe,
  updateRecipe
}

export default connect(
  null,
  mapDispatchToProps
)(RecipeEditor)
