import React from 'react';

import Svg, { Path } from 'react-native-svg';

const Heart = ({color = 'white', filled = false, ...props}) => (
  <Svg
     width="100%"
     height="100%"
     viewBox="0 0 24 21"
     xmlns="http://www.w3.org/2000/svg"
     >
    <Path
      d="M10.892 4.875c-.564-.797-1.167-1.405-1.809-1.823-.641-.418-1.419-.627-2.333-.627-1.167 0-2.139.389-2.917 1.167-.777.778-1.166 1.75-1.166 2.916 0 1.012.359 2.086 1.079 3.223a22.274 22.274 0 0 0 2.581 3.311 44.925 44.925 0 0 0 3.092 3.004c1.06.933 1.92 1.701 2.581 2.304.661-.603 1.522-1.371 2.581-2.304a44.7 44.7 0 0 0 3.092-3.004 22.274 22.274 0 0 0 2.581-3.311c.72-1.137 1.079-2.211 1.079-3.223 0-1.166-.388-2.138-1.166-2.916-.778-.778-1.75-1.167-2.917-1.167-.914 0-1.692.209-2.333.627-.642.418-1.245 1.026-1.809 1.823A1.331 1.331 0 0 1 12 5.458a1.331 1.331 0 0 1-1.108-.583Z"
      style={{
        fill: filled ? color : 'none',
        fillRule: "nonzero",
      }}
      transform="matrix(1.04851 0 0 1.08603 -.582 -.894)"
    />
    <Path
      d="m10.425 20.1-2.012-1.837a79.67 79.67 0 0 1-5.586-5.615C1.165 10.791.333 8.745.333 6.508c0-1.827.613-3.354 1.838-4.579C3.396.704 4.922.092 6.75.092c1.031 0 2.003.219 2.917.656A6.948 6.948 0 0 1 12 2.542 6.948 6.948 0 0 1 14.333.748 6.68 6.68 0 0 1 17.25.092c1.828 0 3.354.612 4.579 1.837 1.225 1.225 1.838 2.752 1.838 4.579 0 2.237-.827 4.288-2.479 6.155-1.653 1.866-3.53 3.743-5.63 5.629L13.575 20.1a2.203 2.203 0 0 1-1.575.642 2.203 2.203 0 0 1-1.575-.642Zm.467-15.225c-.564-.797-1.167-1.405-1.809-1.823-.641-.418-1.419-.627-2.333-.627-1.167 0-2.139.389-2.917 1.167-.777.778-1.166 1.75-1.166 2.916 0 1.012.359 2.086 1.079 3.223a22.274 22.274 0 0 0 2.581 3.311 44.925 44.925 0 0 0 3.092 3.004c1.06.933 1.92 1.701 2.581 2.304.661-.603 1.522-1.371 2.581-2.304a44.7 44.7 0 0 0 3.092-3.004 22.274 22.274 0 0 0 2.581-3.311c.72-1.137 1.079-2.211 1.079-3.223 0-1.166-.388-2.138-1.166-2.916-.778-.778-1.75-1.167-2.917-1.167-.914 0-1.692.209-2.333.627-.642.418-1.245 1.026-1.809 1.823A1.331 1.331 0 0 1 12 5.458a1.331 1.331 0 0 1-1.108-.583Z"
      style={{
        fill: color,
        fillRule: "nonzero",
      }}
    />
  </Svg>
);

export default Heart;
