import React from 'react'
import { Modal, Pressable, ScrollView, StyleSheet, View } from 'react-native';

import common_styles, { fullWidth } from '../../common/components/CommonStyles';

const ScrollableModal = ({ visible, onRequestClose, children }) => (
  <>
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      transparent
    >
      <Pressable style={[common_styles.modalBackground, {...StyleSheet.absoluteFill}]} onPress={onRequestClose}/>
    </Modal>
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      transparent
    >
      <ScrollView style={styles.scrollView}>
        <View
          style={common_styles.modalContainer}
        >
          { children }
        </View>
      </ScrollView>
    </Modal>
  </>
);

const styles = StyleSheet.create({
  scrollView: {
    ...StyleSheet.absoluteFill,
    ...fullWidth,
    height: '100%',
    padding: 0
  }
});

export default ScrollableModal;
