import React, { useEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  useIsFocused,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';

import { track } from '@amplitude/analytics-react-native';

import Button from '../../common/components/Button';
import IngredientsList from '../../common/components/IngredientsList';
import IngredientBase, {
  ING_STATE
} from '../../common/components/Ingredient';

import IngredientPickerContainer from '../../ingredients/containers/IngredientPickerContainer';
import SearchBar from '../../ingredients/containers/SearchBar';

import common_styles, {
  borderRadius,
  margin,
  padding,
  roundedBorder,
  text_styles
} from '../../common/components/CommonStyles';
import { Picker } from '../../common/components/Picker';
import { TitleWClose } from '../../header/components/Header';
import {
  API_REQUEST_PRIORITY,
  APP_TABS,
  COLORS,
  FEED_SCREENS,
  HOUSEHOLD_SCREENS,
  HouseholdIngredientCategories,
  SEARCH_SCREENS,
  NotificationSeverity
} from '../../common/constants/';
import {
  Br,
  Body1,
  Body2,
  ButtonText,
  H1,
  H2Serif,
  RoundButton,
  SmallUppercase,
  SquareButton,
  useAppWidth,
  XSmall
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import Cross from '../../assets/Cross';
import DotDotDot from '../../assets/DotDotDot';
import Plus from '../../assets/Plus';

const PantryState = Object.freeze({"SEARCH": 0, "SELECTION": 1});

const ActiveIngredientsList = ({ ingredients, select, deselect }) => (
  <IngredientsList>
    {
      ingredients.map((ingredient, index) => (
          <IngredientBase
            key={index}
            locked={false}
            state={ING_STATE.ACTIVE}
            ingredient={ingredient}
            ingredientAction={() => ingredient.selected ? deselect([ingredient.id]) : select([ingredient.id])}
            selectable
            selected={ingredient.selected}
          />
      ))
    }
  </IngredientsList>
)
const InactiveIngredientsList = ({ ingredients, select, deselect }) => (
  <IngredientsList>
    {
      ingredients.map((ingredient, index) => (
          <IngredientBase
            key={index}
            locked={false}
            state={ING_STATE.INACTIVE}
            ingredient={ingredient}
            ingredientAction={() => ingredient.selected ? deselect([ingredient.id]) : select([ingredient.id])}
            selectable
            selected={ingredient.selected}
          />
      ))
    }
  </IngredientsList>
)

const Pantry = ({
  onScroll,
  scrollEventThrottle,
  header_height,
  active_household_uuid,
  active_ingredients,
  most_recent_ingredient_date,
  addActiveIngredient,
  deleteActiveIngredient,
  pushNotification,
  refreshHousehold,
  resetIngredients,
  setMostRecentIngredientDate,
  updateActiveIngredient
}) => {
  const [ refreshing, setRefreshing ] = useState(false);
  const [ selected, setSelected ] = useState(new Set());
  const [ state, setState ] = useState(PantryState.SEARCH);
  const [ show_actions_picker, setShowActionsPicker ] = useState(false);
  const [ show_ingredient_status_picker, setShowIngredientStatusPicker ] = useState(false);
  const [ show_ingredient_picker, setShowIngredientPicker ] = useState(false);
  const [ selection_panel_height, setSelectionPanelHeight ] = useState(0);

  const { t } = useTranslation('common');

  const width = useAppWidth();
  const { top } = useSafeAreaInsets();
  const tab_bar_height = useBottomTabBarHeight();

  const theme = useTheme();

  const navigation = useNavigation();
  const is_focused = useIsFocused();

  const fresh_ingredients = active_ingredients.filter(ing => ing.category === HouseholdIngredientCategories.FRESH);
  const staple_ingredients = active_ingredients.filter(ing => ing.category === HouseholdIngredientCategories.STAPLE);
  const other_ingredients = active_ingredients.filter(ing => ing.category === HouseholdIngredientCategories.OTHER);

  const num_fresh_ingredients = fresh_ingredients.length;
  const num_staple_ingredients = staple_ingredients.length;
  const num_other_ingredients = other_ingredients.length;

  const select = (ids) => {
    const new_selected = new Set([...selected]);
    ids.forEach(id => new_selected.add(id));
    setSelected(new_selected);
  };

  const deselect = (ids) => {
    const new_selected = new Set([...selected]);
    ids.forEach(id => new_selected.delete(id));
    setSelected(new_selected);
  };

  const find_recipes = () => {
    track('find_recipes_from_selection', {
      category: 'pantry'
    });
    navigation.navigate(
      APP_TABS.search.name,
      {
        screen: SEARCH_SCREENS.recipeSearch.name,
        params: {
          ingredients: active_ingredients.filter(ing => selected.has(ing.id))
        }
      }
    );
  };

  const onRefresh = () => {
    setRefreshing(true);
    refreshHousehold(
      /*final_callback*/() => setRefreshing(false)
    );
  }

  useEffect(() => {
    if (selected.size === 0) {
      setState(PantryState.SEARCH);
    } else {
      setState(PantryState.SELECTION);
    }
  }, [selected]);

  useEffect(() => {
    if (is_focused && !active_ingredients.length) {
      onRefresh();
    }
  }, [is_focused]);

  useEffect(() => {
    if (most_recent_ingredient_date) {
      setMostRecentIngredientDate(
        most_recent_ingredient_date,
        active_household_uuid
      );
    }
  }, [most_recent_ingredient_date]);

  const pantry = (
    <ScrollView
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          tintColor={theme.general.color}
          progressViewOffset={header_height + top}
        />
      }
      onScroll={onScroll}
      scrollEventThrottle={scrollEventThrottle}
      contentContainerStyle={{
        padding,
        paddingTop: padding + header_height + top,
        paddingBottom: selection_panel_height
      }}
    >
        {
          (num_fresh_ingredients + num_other_ingredients + num_staple_ingredients === 0) ? (
            <>
              <View style={{padding}}>
                <Body1 style={[theme.general, {textAlign: 'center'}]}>{t('pantry.noIngredients')}</Body1>
                <Body1 style={[theme.general, {textAlign: 'center'}]}>{t('pantry.noIngredientsHint')}</Body1>
              </View>
              <View style={{flexDirection: 'row', 'justifyContent': 'center'}}>
                <Button
                  style={[theme.secondary, {width: '50%', margin: 'auto'}]}
                  onPress={() => setShowIngredientPicker(true)}
                >
                  <ButtonText style={theme.secondary}>{t('pantry.addIngredient')}</ButtonText>
                </Button>
              </View>
            </>
          ) : null
        }

        {
          num_fresh_ingredients ? (
            <>
              <View style={styles.sectionTitle}>
                <SmallUppercase style={{...theme.general, flex: 1}}>
                  { t('pantry.yourFreshIngredients') }
                </SmallUppercase>
                <View style={styles.sectionTitleButtons}>
                  <SquareButton
                    style={{...theme.secondary, marginRight: 0.5 * padding}}
                    onPress={() => setShowIngredientPicker(true)}
                  >
                    <View
                      style={[
                        common_styles.squareButton,
                        theme.secondary,
                        text_styles.smallUppercase
                      ]}
                    >
                      <Plus color={theme.secondary.color}/>
                    </View>
                  </SquareButton>
                  <TouchableOpacity
                    style={[
                      common_styles.smallButton,
                      theme.secondary
                    ]}
                    onPress={() => {
                      if (fresh_ingredients.filter(ing => selected.has(ing.id)).length === 0) {
                        select(fresh_ingredients.map(ing => ing.id));
                      } else {
                        deselect(fresh_ingredients.map(ing => ing.id));
                      }
                    }}
                  >
                    <SmallUppercase style={theme.secondary}>
                      {
                        (fresh_ingredients.filter(ing => selected.has(ing.id)).length === 0)
                          ? t('pantry.selectAll')
                          : t('pantry.cancel')
                      }
                    </SmallUppercase>
                  </TouchableOpacity>
                </View>
              </View>
              <ActiveIngredientsList
                ingredients={fresh_ingredients.map(ing => {
                  return {...ing, selected: selected.has(ing.id)}
                })}
                select={select}
                deselect={deselect}
              />
              <View style={{height: padding}}/>
            </>
          ) : null
        }
        {
          num_other_ingredients ? (
            <>
              <View style={styles.sectionTitle}>
                <SmallUppercase style={{...theme.general, flex: 1}}>
                  { t('pantry.yourOtherIngredients') }
                </SmallUppercase>
                <View style={styles.sectionTitleButtons}>
                  <SquareButton
                    style={{...theme.secondary, marginRight: 0.5 * padding}}
                    onPress={() => setShowIngredientPicker(true)}
                  >
                    <View
                      style={[
                        common_styles.squareButton,
                        theme.secondary,
                        text_styles.smallUppercase
                      ]}
                    >
                      <Plus color={theme.secondary.color}/>
                    </View>
                  </SquareButton>
                  <TouchableOpacity
                    style={[
                      common_styles.smallButton,
                      theme.secondary
                    ]}
                    onPress={() => {
                      if (other_ingredients.filter(ing => selected.has(ing.id)).length === 0) {
                        select(other_ingredients.map(ing => ing.id));
                      } else {
                        deselect(other_ingredients.map(ing => ing.id));
                      }
                    }}
                  >
                    <SmallUppercase style={theme.secondary}>
                      {
                        (other_ingredients.filter(ing => selected.has(ing.id)).length === 0)
                          ? t('pantry.selectAll')
                          : t('pantry.cancel')
                      }
                    </SmallUppercase>
                  </TouchableOpacity>
                </View>
              </View>
              <ActiveIngredientsList
                ingredients={other_ingredients.map(ing => {
                  return {...ing, selected: selected.has(ing.id)}
                })}
                select={select}
                deselect={deselect}
              />
              <View style={{height: padding}}/>
            </>
          ) : null
        }
        {
          num_staple_ingredients ? (
            <>
              <View style={styles.sectionTitle}>
                <SmallUppercase style={{...theme.general, flex: 1}}>
                  { t('pantry.yourStapleIngredients') }
                </SmallUppercase>
                <View style={styles.sectionTitleButtons}>
                  <SquareButton
                    style={{...theme.secondary, marginRight: 0.5 * padding}}
                    onPress={() => setShowIngredientPicker(true)}
                  >
                    <View
                      style={[
                        common_styles.squareButton,
                        theme.secondary,
                        text_styles.smallUppercase
                      ]}
                    >
                      <Plus color={theme.secondary.color}/>
                    </View>
                  </SquareButton>
                  <TouchableOpacity
                    style={[
                      common_styles.smallButton,
                      theme.secondary
                    ]}
                    onPress={() => {
                      if (staple_ingredients.filter(ing => selected.has(ing.id)).length === 0) {
                        select(staple_ingredients.map(ing => ing.id));
                      } else {
                        deselect(staple_ingredients.map(ing => ing.id));
                      }
                    }}
                  >
                    <SmallUppercase style={theme.secondary}>
                      {
                        (staple_ingredients.filter(ing => selected.has(ing.id)).length === 0)
                          ? t('pantry.selectAll')
                          : t('pantry.cancel')
                      }
                    </SmallUppercase>
                  </TouchableOpacity>
                </View>
              </View>
              <ActiveIngredientsList
                ingredients={staple_ingredients.map(ing => {
                  return {...ing, selected: selected.has(ing.id)}
                })}
                select={select}
                deselect={deselect}
              />
              <View style={{height: padding}}/>
            </>
          ) : null
        }
        {/*
          delete_confirmed ? (
            <Button
               style={[common_styles.button, theme.button, styles.resetButton]}
               onPress={() => resetIngredients()}
             >
               <Body2 style={[theme.button, styles.resetButton]}>
                 { t('pantry.resetIngredientsConfirm') }
               </Body2>
             </Button>
           ) : (
            <Button
               style={[common_styles.button, theme.button, styles.resetButton]}
               onPress={() => setDeleteConfirmed(true)}
             >
               <Body2 style={[theme.button, styles.resetButton]}>
                 { t('pantry.resetIngredients') }
               </Body2>
             </Button>
           )*/
        }
    </ScrollView>
  );

  const selection_panel = (
    <View
      style={[
        styles.selectionContainer,
        {
          width,
          paddingBottom: tab_bar_height
        }
      ]}
      onLayout={(event) => {
        const { height } = event.nativeEvent?.layout;
        setSelectionPanelHeight(height);
      }}
    >
      <TouchableOpacity
        style={[styles.selection, theme.selection]}
        onPress={find_recipes}
      >
        <RoundButton
          style={[common_styles.smallButton, {backgroundColor: theme.selection.color}]}
          onPress={() => {
            deselect([...selected]);
          }}
        >
          <Cross color={theme.selection.backgroundColor}/>
        </RoundButton>
        <View style={{alignItems: 'center'}}>
          <H1 style={[theme.selection]}>
            { t('pantry.findRecipes') }
          </H1>
          <XSmall style={[theme.selection]}>
            { t('pantry.selectedIngredients', {count: selected.size}) }
          </XSmall>
        </View>
        <RoundButton
          style={[theme.selection]}
          onPress={() => setShowActionsPicker(true)}
        >
          <DotDotDot color={theme.selection.color}/>
        </RoundButton>
      </TouchableOpacity>
    </View>
  );

  const search_panel = (
    <Modal
      animationType={Platform.OS === "web" ? "none" : "fade"}
      visible={show_ingredient_picker}
      onRequestClose={() => setShowIngredientPicker(false)}
      transparent
    >
      <KeyboardAvoidingView behavior='height'>
        <View style={common_styles.modalContainer}>
          <Pressable style={common_styles.modalBackground} onPress={() => setShowIngredientPicker(false)}/>
          <View
            style={[
              common_styles.modal,
              theme.general,
              {
                width,
                top,
                position: (Platform.OS === 'web') ? 'fixed' : 'absolute',
                bottom: 0,
                padding: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }
            ]}
          >
            <View
              style={{width: '100%', margin}}
            >
              <TitleWClose
                style={{padding, paddingBottom: 0}}
                close={() => setShowIngredientPicker(false)}
                title_string={t('pantry.addIngredient')}
              />
              <Br/>
              <View style={{paddingHorizontal: padding}}>
                <SearchBar
                  auto_focus={true}
                />
              </View>
            </View>
            <ScrollView
              keyboardShouldPersistTaps='handled'
              contentContainerStyle={{padding}}
            >
              <IngredientPickerContainer
                addIngredient={(ingredient) => addActiveIngredient(ingredient)}
                deleteIngredient={(ingredient) => select([ingredient.id])}
                onPick={() => setShowIngredientPicker(false)}
              />
            </ScrollView>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );

  return (
    <>
      { pantry }
      { (selected.size === 0) ? null : selection_panel }
      { search_panel }
      <Picker
        options={[
          {
            label: t('pantry.removeIngredients'),
            action: () => setShowIngredientStatusPicker(true)
          },
          {
            label: t('pantry.moveToFreshIngs'),
            action: () => {
              active_ingredients.filter(
                ing => selected.has(ing.id)
              ).forEach(
                ing => updateActiveIngredient(ing, HouseholdIngredientCategories.FRESH)
              )
            },
            disabled: fresh_ingredients.filter(ing => selected.has(ing.id)).length === selected.size
          },
          {
            label: t('pantry.moveToOtherIngs'),
            action: () => {
              active_ingredients.filter(
                ing => selected.has(ing.id)
              ).forEach(
                ing => updateActiveIngredient(ing, HouseholdIngredientCategories.OTHER)
              )
            },
            disabled: other_ingredients.filter(ing => selected.has(ing.id)).length === selected.size
          },
          {
            label: t('pantry.moveToStapleIngs'),
            action: () => {
              active_ingredients.filter(
                ing => selected.has(ing.id)
              ).forEach(
                ing => updateActiveIngredient(ing, HouseholdIngredientCategories.STAPLE)
              )
            },
            disabled: staple_ingredients.filter(ing => selected.has(ing.id)).length === selected.size
          },
          {
            label: t('householdRecipes.addRecipe'),
            action: () => {
              track('recipe_from_selection', {
                category: 'pantry'
              });
              navigation.navigate(
                APP_TABS.feed.name,
                {
                  screen: FEED_SCREENS.postMake.name,
                  params: {
                    ingredients: active_ingredients.filter(ing => selected.has(ing.id))
                  }
                }
              );
            }
          },
          {
            label: t('pantry.findRecipes'),
            action: find_recipes
          }
        ]}
        visible={show_actions_picker}
        onRequestClose={() => setShowActionsPicker(false)}
      />
      <Picker
        options={[
          {
            label: t('pantry.markUsed'),
            action: () => {
              track('mark_ingredients_used', {
                category: 'pantry'
              });
              const ids = [];
              active_ingredients.filter(ing => selected.has(ing.id)).forEach(ing => {
                ids.push(ing.id);
                deleteActiveIngredient(ing, 'used');
              });
              deselect(ids)
            }
          },
          {
            label: t('pantry.markWasted'),
            action: () => {
              track('mark_ingredients_wasted', {
                category: 'pantry'
              });
              const ids = [];
              active_ingredients.filter(ing => selected.has(ing.id)).forEach(ing => {
                ids.push(ing.id);
                deleteActiveIngredient(ing, 'wasted');
              });
              deselect(ids)
            }
          },
          {
            label: t('pantry.skip'),
            action: () => {
              track('remove_ingredients_without_status', {
                category: 'pantry'
              });
              const ids = [];
              active_ingredients.filter(ing => selected.has(ing.id)).forEach(ing => {
                ids.push(ing.id);
                deleteActiveIngredient(ing);
              });
              deselect(ids);
            }
          },
        ]}
        visible={show_ingredient_status_picker}
        onRequestClose={() => setShowIngredientStatusPicker(false)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  resetButton: {
    backgroundColor: COLORS.RED
  },
  sectionTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sectionTitleButtons: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  selectionContainer: {
    flexDirection: 'row',
    padding,
    position: 'absolute',
    bottom: (Platform.OS === 'ios') ? padding : 0
  },
  selection: {
    flex: 1,
    padding,
    borderRadius,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectionButtons: {
    flexDirection: 'row',
    alignItems: 'center'
  }
});

export default Pantry;
