import { connect } from 'react-redux';

import {
  addActiveIngredient,
  addRequiredIngredient,
  deleteRequiredIngredient,
  recipeWizardQuery
} from '../../actions';

import WizardView from '../components/WizardView';

const mapStateToProps = state => ({
  required_ingredients: state.wizard.query.required_ingredients,
  always_active_ingredients: state.ingredients.always_active,
  active_ingredients: state.ingredients.active_ingredients,
  inactive_ingredients: state.ingredients.inactive_ingredients,
  query: state.wizard.query,
  result: state.wizard.result,
  diet: state.profile.userprofile.diet
});

const mapDispatchToProps = {
  addActiveIngredient,
  addRequiredIngredient,
  deleteRequiredIngredient,
  recipeWizardQuery
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WizardView)
