import { put, select } from 'redux-saga/effects';

import i18n from 'i18next';

import {
  connectionLoss,
  setConnectionState,
  pushNotification
} from '../../actions/';
import {
  API_TIMEOUT,
  API_REQUEST_PRIORITY,
  ConnectionState,
  NotificationSeverity
} from '../constants/';

function* apiRequestBase(
  endpoint,
  method,
  headers,
  body,
  error_handling,
  priority
) {
  let success = false;
  let ret_val = {};
  const controller = new AbortController()
  let request = {
    method,
    headers,
    signal: controller.signal
  };
  if (method === 'POST' || method === 'PUT' || method === 'PATCH' || method === 'DELETE') {
    request = {...request, body};
  }
  try {
    const timeout = setTimeout(() => controller.abort(), API_TIMEOUT);
    const response = yield fetch(endpoint, request);
    const errors = []
    if (response.ok) {
      ret_val = yield response.json();
      success = true;
    } else if (error_handling) {
      const data_out = yield response.json();
      for (let key in data_out) {
        const prop = data_out[key];
        if (Array.isArray(prop)) {
          prop.forEach(entry => {
            if (typeof entry === 'string') {
              errors.push(entry)
            }
          });
        } else if (typeof prop === 'string') {
          errors.push(prop)
        }
      }
      console.error('errors in apiRequestBase. errors: ', errors);
    }
    yield put(setConnectionState(ConnectionState.OK));
    return {success, data: ret_val, errors};
  } catch (error) {
    success = false;
    console.error('exception in apiRequestBase. error: ', error);
    if ((error instanceof TypeError) || (error.name === 'AbortError')) {
      console.log('No connection or timeout')
      yield put(setConnectionState(ConnectionState.LOST_CONNECTION, `${method} request to ${endpoint} failed.\n(body: ${body})`));
      yield put(connectionLoss());
      if (priority === API_REQUEST_PRIORITY.P0) {
        yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.connectionLoss'), 3))
      }
    } else if (error instanceof SyntaxError) {
      console.log('There was a SyntaxError (no special treatment)');
      yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.syntaxError'), 3))
    } else {
      console.log(`There was a different error (no special treatment). error name ${error.name}`);
      yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.unknownError'), 3))
    }
    return {success, errors: [error.message ? error.message : error.toString()]};
  }
}

export default function* apiRequest(
  endpoint,
  method,
  data,
  auth = false,
  error_handling = false,
  priority = API_REQUEST_PRIORITY.P0
) {
  let headers = {'Content-Type': 'application/json'};
  if (i18n.language) {
    headers = {...headers, 'Accept-Language': i18n.language.slice(0, 2)};
  }
  if (auth) {
    const token = yield select(state => state.auth.access);
    headers = {...headers, 'Authorization': `Bearer ${token}`}
  }
  return yield apiRequestBase(
    endpoint,
    method,
    headers,
    JSON.stringify(data),
    error_handling,
    priority
  );
};

export function* formDataApiRequest(
  endpoint,
  method,
  form_data,
  auth = false,
  error_handling = false,
  priority = API_REQUEST_PRIORITY.P0
) {
  let headers = {
    'Accept': 'application/json',
    //'Content-Type': 'multipart/form-data'
  };
  if (i18n.language) {
    headers = {...headers, 'Accept-Language': i18n.language.slice(0, 2)};
  }
  if (auth) {
    const token = yield select(state => state.auth.access);
    headers = {...headers, 'Authorization': `Bearer ${token}`}
  }
  return yield apiRequestBase(
    endpoint,
    method,
    headers,
    form_data,
    error_handling,
    priority
  );
};

