import React, { useEffect, useRef, useState } from 'react';
import { Switch, TextInput, View } from 'react-native';

import { useTranslation } from 'react-i18next';

import common_styles, { fullWidth, textField } from '../../common/components/CommonStyles';
import Button from '../../common/components/Button';
import ScrollableModal from '../../common/components/ScrollableModal';
import Picker from '../../common/components/Picker';

import { ButtonText, Small, useAppWidth } from '../../utils/';
import { useTheme } from '../../utils/Theme';

const CATEGORIES = [
  'produce',
  'dairy',
  'meat',
  'seafood',
  'refrigerated',
  'baking & spices',
  'oils & vinegars',
  'bread & baked goods',
  'condiments',
  'liquids & drinks',
  'beer, wine & liquor',
  'canned goods',
  'pasta & grains',
  'nuts & seeds',
];
const SHELF_LIFE = Object.freeze({
  'UP_TO_2_DAYS': 0,
  'UP_TO_5_DAYS': 1,
  'UP_TO_7_DAYS': 2,
  'UP_TO_10_DAYS': 3,
  'UP_TO_14_DAYS': 4,
  'UP_TO_21_DAYS': 5,
  'WEEKS': 6,
  'MONTHS': 7,
  'FOREVER': 8
});

const IngredientEditor = ({ visible, onRequestClose, putIngredient }) => {
  const name_ref = useRef();
  const name_plural_ref = useRef();
  const keywords_ref = useRef();
  const name_de_ref = useRef();
  const name_de_plural_ref = useRef();
  const keywords_de_ref = useRef();
  const emoji_ref = useRef();
  const [category, setCategory] = useState(CATEGORIES[0]);
  const [shelf_life, setShelfLife] = useState(SHELF_LIFE.FOREVER);
  const [is_meat, setIsMeat] = useState(false);
  const [is_fish, setIsFish] = useState(false);
  const [is_animal_product, setIsAnimalProduct] = useState(false);
  const [show_category_picker, setShowCategoryPicker] = useState(false);
  const [show_shelf_life_picker, setShowShelfLifePicker] = useState(false);

  const { t } = useTranslation('common');

  const theme = useTheme();

  const width = useAppWidth();

  const callPostIngredient = () => {
    const keywords_vec = keywords_ref.current.value.split(',').map(keyword => keyword.toLowerCase());
    const keywords_de_vec = keywords_de_ref.current.value.split(',').map(keyword => keyword.toLowerCase());
    let ingredient = {
      name: name_ref.current.value,
      name_de: name_de_ref.current.value,
      keywords: keywords_vec,
      keywords_de: keywords_de_vec,
      category,
      shelf_life,
      is_meat,
      is_fish,
      is_animal_product
    };
    if (emoji_ref.current.value) {
      ingredient = {...ingredient, emoji: emoji_ref.current.value};
    }
    if (name_plural_ref.current.value) {
      ingredient = {...ingredient, name_plural: name_plural_ref.current.value};
    }
    if (name_de_plural_ref.current.value) {
      ingredient = {...ingredient, name_de_plural: name_de_plural_ref.current.value};
    }
    putIngredient(ingredient);
  };

  const simple_text_fields = [
    {
      label: 'Ingredient Name (En)',
      ref: name_ref
    },
    {
      label: 'Name Plural (En)',
      ref: name_plural_ref
    },
    {
      label: 'Keywords (En)',
      ref: keywords_ref
    },
    {
      label: 'Ingredient Name De',
      ref: name_de_ref
    },
    {
      label: 'Name Plural (de)',
      ref: name_de_plural_ref
    },
    {
      label: 'Keywords (De)',
      ref: keywords_de_ref
    },
    {
      label: 'Emoji',
      ref: emoji_ref
    }
  ];

  const checkbox_fields = [
    {
      label: 'Is Meat',
      variable: is_meat,
      setVariable: setIsMeat
    },
    {
      label: 'Is Fish',
      variable: is_fish,
      setVariable: setIsFish
    },
    {
      label: 'Is Animal Product',
      variable: is_animal_product,
      setVariable: setIsAnimalProduct
    }
  ]

  return (
    <ScrollableModal
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <View
        style={[
          common_styles.modal,
          theme.general,
        ]}
      >
        {
          simple_text_fields.map(({label, ref}, index) => (
            <View key={index} style={{...fullWidth}}>
              <Small style={theme.general}>{label}</Small>
              <TextInput ref={ref} onChangeText={text => ref.current.value = text} style={[textField, theme.textField]}/>
            </View>
          ))
        }
        <Picker
          value={category}
          values={CATEGORIES}
          setValue={setCategory}
          visible={show_category_picker}
          onRequestClose={() => setShowCategoryPicker(false)}
          i18n_prefix={'common'}
        />
        <Picker
          value={shelf_life}
          values={SHELF_LIFE}
          setValue={setShelfLife}
          visible={show_shelf_life_picker}
          onRequestClose={() => setShowShelfLifePicker(false)}
          i18n_prefix={'common'}
        />
        <View style={{...fullWidth}}>
          {
            checkbox_fields.map(({label, variable, setVariable}, index) => (
              <View key={index}>
                <Small style={theme.general}>{label}</Small>
                <Switch value={variable} onValueChange={() => setVariable(!variable)} />
              </View>
            ))
          }
        </View>
        <View style={common_styles.buttonContainer}>
          <Button
            onPress={() => callPostIngredient()}
            style={theme.button}
          >
            <ButtonText style={theme.button}>
              submit
            </ButtonText>
          </Button>
          <Button
            onPress={() => onRequestClose()}
            style={theme.button}
          >
            <ButtonText style={theme.button}>
              cancel
            </ButtonText>
          </Button>
        </View>
      </View>
    </ScrollableModal>
  );
}

export default IngredientEditor
