import { connect } from 'react-redux';

import HouseholdNav from '../components/HouseholdNav';

const mapStateToProps = state => {
  const uuid = state.active_household_uuid;
  const household = state.profile.households.find(household => (household.uuid === uuid));
  return {
    active_household: household,
    profile: state.profile
  };
};

export default connect(
  mapStateToProps
)(HouseholdNav)
