import { connect } from 'react-redux';

import { postRecipeShare } from '../../actions';

import PostMake from '../components/PostMake';

const mapStateToProps = state => ({
  household_uuid: state.active_household_uuid
})

const mapDispatchToProps = {
  postRecipeShare
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostMake)
