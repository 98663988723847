import React from 'react';
import { View } from 'react-native';
import Svg, { ForeignObject, Path } from 'react-native-svg';

import { COLORS } from '../common/constants/';

const CircleBlob = ({
  color = COLORS.LAVENDER,
  children,
  ...props
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 79 79"
    {...props}
  >
    <Path
      style={{fill: color}}
      d="M66.239 10.444c7.925 7.12 13.106 17.591 12.743 27.702-.414 10.111-6.475 19.81-14.349 27.238-7.874 7.48-17.613 12.793-28.128 13.516-10.516.773-21.757-2.94-28.388-10.421C1.435 60.999-.586 49.805.14 39.281c.725-10.472 4.247-20.273 10.878-27.34C17.648 4.82 27.49.385 37.644.024c10.205-.361 20.72 3.353 28.595 10.42Z"
    />
    <ForeignObject x={0} y={0} width={79} height={79}>
      <View
        style={{
          width: 79,
          height: 79,
          padding: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        { children }
      </View>
    </ForeignObject>
  </Svg>
);

export default CircleBlob;
