import React from 'react';

import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect
} from "react-native-svg";

const MealPlan = ({color = 'white', stroke_width = 20, filled = false}) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 200 200"
    version="1.1"
    id="svg5"
  >
    <Defs>
      <ClipPath id="_clip1">
          <Rect x="25.69" y="98.303" width="151.995" height="88.081"/>
      </ClipPath>
    </Defs>
    <G id="Layer1">
      <G transform="matrix(1,0,0,1.10092,-1.68742,-32.8655)">
        <Path d="M177.685,87.837C177.685,69.708 161.48,54.989 141.521,54.989L61.854,54.989C41.895,54.989 25.69,69.708 25.69,87.837L25.69,153.535C25.69,171.665 41.895,186.384 61.854,186.384L141.521,186.384C161.48,186.384 177.685,171.665 177.685,153.535L177.685,87.837Z" style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}/>
      </G>
      <G transform="matrix(1,0,0,1,14.0733,-5.39093)">
        <Path d="M9.929,80.749L161.924,80.749" style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}/>
      </G>
      <G transform="matrix(1,0,0,1,4.26769,19.7983)">
        <Path d="M59.72,-7.711L59.745,23.459" style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}/>
      </G>
      <G transform="matrix(1,0,0,1,76.3209,19.7983)">
        <Path d="M59.72,-7.711L59.745,23.459" style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}/>
      </G>
      <G transform="matrix(1,0,0,1.10092,-1.68742,-32.8655)">
        <G clipPath="url(#_clip1)">
          <Path d="M177.685,87.837C177.685,69.708 161.48,54.989 141.521,54.989L61.854,54.989C41.895,54.989 25.69,69.708 25.69,87.837L25.69,153.535C25.69,171.665 41.895,186.384 61.854,186.384L141.521,186.384C161.48,186.384 177.685,171.665 177.685,153.535L177.685,87.837Z" style={{fill: filled ? color : 'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}/>
        </G>
      </G>
    </G>
  </Svg>
);

export default MealPlan;
