import React, { useEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TitleWClose } from '../../header/components/Header';

import common_styles, {
  castShadow,
  padding,
  textField
} from '../../common/components/CommonStyles';
import {
  Body2,
  SearchBar,
  Small,
  useAppWidth
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import Cross from '../../assets/Cross';

export const Tag = ({ tag, onPress, onDelete, ...props }) => {
  const theme = useTheme();

  return (
    <TouchableOpacity
      style={[common_styles.smallIng, theme.purpleBg]}
      onPress={onPress}
      {...props}
    >
      <Small style={theme.purpleBg}>{ tag.tag }</Small>
      {
        onDelete ? (
          <Pressable
            style={{height: 12, width: 12, alignItems: 'center', margin: 3}}
            onPress={onDelete}
          >
            <Cross color={theme.purpleBg.color}/>
          </Pressable>
        ) : null
      }
    </TouchableOpacity>
  );
};

export const PlusTag = ({ onPress }) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <TouchableOpacity
      style={[common_styles.smallIng, theme.purpleBg]}
      onPress={onPress}
    >
      <Small style={theme.purpleBg}>
        { `+ ${t('recipeEditor.addTags')}` }
      </Small>
    </TouchableOpacity>
  );
};

const AddNewTag = ({ tag_name, onPress }) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <TouchableOpacity
      style={[common_styles.smallIng, theme.activeIngredient]}
      onPress={onPress}
    >
      <Body2 style={theme.activeIngredient}>
        { `+${tag_name}` }
      </Body2>
    </TouchableOpacity>
  );
};

export const TagPicker = ({
  tags,
  show,
  setShow,
  addTag,
  deleteTag,
  postTag,
  tagSearch
}) => {
  const [ search_phrase, setSearchPhrase ] = useState('');
  const [ tag_options, setTagOptions ] = useState([]);

  const width = useAppWidth();
  const { top } = useSafeAreaInsets();

  const { t } = useTranslation(['common', 'recipes']);

  const theme = useTheme();

  const doSearch = () => tagSearch(
    search_phrase,
    /*result_callback*/(search_result_tags) => setTagOptions(search_result_tags),
    /*failure_callback*/() => {},
    /*final_callback*/() => {}
  );

  useEffect(() => {
    if (!search_phrase && show) {
      doSearch();
    }
  }, [search_phrase, show]);

  return (
    <Modal
      animationType={Platform.OS === "web" ? "none" : "fade"}
      transparent
      visible={show}
      onRequestClose={() => setShow(false)}
    >
      <KeyboardAvoidingView behavior='height'>
        <View style={common_styles.modalContainer}>
          <Pressable style={common_styles.modalBackground} onPress={() => setShow(false)}/>
          <View
            style={[
              theme.general,
              common_styles.modal,
              styles.ingredientPicker,
              {
                width,
                top,
                bottom: 0,
                padding: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              },
            ]}
          >
            <View style={{width: '100%'}}>
              <TitleWClose
                style={{padding}}
                title_string={t(`recipeEditor.addTags`)}
                close={() => setShow(false)}
              />

              <View style={styles.addedIngredientsContainer}>
                <Small style={[theme.general, {paddingHorizontal: padding}]}>{t('recipeEditor.addedTags', {count: tags.length})}</Small>
                <ScrollView
                  horizontal
                  contentContainerStyle={styles.addedIngredients}
                >
                  {
                    (tags.length !== 0) ? tags.map((tag, index) => (
                      <Tag
                        key={index}
                        tag={tag}
                        onDelete={() => deleteTag(tag)}
                        disabled
                      />
                    )) : (
                      <View style={[common_styles.smallIng, theme.secondary]}>
                        <Small style={theme.secondary}>{t('recipeEditor.noTags')}</Small>
                      </View>
                    )
                  }
                </ScrollView>
              </View>

              <View style={{paddingHorizontal: padding}}>
                <SearchBar
                  search_phrase={search_phrase}
                  setSearchPhrase={setSearchPhrase}
                  doSearch={doSearch}
                  placeholder={t('common.search')}
                  autoFocus
                />
              </View>
            </View>
            <ScrollView
              contentContainerStyle={{padding}}
              keyboardShouldPersistTaps='handled'
            >
              <View style={styles.tagOptions}>
              {
                tag_options.map(
                  (tag, index) => (
                    <Tag
                      key={index}
                      tag={tag}
                      onPress={() => {
                        console.log('add tag inner', tag)
                        addTag(tag);
                        setSearchPhrase('');
                      }}
                    />
                  )
                )
              }
              {
                (search_phrase.trim() && !tag_options.map(tag => tag.tag).includes(search_phrase.trim().toLowerCase())) ? (
                  <AddNewTag
                    tag_name={search_phrase.trim().toLowerCase()}
                    onPress={
                      () => postTag(
                        {tag: search_phrase.trim().toLowerCase()},
                        /*result_callback*/(new_tag) => {
                          addTag(new_tag);
                          setSearchPhrase('');
                        },
                        /*failure_callback*/() => {},
                        /*final_callback*/() => {}
                      )
                    }
                  />
                ) : null
              }
              </View>
            </ScrollView>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  ingredientPicker: {
    padding,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: (Platform.OS === 'web') ? 'fixed' : 'absolute',
    ...castShadow
  },
  addedIngredientsContainer: {
    paddingVertical: padding
  },
  addedIngredients: {
    alignItems: 'center',
    paddingHorizontal: padding,
    paddingVertical: 0.25 * padding
  },
  tagOptions: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
});


export default TagPicker;
