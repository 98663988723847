import { connect } from 'react-redux';

import {
  joinHouseholdRequest
} from '../../actions';

import HouseholdJoin from '../components/HouseholdJoin';

const mapStateToProps = state => {
  const uuid = state.active_household_uuid;
  return {
    active_household: state.profile.households.find(household => (household.uuid === uuid))
  };
};

const mapDispatchToProps = {
  joinHouseholdRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HouseholdJoin)
