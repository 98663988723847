import { View } from 'react-native';

import {
  useTheme
} from './Theme';

export const BlurView = ({style, ...props}) => {
  const theme = useTheme();

  return (
    <View style={[
      theme.general,
      style,
      {
        opacity: 0.63
      }
    ]} {...props}/>
  );
};
