import { connect } from 'react-redux';

import {
  loadSuggestedIngredients,
  ingredientSearch,
  setIngredientSearchResults,
  setIngredientsSearchPhrase
} from '../../actions';
import IngredientPicker from '../components/IngredientPicker';

const mapStateToProps = state => {
  return {
    active_ingredients: state.ingredients.active_ingredients,
    suggested: state.ingredients.suggested,
    current_search_phrase: state.ingredients.search.current_search_phrase,
    results: state.ingredients.search.results
  };
}

const mapDispatchToProps = {
  loadSuggestedIngredients,
  ingredientSearch,
  setIngredientSearchResults,
  setIngredientsSearchPhrase
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IngredientPicker)
