import { connect } from 'react-redux';

import RecipeDetail from '../components/RecipeDetail';
import {
  addBookmark,
  deleteBookmark,
  loadRecipe,
  addActiveIngredient,
  addInactiveIngredient,
  addMealPlanEntry,
  deleteMealPlanEntry
} from '../../actions/';

import {
  AuthState
} from '../../common/constants/';

const mapStateToProps = state => {
  const always_active_ingredients = state.ingredients.always_active;
  const active_ingredients = state.ingredients.active_ingredients;
  const inactive_ingredients = state.ingredients.inactive_ingredients;
  const { query, recipe } = state.recipes.recipe_detail;
  let initial_open = false;
  if (recipe) {
    initial_open = true;
  }

  return {
    recipe,
    query,
    initial_open,
    always_active_ingredients,
    active_ingredients,
    inactive_ingredients,
    logged_in: (state.auth_state === AuthState.LOGGED_IN),
    user_uuid: state.profile.userprofile.uuid
  };
}

const mapDispatchToProps = ({
  addBookmark,
  deleteBookmark,
  loadRecipe,
  addActiveIngredient,
  addInactiveIngredient,
  addMealPlanEntry,
  deleteMealPlanEntry
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipeDetail)
