import { all, delay, put, takeEvery } from 'redux-saga/effects';

import {
  clearNotification,
  queueNotification,
} from '../../actions/';
import * as types from '../../actions/types';

import { S_TO_MS } from '../constants/';

let id_count = 0;

function* handlePushNotification(action) {
  const { severity, message } = action;
  const id = id_count;
  id_count += 1;
  yield put(queueNotification(id, severity, message));
  if (action.clears_after_seconds > 0) {
    yield delay(action.clears_after_seconds * S_TO_MS);
    yield put(clearNotification(id))
  }
}

export default function* watchNotifications() {
  yield takeEvery(types.PUSH_NOTIFICATION, handlePushNotification);
}
