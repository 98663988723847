import React from 'react';

import Svg, { Circle, Path, G, Rect } from 'react-native-svg';

const Filter = ({color = 'white', stroke_width=7}) => (
  <Svg
    width="80%"
    height="80%"
    viewBox="0 0 100 100"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <G>
    <Path
       style={{fill:'none',stroke:color,strokeWidth:stroke_width,strokeLinecap:'round'}}
       d="M 17.402287,25 H 82.690331"
       id="path1120" />
    <Path
       style={{fill:'none',stroke:color,strokeWidth:stroke_width,strokeLinecap:'round'}}
       d="M 17.555641,50.607197 H 82.843682"
       id="path1120-3" />
    <Path
       style={{fill:'none',stroke:color,strokeWidth:stroke_width,strokeLinecap:'round'}}
       d="M 17.607993,75 H 82.896034"
       id="path1120-3-6" />
    <Circle
       style={{fill:color,stroke:'none'}}
       id="path1286"
       cx="29.269386"
       cy="24.810509"
       r="8.5" />
    <Circle
       style={{fill:color,stroke:'none'}}
       id="path1286-3"
       cx="39.508068"
       cy="75.153412"
       r="8.5" />
    <Circle
       style={{fill:color,stroke:'none'}}
       id="path1286-5"
       cx="71.204231"
       cy="50.712723"
       r="8.5" />
    </G>
  </Svg>
);

export default Filter;
