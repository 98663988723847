import React from 'react';
import { View } from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import Button from '../../common/components/Button';
import common_styles, { padding } from '../../common/components/CommonStyles';
import { Title } from '../../header/components/Header';

import {
  APP_TABS,
  HOUSEHOLD_SCREENS,
} from '../../common/constants/';
import { BASE_URL } from '../../common/constants/urls';

import {
  Alert,
  BackButton,
  ButtonText,
  H1,
  HidingHeaderScrollView,
  HouseholdInviteButton
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import AddUser from '../../assets/AddUser';

const HouseholdInvite = ({active_household}) => {
  const { t } = useTranslation('common');

  const navigation = useNavigation();

  const theme = useTheme();

  let body = (
    <View
      style={common_styles.buttonContainer}
    >
      <HouseholdInviteButton
        style={theme.button}
        household_uuid={active_household.uuid}
      />
    </View>
  );

  return (
    <HidingHeaderScrollView
      contentContainerStyle={{padding, flex: 1, justifyContent: 'center'}}
      header={(
        <Title
          title_string={t('householdInvite.inviteToHousehold')}
          goBack={() => navigation.navigate(
            APP_TABS.household.name,
            { screen: HOUSEHOLD_SCREENS.householdHome.name}
          )}
        />
      )}
      body={body}
    />
  );
}

export default HouseholdInvite;
