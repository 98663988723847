import { connect } from 'react-redux';

import {
  loadFeed,
  pushNotification,
  setMostRecentRecipeShareDate
} from '../../actions';

import FeedView from '../components/FeedView';

const mapStateToProps = (state) => {
  const has_entries = (state.feed.entries.length !== 0);
  let most_recent_recipe_share_date = null;
  if (has_entries) {
    most_recent_recipe_share_date = state.feed.entries[0].date;
  }

  return {
    has_entries,
    has_new_notifications: state.update_flags.notifications,
    most_recent_recipe_share_date,
    num_followees: state.profile.userprofile.num_followees,
    userprofile: state.profile.userprofile,
    username: state.profile.username
  };
}

const mapDispatchToProps = {
  loadFeed,
  pushNotification,
  setMostRecentRecipeShareDate
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedView)
