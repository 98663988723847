import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';

import { useTranslation } from 'react-i18next'; 

import {
  useNavigation
} from '@react-navigation/native';

import {
  Body2,
  H1,
  HidingHeaderScrollView
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import { padding } from '../../common/components/CommonStyles';
import { Title } from '../../header/components/Header';

const TermsAndConditions = () => {
  const navigation = useNavigation();

  const { t } = useTranslation('terms');
  const theme = useTheme();

  return (
    <HidingHeaderScrollView
      contentContainerStyle={{padding}}
      header={(
        <Title
          goBack={() => navigation.goBack()}
          title_string={t('title')}
        />
      )}
      body={(
        <>
        <Body2 style={theme.general}>
          { t('validFrom') }
        </Body2>
        <Body2 style={theme.general}>
          { t('intro') }
        </Body2>
        <Body2 style={theme.general}>
          { '\n' }
          <H1>
            { t('basicConditionsTitle') }
          </H1>
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText1') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText2') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText3') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText4') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText5') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText6') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText7') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText8') }
        </Body2>
        <Body2 style={theme.general}>
          { t('basicConditionsText9') }
        </Body2>
        <Body2 style={theme.general}>
          { '\n' }
          <H1>
            { t('limitationsOfLiabilityTitle') }
          </H1>
        </Body2>
        <Body2 style={theme.general}>
          { t('limitationsOfLiabilityText1') }
        </Body2>
        <Body2 style={theme.general}>
          { t('limitationsOfLiabilityText2') }
        </Body2>
        <Body2 style={theme.general}>
          { t('limitationsOfLiabilityText3') }
        </Body2>
        <Body2 style={theme.general}>
          { '\n' }
          <H1>
            { t('generalTermsTitle') }
          </H1>
        </Body2>
        <Body2 style={theme.general}>
          { t('generalTermsText1') }
        </Body2>
        <Body2 style={theme.general}>
          { t('generalTermsText2') }
        </Body2>
        </>
      )}
    />
  );
}

export default TermsAndConditions;
