import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import
  watchApiRequests, {
  handleApiHandshake,
  handleGetHeartbeat,
  handleLoadAlwaysActiveIngredients,
  handleLoadProfile,
  handlePostHeartbeat,
} from './api';
import watchAuthRequests, {
  handleCreateGuestAccountRequest,
  refreshTokenIfNeeded
} from './auth';
import watchNotifications from './notifications';
import {
  storageGetItem,
  storageSetItem,
  storageGetStringItem
} from './utils';

import {
  createGuestAccountRequest,
  initialize,
  postHeartbeat,
  reconnect,
  setActiveHousehold,
  setAppState,
  setAuthState,
  setConnectionState,
  setHiddenEntries,
  setRefreshToken
} from '../../actions/';
import {
  CONNECTION_LOSS,
  HIDE_ENTRY,
  INITIALIZE,
  RECONNECT,
  STORE_ACTIVE_HOUSEHOLD
} from '../../actions/types';

import {
  AppState,
  AuthState,
  ConnectionState,
  REFRESH_RATE,
  REFRESH_TOKEN_ID,
  LOCAL_STORAGE_ID_ACTIVE_HOUSEHOLD_UUID,
  LOCAL_STORAGE_ID_HIDDEN_MAKES
} from '../constants/';

function* handleInitialize() {
  yield put(setAppState(AppState.INITIALIZING));
  yield handleApiHandshake();
  let logged_in = false;
  const token = yield call(storageGetStringItem, REFRESH_TOKEN_ID);
  if (token !== null) {
    yield put(setRefreshToken(token));
    logged_in = yield refreshTokenIfNeeded();
  }
  if (!logged_in) {
    yield put(setAuthState(AuthState.NOT_LOGGED_IN));
  } else {
    yield handlePostLogin();
    yield put(setAuthState(AuthState.LOGGED_IN));
  }
  yield put(setAppState(AppState.INITIALIZED));
}

export function* handlePostLogin() {
  yield handleLoadAlwaysActiveIngredients();
  yield handleLoadProfile();
  const { households } = yield select(state => state.profile);
  const active_household_uuid = yield call(storageGetItem, LOCAL_STORAGE_ID_ACTIVE_HOUSEHOLD_UUID);
  if (active_household_uuid !== null) {
    yield put(setActiveHousehold(active_household_uuid));
  }
  if (!active_household_uuid || (households.filter(household => household.uuid === active_household_uuid).length === 0)) {
    // Active household has not been set before.
    if (households && households.length) {
      yield handleStoreActiveHouseholdUUID({active_household_uuid: households[0].uuid});
    }
  }
  const hidden_makes = yield call(storageGetItem, LOCAL_STORAGE_ID_HIDDEN_MAKES);
  if (hidden_makes) {
    yield put(setHiddenEntries(hidden_makes));
  }
  yield handleGetHeartbeat();
  yield handlePostHeartbeat();
  yield put(setAuthState(AuthState.LOGGED_IN));
}

function* handleStoreActiveHouseholdUUID(action) {
  yield call(storageSetItem, LOCAL_STORAGE_ID_ACTIVE_HOUSEHOLD_UUID, action.active_household_uuid);
  yield put(setActiveHousehold(action.active_household_uuid));
}
function* handleHideEntry(action) {
  let hidden_entries_set = new Set();
  let hidden_entries = yield call(storageGetItem, LOCAL_STORAGE_ID_HIDDEN_MAKES);
  if (hidden_entries) {
    hidden_entries_set = new Set(hidden_entries);
  }
  hidden_entries_set.add(action.recipe_share_uuid);
  hidden_entries = [...hidden_entries_set];
  yield call(storageSetItem, LOCAL_STORAGE_ID_HIDDEN_MAKES, hidden_entries);
  yield put(setHiddenMakes(hidden_entries));
}

function* watchStorageRequests() {
  yield all([
    takeLatest(STORE_ACTIVE_HOUSEHOLD, handleStoreActiveHouseholdUUID),
    takeLatest(HIDE_ENTRY, handleHideEntry),
  ]);
}

function* handleReconnect(action) {
  yield delay(1500);

  yield put(initialize());

  yield delay(1500);

  const connection_state = yield select(state => state.connection_state.state);
  if (connection_state === ConnectionState.NO_CONNECTION) {
    yield put(reconnect());  // TODO this action is not going anywhere?
  }
}

function* refresh(action) {
  const auth_state = yield select(state => state.auth_state);
  while (auth_state === AuthState.LOGGED_IN) {
    yield delay(REFRESH_RATE);
    yield put(postHeartbeat());
  }
}

export default function* rootSaga() {
  while (yield take(INITIALIZE)) {
    const init_task = yield race({
      init: call(handleInitialize),
      cancel: take(CONNECTION_LOSS)
    });
    if (init_task.cancel) {
      yield put(setAppState(AppState.INITIALIZATION_FAILED));
      yield put(setConnectionState(ConnectionState.NO_CONNECTION, ''));
      yield fork(handleReconnect);
      continue;
    }
    const all_tasks = yield all([
      fork(watchStorageRequests),
      fork(watchNotifications),
      fork(watchAuthRequests),
      fork(watchApiRequests),
      fork(refresh)
    ]);
    yield take([INITIALIZE]);
    yield cancel(all_tasks);
    yield fork(handleReconnect);
  }
}
