import React, {
  useEffect,
  useState
} from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import {
  useNavigation,
  useRoute
} from '@react-navigation/native';

import { Title } from '../../header/components/Header';
import Button from '../../common/components/Button';
import common_styles, { padding } from '../../common/components/CommonStyles';

import { useTheme } from '../../utils/Theme';
import {
  Body1,
  HidingHeaderScrollView,
  SearchBar
} from '../../utils/';

import {
  APP_TABS,
  USER_SCREENS
} from '../../common/constants/';
import User from '../../assets/User';

const SearchState = Object.freeze({"ACTIVE": 0, "INACTIVE": 1});

export const Account = ({
  username,
  is_followee,
  userprofile,
  addFollow,
  deleteFollow
}) => {
  const [ follow_button_enabled, setFollowButtonEnabled ] = useState(true);
  const [ is_followee_state, setIsFollowee ] = useState(is_followee);

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const theme = useTheme();

  useEffect(() => {
    setIsFollowee(is_followee);
  }, [is_followee]);

  return (
    <Pressable
      style={styles.user}
      onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: username}})}
    >
      <View style={styles.avatarUsername}>
        <View style={[styles.avatar, theme.secondary]}>
          <User color={theme.general.color}/>
        </View>
        <Body1 style={[theme.general, styles.username]}>
          { username }
        </Body1>
      </View>
      {
        is_followee_state ? (
          <Button
            style={[styles.followButton, theme.button]}
            onPress={() => {
              setFollowButtonEnabled(false);
              deleteFollow(
                userprofile.uuid,
                /*success_callback*/() => setIsFollowee(false),
                /*failure_callback*/() => {},
                /*final_callback*/() => setFollowButtonEnabled(true)
              );
            }}
            disabled={!follow_button_enabled}
          >
            <Body1 style={theme.button}>
              { t('profile.following') }
            </Body1>
          </Button>
        ) : (
          <Button
            style={[styles.followButton, theme.button]}
            onPress={() => {
              setFollowButtonEnabled(false);
              addFollow(
                userprofile.uuid,
                /*success_callback*/() => setIsFollowee(true),
                /*failure_callback*/() => {},
                /*final_callback*/() => setFollowButtonEnabled(true)
              );
            }}
            disabled={!follow_button_enabled}
          >
            <Body1 style={theme.button}>
              { t('profile.follow') }
            </Body1>
          </Button>
        )
      }
    </Pressable>
  );
}

const AccountSearch = ({
  account_search_results,
  accountSearch,
  addFollow,
  deleteFollow
}) => {
  const [ search_state, setSearchState ] = useState(SearchState.INACTIVE);
  const [ search_phrase, setSearchPhrase ] = useState('');

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const route = useRoute();
  const theme = useTheme();

  useEffect(() => {
    if (route.params && route.params.search_phrase) {
      setSearchPhrase(route.params.search_phrase);
      accountSearch(route.params.search_phrase, /*offset*/0)
    }
  }, [route.params]);

  useEffect(() => {
    if (search_phrase) {
      setSearchState(SearchState.ACTIVE);
    } else {
      setSearchState(SearchState.INACTIVE);
    }
  }, [search_phrase]);

  let body = null;
  if (search_state === SearchState.ACTIVE) {
    body = (
      <View style={{padding}}>
        {
          account_search_results.accounts.map((account, index) => (
            <Account
              key={index}
              {...account}
              addFollow={addFollow}
              deleteFollow={deleteFollow}
            />
          ))
        }
        {
          account_search_results.can_load_more ? (
            <Button
              style={common_styles.button}
              onPress={() => accountSearch(search_phrase, account_search_results.accounts.length)}
            >
              <Body1 style={common_styles.button}>
                { t('profile.loadMore') }
              </Body1>
            </Button>
          ) : null
        }
      </View>
    );
  }

  return (
    <HidingHeaderScrollView
      header={(
        <View style={{padding}}>
          <Title
            title_string={t('profile.findFriends')}
            goBack={() => navigation.goBack()}
            style={{marginBottom: padding}}
          />
          <SearchBar
            search_phrase={search_phrase}
            setSearchPhrase={setSearchPhrase}
            doSearch={search_phrase => accountSearch(search_phrase, /*offset*/0)}
            placeholder={t('profile.accountSearchPlaceholder')}
            auto_focus
          />
        </View>
      )}
      body={body}
    />
  );
};

const styles = StyleSheet.create({
  user: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 0.5 * padding
  },
  avatarUsername: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20
  },
  username: {
    padding: 0.5 * padding
  },
  followButton: {
  }
})

export default AccountSearch;

