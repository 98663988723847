import { connect } from 'react-redux';

import {
  deleteMealPlanEntry,
  loadHouseholdMealPlan,
  setMostRecentMealPlanEntryDate
} from '../../actions';

import MealPlan from '../components/MealPlan';

const mapStateToProps = state => {
  let most_recent_meal_plan_entry_date = null;
  if (state.household.meal_plan.entries.length !== 0) {
    const entries = state.household.meal_plan.entries
    entries.sort((a,b) => new Date(b.date_added) - new Date(a.date_added));
    most_recent_meal_plan_entry_date = entries[0].date_added;
  }
  return {
    active_household_uuid: state.active_household_uuid,
    meal_plan: state.household.meal_plan.entries,
    most_recent_meal_plan_entry_date,
    can_load_more: state.household.meal_plan.can_load_more
  }
}
const mapDispatchToProps = {
  deleteMealPlanEntry,
  loadHouseholdMealPlan,
  setMostRecentMealPlanEntryDate
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MealPlan)
