import React from 'react';

import Svg, { Circle, Path, Rect } from 'react-native-svg';

const MagnifyingGlass = ({color = 'white', filled = false}) => {
  if (filled) {
    return (
      <Svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        version="1.1"
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path d="M14.5 21.5C18.6421 21.5 22 18.1421 22 14C22 9.85786 18.6421 6.5 14.5 6.5C10.3579 6.5 7 9.85786 7 14C7 18.1421 10.3579 21.5 14.5 21.5ZM14.5 24C16.7809 24 18.8835 23.2363 20.566 21.9507L25.2584 26.6432L27.0262 24.8754L22.3484 20.1976C23.6957 18.4938 24.5 16.3408 24.5 14C24.5 8.47715 20.0228 4 14.5 4C8.97715 4 4.5 8.47715 4.5 14C4.5 19.5228 8.97715 24 14.5 24ZM14.5 19.5C17.5376 19.5 20 17.0376 20 14C20 10.9624 17.5376 8.5 14.5 8.5C11.4624 8.5 9 10.9624 9 14C9 17.0376 11.4624 19.5 14.5 19.5Z" style={{fill: color, fillRule: 'evenodd'}}/>
      </Svg>
    );
  } else {
    return (
      <Svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        version="1.1"
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Circle cx="14.5" cy="14" r="8.75" style={{stroke: color, strokeWidth: 2.5, fill: 'none'}}/>
        <Rect x="19.6016" y="20.9863" width="2.5" height="8" transform="rotate(-45 19.6016 20.9863)" style={{fill: color, strokeWidth: 2.5}}/>
      </Svg>
    );
  }
}

export default MagnifyingGlass;
