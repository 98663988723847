import React from 'react';

import Svg, { Path, G } from "react-native-svg";

const Cross = ({color = 'white', stroke_width = 20}) => (
   <Svg
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
      version="1.1"
      id="svg5">
     <G
        id="layer1">
       <Path
          style={{stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}
          d="M 100,60 100,140"
          id="path68" />
       <Path
          style={{stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}
          d="M 60,100 140,100"
          id="path68-3" />
     </G>
   </Svg>
);

export default Cross;
