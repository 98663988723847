import React, { useEffect } from 'react';

import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';

import {
  APP_TABS,
  HOUSEHOLD_SCREENS
} from '../../common/constants/';

import AddUser from '../../assets/AddUser';

const HouseholdJoin = ({joinHouseholdRequest}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const is_focused = useIsFocused();

  useEffect(() => {
    if (is_focused) {
      console.log('route', route.params, route.params.uuid);
      if (route.params && route.params.uuid) {
        joinHouseholdRequest(route.params.uuid);
      }
      navigation.navigate(
        APP_TABS.household.name,
        { screen: HOUSEHOLD_SCREENS.householdSwitcher.name }
      );
    }
  }, [route, is_focused]);

  return null;
}

export default HouseholdJoin;
