import { connect } from 'react-redux';

import {
  addMealPlanEntry,
  deleteBookmark,
  deleteRecipe,
  loadHouseholdRecipes
} from '../../actions';

import HouseholdRecipes from '../components/HouseholdRecipes';

const mapStateToProps = state => ({
  recipes: state.household.recipes.recipes,
  can_load_more: state.household.recipes.can_load_more
})
const mapDispatchToProps = {
  addMealPlanEntry,
  deleteBookmark,
  deleteRecipe,
  loadHouseholdRecipes
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HouseholdRecipes)
