import React from 'react';

import Svg, { Circle, Path, G } from "react-native-svg";

const Strike01 = ({color = 'white', stroke_width = 2.8}) => (
  <Svg width="100%" height="100%" viewBox="0 0 337 117" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <G id="Layer1" transform="matrix(1.07317,0,0,1.07317,-16.3565,-4.28052)">
      <Path d="M28.046,53.008C28.046,53.008 323.976,13.218 323.944,23.603C323.911,33.989 21.017,53.84 20.639,70.543C20.003,98.62 310.534,9.707 311.3,32.923C312.066,56.138 33.51,75.17 32.758,97.45" style={{fill:'none',stroke:color,strokeWidth:stroke_width}}/>
    </G>
  </Svg>
);

export default Strike01;
