import * as types from './types';

// General.

export const initialize = () => ({
  type: types.INITIALIZE
})

export const reconnect = () => ({
  type: types.RECONNECT
})

export const setApiState = (state, message, dismissable) => ({
  type: types.SET_API_STATE,
  state,
  message,
  dismissable
})
export const setAppState = state => ({
  type: types.SET_APP_STATE,
  state
})
export const setAuthState = state => ({
  type: types.SET_AUTH_STATE,
  state
})
export const setConnectionState = (state, message) => ({
  type: types.SET_CONNECTION_STATE,
  state,
  message
})
export const connectionLoss = () => ({
  type: types.CONNECTION_LOSS,
})

export const setIngredientsSearchPhrase = search_phrase => ({
  type: types.SET_INGREDIENTS_SEARCH_PHRASE,
  search_phrase
})

export const closeRecipe = () => ({
  type: types.CLOSE_RECIPE
})

export const resetIngredients = () => ({
  type: types.RESET_INGREDIENTS
})

export const setActiveHousehold = (active_household_uuid) => ({
  type: types.SET_ACTIVE_HOUSEHOLD,
  active_household_uuid
})

export const storeActiveHousehold = (active_household_uuid) => ({
  type: types.STORE_ACTIVE_HOUSEHOLD,
  active_household_uuid
})

export const hideEntry = (recipe_share_uuid) => ({
  type: types.HIDE_ENTRY,
  recipe_share_uuid
})
export const setHiddenEntries = (recipe_share_uuids) => ({
  type: types.SET_HIDDEN_ENTRIES,
  recipe_share_uuids
})
export const setUpdateFlags = update_flags => ({
  type: types.SET_UPDATE_FLAGS,
  update_flags
})
export const setHeartBeat = (heart_beat) => ({
  type: types.SET_HEART_BEAT,
  heart_beat
})
export const setMostRecentRecipeShareDate = (date) => ({
  type: types.SET_MOST_RECENT_RECIPE_SHARE_DATE,
  date
})
export const setMostRecentNotificationDate = (date) => ({
  type: types.SET_MOST_RECENT_NOTIFICATION_DATE,
  date
})
export const setMostRecentIngredientDate = (date, active_household_uuid) => ({
  type: types.SET_MOST_RECENT_INGREDIENT_DATE,
  date,
  active_household_uuid
})
export const setMostRecentMealPlanEntryDate = (date, active_household_uuid) => ({
  type: types.SET_MOST_RECENT_MEAL_PLAN_ENTRY_DATE,
  date,
  active_household_uuid
})

// Authentication.

export const signUpRequest = (
  account_info,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.SIGN_UP_REQUEST,
  account_info,
  success_callback,
  failure_callback,
  final_callback
})

export const loginRequest = (
  payload,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.LOGIN_REQUEST,
  payload,
  success_callback,
  failure_callback,
  final_callback
})

export const loginSuccess = (payload) => ({
  type: types.LOGIN_SUCCESS,
  payload
})

export const clearAuth = () => ({
  type: types.CLEAR_AUTH
})

export const setRefreshToken = (token) => ({
  type: types.SET_TOKEN,
  token
})

export const refreshTokenRequest = (token) => ({
  type: types.TOKEN_REQUEST,
  token
})

export const refreshTokenSuccess = (payload) => ({
  type: types.TOKEN_SUCCESS,
  payload
})

export const resetPasswordRequest = (username) => ({
  type: types.RESET_PASSWORD_REQUEST,
  username
})

export const setPasswordRequest = (token, uuid, password) => ({
  type: types.SET_PASSWORD_REQUEST,
  token,
  uuid,
  password
})

export const verifyEMailRequest = (token, uuid) => ({
  type: types.VERIFY_EMAIL_REQUEST,
  token,
  uuid
})

export const joinHouseholdRequest = (uuid) => ({
  type: types.JOIN_HOUSEHOLD_REQUEST,
  uuid
})

export const logOut = () => ({
  type: types.LOG_OUT
})

// Notifications.

export const pushNotification = (severity, message, clears_after_seconds) => ({
  type: types.PUSH_NOTIFICATION,
  severity,
  message,
  clears_after_seconds
})
export const queueNotification = (id, severity, message) => ({
  type: types.QUEUE_NOTIFICATION,
  id,
  severity,
  message
})
export const clearNotification = (id) => ({
  type: types.CLEAR_NOTIFICATION,
  id
})

// Api.

export const postHeartbeat = () => ({
  type: types.POST_HEART_BEAT
})

export const refreshHousehold = (final_callback) => ({
  type: types.REFRESH_HOUSEHOLD,
  final_callback
})

export const loadSuggestedIngredients = () => ({
  type: types.LOAD_SUGGESTED_INGREDIENTS
})

export const setSuggestedIngredients = suggested_ingredients => ({
  type: types.SET_SUGGESTED_INGREDIENTS,
  suggested_ingredients
})

export const loadAlwaysActiveIngredients = () => ({
  type: types.LOAD_ALWAYS_ACTIVE_INGREDIENTS
})

export const setAlwaysActiveIngredients = active_ingredients => ({
  type: types.SET_ALWAYS_ACTIVE_INGREDIENTS,
  active_ingredients
})

export const loadHouseholdIngredients = final_callback => ({
  type: types.LOAD_HOUSEHOLD_INGREDIENTS,
  final_callback
})

export const setActiveIngredients = active_ingredients => ({
  type: types.SET_ACTIVE_INGREDIENTS,
  active_ingredients
})

export const loadFeed = (offset, final_callback) => ({
  type: types.LOAD_FEED,
  offset,
  final_callback
})
export const setFeed = (offset, entries, can_load_more) => ({
  type: types.SET_FEED,
  offset,
  entries,
  can_load_more
})
export const postRecipeShare = (
  recipes,
  images,
  caption,
  tags,
  used_up_ingredients,
  household_uuid,
  visibility_type,
  share_type,
  add_to_meal_plan,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.POST_RECIPE_SHARE,
  recipes,
  images,
  caption,
  tags,
  used_up_ingredients,
  household_uuid,
  visibility_type,
  share_type,
  add_to_meal_plan,
  success_callback,
  failure_callback,
  final_callback
})
export const deleteRecipeShare = uuid => ({
  type: types.DELETE_RECIPE_SHARE,
  uuid
})

export const loadNotifications = (offset, final_callback) => ({
  type: types.LOAD_NOTIFICATIONS,
  offset,
  final_callback
})
export const setNotifications = (offset, notifications, can_load_more) => ({
  type: types.SET_NOTIFICATIONS,
  offset,
  notifications,
  can_load_more
})

export const loadHouseholdRecipes = (offset, search_phrase, final_callback) => ({
  type: types.LOAD_HOUSEHOLD_RECIPES,
  offset,
  search_phrase,
  final_callback
})
export const setHouseholdRecipes = (offset, recipes, can_load_more, total_count) => ({
  type: types.SET_HOUSEHOLD_RECIPES,
  offset,
  recipes,
  can_load_more,
  total_count
})
export const addRecipe = (recipe, success_callback, failure_callback, final_callback) => ({
  type: types.ADD_RECIPE,
  recipe,
  success_callback,
  failure_callback,
  final_callback
})
export const updateRecipe = (recipe, success_callback, failure_callback, final_callback) => ({
  type: types.UPDATE_RECIPE,
  recipe,
  success_callback,
  failure_callback,
  final_callback
})
export const deleteRecipe = uuid => ({
  type: types.DELETE_RECIPE,
  uuid
})

export const loadHouseholdMealPlan = (offset, search_phrase, final_callback) => ({
  type: types.LOAD_HOUSEHOLD_MEAL_PLAN,
  offset,
  search_phrase,
  final_callback
})
export const setHouseholdMealPlan = (offset, entries, can_load_more, total_count) => ({
  type: types.SET_HOUSEHOLD_MEAL_PLAN,
  offset,
  entries,
  can_load_more,
  total_count
})
export const addMealPlanEntry = (recipes, success_callback, failure_callback, final_callback) => ({
  type: types.ADD_MEAL_PLAN_ENTRY,
  recipes,
  success_callback,
  failure_callback,
  final_callback
})
export const deleteMealPlanEntry = uuid => ({
  type: types.DELETE_MEAL_PLAN_ENTRY,
  uuid
})

export const ingredientSearch = search_phrase => ({
  type: types.INGREDIENT_SEARCH,
  search_phrase
})

export const setIngredientSearchResults = (search_phrase, ingredients) => ({
  type: types.SET_INGREDIENT_SEARCH_RESULTS,
  search_phrase,
  ingredients
})

export const loadProfile = () => ({
  type: types.LOAD_PROFILE
})

export const updateProfile = account_update => ({
  type: types.UPDATE_PROFILE,
  account_update
})
export const updateAvatar = image => ({
  type: types.UPDATE_AVATAR,
  image
})

export const deleteProfile = () => ({
  type: types.DELETE_PROFILE
})

export const postOnboardingAnswers = onboarding_answers => ({
  type: types.POST_ONBOARDING_ANSWERS,
  onboarding_answers
})

export const deleteOnboardingAnswers = () => ({
  type: types.DELETE_ONBOARDING_ANSWERS
})

export const registerAPNSDevice = token => ({
  type: types.REGISTER_APNS_DEVICE,
  token
})
export const registerGCMDevice = token => ({
  type: types.REGISTER_GCM_DEVICE,
  token
})

export const setProfile = profile => ({
  type: types.SET_PROFILE,
  profile
})

export const setDiet = diet => ({
  type: types.SET_DIET,
  diet
})

export const loadOtherProfile = (username, success_callback, failure_callback, final_callback) => ({
  type: types.LOAD_OTHER_PROFILE,
  username,
  success_callback,
  failure_callback,
  final_callback
})

export const setOtherProfile = profile => ({
  type: types.SET_OTHER_PROFILE,
  profile
})

export const accountSearch = (search_phrase, offset) => ({
  type: types.ACCOUNT_SEARCH,
  search_phrase,
  offset
})

export const setAccountSearchResults = (offset, accounts, can_load_more) => ({
  type: types.SET_ACCOUNT_SEARCH_RESULTS,
  accounts,
  can_load_more,
  offset
})

export const universalSearch = (search_phrase) => ({
  type: types.UNIVERSAL_SEARCH,
  search_phrase
})

export const setUniversalSearchResults = (ingredients, accounts, recipes, suggestions) => ({
  type: types.SET_UNIVERSAL_SEARCH_RESULTS,
  ingredients,
  accounts,
  recipes,
  suggestions
})

export const tagSearch = (search_phrase, result_callback, failure_callback, final_callback) => ({
  type: types.TAG_SEARCH,
  search_phrase,
  result_callback,
  failure_callback,
  final_callback
})
export const postTag = (tag, result_callback, failure_callback, final_callback) => ({
  type: types.POST_TAG,
  tag,
  result_callback,
  failure_callback,
  final_callback
})

export const addFollow = (
  uuid,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.ADD_FOLLOW,
  uuid,
  success_callback,
  failure_callback,
  final_callback
})

export const deleteFollow = (
  uuid,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.DELETE_FOLLOW,
  uuid,
  success_callback,
  failure_callback,
  final_callback
})

export const challengeSearch = (
  search_phrase,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.CHALLENGE_SEARCH,
  search_phrase,
  success_callback,
  failure_callback,
  final_callback
})

export const challengeLoad = (
  uuid,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.CHALLENGE_LOAD,
  uuid,
  success_callback,
  failure_callback,
  final_callback
})

export const challengeEnroll = (
  uuid,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.CHALLENGE_ENROLL,
  uuid,
  success_callback,
  failure_callback,
  final_callback
})

export const challengeLeave = (
  uuid,
  success_callback,
  failure_callback,
  final_callback
) => ({
  type: types.CHALLENGE_LEAVE,
  uuid,
  success_callback,
  failure_callback,
  final_callback
})

export const addBookmark = (
  uuid
) => ({
  type: types.ADD_BOOKMARK,
  uuid
})
export const deleteBookmark = (
  uuid
) => ({
  type: types.DELETE_BOOKMARK,
  uuid
})

export const addReaction = (
  uuid,
  emoji
) => ({
  type: types.ADD_REACTION,
  uuid,
  emoji
})
export const deleteReaction = (
  uuid
) => ({
  type: types.DELETE_REACTION,
  uuid
})

export const submitFeedEntryReport = (
  feed_entry_uuid,
  report_type,
  comment,
  final_callback
) => ({
  type: types.SUBMIT_FEED_ENTRY_REPORT,
  feed_entry_uuid,
  report_type,
  comment,
  final_callback
})

export const addRequiredIngredient = ingredient => ({
  type: types.ADD_REQUIRED_INGREDIENT,
  ingredient
})
export const deleteRequiredIngredient = ingredient => ({
  type: types.DELETE_REQUIRED_INGREDIENT,
  ingredient
})
export const addActiveIngredient = ingredient => ({
  type: types.ADD_ACTIVE_INGREDIENT,
  ingredient
})
export const updateActiveIngredient = (ingredient, category) => ({
  type: types.UPDATE_ACTIVE_INGREDIENT,
  ingredient,
  category
})
export const deleteActiveIngredient = (ingredient, status) => ({
  type: types.DELETE_ACTIVE_INGREDIENT,
  ingredient,
  status
})
export const addInactiveIngredient = ingredient => ({
  type: types.ADD_INACTIVE_INGREDIENT,
  ingredient
})

export const putIngredient = ingredient => ({
  type: types.PUT_INGREDIENT,
  ingredient
})
export const postIngredientSearchPhrase = ingredient => ({
  type: types.POST_INGREDIENT_SEARCH_PHRASE,
  ingredient
})
export const clearPivotIngredientSlot = slot_idx => ({
  type: types.CLEAR_PIVOT_INGREDIENT_SLOT,
  slot_idx
})

export const loadRecipes = (required_ingredients, optional_ingredients) => ({
  type: types.LOAD_RECIPES,
  required_ingredients,
  optional_ingredients
})

export const recipeWizardQuery = (offset, search_phrase, final_callback) => ({
  type: types.RECIPE_WIZARD_QUERY,
  offset,
  search_phrase,
  final_callback
})
export const setRecipeWizardIntermediateResult = (offset, result) => ({
  type: types.SET_RECIPE_WIZARD_INTERMEDIATE_RESULT,
  offset,
  result
})

export const setRecipes = (query, recipes) => ({
  type: types.SET_RECIPES,
  query,
  recipes
})

export const loadRecipe = uuid => ({
  type: types.LOAD_RECIPE,
  uuid
})

export const setRecipe = (query, recipe) => ({
  type: types.SET_RECIPE,
  query,
  recipe
})

export const loadLabellingIngredient = () => ({
  type: types.LOAD_LABELLING_INGREDIENT
})

export const setLabellingIngredient = ingredient => ({
  type: types.SET_LABELLING_INGREDIENT,
  ingredient
})

export const confirmLabellingIngredient = (original_string_wo_quantity, language_code, ingredient) => ({
  type: types.CONFIRM_LABELLING_INGREDIENT,
  original_string_wo_quantity,
  language_code,
  ingredient
})

export const reparseLabellingIngredient = (original_string_wo_quantity, language_code) => ({
  type: types.REPARSE_LABELLING_INGREDIENT,
  original_string_wo_quantity,
  language_code
})

export const deleteLabellingIngredient = (original_string_wo_quantity, language_code) => ({
  type: types.DELETE_LABELLING_INGREDIENT,
  original_string_wo_quantity,
  language_code
})
