import React from 'react';

import Svg, { G, Path } from 'react-native-svg';

const Bookmark = ({color = 'white', filled = false, ...props}) => (
  <Svg
    width='90%'
    height='90%'
    viewBox='0 0 18 20'
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.587 5.459c0-1.384-1.103-2.507-2.463-2.507H5.735c-1.359 0-2.463 1.123-2.463 2.507v13.402L9.43 16.3l6.157 2.561V5.459Z"
      style={{
        fill: filled ? color : 'none',
        stroke: color,
        strokeWidth: "2.13px",
      }}
      transform="matrix(1.0698 0 0 1.05111 -1.088 -.964)"
    />
  </Svg>
)

export default Bookmark;
