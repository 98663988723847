import React from 'react';

import Svg, { G, Path } from "react-native-svg";

const PaperPlane = ({color, stroke_width = 17, filled = false}) => (
  <Svg width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <G id="Layer4">
      <Path d="M76.358,164.096L88.946,113.142" style={{stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}/>
      <Path d="M55.267,100L76.358,164.096" style={{stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}/>
      <Path d="M76.358,164.096L109.21,127.249"style={{stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}/>
      <Path d="M12.31,90.745L56.532,100L132.722,71.581L88.786,113.388L143.378,146.62L185.656,32L12.31,90.745Z" style={{fill: 'none', stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}/>
      <Path d="M12.31,90.745L56.532,100L132.722,71.581L88.786,113.388L143.378,146.62L185.656,32L12.31,90.745Z" style={{fill: filled ? color : 'none'}}/>
    </G>
  </Svg>
);

export default PaperPlane;
