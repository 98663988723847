import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { Platform } from 'react-native';

import i18n from 'i18next';

import { setUserId } from '@amplitude/analytics-react-native';

import apiRequest, { formDataApiRequest } from './common';
import { refreshTokenIfNeeded } from './auth';
import {
  storageGetItem,
  storageSetItem,
  storageGetStringItem,
  storageSetStringItem
} from './utils';

import {
  loadHouseholdRecipes,
  logOut,
  pushNotification,
  setApiState,
  setDiet,
  setSuggestedIngredients,
  setUniversalSearchResults,
  setAccountSearchResults,
  setAlwaysActiveIngredients,
  setActiveIngredients,
  setIngredientSearchResults,
  setFeed,
  setHeartBeat,
  setHouseholdMealPlan,
  setHouseholdRecipes,
  setNotifications,
  setOtherProfile,
  setProfile,
  setRecipe,
  setUpdateFlags,
  setLabellingIngredient,
  setRecipeWizardIntermediateResult,
  storeActiveHousehold
} from '../../actions/';
import * as types from '../../actions/types';

import {
  API_REQUEST_DELAY,
  API_REQUEST_PRIORITY,
  ApiState,
  AuthState,
  NotificationSeverity,
  ReportType
} from '../constants/';
import { API_PREFIX } from '../constants/urls';
import metadata from '../constants/metadata';

const api_state = new Map();
api_state.set('OK', ApiState.OK);
api_state.set('NEW_VERSION_AVAILABLE', ApiState.NEW_VERSION_AVAILABLE);
api_state.set('NEEDS_UPDATE', ApiState.NEEDS_UPDATE);
api_state.set('ERROR', ApiState.ERROR);

export function* handleApiHandshake(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/handshake/`, 'POST', metadata, false);
  if (success) {
    yield put(setApiState(api_state.get(data.status), data.message, data.dismissable));
  } else {
    yield put(setApiState(ApiState.ERROR, '', false));
  }
}

export function* handleGetHeartbeat() {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/heartbeat/`, 'GET', {}, true);
  if (success) {
    yield put(setHeartBeat(data));
  }
}

export function* handlePostHeartbeat(action) {
  yield refreshTokenIfNeeded();
  const heart_beat = yield select(state => state.heart_beat);
  const active_household_uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/heartbeat/`, 'POST', {...heart_beat, active_household_uuid}, true, API_REQUEST_PRIORITY.P1);
  if (success) {
    yield put(setUpdateFlags(data));
  }
}

//export function* handleLoadSuggestedIngredients(action) {
//  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/ingredients/suggested/`, 'GET', {}, false);
//  if (success) {
//    yield put(setSuggestedIngredients(data));
//  } else {
//    yield put(setSuggestedIngredients([]));
//  }
//}

export function* handleLoadAlwaysActiveIngredients() {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/ingredients/?always_active=True`, 'GET', {}, true);
  if (success) {
    yield put(setAlwaysActiveIngredients(data));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'load always active ingredients failed', 3))
  }
}

export function* handleLoadProfile() {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/userprofile/`, 'GET', {}, true);
  if (success) {
    setUserId(data.userprofile.uuid);
    yield put(setProfile(data));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'load profile failed', 3))
  }
}

function* handleUpdateProfile(action) {
  yield refreshTokenIfNeeded();
  const { success, data, errors } = yield apiRequest(`${API_PREFIX}/api/v0/userprofile/`, 'PATCH', action.account_update, true, true);
  if (success) {
    yield put(setProfile(data));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.updateProfileFailed'), 3))
  }
}

function* handleUpdateAvatar(action) {
  const path = action.image.path;

  const form_data = new FormData();
  form_data.append('image', {
    name: path.substring(path.lastIndexOf('/') + 1),
    type: action.image.mime,
    uri: (Platform.OS === 'ios') ? path.replace('file://', '') : path
  });

  yield refreshTokenIfNeeded();
  const { success, data } = yield formDataApiRequest(`${API_PREFIX}/api/v0/userprofile/avatar/`, 'POST', form_data, true);
  if (success) {
    yield put(setProfile(data));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.updateProfileFailed'), 3))
  }
}

export function* handleDeleteProfile() {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/userprofile/`, 'DELETE', {}, true);
  if (success) {
    yield put(logOut());
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteProfileFailed'), 3))
  }
}

export function* handlePostOnboardingAnswers(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/onboarding_answers/`, 'POST', {...action.onboarding_answers}, true);
  if (success) {
    yield put(setProfile(data));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.postOnboardingAnswersFailed'), 3))
  }
}

export function* handleDeleteOnboardingAnswers(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/onboarding_answers/`, 'DELETE', {}, true);
  if (success) {
    yield put(setProfile(data));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteOnboardingAnswersFailed'), 3))
  }
}

function* handleRegisterAPNSDevice(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/device/apns/`, 'POST', {registration_id: action.token}, true);
  if (!success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.registerDeviceFailed'), 3))
  }
}

function* handleRegisterGCMDevice(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/device/gcm/`, 'POST', {registration_id: action.token}, true);
  if (!success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.registerDeviceFailed'), 3))
  }
}

export function* handleLoadOtherProfile(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/users/${action.username}/`, 'GET', {}, true);
  if (success) {
    yield put(setOtherProfile(data));
    if (action.success_callback) action.success_callback();
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.loadOtherProfileFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleAddFollow(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/follows/`, 'POST', {uuid: action.uuid}, true);
  if (success) {
    if (action.success_callback) action.success_callback();
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.addFollowFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleDeleteFollow(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/follows/${action.uuid}/`, 'DELETE', {}, true);
  if (success) {
    if (action.success_callback) action.success_callback();
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteFollowFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleChallengeSearch(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/challenges/?search=${action.search_phrase}`, 'GET', {}, true);
  if (success) {
    if (action.success_callback) action.success_callback(data);
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.challengeSearchFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleChallengeLoad(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/challenges/${action.uuid}/`, 'GET', {}, true);
  if (success) {
    if (action.success_callback) action.success_callback(data);
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.challengeLoadFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleChallengeEnroll(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/challenge/join/`, 'POST', {uuid: action.uuid}, true);
  if (success) {
    if (action.success_callback) action.success_callback(data);
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.challengeEnrollFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleChallengeLeave(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/challenge/join/`, 'DELETE', {uuid: action.uuid}, true);
  if (success) {
    if (action.success_callback) action.success_callback(data);
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.challengeLeaveFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleAddBookmark(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/bookmark/`, 'POST', {uuid: action.uuid}, true);
  if (!success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.addBookmarkFailed'), 3))
  }
}

function* handleDeleteBookmark(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/bookmark/`, 'DELETE', {uuid: action.uuid}, true);
  if (!success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteBookmarkFailed'), 3))
  }
}

function* handleAddReaction(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/feed/reaction/`, 'POST', {uuid: action.uuid, emoji: action.emoji}, true);
  if (!success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.addReactionFailed'), 3))
  }
}

function* handleDeleteReaction(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/feed/reaction/`, 'DELETE', {uuid: action.uuid}, true);
  if (!success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteReactionFailed'), 3))
  }
}

function* handleSubmitFeedEntryReport(action) {
  yield refreshTokenIfNeeded();
  let include_credentials = false;
  if (action.report_type === ReportType.COPYRIGHT) {
    include_credentials = true;
  }
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/feed/report/`, 'POST', {uuid: action.feed_entry_uuid, report_type: action.report_type, comment: action.comment}, include_credentials);
  if (!success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.postReportFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleSetDiet(action) {
  yield refreshTokenIfNeeded();
  const profile = yield select(state => state.profile);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/userprofile/`, 'PATCH', profile, true);
  if (success) {
    yield put(setProfile(data));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.setDietFailed'), 3))
  }
}

function* handleAccountSearch(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/users/?offset=${action.offset}&search_phrase=${action.search_phrase}`, 'GET', {}, true);
  if (success) {
    yield put(setAccountSearchResults(action.offset, data.accounts, data.can_load_more));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.accountSearchFailed'), 3))
  }
}

function* handleUniversalSearch(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/search/?search_phrase=${action.search_phrase}`, 'GET', {}, true);
  if (success) {
    yield put(setUniversalSearchResults(data.ingredients, data.accounts, data.recipes, data.suggestions));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.universalSearchFailed'), 3))
  }
}

function* handleTagSearch(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/tags/?search=${action.search_phrase}`, 'GET', {}, true);
  if (success) {
    if (action.result_callback) action.result_callback(data);
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.tagSearchFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handlePostTag(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/tags/`, 'POST', action.tag, true);
  if (success) {
    if (action.result_callback) action.result_callback(data);
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.postTagFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

export function* handleLoadHouseholdIngredients(action) {
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/household_ingredients/?household_uuid=${uuid}`, 'GET', {}, true);
  if (success) {
    yield put(setActiveIngredients(data.map(user_ing => {
      return {
        ...user_ing.ingredient,
        ...user_ing
      }
    })));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'Failed to load household ingredients', 3))
  }
  if (action.final_callback) action.final_callback();
}

export function* handleLoadFeed(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/feed/?offset=${action.offset}`, 'GET', {}, true);
  if (success) {
    yield put(setFeed(action.offset, data.entries, data.can_load_more));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'Failed to load feed', 3))
  }
  if (action.final_callback) action.final_callback();
}

export function* handleLoadNotifications(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/notifications/?offset=${action.offset}`, 'GET', {}, true);
  if (success) {
    yield put(setNotifications(action.offset, data.notifications, data.can_load_more));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'Failed to load notifications', 3))
  }
  if (action.final_callback) action.final_callback();
}

export function* handleLoadHouseholdRecipes(action) {
  yield refreshTokenIfNeeded();
  let query_str = `offset=${action.offset}`;
  if (action.search_phrase) {
    query_str = `${query_str}&search_phrase=${action.search_phrase}`;
  }
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/?household_uuid=${uuid}&${query_str}`, 'GET', {}, true);
  if (success) {
    yield put(setHouseholdRecipes(action.offset, data.recipes, data.can_load_more, data.total_count));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'Failed to load household recipes', 3))
  }
  if (action.final_callback) action.final_callback();
}

export function* handleLoadHouseholdMealPlan(action) {
  yield refreshTokenIfNeeded();
  let query_str = `offset=${action.offset}`;
  if (action.search_phrase) {
    query_str = `${query_str}&search_phrase=${action.search_phrase}`;
  }
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/meal_plan/?household_uuid=${uuid}&${query_str}`, 'GET', {}, true);
  if (success) {
    yield put(setHouseholdMealPlan(action.offset, data.entries, data.can_load_more, data.total_count));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'Failed to load household meal plan', 3))
  }
  if (action.final_callback) action.final_callback();
}

export function* handleRefreshHousehold(action) {
  // HACK: here we forward the action (including final callback) to load ingredients, such that it calls final callback as early as possible (to set refreshing to false).
  yield handleLoadHouseholdIngredients(action);
  yield handleLoadHouseholdRecipes({offset: 0});
  yield handleLoadHouseholdMealPlan({offset: 0});
  //if (action.final_callback) action.final_callback();
}

function* handleAddActiveIngredient(action) {
  if (!action.ingredient || !action.ingredient.id) {  // Make sure only valid ingredients can be added.
    return;
  }
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/household_ingredients/?household_uuid=${uuid}`, 'POST', {ingredient: action.ingredient.id}, true);
  if (success) {
    yield put(setActiveIngredients(data.map(user_ing => {
      return {
        ...user_ing.ingredient,
        ...user_ing
      }
    })));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.addIngredientFailed'), 3))
  }
}
function* handleUpdateActiveIngredient(action) {
  if (!action.ingredient || !action.ingredient.id) {  // Make sure only valid ingredients can be updated.
    return;
  }
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/household_ingredients/${action.ingredient.id}/?household_uuid=${uuid}`, 'PUT', {category: action.category}, true);
  if (success) {
    yield put(setActiveIngredients(data.map(user_ing => {
      return {
        ...user_ing.ingredient,
        ...user_ing
      }
    })));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.updateIngredientFailed'), 3))
  }
}
function* handleDeleteActiveIngredient(action) {
  if (!action.ingredient || !action.ingredient.id) {  // Make sure only valid ingredients can be deleted.
    return;
  }
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/household_ingredients/${action.ingredient.id}/?household_uuid=${uuid}`, 'DELETE', {status: action.status}, true);
  if (success) {
    yield put(setActiveIngredients(data.map(user_ing => {
      return {
        ...user_ing.ingredient,
        ...user_ing
      }
    })));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteIngredientFailed'), 3))
  }
}

function* handleAddInactiveIngredient(action) {
  if (!action.ingredient || !action.ingredient.id) {  // Make sure only valid ingredients can be added.
    return;
  }
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/household_ingredients/${action.ingredient.id}/?household_uuid=${uuid}`, 'DELETE', {}, true);
  if (success) {
    yield put(setActiveIngredients(data.map(user_ing => {
      return {
        ...user_ing.ingredient,
        ...user_ing
      }
    })));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteIngredientFailed'), 3))
  }
}

function* handleResetIngredients(action) {
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/household_ingredients/household_uuid=${uuid}`, 'DELETE', {}, true);
  if (success) {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.resetIngredientsSuccess'), 3))
    yield put(setActiveIngredients(data.map(user_ing => {
      return {
        ...user_ing.ingredient,
        ...user_ing
      }
    })));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.resetIngredientsFailed'), 3))
  }
}

function* handleAddRecipe(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/`, 'POST', action.recipe, true);
  if (success) {
    if (action.success_callback) action.success_callback();
    yield put(loadHouseholdRecipes(/*offset*/0));
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.addRecipeFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleUpdateRecipe(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/${action.recipe.uuid}/`, 'PUT', action.recipe, true);
  if (success) {
    if (action.success_callback) action.success_callback();
    yield put(loadHouseholdRecipes(/*offset*/0));
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.addRecipeFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleDeleteRecipe(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/${action.uuid}/`, 'DELETE', {}, true);
  if (success) {
    yield put(loadHouseholdRecipes(/*offset*/0));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteRecipeFailed'), 3))
  }
}
function* handleAddMealPlanEntry(action) {
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/meal_plan/?household_uuid=${uuid}`, 'POST', {recipes: action.recipes}, true);
  if (success) {
    if (action.success_callback) action.success_callback();
    yield put(setHouseholdMealPlan(/*offset*/0, data.entries, data.can_load_more, data.total_count));
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.addMealPlanEntryFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleDeleteMealPlanEntry(action) {
  yield refreshTokenIfNeeded();
  const uuid = yield select(state => state.active_household_uuid);
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/meal_plan/${action.uuid}/?household_uuid=${uuid}`, 'DELETE', {}, true);
  if (success) {
    yield put(setHouseholdMealPlan(/*offset*/0, data.entries, data.can_load_more, data.total_count));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteMealPlanEntryFailed'), 3))
  }
}

function* handlePostRecipeShare(action) {
  const form_data = new FormData();

  if (action.recipes) {
    action.recipes.forEach((recipe) => form_data.append('recipes[]', JSON.stringify(recipe)));
  }

  action.images.forEach((image) => {
    const path = image.path;
    form_data.append('images[]', {
      name: path.substring(path.lastIndexOf('/') + 1),
      type: image.mime,
      uri: (Platform.OS === 'ios') ? path.replace('file://', '') : path
    });
  });
  action.used_up_ingredients.forEach((ing) => form_data.append('used_up_ingredients[]', JSON.stringify(ing)));
  form_data.append('caption', action.caption);
  action.tags.forEach((tag) => form_data.append('tags[]', JSON.stringify(tag)));
  form_data.append('household_uuid', action.household_uuid);
  form_data.append('visibility_type', action.visibility_type);
  form_data.append('share_type', action.share_type);
  form_data.append('add_to_meal_plan', action.add_to_meal_plan);
  yield refreshTokenIfNeeded();
  const { success, data } = yield formDataApiRequest(`${API_PREFIX}/api/v0/feed/`, 'POST', form_data, true);
  if (success) {
    if (action.success_callback) {
      const new_entry = data.entries.find(entry => (entry.uuid === data.new_entry_uuid));
      let new_entry_image = undefined;
      if (new_entry.recipe_share?.images.length > 0) {
        new_entry_image = new_entry.recipe_share.images[0];
      }
      action.success_callback(new_entry_image);
    }
    yield put(setFeed(/*offset*/0, data.entries, data.can_load_more));
  } else {
    if (action.failure_callback) action.failure_callback();
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.postMakeFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleDeleteRecipeShare(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/feed/${action.uuid}/`, 'DELETE', {}, true);
  if (success) {
    yield put(setFeed(/*offset*/0, data.entries, data.can_load_more));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.deleteMakeFailed'), 3))
  }
}

function* handleIngredientSearch(action) {
  yield delay(API_REQUEST_DELAY);
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/ingredients/?search_phrase=${action.search_phrase}`, 'GET', {}, true);
  if (success) {
    yield put(setIngredientSearchResults(action.search_phrase, data));
  } else {
    yield put(pushNotification(NotificationSeverity.DEBUG, 'Ingredient search failed', 3))
  }
}

function* handleRecipeWizardQuery(action) {
  yield delay(API_REQUEST_DELAY);
  const app_state = yield select(state => state.app_state);
  const { recipe_languages } = yield select(state => state.profile.userprofile);
  const { required_ingredients } = yield select(state => state.wizard.query);
  const { active_ingredients, inactive_ingredients}  = yield select(state => state.ingredients);
  const { diet } = yield select(state => state.profile.userprofile);
  const always_active_ingredients = yield select(state => state.ingredients.always_active);
  const all_active_ingredient_ids = [...new Set([...active_ingredients.map(ing => ing.id), ...always_active_ingredients.map(ing => ing.id)])];
  let query_str = `offset=${action.offset}`;
  if (action.search_phrase) {
    query_str = `${query_str}&search_phrase=${action.search_phrase}`;
  }
  if (required_ingredients.length) {
    query_str = `${query_str}&required_ingredients=${required_ingredients.map(ing => ing.id)}`;
  }
  if (all_active_ingredient_ids.length) {
    query_str = `${query_str}&active_ingredients=${all_active_ingredient_ids}`;
  }
  if (inactive_ingredients.length) {
    query_str = `${query_str}&inactive_ingredients=${inactive_ingredients.map(ing => ing.id)}`;
  }
  query_str = `${query_str}&diet=${diet}`;
  query_str = `${query_str}&recipe_languages=${recipe_languages}`;
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/wizard/?${query_str}`, 'GET', {}, true);
  if (success) {
    yield put(setRecipeWizardIntermediateResult(action.offset, data));
  } else {
    yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.recipeSearchFailed'), 3))
  }
  if (action.final_callback) action.final_callback();
}

function* handleLoadRecipe(action) {
  const { auth_state } = yield select(state => state);
  if (auth_state === AuthState.LOGGED_IN) {
    const uuid = yield select(state => state.active_household_uuid);
    const { active_ingredients, inactive_ingredients }  = yield select(state => state.ingredients);
    const always_active_ingredients = yield select(state => state.ingredients.always_active);
    const all_active_ingredient_ids = [...new Set([...active_ingredients.map(ing => ing.id), ...always_active_ingredients.map(ing => ing.id)])];
    const query = {
      active_ingredients: all_active_ingredient_ids,
      inactive_ingredients: inactive_ingredients.map(ing => ing.id)
    };
    let query_str = `household_uuid=${uuid}`;
    if (all_active_ingredient_ids.length) {
      query_str = `${query_str}&active_ingredients=${all_active_ingredient_ids}`;
    }
    if (inactive_ingredients.length) {
      query_str = `${query_str}&inactive_ingredients=${inactive_ingredients.map(ing => ing.id)}`;
    }
    yield refreshTokenIfNeeded();
    const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/${action.uuid}/?${query_str}`, 'GET', {}, true);
    if (success) {
      yield put(setRecipe(data.query, data.recipe));
    } else {
      yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.loadRecipeFailed'), 3))
    }
  } else {
    const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/recipes/${action.uuid}/`, 'GET', {}, false);
    if (success) {
      yield put(setRecipe(data.query, data.recipe));
    } else {
      yield put(pushNotification(NotificationSeverity.ERROR, i18n.t('common:inAppNotifications.loadRecipeFailed'), 3))
    }
  }
}

function* handlePutIngredient(action) {
  yield refreshTokenIfNeeded();
  yield apiRequest(`${API_PREFIX}/api/v0/ingredients/`, 'POST', {...action.ingredient}, true);
}

function* handlePostIngredientSearchPhrase(action) {
  yield refreshTokenIfNeeded();
  yield apiRequest(`${API_PREFIX}/api/v0/ingredients/keywords/`, 'POST', {...action.ingredient}, true);
}

function* handleLoadLabellingIngredient(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/admin/labelling/`, 'GET', {}, true);
  if (success) {
    yield put(setLabellingIngredient(data));
  } else {
    yield put(setLabellingIngredient(undefined));
  }
}

function* handleConfirmLabellingIngredient(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/admin/labelling/`, 'POST', {...action}, true);
  if (success) {
    yield put(setLabellingIngredient(data));
  } else {
    yield put(setLabellingIngredient(undefined));
  }
}
function* handleReparseLabellingIngredient(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/admin/labelling/`, 'PATCH', {...action}, true);
  if (success) {
    yield put(setLabellingIngredient(data));
  } else {
    yield put(setLabellingIngredient(undefined));
  }
}
function* handleDeleteLabellingIngredient(action) {
  yield refreshTokenIfNeeded();
  const { success, data } = yield apiRequest(`${API_PREFIX}/api/v0/admin/labelling/`, 'DELETE', {...action}, true);
  if (success) {
    yield put(setLabellingIngredient(data));
  } else {
    yield put(setLabellingIngredient(undefined));
  }
}

export default function* watchApiRequests() {
  yield all([
    //yield takeLatest(types.LOAD_SUGGESTED_INGREDIENTS, handleLoadSuggestedIngredients),
    takeLatest(types.INGREDIENT_SEARCH, handleIngredientSearch),
    takeLatest(types.ACCOUNT_SEARCH, handleAccountSearch),
    takeLatest(types.UNIVERSAL_SEARCH, handleUniversalSearch),
    takeLatest(types.TAG_SEARCH, handleTagSearch),
    takeLatest(types.POST_TAG, handlePostTag),
    takeLatest(types.RECIPE_WIZARD_QUERY, handleRecipeWizardQuery),
    takeLatest(types.LOAD_RECIPE, handleLoadRecipe),
    takeLatest(types.LOAD_OTHER_PROFILE, handleLoadOtherProfile),
    takeLatest(types.ADD_FOLLOW, handleAddFollow),
    takeLatest(types.DELETE_FOLLOW, handleDeleteFollow),
    takeLatest(types.CHALLENGE_SEARCH, handleChallengeSearch),
    takeLatest(types.CHALLENGE_LOAD, handleChallengeLoad),
    takeLatest(types.CHALLENGE_ENROLL, handleChallengeEnroll),
    takeLatest(types.CHALLENGE_LEAVE, handleChallengeLeave),
    takeLatest(types.ADD_BOOKMARK, handleAddBookmark),
    takeLatest(types.POST_HEART_BEAT, handlePostHeartbeat),
    takeLatest(types.DELETE_BOOKMARK, handleDeleteBookmark),
    takeLatest(types.ADD_REACTION, handleAddReaction),
    takeLatest(types.DELETE_REACTION, handleDeleteReaction),
    takeLatest(types.SUBMIT_FEED_ENTRY_REPORT, handleSubmitFeedEntryReport),
    takeLatest(types.LOAD_FEED, handleLoadFeed),
    takeLatest(types.LOAD_NOTIFICATIONS, handleLoadNotifications),
    takeLatest(types.LOAD_PROFILE, handleLoadProfile),
    takeLatest(types.DELETE_PROFILE, handleDeleteProfile),
    takeLatest(types.SET_DIET, handleSetDiet),
    takeLatest(types.UPDATE_PROFILE, handleUpdateProfile),
    takeLatest(types.UPDATE_AVATAR, handleUpdateAvatar),
    takeLatest(types.POST_ONBOARDING_ANSWERS, handlePostOnboardingAnswers),
    takeLatest(types.DELETE_ONBOARDING_ANSWERS, handleDeleteOnboardingAnswers),
    takeLatest(types.REGISTER_APNS_DEVICE, handleRegisterAPNSDevice),
    takeLatest(types.REGISTER_GCM_DEVICE, handleRegisterGCMDevice),
    takeEvery(types.ADD_ACTIVE_INGREDIENT, handleAddActiveIngredient),
    takeEvery(types.UPDATE_ACTIVE_INGREDIENT, handleUpdateActiveIngredient),
    takeEvery(types.DELETE_ACTIVE_INGREDIENT, handleDeleteActiveIngredient),
    takeEvery(types.ADD_INACTIVE_INGREDIENT, handleAddInactiveIngredient),
    takeLatest(types.RESET_INGREDIENTS, handleResetIngredients),
    takeEvery(types.ADD_MEAL_PLAN_ENTRY, handleAddMealPlanEntry),
    takeEvery(types.DELETE_MEAL_PLAN_ENTRY, handleDeleteMealPlanEntry),
    takeEvery(types.ADD_RECIPE, handleAddRecipe),
    takeEvery(types.UPDATE_RECIPE, handleUpdateRecipe),
    takeEvery(types.DELETE_RECIPE, handleDeleteRecipe),
    takeLatest(types.POST_RECIPE_SHARE, handlePostRecipeShare),
    takeEvery(types.DELETE_RECIPE_SHARE, handleDeleteRecipeShare),
    takeEvery(types.PUT_INGREDIENT, handlePutIngredient),
    takeEvery(types.POST_INGREDIENT_SEARCH_PHRASE, handlePostIngredientSearchPhrase),
    takeLatest(types.REFRESH_HOUSEHOLD, handleRefreshHousehold),
    takeLatest(types.LOAD_HOUSEHOLD_INGREDIENTS, handleLoadHouseholdIngredients),
    takeLatest(types.LOAD_HOUSEHOLD_RECIPES, handleLoadHouseholdRecipes),
    takeLatest(types.LOAD_HOUSEHOLD_MEAL_PLAN, handleLoadHouseholdMealPlan),
    takeLatest(types.LOAD_LABELLING_INGREDIENT, handleLoadLabellingIngredient),
    takeLatest(types.CONFIRM_LABELLING_INGREDIENT, handleConfirmLabellingIngredient),
    takeLatest(types.REPARSE_LABELLING_INGREDIENT, handleReparseLabellingIngredient),
    takeLatest(types.DELETE_LABELLING_INGREDIENT, handleDeleteLabellingIngredient),
  ]);
}
