import React from 'react';

import Svg, { Circle, Path, G, Rect } from 'react-native-svg';

const Eye = ({color = 'white', stroke_width=15}) => (
  <Svg width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <G transform="matrix(1.19541,0,0,1.19541,-19.5618,-19.4112)">
      <G transform="matrix(0.662666,-1.17833,1.17833,0.662666,-83.0101,150.486)">
        <Circle cx="99.129" cy="100.082" r="21.943" style={{fill: color, stroke: 'none'}}/>
      </G>
      <Path d="M29.916,100C29.916,100 61.046,55.255 100,55.255C138.954,55.255 170.295,100 170.295,100M29.916,100C29.916,100 61.046,144.745 100,144.745C138.954,144.745 170.295,100 170.295,100" style={{fill: 'none', stroke: color, strokeWidth: stroke_width}}/>
    </G>
  </Svg>
);

export default Eye;
