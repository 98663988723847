import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useTranslation } from 'react-i18next';

import common_styles, {
  fonts,
  padding
} from '../../common/components/CommonStyles';
import Button from '../../common/components/Button';

import {
  Body2,
  ButtonText,
  CloseButton,
  PageIndicator,
  Title,
  useAppWidth,
  VSkipHuge,
  VSkipLarge
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import MaskView from '../../utils/MaskView';

import CurvyMask from '../../assets/CurvyMask';
import CurvyMask2 from '../../assets/CurvyMask2';
import CurvyMask3 from '../../assets/CurvyMask3';
import CircleBlob from '../../assets/CircleBlob';

import Image2 from '../../assets/green.webp';
import Image1 from '../../assets/potato.webp';
import Image0 from '../../assets/various.webp';

const GettingStartedTour = ({
  visible,
  onRequestClose
}) => {
  const [ active_elem, setActiveElem ] = useState(0);

  const scroll_ref = useRef();

  const theme = useTheme();

  const { t } = useTranslation(['welcome', 'common']);

  const win_width = useWindowDimensions().width;
  const width = useAppWidth();
  const { bottom, top } = useSafeAreaInsets();

  const animated = (Platform.OS !== 'web');  // Setting animated to true breaks 'scrollTo' on (iOS) Safari (in combination with scrollEnabled=false)

  const pages = [
    (
      <>
        <View style={{top: 0, right: 0, left: 0, alignItems: 'center'}}>
          <MaskView
            mask={<CurvyMask/>}
            image_src={Image0}
          />
          <CloseButton
            onPress={onRequestClose}
            style={{position: 'absolute', top: 15, right: 15}}
          />
          <View style={{position: 'absolute', width: 80, height: 80, right: 50, bottom: -15}}>
            <CircleBlob>
              <Text
                style={{
                  ...fonts.handWritten,
                  color: 'white',
                  textAlign: 'center',
                  fontSize: 20,
                  transform: [{rotate: '-6.3deg'}]
                }}
                adjustsFontSizeToFit={true}
                numberOfLines={1}
              >
               { t('gettingStartedPage1.label') }
              </Text>
            </CircleBlob>
          </View>
        </View>
        <View style={{padding}}>
          <VSkipLarge/>
          <Title style={[theme.general]}>{ t('gettingStartedPage1.title') }</Title>
          <VSkipLarge/>
          <Body2 style={[theme.general]}>{ t('gettingStartedPage1.body') }</Body2>
          <VSkipHuge/>
          <View style={common_styles.buttonContainer}>
            <Button
              style={[common_styles.button, theme.button, {flex: 1}]}
              onPress={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
            >
              <ButtonText style={theme.button}>
                { t('common:common.next') }
              </ButtonText>
            </Button>
          </View>
        </View>
      </>
    ), (
      <>
        <View style={{top: 0, right: 0, left: 0}}>
          <MaskView
            mask={<CurvyMask2/>}
            image_src={Image1}
          />
          <View style={{position: 'absolute', width: 100, height: 100, left: 20, bottom: 10}}>
            <CircleBlob>
              <Text
                style={{
                  ...fonts.handWritten,
                  color: 'white',
                  textAlign: 'center',
                  fontSize: 20,
                  transform: [{rotate: '-6.3deg'}]
                }}
                adjustsFontSizeToFit={true}
                numberOfLines={1}
              >
               { t('gettingStartedPage2.label') }
              </Text>
            </CircleBlob>
          </View>
        </View>
        <View style={{padding}}>
          <VSkipLarge/>
          <Title style={[theme.general]}>{ t('gettingStartedPage2.title') }</Title>
          <VSkipLarge/>
          <Body2 style={[theme.general]}>{ t('gettingStartedPage2.body') }</Body2>
          <VSkipHuge/>
          <View style={common_styles.buttonContainer}>
            <Button
              style={[common_styles.button, theme.button, {flex: 1}]}
              onPress={() => scroll_ref.current?.scrollTo({x: (active_elem + 1) * win_width, animated})}
            >
              <ButtonText style={theme.button}>
                { t('common:common.next') }
              </ButtonText>
            </Button>
          </View>
        </View>
      </>
    ), (
      <>
        <View style={{top: 0, right: 0, left: 0}}>
          <MaskView
            mask={<CurvyMask3/>}
            image_src={Image2}
          />
          <View style={{position: 'absolute', width: 80, height: 80, right: 30, bottom: -10}}>
            <CircleBlob>
              <Text
                style={{
                  ...fonts.handWritten,
                  color: 'white',
                  textAlign: 'center',
                  fontSize: 20,
                  transform: [{rotate: '-6.3deg'}]
                }}
                adjustsFontSizeToFit={true}
                numberOfLines={2}
              >
               { t('gettingStartedPage3.label') }
              </Text>
            </CircleBlob>
          </View>
        </View>
        <View style={{padding}}>
          <VSkipLarge/>
          <Title style={[theme.general]}>{ t('gettingStartedPage3.title') }</Title>
          <VSkipLarge/>
          <Body2 style={[theme.general]}>{ t('gettingStartedPage3.body') }</Body2>
          <VSkipHuge/>
          <View style={common_styles.buttonContainer}>
            <Button
              style={[common_styles.button, theme.button, {flex: 1}]}
              onPress={onRequestClose}
            >
              <ButtonText style={theme.button}>
                { t('getStarted') }
              </ButtonText>
            </Button>
          </View>
        </View>
      </>
    )
  ];

  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      transparent
    >
      <View
        style={common_styles.modalContainer}
      >
        <Pressable style={common_styles.modalBackground} onPress={onRequestClose}/>
      <ScrollView
        ref={scroll_ref}
        //disableIntervalMomentum={true}
        showsHorizontalScrollIndicator={false}
        horizontal
        decelerationRate="fast"
        snapToInterval={win_width}
        snapToAlignment="center"
        onScroll={event => setActiveElem(Math.round(event.nativeEvent.contentOffset.x / event.nativeEvent.contentSize.width * pages.length))}
        scrollEventThrottle={16}
        scrollEnabled={true}
        style={{...StyleSheet.absoluteFill}}
      >
        {
          pages.map((page, index) => (
            <View
              key={index}
              style={[
                {
                  width,
                  padding,
                  paddingTop: top,
                  paddingBottom: bottom,
                  justifyContent: 'center'
                }
              ]}
            >
              <View
                style={[
                  theme.general,
                  common_styles.card,
                  { padding: 0, overflow: 'hidden' }
                ]}
              >
                { page }
                <View style={{alignItems: 'center'}}>
                  <PageIndicator
                    pages={pages}
                    color={theme.general.color}
                    active_index={active_elem}
                  />
                </View>
                <VSkipLarge/>
              </View>
            </View>
          ))
        }
      </ScrollView>
      </View>
    </Modal>
  );
}

export default GettingStartedTour;
