import { connect } from 'react-redux';

import RecipesList from '../components/RecipesList';

const mapStateToProps = state => ({
  result: state.wizard.result
});

export default connect(
  mapStateToProps
)(RecipesList)
