import React from 'react';

import Svg, { Path, G } from "react-native-svg";

const ArrowBase = ({color = 'white', stroke_width = 40.0}) => (
  <G
     id="layer1"
     style={{display:'inline'}}>
    <G transform="matrix(1,0,0,1,-7.73394,0)">
      <G transform="matrix(0.624645,0,0,0.624645,37.5355,37.5355)">
        <Path
           style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
           d="M60,100L140,20"
           id="path68"
        />
      </G>
      <G transform="matrix(0.624645,0,0,-0.624645,37.5355,162.464)">
        <Path
           style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
           d="M60,100L140,20"
           id="path68-3"
        />
      </G>
    </G>
  </G>
);
const ArrowRotated = ({angle, ...props}) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 200 200"
    version="1.1"
    id="svg5"
  >
    <G transform={`translate(100 100) rotate(${angle}) translate(-100 -100)`}>
      <ArrowBase {...props}/>
    </G>
  </Svg>
);
export const ArrowLeft = (props) => (
  <ArrowRotated {...props} angle={0}/>
);
export const ArrowUp = (props) => (
  <ArrowRotated {...props} angle={90}/>
);
export const ArrowRight = (props) => (
  <ArrowRotated {...props} angle={180}/>
);
export const ArrowDown = (props) => (
  <ArrowRotated {...props} angle={270}/>
);
