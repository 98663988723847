import { connect } from 'react-redux';

import HouseholdInvite from '../components/HouseholdInvite';

const mapStateToProps = state => {
  const uuid = state.active_household_uuid;
  return {
    active_household: state.profile.households.find(household => (household.uuid === uuid))
  };
};

export default connect(
  mapStateToProps
)(HouseholdInvite)
