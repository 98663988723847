import { connect } from 'react-redux';

import { setIngredientsSearchPhrase } from '../../actions';
import SearchBar from '../components/SearchBar';

const mapStateToProps = state => ({
  search_phrase: state.ingredients.search.current_search_phrase
})

const mapDispatchToProps = {
  setIngredientsSearchPhrase
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar)
