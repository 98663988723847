import React, { useEffect, useState } from 'react';

import { TextInput, View } from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation, useRoute } from '@react-navigation/native';

import styles from './AuthStyles';
import common_styles, { textField } from '../../common/components/CommonStyles';
import {
  Alert,
  Body2,
  ButtonText,
  HidingHeaderScrollView,
  Small,
  VSkipHuge,
  VSkipMedium
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import Button from '../../common/components/Button';
import { Title } from '../../header/components/Header';
import {
  AuthState,
  APP_TABS,
  USER_SCREENS
} from '../../common/constants/';

const ResetPasswordState = Object.freeze({'ENTER_PASSWORD': 0, 'PASSWORD_SENT': 1});

const ResetPassword = ({ auth_state, resetPasswordRequest, setPasswordRequest }) => {
  const [password, setPassword] = useState('');
  const [password_confirm, setPasswordConfirm] = useState('');
  const [state, setState] = useState(ResetPasswordState.ENTER_PASSWORD);

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const route = useRoute();

  const theme = useTheme();

  useEffect(() => {
    if (auth_state === AuthState.LOGGED_IN) {
      navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name});
    }
    if (!route.params && !route.params.token && !route.params.uuid) {
      navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name});
    }
  }, [route]);

  if (state == ResetPasswordState.ENTER_PASSWORD) {
    return (
      <HidingHeaderScrollView
        contentContainerStyle={styles.auth}
        header={(
          <Title
            goBack={() => navigation.goBack()}
            title_string={t('auth.setNewPassword')}
          />
        )}
        body={(
          <>
            <VSkipHuge/>

            <Small style={theme.general}>
              {t('auth.password')}
            </Small>
            <VSkipMedium/>
            <TextInput
              autocompleteType="password-new"
              textContentType="oneTimeCode" //"newPassword"
              placeholder={t('auth.password')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={password}
              onChangeText={text => setPassword(text)}
              style={[textField, theme.textField]}
              secureTextEntry
            />

            <VSkipHuge/>

            <Small style={theme.general}>
              {t('auth.confirmPassword')}
            </Small>
            <VSkipMedium/>
            <TextInput
              autocompleteType="password-new"
              textContentType="oneTimeCode" //"newPassword"
              placeholder={t('auth.confirmPassword')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={password_confirm}
              onChangeText={text => setPasswordConfirm(text)}
              style={[textField, theme.textField]}
              secureTextEntry
            />

            <VSkipHuge/>

            <View style={common_styles.buttonContainer}>
              <Button
                style={theme.button}
                onPress={() => {
                  setPasswordRequest(route.params.token, route.params.uuid, password);
                  setState(ResetPasswordState.PASSWORD_SENT);
                }}
                disabled={password !== password_confirm}
              >
                <ButtonText style={theme.button}>
                  { t('auth.setNewPassword') }
                </ButtonText>
              </Button>
            </View>
          </>
        )}
      />
    );
  } else if (state == ResetPasswordState.PASSWORD_SENT) {
    return (
      <HidingHeaderScrollView
        contentContainerStyle={styles.auth}
        header={(
          <Title
            goBack={() => navigation.goBack()}
            title_string={t('auth.setNewPassword')}
          />
        )}
        body={(
          <Body2 style={theme.general}>
            { t('auth.loggingIn') }
          </Body2>
        )}
      />
    );
  }
}

export default ResetPassword;
