import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';
import {
  useIsFocused,
  useNavigation,
  useRoute
} from '@react-navigation/native';

import Button from '../../common/components/Button';
import { TitleWClose } from '../../header/components/Header';

import FeedContainer from '../containers/FeedContainer';

import common_styles, {
  borderRadius,
  padding,
  roundedBorder,
  textField
} from '../../common/components/CommonStyles';
import {
  Body1,
  Body2,
  Br,
  ButtonText,
  FollowInviteButton,
  H1,
  HidingHeaderScrollView,
  PlusButton,
  relativeTime,
  RoundButton,
  Title
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import Bell from '../../assets/Bell';
import Brand from '../../assets/Brand';
import Dot from '../../assets/Dot';

import {
  APP_TABS,
  COLORS,
  FEED_SCREENS,
  HEADER_HEIGHT,
  PROFILE_SCREENS,
} from '../../common/constants/';
import {
  BASE_URL,
  MEDIA_PREFIX
} from '../../common/constants/urls';

const FeedView = ({
  has_entries,
  has_new_notifications,
  most_recent_recipe_share_date,
  num_followees,
  userprofile,
  username,
  loadFeed,
  pushNotification,
  setMostRecentRecipeShareDate
}) => {
  const { t } = useTranslation(['common', 'recipes']);
  const theme = useTheme();
  const navigation = useNavigation();
  const is_focused = useIsFocused();
  const route = useRoute();

  const onRefresh = useCallback((final_callback) => {
    loadFeed(
      /*offset*/0,
      final_callback
    );
  });

  useEffect(() => {
    if (is_focused && !has_entries) {
      onRefresh();
    }
  }, [is_focused, userprofile]);

  useEffect(() => {
    if (most_recent_recipe_share_date) {
      setMostRecentRecipeShareDate(most_recent_recipe_share_date);
    }
  }, [most_recent_recipe_share_date]);

  return (
    <HidingHeaderScrollView
      keyboardShouldPersistTaps='handled'
      onRefresh={onRefresh}
      header={(
        <View style={styles.header}>
          <View style={[styles.brandContainer, {width: '37%'}]}>
            <Brand color={theme.general.color}/>
          </View>
          <View style={{padding, flexDirection: 'row', alignItems: 'center'}}>
            <TouchableOpacity
              style={{height: 50, width: 50, alignItems: 'center', justifyContent: 'center'}}
              onPress={() => navigation.navigate(
                APP_TABS.feed.name, {
                  screen: FEED_SCREENS.notifications.name
                }
              )}
            >
              <View style={{height: 5, width: 45, opacity: 0.0}}/>
              <View style={{height: 35, width: 55, alignItems: 'center', justifyContent: 'center'}}>
                <Bell color={theme.general.color}/>
              </View>
              <View style={{height: 5, width: 45, opacity: has_new_notifications ? 1.0 : 0.0, alignItems: 'center', justifyContent: 'center'}}>
                <Dot color={COLORS.RED}/>
              </View>
            </TouchableOpacity>
            <PlusButton
              onPress={() => {
                navigation.navigate(APP_TABS.feed.name, { screen: FEED_SCREENS.postMake.name })
              }}
            />
          </View>
        </View>
      )}
      body={(
        <>
          {
            !has_entries ? (
              <View style={[roundedBorder, {padding, margin: padding}]}>
                <Body1 style={[theme.general, {textAlign: 'center'}]}>{t('feed.empty')}</Body1>
                <Body1 style={[theme.general, {textAlign: 'center'}]}>
                  {
                    (num_followees === 0) ? t('feed.noFollowees') : t('feed.noPosts')
                  }
                </Body1>
                <Button
                  style={theme.button}
                  onPress={() => navigation.navigate(APP_TABS.profile.name, { screen: PROFILE_SCREENS.search.name })}
                >
                  <ButtonText style={theme.button}>{t('feed.findFriends')}</ButtonText>
                </Button>
                <FollowInviteButton
                  style={theme.secondary}
                  username={username}
                />
              </View>
            ) : null
          }
          <FeedContainer />
        </>
      )}
    />
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: HEADER_HEIGHT,
    width: '100%'
  },
  brandContainer: {
    height: HEADER_HEIGHT,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: padding
  }
});

export default FeedView;
