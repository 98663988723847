import React from 'react';

import Svg, { G, Path } from 'react-native-svg';

const CurvyMask = (props) => (
  <G transform="matrix(1,0,0,1.40066,0,4.99936)">
    <Path
      d="M-7.683,274.121C-7.683,274.121 64.795,251.969 186.886,251.975C228.605,251.977 266.773,267.546 301.059,266.964C333.893,266.407 406.17,247.44 406.17,247.44L405.964,-7.32L-6.896,-8.587L-7.683,274.121Z"
      style={{fill:'white'}}
    />
  </G>
);

export default CurvyMask;
/*
  <Svg
    width="400"
    height="300"
    viewBox="0 0 400 300"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  </Svg>
 */
