import React from 'react';
import { StyleSheet, View } from 'react-native';

const Tile = (props) => (
  <View style={[styles.fullTile, props.style]}>
    { props.children }
  </View>
)

const styles = StyleSheet.create({
  fullTile: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
  }
});

export default Tile;
