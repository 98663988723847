import React from 'react';

import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

// xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
//    <Title id="title">Need Shop Icon</Title>
const NeedShop = ({color = 'white'}) => (
  <Svg className="needshop" width="100%" height="100%" viewBox="0 0 110 100" version="1.1" xmlns="http://www.w3.org/2000/svg" ariaLabelledby="title" style={{'fillRule':'evenodd','clipRule':'evenodd','strokeLinecap':'round','strokeLinejoin':'round','strokeMiterlimit':1.5}}>
    <Defs>
      <ClipPath id="_clip1">
        <Path d="M117.737,-5.904L117.737,107.695L-4.932,107.695L-4.932,-5.904L117.737,-5.904ZM80.073,1C95.94,1 108.822,13.882 108.822,29.749C108.822,45.616 95.94,58.498 80.073,58.498C64.206,58.498 51.324,45.616 51.324,29.749C51.324,13.882 64.206,1 80.073,1Z"/>
      </ClipPath>
    </Defs>
    <Path d="M117.737,-5.904L117.737,107.695L-4.932,107.695L-4.932,-5.904L117.737,-5.904ZM80.073,1C95.94,1 108.822,13.882 108.822,29.749C108.822,45.616 95.94,58.498 80.073,58.498C64.206,58.498 51.324,45.616 51.324,29.749C51.324,13.882 64.206,1 80.073,1Z" style={{'fill':'none'}}/>
    <G clipPath="url(#_clip1)">
        <G id="Layer1" transform="matrix(1.01449,0,0,1.01449,-2.94604,-1.75998)">
            <G transform="matrix(1,0,0,1,-4.92856,0)">
                <Path d="M39.931,34.78C42.912,34.587 46.19,34.481 49.798,34.481C83.107,34.481 88.402,43.858 89.232,46.482L89.383,46.481C89.383,46.481 89.375,46.601 89.325,46.819C89.375,47.036 89.383,47.156 89.383,47.156L89.232,47.155C88.402,49.779 83.107,59.156 49.798,59.156C11.073,59.156 10.213,46.927 10.213,46.927L10.213,46.71C10.213,46.71 10.824,38.022 34.205,35.292C32.431,47.196 32.21,58.037 32.21,58.037L37.437,58.655C37.437,58.655 38.06,46.733 39.931,34.78Z" style={{'fill':'none','stroke': color, 'strokeWidth':'2.17px'}}/>
            </G>
            <G transform="matrix(1,0,0,1,-4.92856,0)">
                <Path d="M89.383,46.481L89.383,46.517C89.353,48.049 88.12,97.073 50,97.073C11.275,97.073 10.213,46.927 10.213,46.927L10.214,46.935" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
            </G>
            <G transform="matrix(1,0,0,1,-4.92856,0)">
                <Path d="M39.931,34.78C42.912,34.587 46.19,34.481 49.798,34.481C65.867,34.481 89.478,38.425 89.383,46.481C89.333,50.738 83.107,59.156 49.798,59.156C11.073,59.156 10.352,50.262 10.213,46.71C10.086,43.456 16.087,37.331 33.219,35.292" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
            </G>
            <G transform="matrix(1,0,0,1,-4.92856,0)">
                <G transform="matrix(0.985713,0,0,0.985713,-0.053188,1.73484)">
                    <Path d="M32.653,56.498C32.653,56.498 33.168,3.129 50.574,2.926C63.804,2.771 65.455,34.221 65.455,34.221" style={{'fill':'none','stroke': color, 'strokeWidth':'4px'}}/>
                </G>
                <G transform="matrix(0.985713,0,0,0.985713,-0.053188,1.73484)">
                    <Path d="M38.653,57.498C38.653,57.498 39.781,9.833 50.574,9.926C57.995,9.99 59.244,33.221 59.244,33.221" style={{'fill':'none','stroke': color, 'strokeWidth':'4px'}}/>
                </G>
            </G>
            <G transform="matrix(1,0,0,1,-4.92856,0)">
                <G transform="matrix(1,0,0,1,5.83469,18.2415)">
                    <Path d="M12.259,58.753C16.179,61.848 17.896,63.348 23.925,64.768" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
                <G transform="matrix(1,0,0,1,1.69827,8.33447)">
                    <Path d="M12.259,58.753C16.179,61.848 18.896,63.348 24.925,64.768" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
                <G transform="matrix(1,0,0,1,-0.0762175,0)">
                    <Path d="M12.259,58.753C16.179,61.848 19.896,63.348 25.925,64.768" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
            </G>
            <G transform="matrix(1,0,0,1,-4.92856,0)">
                <G transform="matrix(1,0,0,1,27.1995,24.8197)">
                    <Path d="M12.507,60.01C18.185,61.127 16.221,61.375 22.898,61.245" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
                <G transform="matrix(1,0,0,1,23.5995,15.25)">
                    <Path d="M12.507,60.01C18.185,61.127 19.221,61.375 25.898,61.245" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
                <G transform="matrix(1,0,0,1,21.9774,6.4197)">
                    <Path d="M12.507,60.01C18.185,61.127 21.221,61.375 27.898,61.245" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
            </G>
            <G transform="matrix(-1,0,0,1,104.653,-1)">
                <G transform="matrix(1,0,0,1,25.1995,25.8197)">
                    <Path d="M13.507,60.01C18.389,61.041 18.221,61.375 24.898,61.245" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
                <G transform="matrix(1,0,0,1,22.5995,16.25)">
                    <Path d="M13.507,60.01C19.185,61.127 20.221,61.375 26.898,61.245" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
                <G transform="matrix(1,0,0,1,20.9774,7.4197)">
                    <Path d="M12.507,59.01C18.185,60.127 21.221,61.375 27.898,61.245" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
            </G>
            <G transform="matrix(-1,0,0,1,103.73,-1.333)">
                <G transform="matrix(1,0,0,1,1.69827,8.33447)">
                    <Path d="M22.272,63.149C22.937,63.905 24.061,64.438 24.925,64.768" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
                <G transform="matrix(1,0,0,1,-0.0762175,0)">
                    <Path d="M21.075,61.396C22.21,62.192 23.877,63.856 25.522,64.223" style={{'fill':'none','stroke': color, 'strokeWidth':'3.94px'}}/>
                </G>
            </G>
        </G>
    </G>
    <G>
        <Path d="M80.073,1C95.94,1 108.822,13.882 108.822,29.749C108.822,45.616 95.94,58.498 80.073,58.498C64.206,58.498 51.324,45.616 51.324,29.749C51.324,13.882 64.206,1 80.073,1ZM74.305,49.352C74.305,48.58 74.445,47.856 74.727,47.179C75.008,46.501 75.399,45.907 75.899,45.397C76.399,44.886 76.988,44.485 77.666,44.193C78.343,43.901 79.078,43.755 79.87,43.755C80.62,43.755 81.334,43.901 82.011,44.193C82.689,44.485 83.277,44.886 83.778,45.397C84.278,45.907 84.674,46.501 84.966,47.179C85.257,47.856 85.403,48.58 85.403,49.352C85.403,50.123 85.257,50.847 84.966,51.524C84.674,52.202 84.278,52.791 83.778,53.291C83.277,53.791 82.689,54.187 82.011,54.479C81.334,54.771 80.62,54.917 79.87,54.917C79.078,54.917 78.343,54.771 77.666,54.479C76.988,54.187 76.399,53.791 75.899,53.291C75.399,52.791 75.008,52.202 74.727,51.524C74.445,50.847 74.305,50.123 74.305,49.352ZM84.559,37.94L85.841,4.582L74.305,4.582L75.586,37.94L84.559,37.94Z" style={{'fill': color}}/>
    </G>
</Svg>
)

export default NeedShop;
