import { connect } from 'react-redux';

import {
  loadNotifications,
  setMostRecentNotificationDate
} from '../../actions';

import Notifications from '../components/Notifications';

const mapStateToProps = (state) => {
  let most_recent_notification_date = null;
  if (state.notifications.notifications.length !== 0) {
    most_recent_notification_date = state.notifications.notifications[0].date
  }
  return {
    most_recent_notification_date,
    notifications: state.notifications.notifications,
    can_load_more: state.notifications.can_load_more
  }
}

const mapDispatchToProps = {
  loadNotifications,
  setMostRecentNotificationDate
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
