import { connect } from 'react-redux';

import { postIngredientSearchPhrase } from '../../actions';

import IngredientSearchPhraseEditor from '../components/IngredientSearchPhraseEditor';

const mapDispatchToProps = ({
  postIngredientSearchPhrase
})

export default connect(
  null,
  mapDispatchToProps
)(IngredientSearchPhraseEditor)
