import React, {
  useCallback,
  useEffect,
  useRef
} from 'react';
import { Animated, Easing, View } from 'react-native';
import Svg, { Ellipse, Path, G, Rect } from 'react-native-svg';

const AnimatedG = Animated.createAnimatedComponent(G);
const AnimatedPath = Animated.createAnimatedComponent(Path);

const LID_PERIOD = 400;
const LID_BOUNCE_START = 8;
const LID_BOUNCE_END = -1;
const LID_ROTATE_AMPLITUDE = '-13deg';

const Pot = ({color='white', background_color='none', stroke_width=4, animated=false}) => {
  const bounce_animation = new Animated.Value(LID_BOUNCE_START);
  const rotate_value = new Animated.Value(0);
  const steam_animation_0 = new Animated.Value(0);
  const steam_animation_1 = new Animated.Value(0);
  const steam_animation_2 = new Animated.Value(0);
  const steam_animation_3 = new Animated.Value(0);

  Animated.loop(
    Animated.sequence([
      Animated.timing(bounce_animation, {
        toValue: LID_BOUNCE_END,
        duration: 0.5 * LID_PERIOD,
        easing: Easing.sin,
        useNativeDriver: false,

      }),
      Animated.timing(bounce_animation, {
        toValue: LID_BOUNCE_START,
        duration: 0.5 * LID_PERIOD,
        easing: Easing.sin,
        useNativeDriver: false
      }),
    ])
  ).start()

  Animated.loop(
    Animated.sequence([
      Animated.timing(rotate_value, {
        toValue: 1,
        duration: LID_PERIOD,
        easing: Easing.sin,
        useNativeDriver: false
      }),
      Animated.timing(rotate_value, {
        toValue: 0,
        duration: LID_PERIOD,
        easing: Easing.sin,
        useNativeDriver: false
      }),
    ])
  ).start()

  const rotate_animation = rotate_value.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', LID_ROTATE_AMPLITUDE]
  });

  const make_animated = useCallback((animated_value, dash_array, random_value) => {
      Animated.loop(
        Animated.timing(animated_value, {
          toValue: -(dash_array[0] + dash_array[1]),
          duration: 500 + 1000 * random_value,
          easing: Easing.linear,
          useNativeDriver: false
        })
      ).start();
    }
  );

  const dash_array_0 = [15, 10];
  const dash_array_1 = [25, 15];
  const dash_array_2 = [35, 10];
  const dash_array_3 = [45, 15];

  const random_value_0 = Math.random();
  const random_value_1 = Math.random();
  const random_value_2 = Math.random();
  const random_value_3 = Math.random();

  make_animated(steam_animation_0, dash_array_0, random_value_0);
  make_animated(steam_animation_1, dash_array_1, random_value_1);
  make_animated(steam_animation_2, dash_array_2, random_value_2);
  make_animated(steam_animation_3, dash_array_3, random_value_3);

  return (
    <Svg className="Pot" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 100" aria-labelledby="title">
      <Rect id="Artboard1" x="0" y="0" width="100" height="100" style={{'fill':'none'}}/>
      <G id="pot">
        <G
           id="g84"
           transform="matrix(0.63619156,0,0,-0.63619156,70.450229,72.751192)">
          <Path
             d="M 0,0 H 20.918"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path86" />
        </G>
        <G
           id="g88"
           transform="matrix(0.63619156,0,0,-0.63619156,78.491436,67.642382)">
          <Path
             d="M 0,0 H 8.278"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path90" />
        </G>
        <G
           id="g44"
           transform="matrix(0.63619156,0,0,-0.63619156,19.41074,72.751192)">
          <Path
             d="M 0,0 H 9.042"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path46" />
        </G>
        <G
           id="g40"
           transform="matrix(0.63619156,0,0,-0.63619156,19.41074,67.642382)">
          <Path
             d="M 0,0 H 18.083"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path42" />
        </G>
        <G
           id="g36"
           transform="matrix(0.63619156,0,0,-0.63619156,84.007157,53.13238)">
          <Path
             d="m 0,0 c 0,-4.747 -22.729,-8.594 -50.768,-8.594 -28.038,0 -50.768,3.847 -50.768,8.594"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path38" />
        </G>
        <G
           id="g32"
           transform="matrix(0.63619156,0,0,-0.63619156,84.007157,47.306646)">
          <Path
             d="m 0,0 c 0,-4.746 -22.729,-8.594 -50.768,-8.594 -28.038,0 -50.768,3.848 -50.768,8.594 0,4.747 22.73,8.594 50.768,8.594 C -22.729,8.594 0,4.747 0,0 Z"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path34" />
        </G>
        <G
           id="g28"
           transform="matrix(0.63619156,0,0,-0.63619156,93.654416,48.940896)">
          <Path
             d="M 0,0 H -15.164 V -8.031 H 0 c 2.218,0 4.016,1.798 4.016,4.015 C 4.016,-1.798 2.218,0 0,0 Z"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path30" />
        </G>
        <G
           id="g24"
           transform="matrix(0.63619156,0,0,-0.63619156,9.7634676,54.050023)">
          <Path
             d="M 0,0 H 15.164 V 8.031 H 0 C -2.217,8.031 -4.015,6.233 -4.015,4.015 -4.015,1.797 -2.217,0 0,0 Z"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path26" />
        </G>
        <G
           id="g20"
           transform="matrix(0.63619156,0,0,-0.63619156,84.007157,47.306646)">
          <Path
             d="m 0,0 v -47.833 c 0,-11.462 -9.292,-20.754 -20.755,-20.754 h -60.026 c -11.462,0 -20.755,9.292 -20.755,20.754 V 0"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             id="path22" />
        </G>
      </G>
      <G id="steam">
        <G
           id="g80"
           transform="matrix(0.63619156,0,0,-0.63619156,82.355155,24.185982)">
          <AnimatedPath
             d="M 0,0 C 0,0 2.597,7.719 10.547,9.716"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             strokeDasharray={dash_array_0}
             strokeDashoffset={steam_animation_0}
             id="path82" />
        </G>
        <G
           id="g76"
           transform="matrix(0.63619156,0,0,-0.63619156,80.055199,39.829233)">
          <AnimatedPath
             d="m 0,0 c 0,0 6.801,-0.843 11.291,8.841 4.49,9.683 9.417,10.34 9.417,10.34"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             strokeDasharray={dash_array_1}
             strokeDashoffset={steam_animation_1}
             id="path78" />
        </G>
        <G
           id="g72"
           transform="matrix(0.63619156,0,0,-0.63619156,28.872817,39.829233)">
          <AnimatedPath
             d="m 0,0 c 0,0 -6.801,-0.843 -11.291,8.841 -4.49,9.683 -9.417,10.34 -9.417,10.34"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             strokeDasharray={dash_array_2}
             strokeDashoffset={steam_animation_2}
             id="path74" />
        </G>
        <G
           id="g68"
           transform="matrix(0.63619156,0,0,-0.63619156,17.22135,35.51191)">
          <AnimatedPath
             d="m 0,0 c 0,0 -6.801,-0.843 -11.291,8.841 -4.49,9.683 -9.417,10.34 -9.417,10.34"
             style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
             strokeDasharray={dash_array_3}
             strokeDashoffset={steam_animation_3}
             id="path70" />
        </G>
      </G>
      <G id="lid" transform="translate(50, 20)">
      <AnimatedG style={{transform: [{rotate: rotate_animation}, {translateY: bounce_animation}]}}>
        <G id="lid" transform="translate(-50, -20)">
          <G
             id="g64"
             transform="matrix(0.63619156,0,0,-0.63619156,59.622497,29.142552)">
            <Path
               d="M 0,0 C 0,0 18.035,-2.455 34.04,0.815"
               style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
               id="path66" />
          </G>
          <G
             id="g60"
             transform="matrix(0.63619156,0,0,-0.63619156,83.750572,31.847319)">
            <Path
               d="m 0,0 c -0.591,-4.709 -23.622,-5.7 -51.443,-2.213 -27.821,3.487 -49.895,10.132 -49.305,14.842 0.591,4.709 24.342,11.437 52.162,7.95 C -20.766,17.092 0.59,4.709 0,0 Z"
               style={{fill: background_color, stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
               id="path62" />
          </G>
          <G
             id="g56"
             transform="matrix(0.63619156,0,0,-0.63619156,55.927124,13.851305)">
            <Path
               d="M 0,0 -0.936,-7.464"
               style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
               id="path58" />
          </G>
          <G
             id="g52"
             transform="matrix(0.63619156,0,0,-0.63619156,51.059997,13.241197)">
            <Path
               d="M 0,0 -0.936,-7.464"
               style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
               id="path54" />
          </G>
          <G
             id="g48"
             transform="matrix(0.63619156,0,0,-0.63619156,57.143574,14.0038)">
            <Path
               d="m 0,0 -11.475,1.438 c -2.191,0.275 -3.759,2.292 -3.484,4.484 0.274,2.191 2.292,3.759 4.483,3.485 L 0.999,7.968 C 3.19,7.694 4.758,5.676 4.483,3.485 4.209,1.293 2.191,-0.275 0,0 Z"
               style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}
               id="path50" />
          </G>
        </G>
      </AnimatedG>
      </G>
    </Svg>
  );
}

export default Pot;
