export const MAX_APP_WIDTH = 1000; // [px]
export const MIN_INGREDIENT_WIDTH = 110; // [px]
export const MIN_INGREDIENT_COLS = 3;
export const MIN_RECIPE_WIDTH = 160; // [px]
export const MIN_RECIPE_COLS = 2;

export const INGREDIENT_MAX_LENGTH = 20; // [#char]
export const RECIPE_NAME_MAX_LENGTH = 26; // [#char]

export const RECIPE_COMPACT_MAX_INGREDIENTS = 3;
export const MAKE_MAX_TAGS = 3;

// Root screens.
export const APP_PATH = 'app';
// Misc screens.
export const TERMS_PATH = "terms_and_conditions";
export const PRIVACY_POLICY_PATH = "privacy_policy";
export const ABOUT_PATH = "about";
// App tabs.
export const FEED_PATH = "feed";
export const SEARCH_PATH = "search";
export const CHALLENGES_PATH = "challenges";
export const CHALLENGE_PATH = "challenge";
export const STORY_SHARE_PATH = "story_share";
export const HOUSEHOLD_PATH = "household";
export const MEAL_PLANNING_PATH = "meal_planning";
//export const SHARE_PATH = "share";
//export const LIKES_PATH = "likes";
//export const HEART_PATH = "interactions";
export const PROFILE_PATH = "profile";
export const USER_PATH = "user";
export const ADMIN_PATH = "admin";
export const RECIPE_PATH = "recipes";
// Feed screens.
export const HOME_PATH = "home";
export const POST_MAKE_PATH = "post";
export const NOTIFICATIONS_PATH = "notifications";
// Search screens.
export const UNIVERSAL_SEARCH_PATH = "anything";
export const RECIPE_SEARCH_PATH = "recipes";
// Challenges screens.
export const CHALLENGES_OVERVIEW_PATH = "overview";
// Household sub-screens.
export const HOUSEHOLD_HOME_PATH = "household";
export const HOUSEHOLD_SWITCHER_PATH = "household_switcher";
export const HOUSEHOLD_INVITE_PATH = "household_invite";
export const HOUSEHOLD_JOIN_PATH = "household_join";
export const RECIPE_EDITOR_PATH = "recipe_editor";
export const MEAL_PLAN_ENTRY_PATH = "meal_plan_entry";
// Account sub-screens.
export const LOGIN_SIGN_UP_PATH = "login_signup";
export const RESET_PASSWORD_PATH = "reset_password";
export const VERIFY_EMAIL_PATH = "verify_email";
export const SETTINGS_PATH = "settings";
export const ACCOUNT_SEARCH_PATH = "search";
// Admin sub-screens.
export const LABELLING_PATH = "labelling";
export const BIG_TEXT_MAKER_PATH = "big_text";

export const ROOT_SCREENS = {
  app: {name: 'App', path: APP_PATH}
}
export const MODAL_SCREENS = {
  recipeDetail : {name: 'RecipeDetail', is_dynamic: true, param_name: 'uuid', path_prefix: RECIPE_PATH},
  challengeDetail : {name: 'Challenge', is_dynamic: true, param_name: 'uuid', path_prefix: CHALLENGE_PATH},
  storyShare : {name: 'StoryShare', path: STORY_SHARE_PATH}
};
export const MISC_SCREENS = {
  about : {name: 'About', path: ABOUT_PATH},
  termsAndConditions : {name: 'TermsAndConditions', path: TERMS_PATH},
  privacyPolicy : {name: 'PrivacyPolicy', path: PRIVACY_POLICY_PATH}
}
export const APP_TABS = {
  feed : {name: 'Feed', path: FEED_PATH},
  search : {name: 'Search', path: SEARCH_PATH},
  challenges : {name: 'Challenges', path: CHALLENGES_PATH},
  household : {name: 'Household', path: HOUSEHOLD_PATH},
  profile : {name: 'Profile', path: PROFILE_PATH},
  admin : {name: 'Admin', path: ADMIN_PATH}
}
export const FEED_SCREENS = {
  home : {name: 'Home', path: HOME_PATH},
  postMake : {name: 'PostMake', path: POST_MAKE_PATH},
  notifications : {name: 'Notifications', path: NOTIFICATIONS_PATH}
}
export const SEARCH_SCREENS = {
  universalSearch: { name: 'UniversalSearch', path: UNIVERSAL_SEARCH_PATH },
  recipeSearch: { name: 'RecipeSearch', path: RECIPE_SEARCH_PATH }
}
export const CHALLENGES_SCREENS = {
  overview : { name: 'Overview', path: CHALLENGES_OVERVIEW_PATH },
}
export const HOUSEHOLD_SCREENS = {
  householdHome : {name: 'HouseholdHome', path: HOUSEHOLD_HOME_PATH},
  householdSwitcher: {name: 'HouseholdSwitcher', path: HOUSEHOLD_SWITCHER_PATH},
  householdInvite: {name: 'HouseholdInvite', path: HOUSEHOLD_INVITE_PATH},
  householdJoin: {name: 'HouseholdJoin', path: HOUSEHOLD_JOIN_PATH},
  recipeEditor: {name: 'RecipeEditor', path: RECIPE_EDITOR_PATH},
}
export const PROFILE_SCREENS = {
  loginSignUp : {name: 'LoginSignUp', path: LOGIN_SIGN_UP_PATH},
  resetPassword : {name: 'ResetPassword', path: RESET_PASSWORD_PATH},
  verifyEMail : {name: 'VerifyEMail', path: VERIFY_EMAIL_PATH},
  settings : {name: 'Settings', path: SETTINGS_PATH},
  search : {name: 'ProfileSearch', path: ACCOUNT_SEARCH_PATH}
};
export const USER_SCREENS = {
  account : {name: 'Account', is_dynamic: true, param_name: 'username'}
};
export const ADMIN_SCREENS = {
  labelling : {name: 'Labelling', path: LABELLING_PATH},
  bigTextMaker : {name: 'BigTextMaker', path: BIG_TEXT_MAKER_PATH}
}

export const S_TO_MS = 1000;

export const API_REQUEST_DELAY = 300; // [ms]
export const API_TIMEOUT = 10000; // [ms]
export const API_REQUEST_PRIORITY = Object.freeze({
  "P0": 0,  // Notify user of failed request
  "P1": 1   // Don't notify user of failed request
});
export const REFRESH_RATE = 10000; // [ms]

export const LANGUAGES = ['en', 'de'];
export const DIETS = Object.freeze({
  'FLEXITARIAN': 'flexitarian',
  'VEGETARIAN': 'vegetarian',
  'VEGAN': 'vegan',
  'PESCETARIAN': 'pescetarian'
});

export const ReportType = Object.freeze({
  'DISLIKE': 'dislike',
  'SPAM': 'spam',
  'NUDITY': 'nudity',
  'FRAUD': 'fraud',
  'HATE_SPEECH': 'hate_speech',
  'FALSE_INFO': 'false_info',
  'HARASSMENT': 'harassment',
  'VIOLENCE': 'violence',
  'COPYRIGHT': 'copyright',
  'SALE_ILLEGAL_GOODS': 'sale_illegal_goods',
  'SUICIDE_SELFHARM': 'suicide_selfharm',
  'EATING_DISORDER': 'eating_disorder',
  'OTHER': 'other'
});

// IDs used for local storage.
export const REFRESH_TOKEN_ID = 'refresh_token';
export const LOCAL_STORAGE_ID_ONBOARDING_DONE = 'onboarding_done';
export const LOCAL_STORAGE_ID_ACTIVE_HOUSEHOLD_UUID = 'active_household_uuid';
export const LOCAL_STORAGE_ID_HIDDEN_MAKES = 'hidden_makes';

export const AuthState = Object.freeze({
  "NOT_LOGGED_IN": 0,
  "LOGGED_IN": 1
});
export const ApiState = Object.freeze({
  "OK": 0,
  "NEW_VERSION_AVAILABLE": 1,
  "NEEDS_UPDATE": 2,
  "ERROR": 3
 });
export const AppState = Object.freeze({
  "INITIALIZING": 0,
  "INITIALIZED": 1,
  "INITIALIZATION_FAILED": 2
});
export const ConnectionState = Object.freeze({
  "OK": 0,
  "NO_CONNECTION": 1,  // Connection lost during initialization
  "LOST_CONNECTION": 2,  // Connection lost during operation
  "MAINTAINANCE": 3,
});
export const ErrorScreenType = Object.freeze({
  "NO_CONNECTION": 0,
  "MAINTAINANCE": 1,
  "NEW_VERSION_AVAILABLE": 2,
  "NEEDS_UPDATE": 3,
  "OTHER": 4
});

export const NotificationSeverity = Object.freeze({
  "DEBUG": 0,
  "INFO": 1,
  "WARNING": 2,
  "ERROR": 3,
  "FATAL": 4
});

export const RecipeType = Object.freeze({
  //"FREESTYLE": "freestyle",
  "CUSTOM": "custom",
  "WEB": "web",
  "BOOK": "book"
});

export const HouseholdIngredientCategories = Object.freeze({
  "FRESH": "fresh",
  "STAPLE": "staple",
  "OTHER": "other"
});

export const PRIMARY_COLOR = '#300D25';
export const SECONDARY_COLOR = '#eeeeee';
export const TERTIARY_COLOR = '#d1d1d1';
export const LIGHT_COLOR = '#FAFAFA';//beige: '#FFF8EE';

export const PRIMARY_DARK = 'rgb(46,18,38)';
export const PRIMARY_MEDIUM = 'rgb(69,24,57)';
export const PRIMARY_LIGHT = 'rgb(79,36,49)';
export const PRIMARY_LIGHTER = 'rgb(164,143,149)';

export const PINK = '#FADEEB';//'rgb(250,222,235)';
export const PURPLE = "#491F87";
export const LAVENDER = '#A18CBF';
export const BEIGE = 'rgb(247,242,237)';
export const BLACKISH = 'rgb(25,41,43)';
export const LIGHT_BLACKISH = '#2B474A';
export const LIGHTER_BLACKISH = '#34575A';

export const DARK_BEIGE = '#e8ded6';
export const DARKER_BEIGE = '#dfd2c8';

export const YELLOW = 'rgb(245,214,135)';
export const GREEN = 'rgb(77,166,89)';
export const BLUE = 'rgb(61,171,222)';
export const RED = 'rgb(219,102,89)';

export const LIGHT_GRAY = 'rgb(213,205,211)';

export const COLORS = {
  PRIMARY_DARK,
  PRIMARY_MEDIUM,
  PRIMARY_LIGHT,
  PRIMARY_LIGHTER,
  PINK,
  PURPLE,
  LAVENDER,
  BEIGE,
  BLACKISH,
  LIGHT_BLACKISH,
  LIGHTER_BLACKISH,
  DARK_BEIGE,
  DARKER_BEIGE,
  YELLOW,
  GREEN,
  BLUE,
  RED,
  LIGHT_GRAY
}

export const HEADER_HEIGHT = 50;
export const BORDER_RADIUS = 12;
export const BIG_BORDER_RADIUS = 40;
export const PADDING = 12;
export const MARGIN = 3;
