import React from "react";

import Svg, { Path } from "react-native-svg";

const Library = ({color = 'white', ...props}) => (
  <Svg
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 200 200"
    {...props}
  >
    <Path
      d="M177.685 87.836c0-18.129-17.027-32.847-37.999-32.847H63.689c-20.972 0-37.999 14.718-37.999 32.847v65.7c0 18.129 17.027 32.848 37.999 32.848h75.997c20.972 0 37.999-14.719 37.999-32.848v-65.7Z"
      style={{
        fill: "none",
        stroke: color,
        strokeWidth: "12.02px",
      }}
      transform="matrix(1 0 0 1.15681 -1.687 -39.611)"
    />
    <Path
      d="m24.003 117.611 36.994-24.626 29.59 20.238 42.63-29.997 42.78 28.4V61.999c0-20.972-17.026-37.999-37.998-37.999H62.001c-20.972 0-37.998 17.027-37.998 37.999v55.612Zm45.119-73.797c9.001 0 16.309 7.308 16.309 16.309 0 9.002-7.308 16.31-16.309 16.31-9.002 0-16.31-7.308-16.31-16.31 0-9.001 7.308-16.309 16.31-16.309Z"
      style={{
        fill: color,
        stroke: 'none',
        strokeWidth: "12.02px",
      }}
    />
  </Svg>
)

export default Library;
