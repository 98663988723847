import { connect } from 'react-redux';

import {
  accountSearch,
  addFollow,
  deleteFollow
} from '../../actions';
import AccountSearch from '../components/AccountSearch';

const mapStateToProps = state => ({
  account_search_results: state.account_search_results
})

const mapDispatchToProps = {
  accountSearch,
  addFollow,
  deleteFollow
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSearch)
