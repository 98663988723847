import { connect } from 'react-redux';

import RecipeDetail from '../components/TagPicker';
import {
  postTag,
  tagSearch
} from '../../actions/';

const mapDispatchToProps = ({
  postTag,
  tagSearch
})

export default connect(
  null,
  mapDispatchToProps
)(RecipeDetail)
