import { connect } from 'react-redux';

import {
  challengeLoad,
  challengeEnroll,
  challengeLeave
} from '../../actions';
import ChallengeDetail from '../components/ChallengeDetail';

const mapDispatchToProps = {
  challengeLoad,
  challengeEnroll,
  challengeLeave
}

export default connect(
  null,
  mapDispatchToProps
)(ChallengeDetail)
