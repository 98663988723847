import React from 'react';

import {
  useColorScheme,
  StyleSheet
} from 'react-native';

import {
  LIGHT_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  COLORS
} from '../common/constants';

import {
  castShadow
} from '../common/components/CommonStyles';

const ingredientBase = {
  color: 'white'
}
const activeIngredient = {
  ...ingredientBase,
  backgroundColor: COLORS.GREEN
}
const unknownIngredient = {
  ...ingredientBase,
  backgroundColor: COLORS.PURPLE
}
const inactiveIngredient = {
  ...ingredientBase,
  backgroundColor: COLORS.RED
}
const blueBg = {
  color: 'white',
  backgroundColor: COLORS.BLUE
}
const greenBg = {
  color: 'white',
  backgroundColor: COLORS.GREEN
}
const redBg = {
  color: 'white',
  backgroundColor: COLORS.RED
}
const purpleBg = {
  color: 'white',
  backgroundColor: COLORS.PURPLE
}
const yellowBg = {
  color: 'white',
  backgroundColor: COLORS.YELLOW
}

export const LightTheme = {
  general: {
    backgroundColor: COLORS.BEIGE,
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK
  },
  link: {
    color: COLORS.PURPLE
  },
  textField: {
    backgroundColor: 'white',
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK
  },
  textFieldPlaceholder: {
    color: COLORS.PRIMARY_LIGHTER
  },
  button: {
    backgroundColor: COLORS.PRIMARY_DARK,
    color: 'white',
    shadowColor: COLORS.PRIMARY_DARK
  },
  secondaryButton: {
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_LIGHT
  },
  tertiaryButton: {
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK,
    textDecorationLine: 'underline'
  },
  errorState: {
    borderWidth: 1,
    borderColor: COLORS.RED,
    color: COLORS.RED
  },
  secondary: {
    backgroundColor: COLORS.DARK_BEIGE,
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK,
    borderWidth: 2,
    borderColor: COLORS.DARK_BEIGE
  },
  secondarySelected: {
    backgroundColor: COLORS.DARK_BEIGE,
    color: 'white',
    shadowColor: COLORS.PRIMARY_DARK,
    borderWidth: 2,
    borderColor: COLORS.PRIMARY_DARK
  },
  tertiary: {
    backgroundColor: COLORS.DARKER_BEIGE,
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK
  },
  card: {
    backgroundColor: 'white',
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK,
    ...castShadow,
    borderWidth: 2,
    borderColor: 'white'
  },
  cardSelected: {
    backgroundColor: 'white',
    color: COLORS.PRIMARY_LIGHT,
    shadowColor: COLORS.PRIMARY_DARK,
    ...castShadow,
    borderWidth: 2,
    borderColor: COLORS.GREEN
  },
  cardBody: {
    backgroundColor: 'white',
    color: COLORS.PRIMARY_LIGHT
  },
  selection: {
    backgroundColor: COLORS.GREEN,
    color: 'white'
  },
  tabBarIconActiveColor: COLORS.PURPLE,
  switchThumbColor: 'white',
  switchTrackColor: {true: COLORS.PRIMARY_LIGHT, false: COLORS.PRIMARY_LIGHT},
  ingredientBase,
  activeIngredient,
  unknownIngredient,
  inactiveIngredient,
  blueBg,
  greenBg,
  purpleBg,
  redBg,
  yellowBg
};

export const DarkTheme = {
  general: {
    backgroundColor: COLORS.BLACKISH,
    color: 'white',
    shadowColor: 'black'
  },
  link: {
    color: COLORS.PURPLE
  },
  textField: {
    backgroundColor: 'black',
    color: 'white',
    shadowColor: 'black'
  },
  textFieldPlaceholder: {
    color: '#FFFFFF7F'
  },
  button: {
    backgroundColor: 'white',
    color: COLORS.PRIMARY_DARK,
    shadowColor: 'black'
  },
  secondaryButton: {
    color: 'white',
    shadowColor: 'black',
    borderWidth: 1,
    borderColor: 'white'
  },
  tertiaryButton: {
    color: 'white',
    shadowColor: 'black',
    textDecorationLine: 'underline'
  },
  errorState: {
    borderWidth: 1,
    borderColor: COLORS.RED,
    color: COLORS.RED
  },
  secondary: {
    backgroundColor: COLORS.LIGHT_BLACKISH,
    color: 'white',
    shadowColor: 'black',
    borderWidth: 2,
    borderColor: COLORS.LIGHT_BLACKISH
  },
  secondarySelected: {
    backgroundColor: COLORS.LIGHT_BLACKISH,
    color: 'white',
    shadowColor: 'black',
    borderWidth: 2,
    borderColor: 'white'
  },
  tertiary: {
    backgroundColor: COLORS.LIGHTER_BLACKISH,
    color: 'white',
    shadowColor: 'black'
  },
  card: {
    backgroundColor: COLORS.PRIMARY_MEDIUM,
    color: 'white',
    shadowColor: 'black',
    ...castShadow,
    borderWidth: 2,
    borderColor: COLORS.PRIMARY_MEDIUM
  },
  cardSelected: {
    backgroundColor: COLORS.PRIMARY_MEDIUM,
    color: 'white',
    shadowColor: 'black',
    ...castShadow,
    borderWidth: 2,
    borderColor: COLORS.GREEN
  },
  cardBody: {
    backgroundColor: COLORS.PRIMARY_MEDIUM,
    color: 'white',
  },
  selection: {
    backgroundColor: COLORS.GREEN,
    color: 'white'
  },
  tabBarIconActiveColor: 'white',
  switchThumbColor: COLORS.BLACKISH,
  switchTrackColor: {true: 'white', false: 'white'},
  ingredientBase,
  activeIngredient,
  unknownIngredient,
  inactiveIngredient,
  blueBg,
  greenBg,
  purpleBg,
  redBg,
  yellowBg
};

const Theme = (scheme) => {
  const theme_map = new Map();
  theme_map.set("light", LightTheme);
  theme_map.set("dark", DarkTheme);
  if (!scheme || !theme_map.has(scheme)) {
    console.error(`Unknown scheme ${scheme}. Falling back to 'light' scheme.`)
    return LightTheme;
  }
  return theme_map.get(scheme);
};

export const useTheme = () => {
  return Theme(useColorScheme());
};

export default Theme;
