import React, { useState } from 'react';
import {
  Image,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import Button from '../../common/components/Button';
import common_styles, {
  borderRadius,
  margin,
  padding,
  roundedBorder,
  text_styles
} from '../../common/components/CommonStyles';
import {
  APP_TABS,
  COLORS,
  HOUSEHOLD_SCREENS,
  USER_SCREENS
} from '../../common/constants/';
import { MEDIA_PREFIX } from '../../common/constants/urls';

import {
  Title,
  Body1,
  RoundButton,
  SmallUppercase
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import Dot from '../../assets/Dot';
import User from '../../assets/User';
import { ArrowDown, ArrowRight } from '../../assets/Arrow';

const HouseholdNav = ({
  screens,
  active_screen_idx,
  active_household,
  profile,
  ...props
}) => {
  const theme = useTheme();

  const navigation = useNavigation();

  const { t } = useTranslation('common');

  let image = '';
  let image_placeholder_uri = '';

  if (profile.userprofile.image && profile.userprofile.image.image) {
    image = profile.userprofile.image.image_50;
    const { image_placeholder } = profile.userprofile.image;
    image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
  }

  return (
    <View
      {...props}
      style={[theme.general, props.style]}
    >
      <View
        style={styles.header}
      >
      <View style={styles.headerRow}>
        <TouchableOpacity
          style={styles.householdName}
          onPress={() => navigation.navigate(APP_TABS.household.name, { screen: HOUSEHOLD_SCREENS.householdSwitcher.name })}
        >
          <Title style={theme.general}>
            { (active_household && active_household.name) ? active_household.name : t('householdNav.blankHouseholdNamePlaceholder') }
          </Title>

          <View style={{width: 20, height: 20}}>
            <ArrowDown color={theme.general.color}/>
          </View>
        </TouchableOpacity>
        {
          image ? (
            <TouchableOpacity
              onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name})}
            >
              <Image
                style={common_styles.roundButton}
                defaultSource={image_placeholder_uri}
                source={{uri: `${MEDIA_PREFIX}${image}`}}
              />
            </TouchableOpacity>
          ) : (
            <RoundButton
              style={theme.secondary}
              onPress={() => navigation.navigate(APP_TABS.profile.name, { screen: USER_SCREENS.account.name})}
            >
              <User color={theme.general.color}/>
            </RoundButton>
          )
        }
        </View>
        <View style={styles.headerRow}>
          <View style={[
            theme.secondary,
            styles.headerNav
          ]}>
            {
              screens.map(({displayName, hasUpdates, onPress}, index) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    common_styles.smallIng,
                    styles.headerNavItem,
                    (active_screen_idx === index) ? theme.general : theme.secondary,
                    {paddingHorizontal: 0}
                  ]}
                  onPress={onPress}
                >
                  <View style={styles.householdNavItemDotContainer}>
                    <View style={[styles.householdNavItemDot, {opacity: hasUpdates ? 1.0 : 0.0}]}>
                      <Dot color={COLORS.RED}/>
                    </View>
                  </View>
                  <Body1
                    style={(active_screen_idx === index) ? theme.general : theme.secondary}
                  >
                    {displayName}
                  </Body1>
                  <View style={styles.householdNavItemDotContainer}>
                    <View style={styles.householdNavItemDot}/>
                  </View>
                </TouchableOpacity>
              ))
            }
          </View>
        </View>
      </View>
      <View style={{width: '100%', height: StyleSheet.hairlineWidth, backgroundColor: COLORS.LIGHT_GRAY}}/>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    width: '100%',
    paddingBottom: 0.5 * padding
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: padding,
    marginVertical: margin
  },
  headerNav: {
    borderRadius: 1.5 * borderRadius,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0.1 * margin,
    paddingHorizontal: margin
  },
  headerNavItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  householdName: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  householdNavItem: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  householdNavItemDotContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: padding
  },
  householdNavItemDot: {width: 5, height: 5},
  householdNavItemText: {
    flex: 1
  },
  householdNavItemCount: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

export default HouseholdNav;
