// General
export const INITIALIZE = 'INITIALIZE';  // Request access token from server and load active ingredients from server
export const RECONNECT = 'RECONNECT';
export const SET_API_STATE = 'SET_API_STATE';
export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const SET_CONNECTION_STATE = 'SET_CONNECTION_STATE';
export const CONNECTION_LOSS = 'CONNECTION_LOSS';
export const SET_INGREDIENTS_SEARCH_PHRASE = 'SET_INGREDIENTS_SEARCH_PHRASE';
export const CLOSE_RECIPE = 'CLOSE_RECIPE';
export const RESET_INGREDIENTS = 'RESET_INGREDIENTS';
export const STORE_ACTIVE_HOUSEHOLD = 'STORE_ACTIVE_HOUSEHOLD';
export const SET_ACTIVE_HOUSEHOLD = 'SET_ACTIVE_HOUSEHOLD';
export const HIDE_ENTRY = 'HIDE_ENTRY';
export const SET_HIDDEN_ENTRIES = 'SET_HIDDEN_ENTRIES';
export const SET_UPDATE_FLAGS = 'SET_UPDATE_FLAGS';
export const SET_HEART_BEAT = 'SET_HEART_BEAT';
export const SET_MOST_RECENT_RECIPE_SHARE_DATE = 'SET_MOST_RECENT_RECIPE_SHARE_DATE';
export const SET_MOST_RECENT_NOTIFICATION_DATE = 'SET_MOST_RECENT_NOTIFICATION_DATE';
export const SET_MOST_RECENT_INGREDIENT_DATE = 'SET_MOST_RECENT_INGREDIENT_DATE';
export const SET_MOST_RECENT_MEAL_PLAN_ENTRY_DATE = 'SET_MOST_RECENT_MEAL_PLAN_ENTRY_DATE';

// Authentication.
export const CREATE_GUEST_ACCOUNT_REQUEST = 'CREATE_GUEST_ACCOUNT_REQUEST';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const SET_TOKEN = 'SET_TOKEN';
export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const JOIN_HOUSEHOLD_REQUEST = 'JOIN_HOUSEHOLD_REQUEST';
export const LOG_OUT = 'LOG_OUT';

// Notifications.
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const QUEUE_NOTIFICATION = 'QUEUE_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

// Api.
export const PUT_INGREDIENT = 'PUT_INGREDIENT';
export const POST_INGREDIENT_SEARCH_PHRASE = 'POST_INGREDIENT_SEARCH_PHRASE';

export const POST_HEART_BEAT = 'POST_HEART_BEAT';
export const REFRESH_HOUSEHOLD = 'REFRESH_HOUSEHOLD';
export const LOAD_SUGGESTED_INGREDIENTS = 'LOAD_SUGGESTED_INGREDIENTS';
export const SET_SUGGESTED_INGREDIENTS = 'SET_SUGGESTED_INGREDIENTS';
export const LOAD_ALWAYS_ACTIVE_INGREDIENTS = 'LOAD_ALWAYS_ACTIVE_INGREDIENTS';
export const SET_ALWAYS_ACTIVE_INGREDIENTS = 'SET_ALWAYS_ACTIVE_INGREDIENTS';
export const LOAD_HOUSEHOLD_INGREDIENTS = 'LOAD_HOUSEHOLD_INGREDIENTS';
export const SET_ACTIVE_INGREDIENTS = 'SET_ACTIVE_INGREDIENTS';
export const SET_INACTIVE_INGREDIENTS = 'SET_INACTIVE_INGREDIENTS';

export const LOAD_FEED = 'LOAD_FEED';
export const SET_FEED = 'SET_FEED';
export const POST_RECIPE_SHARE = 'POST_RECIPE_SHARE';
export const DELETE_RECIPE_SHARE = 'DELETE_RECIPE_SHARE';

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const LOAD_HOUSEHOLD_RECIPES = 'LOAD_HOUSEHOLD_RECIPES';
export const SET_HOUSEHOLD_RECIPES = 'SET_HOUSEHOLD_RECIPES';
export const ADD_RECIPE = 'ADD_RECIPE';
export const UPDATE_RECIPE = 'UPDATE_RECIPE'
export const DELETE_RECIPE = 'DELETE_RECIPE';

export const LOAD_HOUSEHOLD_MEAL_PLAN = 'LOAD_HOUSEHOLD_MEAL_PLAN';
export const SET_HOUSEHOLD_MEAL_PLAN = 'SET_HOUSEHOLD_MEAL_PLAN';
export const ADD_MEAL_PLAN_ENTRY = 'ADD_MEAL_PLAN_ENTRY';
export const DELETE_MEAL_PLAN_ENTRY = 'DELETE_MEAL_PLAN_ENTRY';

export const INGREDIENT_SEARCH = 'INGREDIENT_SEARCH';
export const SET_INGREDIENT_SEARCH_RESULTS = 'SET_INGREDIENT_SEARCH_RESULTS';

export const LOAD_PROFILE = 'LOAD_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const POST_ONBOARDING_ANSWERS = 'POST_ONBOARDING_ANSWERS';
export const DELETE_ONBOARDING_ANSWERS = 'DELETE_ONBOARDING_ANSWERS';
export const REGISTER_APNS_DEVICE = 'REGISTER_APNS_DEVICE';
export const REGISTER_GCM_DEVICE = 'REGISTER_GCM_DEVICE';

export const LOAD_OTHER_PROFILE = 'LOAD_OTHER_PROFILE';
export const SET_OTHER_PROFILE = 'SET_OTHER_PROFILE';

export const ACCOUNT_SEARCH = 'ACCOUNT_SEARCH';
export const SET_ACCOUNT_SEARCH_RESULTS = 'SET_ACCOUNT_SEARCH_RESULTS';

export const UNIVERSAL_SEARCH = 'UNIVERSAL_SEARCH';
export const SET_UNIVERSAL_SEARCH_RESULTS = 'SET_UNIVERSAL_SEARCH_RESULTS';

export const TAG_SEARCH = 'TAG_SEARCH';
export const POST_TAG = 'POST_TAG';

export const ADD_FOLLOW = 'ADD_FOLLOW';
export const DELETE_FOLLOW = 'DELETE_FOLLOW';

export const CHALLENGE_SEARCH = 'CHALLENGE_SEARCH';
export const CHALLENGE_LOAD = 'CHALLENGE_LOAD';
export const CHALLENGE_ENROLL = 'CHALLENGE_ENROLL';
export const CHALLENGE_LEAVE = 'CHALLENGE_LEAVE';

export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';

export const ADD_REACTION = 'ADD_REACTION';
export const DELETE_REACTION = 'DELETE_REACTION';

export const SUBMIT_FEED_ENTRY_REPORT = 'SUBMIT_FEED_ENTRY_REPORT';

// Wizard.
export const SET_DIET = 'SET_DIET';

export const ADD_REQUIRED_INGREDIENT = 'ADD_REQUIRED_INGREDIENT';
export const DELETE_REQUIRED_INGREDIENT = 'DELETE_REQUIRED_INGREDIENT';
export const ADD_ACTIVE_INGREDIENT = 'ADD_ACTIVE_INGREDIENT';
export const UPDATE_ACTIVE_INGREDIENT = 'UPDATE_ACTIVE_INGREDIENT';
export const DELETE_ACTIVE_INGREDIENT = 'DELETE_ACTIVE_INGREDIENT';
export const ADD_INACTIVE_INGREDIENT = 'ADD_INACTIVE_INGREDIENT';

export const CLEAR_PIVOT_INGREDIENT_SLOT = 'CLEAR_PIVOT_INGREDIENT_SLOT';

export const RECIPE_WIZARD_QUERY = 'RECIPE_WIZARD_QUERY';
export const SET_RECIPE_WIZARD_INTERMEDIATE_RESULT = 'SET_RECIPE_WIZARD_INTERMEDIATE_RESULT';

export const LOAD_RECIPES = 'LOAD_RECIPES';
export const SET_RECIPES = 'SET_RECIPES';
export const LOAD_RECIPE = 'LOAD_RECIPE';
export const SET_RECIPE = 'SET_RECIPE';
export const LOAD_LABELLING_INGREDIENT = 'LOAD_LABELLING_INGREDIENT';
export const SET_LABELLING_INGREDIENT = 'SET_LABELLING_INGREDIENT';
export const CONFIRM_LABELLING_INGREDIENT = 'CONFIRM_LABELLING_INGREDIENT';
export const REPARSE_LABELLING_INGREDIENT = 'REPARSE_LABELLING_INGREDIENT';
export const DELETE_LABELLING_INGREDIENT = 'DELETE_LABELLING_INGREDIENT';
