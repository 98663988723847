import { connect } from 'react-redux';

import {
  addBookmark,
  deleteBookmark,
  addReaction,
  deleteReaction,
  hideEntry,
  loadFeed,
  loadRecipe,
  pushNotification,
  submitFeedEntryReport
} from '../../actions';

import Feed from '../../feed/components/Feed';

const mapStateToProps = (state) => ({
  entries: state.other_profile.entries,
  hidden_entries: state.feed.hidden_entries,
  user_uuid: state.profile.userprofile.uuid//,
  //can_load_more: state.feed.can_load_more
})

const mapDispatchToProps = {
  addBookmark,
  deleteBookmark,
  addReaction,
  deleteReaction,
  hideEntry,
  loadFeed,
  loadRecipe,
  pushNotification,
  submitReport: submitFeedEntryReport
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feed)
