import { connect } from 'react-redux';

import Onboarding from '../components/Onboarding';
import { AuthState } from '../../common/constants/';

const mapStateToProps = state => ({
  visible: (state.auth_state !== AuthState.LOGGED_IN)
})

export default connect(
  mapStateToProps,
  null
)(Onboarding)
