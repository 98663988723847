import React from 'react';

import Svg, {
  Defs,
  LinearGradient,
  Rect,
  Stop
} from "react-native-svg";

const LinearGradientComponent = ({color = 'black', stroke_width = 12}) => (
  <Svg
    height="100%" width="100%"
    version="1.1"
    id="svg5"
  >
    <Defs>
      <LinearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
        <Stop offset="0" stopColor={color} stopOpacity="0" />
        <Stop offset="1" stopColor={color} stopOpacity="0.5" />
      </LinearGradient>
    </Defs>
    <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
  </Svg>
);

export default LinearGradientComponent;
