import { connect } from 'react-redux';

import {
  addActiveIngredient,
  deleteActiveIngredient,
  pushNotification,
  refreshHousehold,
  resetIngredients,
  setMostRecentIngredientDate,
  updateActiveIngredient
} from '../../actions';

import Pantry from '../components/Pantry';

const mapStateToProps = state => {
  let most_recent_ingredient_date = null;
  if (state.ingredients.active_ingredients.length !== 0) {
    const ingredients = state.ingredients.active_ingredients
    ingredients.sort((a,b) => new Date(b.date_added) - new Date(a.date_added));
    most_recent_ingredient_date = ingredients[0].date_added;
  }
  return {
    active_household_uuid: state.active_household_uuid,
    active_ingredients: state.ingredients.active_ingredients,
    most_recent_ingredient_date
  };
}

const mapDispatchToProps = {
  addActiveIngredient,
  deleteActiveIngredient,
  pushNotification,
  refreshHousehold,
  resetIngredients,
  setMostRecentIngredientDate,
  updateActiveIngredient
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pantry)
