import React, {
  useState,
  useRef
} from 'react';
import {
  Animated,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useColorScheme,
  useWindowDimensions,
  View,
} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useTranslation, Trans } from 'react-i18next';

import HouseholdNav from '../containers/HouseholdNavContainer';
import PantryContainer from '../../pantry/containers/PantryContainer';
import HouseholdRecipes from '../../recipes/containers/HouseholdRecipesContainer';
import MealPlanContainer from '../../meal_plan/containers/MealPlanContainer';

import { BlurView } from '../../utils/BlurView';
import { useTheme } from '../../utils/Theme';
import {
  useAppWidth
} from '../../utils/';


const HouseholdHome = ({
  ingredients_have_updates,
  meal_plan_has_updates
}) => {
  const [ active_screen_idx, setActiveScreenIdx] = useState(0);
  const [ header_height, setHeaderHeight] = useState(0);

  const scroll_ref = useRef();

  const minScroll = 100;

  const scrollY = new Animated.Value(0);

  const clampedScrollY = scrollY.interpolate({
    inputRange: [minScroll, minScroll + 1],
    outputRange: [0, 1],
    extrapolateLeft: 'clamp',
  });

  const minusScrollY = Animated.multiply(clampedScrollY, -1);

  const translateY = Animated.diffClamp(
    minusScrollY,
    -header_height,
    0,
  );

  const opacity = translateY.interpolate({
    inputRange: [-header_height, 0],
    outputRange: [0.0, 1],
    extrapolate: 'clamp',
  });

  const { t } = useTranslation('common');

  const theme = useTheme();
  const scheme = useColorScheme();

  const { top } = useSafeAreaInsets();
  const win_width = useWindowDimensions().width;
  const width = useAppWidth();

  const animated = (Platform.OS !== 'web');  // Setting animated to true breaks 'scrollTo' on (iOS) Safari (in combination with scrollEnabled=false)

  const onScroll = (e) => {
    scrollY.setValue(e.nativeEvent.contentOffset.y);
  };

  const screens = [
    {
      displayName: t('app.householdScreens.recipes'),
      component: <HouseholdRecipes onScroll={onScroll} scrollEventThrottle={16} header_height={header_height}/>,
      hasUpdates: false,
      onPress: () => scroll_ref.current?.scrollTo({x: 0 * win_width, animated})
    },
    {
      displayName: t('app.householdScreens.mealplan'),
      component: <MealPlanContainer onScroll={onScroll} scrollEventThrottle={16} header_height={header_height}/>,
      hasUpdates: meal_plan_has_updates,
      onPress: () => scroll_ref.current?.scrollTo({x: 1 * win_width, animated})
    },
    {
      displayName: t('app.householdScreens.pantry'),
      component: <PantryContainer onScroll={onScroll} scrollEventThrottle={16} header_height={header_height}/>,
      hasUpdates: ingredients_have_updates,
      onPress: () => scroll_ref.current?.scrollTo({x: 2 * win_width, animated})
    },
  ];

  return (
    <>
      <Animated.View
        style={{
          transform: [{translateY: translateY}],
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          elevation: 4,
          zIndex: 1,
          overflow: 'hidden'
        }}
      >
        <BlurView
          style={StyleSheet.absoluteFill}
          reducedTransparencyFallbackColor={theme.general.backgroundColor}
          overlayColor=""
          blurType={scheme}
          blurAmount={20}
        />
        <Animated.View
          style={{
            ...theme.general,
            opacity,
            paddingTop: top
          }}
        >
          <HouseholdNav
            screens={screens}
            active_screen_idx={active_screen_idx}
            onLayout={(event) => {
              const { height } = event.nativeEvent.layout;
              setHeaderHeight(height);
            }}
          />
        </Animated.View>
      </Animated.View>
      <ScrollView
        ref={scroll_ref}
        showsHorizontalScrollIndicator={false}
        horizontal
        decelerationRate="fast"
        snapToInterval={win_width}
        snapToAlignment="center"
        onScroll={event => setActiveScreenIdx(Math.round(event.nativeEvent.contentOffset.x / event.nativeEvent.contentSize.width * screens.length))}
        scrollEventThrottle={16}
        scrollEnabled={Platform.OS !== 'web'}
        style={{flex: 1}}
      >
        {
          screens.map((screen, index) => (
            <View
              style={{
                height: '100%',
                width: win_width,
                flexDirection: 'row',
                justifyContent: 'center'
              }}
              key={index}
            >
              <View
                style={[
                  theme.general,
                  {
                    width,
                    justifyContent: 'center',
                  }
                ]}
              >
                { screen.component }
              </View>
            </View>
          ))
        }
      </ScrollView>
    </>
  );
};

export default HouseholdHome;
