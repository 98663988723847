import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  useWindowDimensions,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { H1, Body2, ButtonText, Small, useAppWidth } from '../../utils/';
import { useTheme } from '../../utils/Theme';
import { ErrorScreenType } from '../../common/constants/';
import metadata from '../../common/constants/metadata';

import common_styles, { padding } from '../../common/components/CommonStyles';
import Button from '../../common/components/Button';

import NoConnection from '../../assets/NoConnection';
import Maintenance from '../../assets/Maintenance';

const ErrorScreen = ({ error_screen_type, message, dismissable, initialize, reconnect }) => {
  const { t } = useTranslation('common');

  const width = 0.5 * useAppWidth();

  const theme = useTheme();

  const icon = new Map();
  icon.set(ErrorScreenType.NO_CONNECTION, <NoConnection color={theme.general.color}/>);
  icon.set(ErrorScreenType.MAINTENANCE, <Maintenance color={theme.general.color}/>);
  icon.set(ErrorScreenType.NEW_VERSION_AVAILABLE, <Maintenance color={theme.general.color}/>);
  icon.set(ErrorScreenType.NEEDS_UPDATE, <Maintenance color={theme.general.color}/>);
  icon.set(ErrorScreenType.OTHER, <NoConnection color={theme.general.color}/>);

  const title = new Map();
  title.set(ErrorScreenType.NO_CONNECTION, t('errorScreens.noConnection'));
  title.set(ErrorScreenType.MAINTENANCE, t('errorScreens.maintenance'));
  title.set(ErrorScreenType.NEW_VERSION_AVAILABLE, t('errorScreens.newVersionAvailable'));
  title.set(ErrorScreenType.NEEDS_UPDATE, t('errorScreens.needsUpdate'));
  title.set(ErrorScreenType.OTHER, t('errorScreens.other'));

  const subtitle = new Map();
  subtitle.set(ErrorScreenType.NO_CONNECTION, t('errorScreens.noConnectionSub'));
  subtitle.set(ErrorScreenType.MAINTENANCE, t('errorScreens.maintenanceSub'));
  subtitle.set(ErrorScreenType.NEW_VERSION_AVAILABLE, t('errorScreens.newVersionAvailableSub'));
  subtitle.set(ErrorScreenType.NEEDS_UPDATE, t('errorScreens.needsUpdateSub'));
  subtitle.set(ErrorScreenType.OTHER, t('errorScreens.otherSub'));

  const can_retry = new Map();
  can_retry.set(ErrorScreenType.NO_CONNECTION, true);
  can_retry.set(ErrorScreenType.MAINTENANCE, true);
  can_retry.set(ErrorScreenType.NEW_VERSION_AVAILABLE, false);
  can_retry.set(ErrorScreenType.NEEDS_UPDATE, false);
  can_retry.set(ErrorScreenType.OTHER, true);

  return (
    <View style={[styles.errorScreen, theme.general]}>
      <View style={{ width, height: width }}>
        { icon.get(error_screen_type) }
      </View>
      <H1 style={[common_styles.centeredText, theme.general]}>
        { title.get(error_screen_type) }
      </H1>
      <Body2 style={[common_styles.centeredText, theme.general]}>
        { subtitle.get(error_screen_type) }
      </Body2>
      <Body2 style={[common_styles.centeredText, theme.general]}>
        { message }
      </Body2>
      {/*
        dismissable ? (
          <View style={common_styles.buttonContainer}>
            <Button style={theme.button} onPress={TODO}>
              <Body2 style={[common_styles.centeredText]}>
                { t('errorScreens.dismiss') }
              </Body2>
            </Button>
          </View>
        ) : null
        */
      }
      {
        can_retry.get(error_screen_type) ? (
          <View style={common_styles.buttonContainer}>
            <Button style={theme.button} onPress={initialize}>
              <ButtonText style={[common_styles.centeredText, theme.button]}>
                { t('errorScreens.retry') }
              </ButtonText>
            </Button>
          </View>
        ) : null
      }
      <Small style={[common_styles.centeredText, theme.general]}>
        { `App version ${metadata.app.version.major}.${metadata.app.version.minor}.${metadata.app.version.revision} ${metadata.app.build_number} (${metadata.app.type})` }
      </Small>
    </View>
  );
}

const styles = StyleSheet.create({
  errorScreen: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding
  }
});

export default ErrorScreen;
