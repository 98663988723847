import React from 'react';
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import Button from '../../common/components/Button';
import { Title } from '../../header/components/Header';
import common_styles, {
  borderRadius,
  margin,
  padding
} from '../../common/components/CommonStyles';

import {
  APP_TABS,
  COLORS,
  HOUSEHOLD_SCREENS
} from '../../common/constants/';
import { MEDIA_PREFIX } from '../../common/constants/urls';

import {
  BackButton,
  H1,
  HidingHeaderScrollView,
  Body2,
  RoundButton,
  XSmall
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import AddUser from '../../assets/AddUser';
import User from '../../assets/User';

const Member = ({username, userprofile}) => {
  const theme = useTheme();

  let image = '';
  let image_placeholder_uri = '';

  if (userprofile.image && userprofile.image.image) {
    image = userprofile.image.image_50;
    const { image_placeholder } = userprofile.image;
    image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
  }

  return (
    <View style={[styles.member, theme.button]}>
      {
        image ? (
          <Image
            style={common_styles.squareButton}
            defaultSource={image_placeholder_uri}
            source={{uri: `${MEDIA_PREFIX}${image}`}}
          />
        ) : (
          <View style={common_styles.squareButton}>
            <User color={theme.button.color}/>
          </View>
        )
      }
      <Body2 style={theme.button}>{username}</Body2>
    </View>
  );
}

const Household = ({name, uuid, members, active, onPress}) => {
  const { t } = useTranslation('common');

  const theme = useTheme();

  const navigation = useNavigation();

  return (
    <Pressable
      style={[
        common_styles.card,
        active ? theme.cardSelected : theme.card,
        { marginVertical: margin }
      ]}
      onPress={onPress}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <H1 style={[theme.cardBody, {flex: 1}]}>
          { name ? name : t('householdNav.blankHouseholdNamePlaceholder') }
        </H1>
        <RoundButton
          onPress={() => navigation.navigate(
            APP_TABS.household.name,
            { screen: HOUSEHOLD_SCREENS.householdInvite.name }
          )}
        >
          <AddUser color={theme.general.color}/>
        </RoundButton>
      </View>
      <View>
        <XSmall style={theme.cardBody}>{t('householdSwitcher.members')}</XSmall>
        <View style={styles.membersContainer}>
          {
            members.map((member, index) => (
              <Member
                {...member}
                key={index}
              />
            ))
          }
        </View>
      </View>
    </Pressable>
  )
}

const HouseholdSwitcher = ({households, storeActiveHousehold}) => {
  const { t } = useTranslation('common');

  const navigation = useNavigation();

  const theme = useTheme();

  return (
    <HidingHeaderScrollView
      contentContainerStyle={{padding}}
      header={(
        <Title
          title_string={t('householdSwitcher.myHouseholds')}
          goBack={() => navigation.navigate(
            APP_TABS.household.name,
            { screen: HOUSEHOLD_SCREENS.householdHome.name }
          )}
        />
      )}
      body={(
        <>
          {
            (households.length === 0) ? (
              <Body2 style={theme.general}>
                { t('householdSwitcher.noHouseholds') }
              </Body2>
            ) : null
          }
          {
            households.map((household, index) => (
              <Household
                key={index}
                {...household}
                onPress={() => storeActiveHousehold(household.uuid)}
              />
            ))
          }
          {
            /*
            <View style={common_styles.buttonContainer}>
              <Button
                style={theme.button}
                onPress={() => console.log('TODO')}
              >
                <Body2 style={theme.button}>
                  { t('householdSwitcher.addHousehold') }
                </Body2>
              </Button>
            </View>
            */
          }
        </>
      )}
    />
  );
}

const styles = StyleSheet.create({
  membersContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  member: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    padding: 0.5 * padding,
    margin: 1
  }
});

export default HouseholdSwitcher;
