import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  Pressable,
  RefreshControl,
  ScrollView,
  Share,
  StyleSheet,
  useColorScheme,
  View
} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useTranslation } from 'react-i18next';

import {
  useIsFocused,
  useNavigation
} from '@react-navigation/native';

import Clipboard from '@react-native-clipboard/clipboard';

import { BlurView } from '../../utils/BlurView';

import { track } from '@amplitude/analytics-react-native';

import Button from '../../common/components/Button';
import { Picker } from '../../common/components/Picker';
import RecipeCompact from '../../recipes/components/RecipeCompact';

import common_styles, { padding } from '../../common/components/CommonStyles';
import {
  Body1,
  BottomStickyView,
  ButtonText,
  H1,
  PlusButton,
  SearchBar,
  XSmall
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import Cross from '../../assets/Cross';

import {
  APP_TABS,
  COLORS,
  FEED_SCREENS,
  HOUSEHOLD_SCREENS,
  MEAL_PLANNING_SCREENS,
  RECIPE_PATH,
  MODAL_SCREENS,
  ROOT_SCREENS,
  SEARCH_SCREENS
} from '../../common/constants/';
import {
  BASE_URL
} from '../../common/constants/urls';

const HouseholdRecipes = ({
  onScroll,
  scrollEventThrottle,
  header_height,
  recipes,
  can_load_more,
  addMealPlanEntry,
  deleteBookmark,
  deleteRecipe,
  loadHouseholdRecipes,
  ...props
}) => {
  const [ refreshing, setRefreshing ] = useState(false);
  const [ show_add_recipe_modal, setShowAddRecipeModal ] = useState(false);
  const [ show_actions_picker, setShowActionsPicker ] = useState(false);
  const [ selected_recipe, setSelectedRecipe ] = useState(0);
  const [ search_phrase, setSearchPhrase ] = useState('');
  const [ search_bar_height, setSearchBarHeight ] = useState(0);

  const { t } = useTranslation(['common', 'recipes']);

  const navigation = useNavigation();
  const is_focused = useIsFocused();

  const theme = useTheme();
  const scheme = useColorScheme();

  const { top } = useSafeAreaInsets();

  const onRefresh = () => {
    setRefreshing(true);
    loadHouseholdRecipes(
      /*offset*/0,
      search_phrase,
      /*final_callback*/() => setRefreshing(false)
    );
  }

  useEffect(() => {
    if (is_focused && !recipes.length) {
      onRefresh();
    }
  }, [is_focused]);

  return (
    <View
      style={StyleSheet.absoluteFill}
      {...props}
    >
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={theme.general.color}
            progressViewOffset={header_height + top}
          />
        }
        onScroll={onScroll}
        scrollEventThrottle={scrollEventThrottle}
        contentContainerStyle={{
          padding,
          paddingTop: padding + header_height + top,
          paddingBottom: search_bar_height
        }}
      >
        {
          (recipes.length === 0) ? (
            <Body1 style={theme.general}>
              { t('householdRecipes.noRecipes') }
            </Body1>
          ) : null
        }
        {
          recipes.map((recipe, index) => (
            <View
              key={index}
              style={[
                theme.tertiary,
                common_styles.card,
                {
                  padding: 0,
                  marginBottom: padding
                }
              ]}
            >
              <RecipeCompact
                style={{margin: 0}}
                recipe={recipe}
                onPress={() => {
                  track('show_actions', {
                    category: 'household_recipes',
                    name: recipe.name
                  });
                  setSelectedRecipe(index);
                  setShowActionsPicker(true);
                }}
              />
              {
                (recipe.bookmarks.length !== 0) ? (
                  <View style={styles.footNote}>
                    <XSmall style={theme.tertiary}>
                      {
                        `${t('recipes:bookmarkedBy')} ${recipe.bookmarks.map(bookmark => bookmark.added_by.username).join(', ')}`
                      }
                    </XSmall>
                  </View>
                ) : null
              }
            </View>
          ))
        }
        {
          can_load_more ? (
            <View style={common_styles.buttonContainer}>
              <Button
                style={theme.button}
                onPress={() => loadHouseholdRecipes(
                  /*offset*/recipes.length,
                  search_phrase
                )}
              >
                <ButtonText style={theme.button}>
                  { t('common.loadMore') }
                </ButtonText>
              </Button>
            </View>
          ) : null
        }
        <View style={{height: search_bar_height}}/>
      </ScrollView>
      <KeyboardAvoidingView
        behavior="position"
        enabled
      >
        <BottomStickyView
          onLayout={(event) => {
            const { height } = event.nativeEvent?.layout;
            setSearchBarHeight(height);
          }}
        >
          <BlurView
            style={StyleSheet.absoluteFill}
            reducedTransparencyFallbackColor={theme.general.backgroundColor}
            overlayColor=""
            blurType={scheme}
            blurAmount={10}
          />
          <View style={styles.header}>
            <SearchBar
              search_phrase={search_phrase}
              setSearchPhrase={setSearchPhrase}
              doSearch={(new_search_phrase) => loadHouseholdRecipes(
                /*offset*/0,
                new_search_phrase,
                /*final_callback*/() => setRefreshing(false)
              )}
              placeholder={t('search.universalSearchPlaceholder')}
              style={{flex: 1}}
            />
            <PlusButton
              onPress={() => setShowAddRecipeModal(true)}
              style={{marginLeft: padding}}
            />
          </View>
        </BottomStickyView>
      </KeyboardAvoidingView>
      <Picker
        options={[
          {
            label: t('householdRecipes.findRecipe'),
            action: () => {
              track('go_to_recipe_search', {
                category: 'household_recipes',
              });
              navigation.navigate(
                APP_TABS.search.name,
                { screen: SEARCH_SCREENS.recipeSearch.name }
              );
            }
          },
          {
            label: t('householdRecipes.addRecipe'),
            action: () => {
              track('start_custom_meal', {
                category: 'household_recipes'
              });
              navigation.navigate(
                APP_TABS.feed.name,
                { screen: FEED_SCREENS.postMake.name }
              );
            }
          }
        ]}
        visible={show_add_recipe_modal}
        onRequestClose={() => setShowAddRecipeModal(false)}
      />
      <Picker
        options={[
          {
            label: t('householdRecipes.showRecipe'),
            action: () => {
              track('view_recipe', {
                category: 'household_recipes',
                name: recipes[selected_recipe].name
              });
              navigation.navigate(
                MODAL_SCREENS.recipeDetail.name,
                {
                  uuid: recipes[selected_recipe].uuid,
                  nav_origin: { name: HOUSEHOLD_SCREENS.householdHome.name }
                }
              );
            }
          },
          {
            label: t('householdRecipes.editRecipe'),
            action: () => {
              track('edit_recipe', {
                category: 'household_recipes',
                name: recipes[selected_recipe].name
              });
              navigation.navigate(
                APP_TABS.household.name,
                {
                  screen: HOUSEHOLD_SCREENS.recipeEditor.name,
                  params: {
                    recipe: recipes[selected_recipe]
                  }
                }
              );
            },
            disabled: (recipes.length === 0) || (recipes[selected_recipe] && !recipes[selected_recipe].is_own_recipe),
          },
          {
            label: t('householdRecipes.addToMealPlan'),
            action: () => {
              track('add_to_meal_plan', {
                category: 'household_recipes',
                name: recipes[selected_recipe].name
              });
              addMealPlanEntry([recipes[selected_recipe]])
            }
          },
          Platform.select({
            web: {
              label: t('householdRecipes.copyRecipeLink'),
              action: () => {
                track('copy_recipe_link', {
                  category: 'household_recipes'
                });
                Clipboard.setString(
                  t('recipes:shareRecipeText', {link: `${BASE_URL}/${ROOT_SCREENS.app.path}/${RECIPE_PATH}/${recipes[selected_recipe].uuid}/`})
                );
              }
            },
            default: {
              label: t('householdRecipes.shareRecipe'),
              action: async () => {
                track('recipe_share_started', {
                  category: 'household_recipes'
                });
                const share_url = `${BASE_URL}/${ROOT_SCREENS.app.path}/${RECIPE_PATH}/${recipes[selected_recipe].uuid}/`;
                const title = t('recipes:shareRecipeTitle');
                const message = t('recipes:shareRecipeText', {link: share_url});
                const result = await Share.share({
                  title,
                  url: share_url,
                  message
                }, {
                  dialogTitle: title,
                  subject: title,
                  tintColor: theme.general.color
                });
                if (result.action === Share.sharedAction) {
                  console.log('shared with activityType', result.activityType);
                  track('recipe_share_completed', {
                    category: 'household_recipes'
                  });
                } else if (result.action === Share.dismissedAction) {
                  console.log('share dismissed', result);
                  track('recipe_share_aborted', {
                    category: 'household_recipes'
                  });
                }
              }
            }
          }),
          {
            label: t('householdRecipes.postMake'),
            action: () => {
              track('post_make', {
                category: 'household_recipes',
                name: recipes[selected_recipe].name
              });
              navigation.navigate(
                APP_TABS.feed.name,
                {
                  screen: FEED_SCREENS.postMake.name,
                  params: {
                    recipes: [recipes[selected_recipe]]
                  }
                }
              );
            }
          },
          {
            label: t('householdRecipes.deleteRecipe'),
            action: () => {
              const recipe = recipes[selected_recipe];
              if (recipe.have_bookmarked) {
                track('delete_bookmark', {
                  category: 'household_recipes',
                  name: recipe.name
                });
                deleteBookmark(recipe.uuid);
              } else if (recipe.is_own_recipe) {
                track('delete_recipe', {
                  category: 'household_recipes',
                  name: recipe.name
                });
                deleteRecipe(recipe.uuid);
              } else {
                console.error('This should not be reached');
                return;
              }
              onRefresh();
            },
            disabled: (recipes.length === 0) || (recipes[selected_recipe] && !recipes[selected_recipe].have_bookmarked && !recipes[selected_recipe].is_own_recipe),
            style: {color: COLORS.RED}
          }
        ]}
        visible={show_actions_picker}
        onRequestClose={() => setShowActionsPicker(false)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    padding,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footNote: {
    padding: 0.5 * padding,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default HouseholdRecipes;
