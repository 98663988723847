import React, { useEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  ScrollView,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useNavigation, useRoute } from '@react-navigation/native';

import { track } from '@amplitude/analytics-react-native';

import RecipeEditorBase, { IngredientPicker } from '../../recipes/components/RecipeEditorBase';

import common_styles, {
  padding
} from '../../common/components/CommonStyles';
import Button from '../../common/components/Button';
import { TitleWAction } from '../../header/components/Header';

import {
  APP_TABS,
  HOUSEHOLD_SCREENS
} from '../../common/constants/';

import { HidingHeaderScrollView } from '../../utils/';
import { useTheme } from '../../utils/Theme';

const RECIPE_MODE = Object.freeze({"NEW": 0, "UPDATE": 1});

const RecipeEditor = ({
  addRecipe,
  updateRecipe
}) => {
  const [ recipe_mode, setRecipeMode ] = useState(RECIPE_MODE.NEW);
  const [ submit_enabled, setSubmitEnabled ] = useState(true);

  const [ uuid, setUUID ] = useState('');
  const [ instance_uuid, setInstanceUUID ] = useState('');
  const [ meal_name, setMealName ] = useState('');
  const [ show_empty_meal_name_warning, setShowEmptyMealNameWarning ] = useState(false);
  const [ notes, setNotes ] = useState('');
  const [ ingredients, setIngredients ] = useState([]);

  const [ show_ingredient_picker, setShowIngredientPicker ] = useState(false);

  const theme = useTheme();

  const navigation = useNavigation();
  const route = useRoute();

  const { t } = useTranslation(['common']);

  const validate = () => {
    if (!meal_name) {
      setShowEmptyMealNameWarning(true);
      return false;
    } else {
      setShowEmptyMealNameWarning(false);
      return true;
    }
  }

  const addIngredient = (ingredient) => {
    track('add_ingredient', {
      category: 'recipe_editor',
      name: t(`ingredients:${ingredient.id}`)
    });
    setIngredients([...ingredients, ingredient]);
  }
  const deleteIngredient = (ingredient) => {
    track('delete_ingredient', {
      category: 'recipe_editor',
      name: t(`ingredients:${ingredient.id}`)
    });
    setIngredients(ingredients.filter(ing => ing.ingredient.id !== ingredient.ingredient.id));
  }

  const submit = () => {
    track('submit_recipe', {
      category: 'recipe_editor',
      name: meal_name
    });
    setSubmitEnabled(false);
    addRecipe(
      {
        ingredients_with_properties: ingredients,
        instances: [
          {
            name: meal_name,
            notes
          }
        ]
      },
      /*success_callback*/() => navigation.navigate(APP_TABS.household.name, { screen: HOUSEHOLD_SCREENS.householdHome.name }),
      /*failure_callback*/() => {},
      /*final_callback*/() => setSubmitEnabled(true)
    );
  };

  const update = () => {
    track('update_recipe', {
      category: 'recipe_editor',
      name: meal_name
    });
    setSubmitEnabled(false);
    updateRecipe(
      {
        uuid,
        ingredients_with_properties: ingredients.filter(ing => (ing.ingredient && ing.ingredient.id)),
        instances: [
          {
            uuid: instance_uuid,
            name: meal_name,
            notes
          }
        ]
      },
      /*success_callback*/() => navigation.navigate(APP_TABS.household.name, { screen: HOUSEHOLD_SCREENS.householdHome.name }),
      /*failure_callback*/() => {},
      /*final_callback*/() => setSubmitEnabled(true)
    );
  };

  useEffect(() => {
    if (route.params) {
      if (route.params.ingredients && (route.params.ingredients.length > 0)) {
        setIngredients(route.params.ingredients.map(ingredient => { ingredient }));
      } else if (route.params.recipe && route.params.recipe.uuid) {
        setUUID(route.params.recipe.uuid);
        setInstanceUUID(route.params.recipe.instance.uuid);
        setRecipeMode(RECIPE_MODE.UPDATE);
        setMealName(route.params.recipe.instance.name);
        setNotes(route.params.recipe.instance.notes);
        // TODO: need to transition to "ings with props" data structure to keep uuid (and qty in the future)
        // applies to all "recipe editors": post make, meal plan entry and this
        setIngredients(route.params.recipe.ingredients_with_properties.filter(ing => (ing.ingredient && ing.ingredient.id)));
      }
    }
  }, [route.params]);

  return (
    <>
      <HidingHeaderScrollView
        contentContainerStyle={{padding}}
        header={(
          <TitleWAction
            title_string={(recipe_mode === RECIPE_MODE.UPDATE) ? t('householdRecipes.updateRecipe') : t('householdRecipes.addRecipe')}
            action_string={(recipe_mode === RECIPE_MODE.UPDATE) ? t('common.save') : t('common.save')}
            goBack={() => navigation.navigate(APP_TABS.household.name, { screen: HOUSEHOLD_SCREENS.householdHome.name })}
            goForward={() => {
              if (validate()) {
                if (recipe_mode === RECIPE_MODE.UPDATE) {
                  update();
                } else {
                  submit();
                }
              };
            }}
            action_disabled={!submit_enabled}
          />
        )}
        body={(
          <RecipeEditorBase
            meal_name={meal_name}
            setMealName={setMealName}
            show_empty_meal_name_warning={show_empty_meal_name_warning}
            validate={validate}
            notes={notes}
            setNotes={setNotes}
            ingredients={ingredients}
            deleteIngredient={deleteIngredient}
            show_ingredient_picker={show_ingredient_picker}
            setShowIngredientPicker={setShowIngredientPicker}
          />
        )}
      />
      <IngredientPicker
        ingredients={ingredients}
        show={show_ingredient_picker}
        setShow={setShowIngredientPicker}
        addIngredient={addIngredient}
        deleteIngredient={deleteIngredient}
      />
    </>
  );
}

export default RecipeEditor;
