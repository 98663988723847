import React from 'react';
import { View } from 'react-native';

import Dot from '../../assets/Dot';

import {
  COLORS
} from '../../common/constants/';

const TabBarIconUpdateIndicator = ({has_updates}) => {
  return (
    <View
      style={{
        height: 4,
        width: 4,
        marginTop: 2,
        opacity: has_updates ? 1.0 : 0.0,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Dot color={COLORS.RED}/>
    </View>
  )
};

export default TabBarIconUpdateIndicator;
