import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Animated,
  PanResponder,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import { useTheme } from '../../utils/Theme';

import {
  borderRadius,
  castShadow,
  padding
} from '../../common/components/CommonStyles';
import { COLORS } from '../../common/constants/';

export const ActivePanel = Object.freeze({'TOP': 0, 'BOTTOM': 1});

const HANDLE_HEIGHT = 4;
const HANDLE_CONTAINER_PADDING = 10;
export const HANDLE_CONTAINER_HEIGHT = HANDLE_HEIGHT + 2 * HANDLE_CONTAINER_PADDING;

const SplitView = ({
  topComponent,
  bottomComponent,
  bottomPanelNaturalHeight,
  bottomPanelCollapsedHeight,
  refreshing,
  onRefresh
}) => {
  const theme = useTheme();

  const [active_panel, setActivePanel] = useState(ActivePanel.BOTTOM);

  const collapsing_height = bottomPanelNaturalHeight - bottomPanelCollapsedHeight;

  const activate_top = () => {
    setActivePanel(ActivePanel.TOP);
  };
  const activate_bottom = () => {
    setActivePanel(ActivePanel.BOTTOM);
  };

  const bottom_panel_offset = useRef(new Animated.Value(0)).current;
  const bottom_panel_opacity = useRef(new Animated.Value(1.0)).current;
  const pan_responder = useRef(null);

  useEffect(() => {
    let bottom_panel_offset_final = 0.0;
    let bottom_panel_opacity_final = 0.0;
    if (active_panel === ActivePanel.BOTTOM) {
      bottom_panel_offset_final = collapsing_height;
      bottom_panel_opacity_final = 1.0;
    } else {
      bottom_panel_offset_final = 0.0;
      bottom_panel_opacity_final = 0.0;
    }
    Animated.parallel([
      Animated.spring(bottom_panel_offset, {
        toValue: bottom_panel_offset_final,
        useNativeDriver: false
      }),
      Animated.timing(bottom_panel_opacity, {
        toValue: bottom_panel_opacity_final,
        duration: 100,
        useNativeDriver: false
      })
    ]).start();
  }, [active_panel, collapsing_height]);

  useEffect(() => {
    // PanResponder is created inside useEffect because otherwise we have stale closure problem.
    pan_responder.current = PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: (event, gesture_state) => {
        bottom_panel_offset.setOffset(bottom_panel_offset._value);
        bottom_panel_offset.setValue(0.0);
      },
      onPanResponderMove: (event, gesture_state) => bottom_panel_offset.setValue(-gesture_state.dy),
      onPanResponderRelease: (event, gesture_state) => {
        bottom_panel_offset.flattenOffset();
        let bottom_panel_offset_final = collapsing_height;
        let bottom_panel_opacity_final = 1.0;
        if (gesture_state.dy > 0) {
          bottom_panel_offset_final = 0.0;
          bottom_panel_opacity_final = 0.0;
        }
        Animated.parallel([
          Animated.spring(bottom_panel_offset, {
            toValue: bottom_panel_offset_final,
            useNativeDriver: false
          }),
          Animated.timing(bottom_panel_opacity, {
            toValue: bottom_panel_opacity_final,
            duration: 50,
            useNativeDriver: false
          })
        ]).start();
      }
    });
  }, [bottomPanelNaturalHeight, bottomPanelCollapsedHeight, collapsing_height]);

  return (
    <View
      style={{flex: 1}}
    >
      <View
        style={{flex: 1}}
      >
        <ScrollView
          onScroll={activate_top}
          scrollEventThrottle={0 /*fire onScroll only once*/}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={theme.general.color}
            />
          }
        >
          { topComponent }
          <Animated.View
            style={{
              height: Animated.add(bottom_panel_offset, bottomPanelCollapsedHeight)
            }}
          />
        </ScrollView>
      </View>
      <View
        style={[
          theme.general,
          styles.bottomPanel,
          { bottom: -collapsing_height }
        ]}
        {...pan_responder.current?.panHandlers}
      >
        <View
          style={styles.handleContainer}
        >
          <View
            style={[styles.handle, theme.button]}
          />
        </View>
        <Animated.View
          style={{ opacity: bottom_panel_opacity }}
        >
          { bottomComponent }
        </Animated.View>
        <Animated.View
          style={[theme.general, {height: bottom_panel_offset}]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bottomPanel: {
    ...castShadow,
    borderTopEndRadius: borderRadius,
    borderTopLeftRadius: borderRadius,
    position: 'absolute',
    width: '100%',
    borderColor: COLORS.LIGHT_GRAY,
    borderTopWidth: StyleSheet.hairlineWidth
  },
  handleContainer: {
    width: '20%',
    height: HANDLE_CONTAINER_HEIGHT,
    padding: HANDLE_CONTAINER_PADDING,
    alignSelf: 'center'
  },
  handle: {
    height: HANDLE_HEIGHT,
    borderRadius: 0.5 * HANDLE_HEIGHT,
    width: '100%'
  },
});

export default SplitView;
