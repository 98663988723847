import React from 'react';

import Svg, { Path } from "react-native-svg";

const Feed = ({color = 'white', filled}) => {
  if (filled) {
    return (
      <Svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        version="1.1"
        id="svg5"
      >
        <Path d="M16 3L3.5 10.8V29H13.5V23C13.5 21.6193 14.6193 20.5 16 20.5C17.3807 20.5 18.5 21.6193 18.5 23V29H28.5V10.8L16 3Z" style={{fill: color}}/>
      </Svg>
    );
  } else {
      return (
      <Svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        version="1.1"
        id="svg5"
      >
        <Path fillRule="evenodd" clipRule="evenodd" d="M16 5.94679L26 12.1868V26.5H20V22C20 19.7909 18.2091 18 16 18C13.7909 18 12 19.7909 12 22V26.5H6V12.1868L16 5.94679ZM14.5 26.5H17.5V22C17.5 21.1716 16.8284 20.5 16 20.5C15.1716 20.5 14.5 21.1716 14.5 22V26.5ZM12 29H6H3.5V26.5V10.8L16 3L28.5 10.8V26.5V29H26H20H17.5H14.5H12Z" style={{fill: color}}/>
      </Svg>
    );
  }
};

export default Feed;
