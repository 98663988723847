import { connect } from 'react-redux';

import {
  deleteOnboardingAnswers,
  deleteProfile,
  pushNotification,
  updateAvatar,
  updateProfile,
  logOut
} from '../../actions';
import Settings from '../components/Settings';

const mapStateToProps = state => ({
  profile: state.profile
})

const mapDispatchToProps = {
  deleteOnboardingAnswers,
  deleteProfile,
  pushNotification,
  updateAvatar,
  updateProfile,
  logOut
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)
