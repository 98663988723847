import React from 'react';

import Svg, { Path, G } from "react-native-svg";

const Cross = ({color = 'white', stroke_width = 20}) => (
   <Svg
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
      version="1.1"
      id="svg5">
     <G
        id="layer1">
       <Path
          //style="fill:none;stroke:#000000;stroke-width:1.41787;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          style={{stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}
          d="M 60,140 140,60"
          id="path68" />
       <Path
          //style="fill:none;stroke:#000000;stroke-width:1.41787;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          style={{stroke: color, strokeLinecap: 'round', strokeWidth: stroke_width}}
          d="M 60,60 140,140"
          id="path68-3" />
     </G>
   </Svg>
);

export default Cross;
