import { connect } from 'react-redux';

import HouseholdHome from '../components/HouseholdHome';

const mapStateToProps = state => {
  const uuid = state.active_household_uuid;
  const household = state.profile.households.find(household => (household.uuid === uuid));
  let update_flags = {
    ingredients: false,
    meal_plan: false
  };
  if (state.update_flags.households[uuid]) {
    update_flags = state.update_flags.households[uuid];
  }
  return {
    ingredients_have_updates: update_flags.ingredients,
    meal_plan_has_updates: update_flags.meal_plan
  };
};

export default connect(
  mapStateToProps
)(HouseholdHome)
