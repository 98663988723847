import React from 'react';

import Svg, { Circle, G } from "react-native-svg";

const DotDotDot = ({color = 'white'}) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 200 200"
    version="1.1"
    id="svg5"
  >
    <G>
      <Circle
         style={{stroke:'none',fill:color}}
         cx="40"
         cy="100"
         r="20" />
      <Circle
         style={{stroke:'none',fill:color}}
         cx="100"
         cy="100"
         r="20" />
      <Circle
         style={{stroke:'none',fill:color}}
         cx="160"
         cy="100"
         r="20" />
    </G>
  </Svg>
);

export default DotDotDot;
