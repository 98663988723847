import React from 'react'

import Picker from './Picker';

import { DIETS } from '../../common/constants/';

const DietPicker = ({ diet, setDiet, visible, onRequestClose }) => {
  return (
    <Picker
      values={Object.values(DIETS)}
      value={diet}
      setValue={setDiet}
      visible={visible}
      onRequestClose={onRequestClose}
      i18n_prefix={'common.diets'}
    />
  );
}

export default DietPicker;
