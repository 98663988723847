import { StyleSheet } from 'react-native';

import { COLORS } from '../../common/constants/';
import { margin } from './CommonStyles';

const styles = StyleSheet.create({
  ingredientBase: {
    height: 135,
    borderRadius: 8,
    padding: '5%',
    marginVertical: 2 * margin
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  ingredientBody: {
    flex: 4,
    width: '100%',
    justifyContent: 'space-around'
  },
  ingredientText: {
    textAlign: 'center'
  },
  menu: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  dot: {
    width: 30,
    height: 30,
    borderRadius: 5,
    alignSelf: 'flex-end',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  purpleBg: {
    backgroundColor: COLORS.PURPLE
  },
  greenBg: {
    backgroundColor: COLORS.GREEN
  },
  redBg: {
    backgroundColor: COLORS.RED
  },
  blueBg: {
    backgroundColor: COLORS.BLUE
  },
  dottedOutline: {
    borderWidth: 2,
    borderColor: COLORS.LIGHT_GRAY,
    borderStyle: 'dotted'
  }
});

export default styles;
