import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  ImageBackground,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  View
} from 'react-native';

import { useTranslation, Trans } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import auth_styles from '../../auth/components/AuthStyles';
import common_styles, { longTextField, textField, padding } from '../../common/components/CommonStyles';
import { useTheme } from '../../utils/Theme';
import {
  Alert,
  Body2,
  ButtonText,
  H2Serif,
  HidingHeaderScrollView,
  sleep,
  Small,
  VSkipHuge,
  VSkipLarge,
  VSkipMedium
} from '../../utils/';

import Button from '../../common/components/Button';
import { Title } from '../../header/components/Header';
import ValuePicker, { Picker } from '../../common/components/Picker';

import { ArrowRight } from '../../assets/Arrow';
import LinearGradient from '../../assets/LinearGradient';
import User from '../../assets/User';

import {
  ADMIN_SCREENS,
  APP_TABS,
  AuthState,
  DIETS,
  LANGUAGES,
  MISC_SCREENS,
  NotificationSeverity,
  PROFILE_SCREENS,
  USER_SCREENS
} from '../../common/constants/';
import {
  MEDIA_PREFIX
} from '../../common/constants/urls';

const Settings = ({
  profile,
  deleteOnboardingAnswers,
  deleteProfile,
  pushNotification,
  updateAvatar,
  updateProfile,
  logOut
}) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [full_name, setFullName] = useState('');
  const [bio, setBio] = useState('');
  const [url, setUrl] = useState('');
  const [language, setLanguage] = useState('');
  const [recipeLanguages, setRecipeLanguages] = useState(LANGUAGES.map(recipe_language => false));
  const [diet, setDiet] = useState('');
  const [meal_plan_reminder_enabled, setMealPlanReminderEnabled] = useState(false);

  const [show_diet_picker, setShowDietPicker] = useState(false);
  const [show_lang_picker, setShowLangPicker] = useState(false);
  const [show_image_type_picker, setShowImageTypePicker] = useState(false);
  const [image_tmp, setImageTmp] = useState(undefined);
  const [delete_confirmed, setDeleteConfirmed] = useState(false);
  const [delete_scroll_position, setDeleteScrollPosition] = useState(0);

  const scroll_ref = useRef();

  const { t } = useTranslation('common');

  const navigation = useNavigation();

  const theme = useTheme();

  const setRecipeLanguage = (index, value) => {
    const newRecipeLanguages = [...recipeLanguages];
    newRecipeLanguages[index] = value;
    setRecipeLanguages(newRecipeLanguages);
  };

  let image = '';
  let image_placeholder_uri = '';
  let image_width = 0;
  let image_height = 0;

  if (profile.userprofile.image && profile.userprofile.image.image) {
    image = profile.userprofile.image.image;
    const { image_placeholder } = profile.userprofile.image;
    image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
    image_width = profile.userprofile.image.width;
    image_height = profile.userprofile.image.height;
  }

  useEffect(() => {
    LANGUAGES.forEach((recipe_language, index) => {
      const is_main_language = (language === recipe_language);
      if (is_main_language && !recipeLanguages[index]) {
        setRecipeLanguage(index, true);
      }
    });
  });

  useEffect(() => {
    if (profile.username) {
      setUsername(profile.username);
    }
    if (profile.email) {
      setEmail(profile.email);
    }
    if (profile.userprofile.subscribed !== undefined) {
      setSubscribed(profile.userprofile.subscribed);
    }
    if (profile.userprofile.full_name) {
      setFullName(profile.userprofile.full_name);
    }
    if (profile.userprofile.bio) {
      setBio(profile.userprofile.bio);
    }
    if (profile.userprofile.url) {
      setUrl(profile.userprofile.url);
    }
    if (profile.userprofile.language) {
      setLanguage(profile.userprofile.language);
    }
    if (profile.userprofile.recipe_languages) {
      setRecipeLanguages(LANGUAGES.map(recipe_language => profile.userprofile.recipe_languages.includes(recipe_language)));
    }
    if (profile.userprofile.diet) {
      setDiet(profile.userprofile.diet);
    }
    if (profile.userprofile.meal_plan_reminder_enabled) {
      setMealPlanReminderEnabled(profile.userprofile.meal_plan_reminder_enabled);
    }
  }, [profile]);

  useEffect(() => {
    if (image_tmp) {
      updateAvatar(image_tmp);
    }
  }, [image_tmp, updateAvatar]);

  const launchCamera = useCallback(async () =>
    import('react-native-image-crop-picker').then(
      ImagePicker => ImagePicker.openCamera(image_picker_options).then(
        new_image => {
          setImageTmp(new_image);
          setShowImageTypePicker(false);
        }
      ).catch((error) =>
        console.log("Picker error", error)
      )
    ).catch((error) =>
      console.log("Import error", error)
    )
  );

  const launchImagePicker = useCallback(async () =>
    import('react-native-image-crop-picker').then(
      ImagePicker => ImagePicker.openPicker(image_picker_options).then(
        new_image => {
          setImageTmp(new_image);
          setShowImageTypePicker(false);
        }
      ).catch((error) =>
        console.log("Picker error", error)
      )
    ).catch((error) =>
      console.log("Import error", error)
    )
  );

  const submit = () => {
    const recipe_languages = [];
    LANGUAGES.forEach((recipe_language_candidate, index) => {
      if (recipeLanguages[index]) {
        recipe_languages.push(recipe_language_candidate);
      }
    });
    let submit_email = undefined;
    if (email !== '') {
      submit_email = email;
    }
    let submit_url = url;
    if (!url.includes('://')) {
      submit_url = `http://${url}`;
    }
    updateProfile({
      username,
      email: submit_email,
      userprofile: {
        subscribed,
        language,
        recipe_languages,
        diet,
        full_name,
        bio,
        url: submit_url,
        meal_plan_reminder_enabled
      }
    });
  };

  const image_picker_options = {
    mediaType: 'photo',
    width: 500,
    height: 500,
    cropping: true,
    cropperCircleOverlay: true,
    useFrontCamera: true
  };

  return (
    <>
      <HidingHeaderScrollView
        ref={scroll_ref}
        header={(
          <Title
            title_string={t('app.settings')}
            goBack={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name})}
          />
        )}
        body={(
          <View style={{padding}}>
            {
              profile.is_superuser ? (
                <>
                  <VSkipMedium/>
                  <Button
                    style={theme.button}
                    onPress={() => navigation.navigate(
                      APP_TABS.admin.name,
                      { screen: ADMIN_SCREENS.labelling.name }
                    )}
                  >
                    <ButtonText style={theme.button}>
                      {t('profile.settings.navigateToAdmin')}
                    </ButtonText>
                  </Button>
                </>
              ) : null
            }

            <View style={[theme.secondary, common_styles.card]}>

            <H2Serif style={theme.secondary}>{t('profile.settings.profileSettingsTitle')}</H2Serif>
            <VSkipHuge/>

            <Pressable
              onPress={() => {
                if (Platform.OS === 'web') {
                  pushNotification(
                    NotificationSeverity.WARNING,
                    t('profile.settings.imagePickerNotAvailable'),
                    3
                  );
                } else {
                  setShowImageTypePicker(true)
                }
              }}
              style={styles.avatar}
            >
              {
                image ? (
                  <ImageBackground
                    defaultSource={image_placeholder_uri}
                    style={styles.avatar}
                    source={{uri: `${MEDIA_PREFIX}${image}`}}
                  >
                    <View style={styles.avatarGradient}>
                      <LinearGradient/>
                    </View>
                    <View style={[styles.avatarContent, {padding}]}>
                      <Body2 style={{color: 'white'}}>{t('profile.settings.editAvatar')}</Body2>
                    </View>
                  </ImageBackground>
                ) : (
                  <View style={[styles.avatar, theme.secondary]}>
                    <View style={styles.avatarContent}>
                    <User color={theme.general.color}/>
                    </View>
                    <View style={styles.avatarGradient}>
                      <LinearGradient/>
                    </View>
                    <View style={[styles.avatarContent, {padding}]}>
                      <Body2 style={{color: 'white'}}>{t('profile.settings.editAvatar')}</Body2>
                    </View>
                  </View>
                )
              }
            </Pressable>
            <VSkipHuge/>

            <Small style={theme.secondary}>{t('auth.username')}</Small>
            <VSkipMedium/>
            <TextInput
              autoCapitalize="none"
              autoComplete="username"
              textContentType="username"
              placeholder={t('auth.username')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={username}
              onChangeText={setUsername}
              style={[textField, theme.textField, {color: theme.textFieldPlaceholder.color}]}
              editable={false}
            />

            <VSkipHuge/>

            <Small style={theme.secondary}>{t('profile.settings.eMail')}</Small>
            <VSkipMedium/>
            <TextInput
              autoCapitalize="none"
              autoComplete="email"
              textContentType="emailAddress"
              placeholder={t('profile.settings.eMail')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={email}
              onChangeText={setEmail}
              style={[textField, theme.textField, {color: theme.textFieldPlaceholder.color}]}
              editable={false}
            />

            <VSkipMedium/>

            <View style={auth_styles.switchContainer}>
              <Body2 style={{...theme.secondary, flex:1}}>
                { `${t('profile.settings.subscribe')} (${t('profile.settings.noSpam')})` }
              </Body2>
              <Switch
                value={subscribed}
                onValueChange={() => setSubscribed(!subscribed)}
                thumbColor={theme.switchThumbColor}
                trackColor={theme.switchTrackColor}
              />
            </View>

            <VSkipHuge/>

            <Small style={theme.secondary}>{t('auth.fullNameLabel')}</Small>
            <VSkipMedium/>
            <TextInput
              autoCapitalize="words"
              autoComplete="name"
              textContentType="name"
              style={[textField, theme.textField]}
              placeholder={t('auth.fullNamePlaceholder')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={full_name}
              onChangeText={setFullName}
            />

            <VSkipHuge/>

            <Small style={theme.secondary}>{t('auth.bioLabel')}</Small>
            <VSkipMedium/>
            <TextInput
              autoCapitalize="sentences"
              textContentType="none"
              style={[longTextField, theme.textField]}
              placeholder={t('auth.bioPlaceholder')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={bio}
              onChangeText={setBio}
              maxLength={160}
              multiline
            />

            <VSkipHuge/>

            <Small style={theme.secondary}>{t('auth.urlLabel')}</Small>
            <VSkipMedium/>
            <TextInput
              autoCapitalize='none'
              autoCorrect={false}
              autoComplete={(Platform.OS === 'ios') ? "url" : null}
              textContentType="URL"
              inputMode="url"
              style={[textField, theme.textField]}
              placeholder={t('auth.urlPlaceholder')}
              placeholderTextColor={theme.textFieldPlaceholder.color}
              value={url}
              onChangeText={setUrl}
            />

            <VSkipHuge/>

            <Small style={theme.secondary}>{t('profile.settings.languageLabel')}</Small>
            <VSkipMedium/>
            <Button
              style={[textField, theme.textField, {justifyContent: 'space-between'}]}
              onPress={() => setShowLangPicker(true)}
            >
              <Body2 style={theme.textField}>{t('profile.settings.languageSelectionLabel')}</Body2>
              <View style={styles.pickerSelection}>
                <Body2 style={[theme.textField, theme.general]}>{t(`language.${language}`)}</Body2>
                <View style={common_styles.inlineIcon}>
                  <ArrowRight color={theme.secondary.color}/>
                </View>
              </View>
            </Button>

            </View>

            <VSkipHuge/>

            <View style={[theme.secondary, common_styles.card]}>

            <H2Serif style={theme.secondary}>{t('profile.settings.recipeSearchSettingsTitle')}</H2Serif>
            <VSkipHuge/>

            <Small style={theme.secondary}>{t('profile.settings.recipeLanguages')}</Small>
            <VSkipMedium/>
            {
              LANGUAGES.map((recipe_language, index) => {
                const is_main_language = (language === recipe_language);
                const value = recipeLanguages[index];
                return (
                  <React.Fragment key={index}>
                    <View style={auth_styles.switchContainer}>
                      <Body2 style={theme.secondary}>{t(`language.${recipe_language}`)}</Body2>
                      <Switch
                        value={value}
                        onValueChange={() => setRecipeLanguage(index, !value)}
                        disabled={is_main_language}
                        thumbColor={theme.switchThumbColor}
                        trackColor={theme.switchTrackColor}
                      />
                    </View>
                    <VSkipMedium/>
                  </React.Fragment>
                )
              })
            }

            <VSkipHuge/>

            <Small style={theme.secondary}>{t('profile.settings.dietLabel')}</Small>
            <VSkipMedium/>
            <Button
              style={[textField, theme.textField, {justifyContent: 'space-between'}]}
              onPress={() => setShowDietPicker(true)}
            >
              <Body2 style={theme.textField}>{t('profile.settings.dietSelectionLabel')}</Body2>
              <View style={styles.pickerSelection}>
                <Body2 style={[theme.textField, theme.general]}>{t(`common.diets.${diet}`)}</Body2>
                <View style={common_styles.inlineIcon}>
                  <ArrowRight color={theme.secondary.color}/>
                </View>
              </View>
            </Button>

            </View>

            <VSkipHuge/>

            {
              Platform.select({
                default: (
                  <View style={[theme.secondary, common_styles.card]}>

                  <H2Serif style={theme.secondary}>{t('profile.settings.reminderSettingsTitle')}</H2Serif>
                  <VSkipLarge/>

                  <Body2 style={theme.secondary}>{t('profile.settings.reminderSettingsExplanation')}</Body2>
                  <VSkipHuge/>

                  <Small style={theme.secondary}>
                    { t('profile.settings.mealPlanReminderLabel') }
                  </Small>
                  <VSkipMedium/>

                  <View style={auth_styles.switchContainer}>
                    <Body2 style={theme.secondary}>
                      { t('profile.settings.weeklyReminderLabel') }
                    </Body2>
                    <Switch
                      value={meal_plan_reminder_enabled}
                      onValueChange={() => setMealPlanReminderEnabled(!meal_plan_reminder_enabled)}
                      thumbColor={theme.switchThumbColor}
                      trackColor={theme.switchTrackColor}
                    />
                  </View>

                  </View>
                ),
                android: null
              })
            }

            <VSkipHuge/>

            <View style={[theme.secondary, common_styles.card]}>
              <H2Serif style={theme.secondary}>{t('profile.settings.onboardingAnswersTitle')}</H2Serif>
              <VSkipLarge/>

              <Body2 style={theme.secondary}>{t('profile.settings.onboardingAnswersExplanation')}</Body2>
              <VSkipHuge/>

              <Button
                style={theme.secondaryButton}
                onPress={() => deleteOnboardingAnswers()}
              >
                <ButtonText style={theme.secondaryButton}>
                  { t('profile.settings.onboardingAnswersReset') }
                </ButtonText>
              </Button>
            </View>

            <VSkipHuge/>

            <Button
              style={theme.button}
              onPress={submit}
            >
              <ButtonText style={theme.button}>
                {t('common.save')}
              </ButtonText>
            </Button>

            <VSkipMedium/>
            <Button
              style={theme.secondaryButton}
              onPress={() => logOut()}
            >
              <ButtonText style={theme.secondaryButton}>
                {t('auth.logOut')}
              </ButtonText>
            </Button>

            <VSkipMedium/>

            <Button
              style={theme.tertiaryButton}
              onPress={() => {navigation.navigate(MISC_SCREENS.termsAndConditions.name)}}
            >
              <ButtonText style={theme.tertiaryButton}>
                {t('common.termsAndConditions')}
              </ButtonText>
            </Button>

            <Button
              style={theme.tertiaryButton}
              onPress={() => {navigation.navigate(MISC_SCREENS.privacyPolicy.name)}}
            >
              <ButtonText style={theme.tertiaryButton}>
                {t('common.privacyPolicy')}
              </ButtonText>
            </Button>

            <Button
              style={theme.tertiaryButton}
              onPress={() => {navigation.navigate(MISC_SCREENS.about.name)}}
            >
              <ButtonText style={theme.tertiaryButton}>
                {t('common.about')}
              </ButtonText>
            </Button>

            {
              delete_confirmed ? (
                <Button
                  style={[theme.secondaryButton, theme.errorState]}
                  onPress={deleteProfile}
                >
                  <ButtonText style={[theme.secondaryButton, theme.errorState]}>
                    {t('profile.settings.deleteAccount')}
                  </ButtonText>
                </Button>
              ) : (
                <Button
                  style={theme.tertiaryButton}
                  onPress={() => {
                    setDeleteConfirmed(true);
                    scroll_ref.current?.scrollTo({y: delete_scroll_position, animated: (Platform.OS !== 'web')});
                  }}
                >
                  <ButtonText style={theme.tertiaryButton}>
                    {t('profile.settings.deleteAccount')}
                  </ButtonText>
                </Button>
              )
            }
            <VSkipMedium/>
            <Small
              style={[theme.errorState, {opacity: delete_confirmed ? 1.0 : 0.0}]}
              onLayout={(event) => {
                const { y } = event.nativeEvent?.layout;
                setDeleteScrollPosition(y);
              }}
            >
              { t('profile.settings.reallyDeleteAccount') }
            </Small>

            <ValuePicker
              values={LANGUAGES}
              value={language}
              setValue={setLanguage}
              visible={show_lang_picker}
              onRequestClose={() => setShowLangPicker(false)}
              i18n_prefix={'language'}
            />
            <ValuePicker
              values={Object.values(DIETS)}
              value={diet}
              setValue={setDiet}
              visible={show_diet_picker}
              onRequestClose={() => setShowDietPicker(false)}
              i18n_prefix={'common.diets'}
            />
          </View>
        )}
      />
      <Picker
        options={[
          {
            label: t('postMake.launchCamera'),
            action: launchCamera
          },
          {
            label: t('postMake.pickImageFromLibrary'),
            action: launchImagePicker
          }
        ]}
        visible={show_image_type_picker}
        onRequestClose={() => setShowImageTypePicker(false)}
        close_on_pick={false}
      />
    </>
  );
}

const styles = StyleSheet.create({
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 24,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  avatarGradient: {
    height: 50,
    width: 120,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  avatarContent: {
    ...StyleSheet.absoluteFill,
    bottom: 0,
    height: 120,
    width: 120,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  pickerSelection: {
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 0.5
  }
})

export default Settings;
