import React from "react";

import Svg, { Path } from "react-native-svg";

const Checkmark = ({color = 'white', ...props}) => (
  <Svg
    width="75%"
    height="75%"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M4.383 7.934a.889.889 0 0 1-.333-.068.94.94 0 0 1-.316-.216L.917 4.834a.865.865 0 0 1-.259-.659.921.921 0 0 1 .276-.658.893.893 0 0 1 .658-.267c.26 0 .48.089.658.267l2.167 2.166 5.35-5.35a.904.904 0 0 1 .641-.275.82.82 0 0 1 .642.276.882.882 0 0 1 .267.65.885.885 0 0 1-.267.65L5.034 7.65c-.1.1-.206.172-.317.216a.885.885 0 0 1-.334.068Z"
      fill={color}
    />
  </Svg>
)

export default Checkmark
