import React, { useEffect, useRef } from 'react';

import { TextInput, useColorScheme } from 'react-native';

import { useTranslation } from 'react-i18next';

import { SearchBar as SearchBarComponent } from '../../utils/';
import { useTheme } from '../../utils/Theme';

const SearchBar = ({ search_phrase, setIngredientsSearchPhrase, auto_focus, ...props }) => {
  const text_input = useRef();

  const theme = useTheme();

  useEffect(() => {
    if (text_input.current && auto_focus) {
      text_input.current.focus();
    }
  }, [text_input]);

  const { t } = useTranslation('common')

  return (
    <SearchBarComponent
      search_phrase={search_phrase}
      setSearchPhrase={setIngredientsSearchPhrase}
      doSearch={() => {/*search is dispatched in ingredient picker*/}}
      placeholder={t('common.search')}
      ref={text_input}
      {...props}
    />
  );
};

export default SearchBar;
