import React from 'react';
import {
  Image,
  Pressable,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import common_styles, {
  padding,
  roundedBorder
} from '../../common/components/CommonStyles';
import {
  Body1,
  Body2,
  getEmojisFromRecipe,
  H1,
  H2Serif,
  Small,
  XSmall
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import Cross from '../../assets/Cross';
import { RecipeImagePlaceholderSmall } from '../../assets/RecipeImagePlaceholder';

import {
  RECIPE_COMPACT_MAX_INGREDIENTS
} from '../../common/constants/';
import { MEDIA_PREFIX } from '../../common/constants/urls';

const RecipeCompact = ({
  recipe,
  delete_enabled,
  deleteEntry,
  onPress,
  ...props
}) => {
  const { t } = useTranslation('common');

  const theme = useTheme();

  const emojis = getEmojisFromRecipe(recipe);

  let image_component = null;
  if (recipe && recipe.images && recipe.images.length !== 0) {
    const {image, image_placeholder} = recipe.images[0];
    const image_width = recipe.images[0].width;
    const image_height = recipe.images[0].height;
    const image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
    image_component = (
      <Image
        defaultSource={image_placeholder_uri}
        source={{uri: `${MEDIA_PREFIX}${image}`}}
        style={styles.image}
      />
    );
  } else {
    image_component = (
      <View style={styles.image}>
        <RecipeImagePlaceholderSmall
          stroke_color={theme.secondary.backgroundColor}
          background_color={theme.general.backgroundColor}
          emoji={emojis[0]}
        />
      </View>
    );
  }

  return (
    <Pressable
      style={[
        common_styles.card,
        theme.secondary,
        { padding: 0.5 * padding },
        props.style,
      ]}
      onPress={onPress}
    >
      <View style={styles.header}>
        { image_component }
        <View style={styles.title}>
          <H2Serif
            style={theme.secondary}
            numberOfLines={2}
          >
            { recipe.instance?.name }
          </H2Serif>
          {
            (recipe && recipe.author) ? (
              <XSmall style={theme.secondary}>{`${t(`recipes:recipeType.${recipe.recipe_type}`)} ${t('recipes:byAuthor')} ${recipe.author.username}`}</XSmall>
            ) : null
          }
          {
            (recipe && recipe.source) ? (
              <XSmall style={theme.secondary}>{recipe.source.name}</XSmall>
            ) : null
          }
        </View>
        {
          delete_enabled ? (
            <Pressable
              style={styles.crossContainer}
              onPress={() => deleteEntry()}
            >
              <Cross color={theme.secondary.color}/>
            </Pressable>
          ) : null
        }
      </View>
      <>
        {
          (recipe.ingredients_with_properties.length > 0) ? (
            <XSmall style={{...theme.secondary, marginTop: padding}}>
              {t('recipeCompact.ingredientsLabel')}
            </XSmall>
          ) : null
        }
        <View style={styles.ingredientsContainer}>
          {
            recipe.ingredients_with_properties.slice(0, RECIPE_COMPACT_MAX_INGREDIENTS).map((ing, index) => (
              <View style={[common_styles.smallIng, theme.tertiary]} key={index}>
                {
                  ing.ingredient ? (
                    <>
                      <Small>{ing.ingredient.emoji}</Small>
                      <Small style={theme.tertiary}>{ing.ingredient.name}</Small>
                    </>
                  ) : (
                    <Small style={theme.tertiary}>{ing.original_string_wo_quantity}</Small>
                  )
                }
              </View>
            ))
          }
          {
            (recipe.ingredients_with_properties.length > RECIPE_COMPACT_MAX_INGREDIENTS) ? (
              <View style={common_styles.smallIng}>
                <Body2 style={theme.secondary}>{t('recipeCompact.ingredientsCapped')}</Body2>
              </View>
            ) : null
          }
        </View>
      </>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  image: {
    height: 72,
    width: 72,
    ...roundedBorder,
    padding: 0,
    overflow: 'hidden'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    paddingHorizontal: padding,
    flex: 1
  },
  ingredientsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  crossContainer: {
    height: 20,
    width: 20
  }
});

export default RecipeCompact;
