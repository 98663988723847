import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from './LanguageDetector';

import { API_PREFIX } from './common/constants/urls';

import common_en from './locales/en/common.json';
import recipes_en from './locales/en/recipes.json';
import welcome_en from './locales/en/welcome.json';
import about_en from './locales/en/about.json';
import terms_en from './locales/en/terms.json';

import common_de from './locales/de/common.json';
import recipes_de from './locales/de/recipes.json';
import welcome_de from './locales/de/welcome.json';
import about_de from './locales/de/about.json';
import terms_de from './locales/de/terms.json';

const resources = {
  en: {
    common: common_en,
    recipes: recipes_en,
    welcome: welcome_en,
    about: about_en,
    terms: terms_en
  },
  de: {
    common: common_de,
    recipes: recipes_de,
    welcome: welcome_de,
    about: about_de,
    terms: terms_de
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common', 'recipes', 'welcome', 'about', 'terms'],

    resources,

    supportedLngs: ['en', 'de'],
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    detection: {
      order: ['localStorage', 'sessionStorage', 'navigator'],
      caches: ['localStorage']
    }
  });

export default i18n;
