import React, {
  useEffect,
  useState
} from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  useColorScheme,
  View
} from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useTranslation } from 'react-i18next';

import {
  useIsFocused,
  useNavigation
} from '@react-navigation/native';

import { BlurView } from '../../utils/BlurView';

import { track } from '@amplitude/analytics-react-native';

import Button from '../../common/components/Button';
import { Picker } from '../../common/components/Picker';
import RecipeCompact from '../../recipes/components/RecipeCompact';

import common_styles, { padding } from '../../common/components/CommonStyles';
import {
  Body1,
  BottomStickyView,
  ButtonText,
  H1,
  PlusButton,
  SearchBar,
  Smaller,
  XSmall
} from '../../utils/';
import {
  useTheme
} from '../../utils/Theme';
import Cross from '../../assets/Cross';

import {
  APP_TABS,
  COLORS,
  FEED_SCREENS,
  HOUSEHOLD_SCREENS,
  RECIPE_COMPACT_MAX_INGREDIENTS,
  MODAL_SCREENS,
  ROOT_SCREENS
} from '../../common/constants/';

const MealPlanEntry = ({added_by, recipes, deleteEntry, onPress}) => {
  const { t } = useTranslation('common');

  const theme = useTheme();

  let recipe_component;
  if (recipes.length === 0) {
     recipe_component = (
      <RecipeCompact
        style={{margin: 0}}
        recipe={{
          ingredients_with_properties: [],
          instance: {
            name: t('recipeCompact.empty')
          }
        }}
        deleteEntry={deleteEntry}
        onPress={onPress}
      />
    );
  } else {
    const recipe = recipes[0];
    recipe_component = (
      <RecipeCompact
        style={{margin: 0}}
        recipe={recipe}
        deleteEntry={deleteEntry}
        onPress={onPress}
      />
    );
  }

  return (
    <View
      style={[
        theme.tertiary,
        common_styles.card,
        {
          padding: 0,
          marginBottom: padding
        }
      ]}
    >
      { recipe_component }
      {
        added_by ? (
          <View style={styles.footNote}>
            <XSmall style={theme.tertiary}>
              { `${t('mealPlanning.addedBy')} ${added_by.username}` }
            </XSmall>
          </View>
        ) : null
      }
    </View>
  )
}

const MealPlan = ({
  onScroll,
  scrollEventThrottle,
  header_height,
  active_household_uuid,
  meal_plan,
  most_recent_meal_plan_entry_date,
  can_load_more,
  deleteMealPlanEntry,
  loadHouseholdMealPlan,
  setMostRecentMealPlanEntryDate
}) => {
  const [ refreshing, setRefreshing ] = useState(false);
  const [ show_add_meal_modal, setShowAddMealModal ] = useState(false);
  const [ show_actions_picker, setShowActionsPicker ] = useState(false);
  const [ selected_entry, setSelectedEntry ] = useState(0);
  const [ search_phrase, setSearchPhrase ] = useState('');
  const [ search_bar_height, setSearchBarHeight ] = useState(0);

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const is_focused = useIsFocused();

  const theme = useTheme();
  const scheme = useColorScheme();

  const { top } = useSafeAreaInsets();

  const onRefresh = () => {
    setRefreshing(true);
    loadHouseholdMealPlan(
      /*offset*/0,
      search_phrase,
      /*final_callback*/() => setRefreshing(false)
    );
  };

  useEffect(() => {
    if (is_focused && !meal_plan.length) {
      onRefresh();
    }
  }, [is_focused]);

  useEffect(() => {
    if (is_focused) {
      setMostRecentMealPlanEntryDate(
        most_recent_meal_plan_entry_date,
        active_household_uuid
      );
    }
  }, [is_focused, most_recent_meal_plan_entry_date]);

  return (
    <>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={theme.general.color}
            progressViewOffset={header_height + top}
          />
        }
        onScroll={onScroll}
        scrollEventThrottle={scrollEventThrottle}
        contentContainerStyle={{
          padding,
          paddingTop: padding + header_height + top,
          paddingBottom: search_bar_height
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          {
            (meal_plan.length === 0) ? (
              <Body1 style={theme.general}>
                { t('mealPlanning.noMealsPlanned') }
              </Body1>
            ) : null
          }
          {
            meal_plan.map((entry, index) => (
              <MealPlanEntry
                key={index}
                {...entry}
                deleteEntry={() => {
                  let recipe_name = 'Meal plan entry without recipe';
                  if (entry.recipes.length !== 0) {
                    recipe_name = entry.recipes[0].name;
                  };
                  track('delete_entry', {
                    category: 'meal_plan',
                    name: recipe_name
                  });
                  deleteMealPlanEntry(entry.uuid)
                }}
                onPress={() => {
                  let recipe_name = 'Meal plan entry without recipe';
                  if (entry.recipes.length !== 0) {
                    recipe_name = entry.recipes[0].name;
                  };
                  track('show_actions', {
                    category: 'meal_plan',
                    name: recipe_name
                  });
                  setSelectedEntry(index);
                  setShowActionsPicker(true);
                }}
              />
            ))
          }
        </View>
        {
          can_load_more ? (
            <View style={common_styles.buttonContainer}>
              <Button
                style={theme.button}
                onPress={() => loadHouseholdMealPlan(
                  /*offset*/meal_plan.length,
                  search_phrase
                )}
              >
                <ButtonText style={theme.button}>
                  { t('common.loadMore') }
                </ButtonText>
              </Button>
            </View>
          ) : null
        }
        <View style={{height: search_bar_height}}/>
      </ScrollView>
      <KeyboardAvoidingView
        behavior="position"
        enabled
      >
        <BottomStickyView
          onLayout={(event) => {
            const { height } = event.nativeEvent?.layout;
            setSearchBarHeight(height);
          }}
        >
          <BlurView
            style={StyleSheet.absoluteFill}
            reducedTransparencyFallbackColor={theme.general.backgroundColor}
            overlayColor=""
            blurType={scheme}
            blurAmount={10}
          />
          <View style={styles.header}>
            <SearchBar
              search_phrase={search_phrase}
              setSearchPhrase={setSearchPhrase}
              doSearch={(new_search_phrase) => loadHouseholdMealPlan(
                /*offset*/0,
                new_search_phrase,
                /*final_callback*/() => setRefreshing(false)
              )}
              placeholder={t('search.universalSearchPlaceholder')}
              style={{flex: 1}}
            />
            <PlusButton
              onPress={() => setShowAddMealModal(true)}
              style={{marginLeft: padding}}
            />
          </View>
        </BottomStickyView>
      </KeyboardAvoidingView>
      <Picker
        options={[
          {
            label: t('mealPlanning.findRecipe'),
            action: () => {
              track('go_to_recipe_search', {
                category: 'meal_plan'
              });
              navigation.navigate(APP_TABS.search.name);
            }
          },
          {
            label: t('mealPlanning.addMealPlanEntry'),
            action: () => {
              track('start_custom_meal', {
                category: 'meal_plan'
              });
              navigation.navigate(
                APP_TABS.feed.name,
                {
                  screen: FEED_SCREENS.postMake.name,
                  params: {
                    add_to_meal_plan: true,
                    is_make: false
                  }
                }
              )
            }
          }
        ]}
        visible={show_add_meal_modal}
        onRequestClose={() => setShowAddMealModal(false)}
      />
      <Picker
        options={[
          {
            label: t('mealPlanning.showRecipe'),
            action: () => {
              if (meal_plan[selected_entry].recipes.length === 0) return;
              track('view_recipe', {
                category: 'meal_plan',
                name: meal_plan[selected_entry].recipes[0].name
              });
              navigation.navigate(
                MODAL_SCREENS.recipeDetail.name,
                {
                  uuid: meal_plan[selected_entry].recipes[0].uuid,
                  nav_origin: { name: HOUSEHOLD_SCREENS.householdHome.name }
                }
              );
            }
          },
          {
            label: t('mealPlanning.addMakeAndRemoveEntry'),
            action: () => {
              track('add_make_and_remove_entry', {
                category: 'meal_plan'
              });
              navigation.navigate(
                APP_TABS.feed.name,
                {
                  screen: FEED_SCREENS.postMake.name,
                  params: {
                    recipes: meal_plan[selected_entry].recipes
                  }
                }
              );
              deleteMealPlanEntry(meal_plan[selected_entry].uuid);
            }
          },
          {
            label: t('mealPlanning.removeEntry'),
            action: () => {
              track('remove_entry', {
                category: 'meal_plan'
              });
              deleteMealPlanEntry(meal_plan[selected_entry].uuid);
            },
            style: {color: COLORS.RED}
          }
        ]}
        visible={show_actions_picker}
        onRequestClose={() => setShowActionsPicker(false)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    padding,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footNote: {
    padding: 0.5 * padding,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default MealPlan;
