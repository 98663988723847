import { connect } from 'react-redux';

import { loadRecipe } from '../../actions/';

import Recipe from '../components/Recipe';

const mapDispatchToProps = ({
  loadRecipe
})

export default connect(
  null,
  mapDispatchToProps
)(Recipe)
