import React from 'react';

import Svg, { G, Path } from "react-native-svg";

const Edit = ({color = 'white', stroke_width = 20, filled = false}) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 200 200"
    version="1.1"
    id="svg5"
  >
    <G id="Layer1">
      <G transform="matrix(1,0,0,1.15681,-1.68742,-39.6115)">
        <Path d="M127.687,54.989L63.689,54.989C42.717,54.989 25.69,69.707 25.69,87.836L25.69,153.536C25.69,171.665 42.717,186.384 63.689,186.384L139.686,186.384C160.658,186.384 177.685,171.665 177.685,153.536L177.685,103.397"  style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}/>
      </G>
      <G transform="matrix(0.707107,-0.707107,0.707107,0.707107,-15.096,80.0257)">
        <Path d="M164.276,83.911L59.274,83.911L30.424,98.794L59.274,112.22L164.276,112.22L164.276,83.911Z"  style={{fill:'none', stroke: color, strokeWidth: stroke_width, strokeLinecap: 'round'}}/>
      </G>
    </G>
  </Svg>
);

export default Edit;
