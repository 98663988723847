import { connect } from 'react-redux';

import {
  loginRequest,
  resetPasswordRequest,
  signUpRequest
} from '../../actions';

import LoginSignUp from '../components/LoginSignUp';

const mapStateToProps = state => ({
  auth_state: state.auth_state
})

const mapDispatchToProps = {
  loginRequest,
  resetPasswordRequest,
  signUpRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginSignUp)
