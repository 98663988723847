import React from 'react';

import Svg, { G, Path } from 'react-native-svg';

const CurvyMask = (props) => (
  <G transform="matrix(1,0,0,1.4012,0,5.81485)">
    <Path
      d="M-7.596,242.649C-7.596,242.649 88.97,241.214 131.613,242.899C206.315,245.85 273.155,272.869 307.445,272.622C344.906,272.352 406.208,251.826 406.208,251.826L405.964,-7.32L-6.896,-8.587L-7.596,242.649Z"
      style={{fill:'white'}}
    />
  </G>
);

export default CurvyMask;
