import React from 'react';
import { Pressable, Text, View } from 'react-native';

import { useTranslation } from 'react-i18next';

import styles from '../../common/components/IngredientStyles';
import common_styles from '../../common/components/CommonStyles';
import Tile from '../../common/components/Tile';
import { BigLetter, BigEmoji } from '../../common/components/misc';

import { Body2, XSmall } from '../../utils/';
import { useTheme } from '../../utils/Theme';

import Checkmark from '../../assets/Checkmark';
import Cross from '../../assets/Cross';

const WizardIngredient = ({ slot_idx, ingredient, addActiveIngredient, addInactiveIngredient, clearPivotIngredientSlot }) => {

  const { t } = useTranslation('common');

  const theme = useTheme();

  const tile_body_loading = (
    <Body2 style={theme.cardBody}>
      { t('common.loading') }
    </Body2>
  );

  let tile_body = tile_body_loading;
  if (!ingredient) {
    tile_body = tile_body_loading;
  } else {
    let icon = (<BigLetter style={theme.cardBody} name={ingredient.name}/>);
    if (ingredient.emoji) {
      icon = (<BigEmoji emoji={ingredient.emoji}/>);
    }
    tile_body = (
      <>
        <View style={styles.header}>
          { icon }
        </View>
        <View style={styles.ingredientBody}>
          <XSmall style={[styles.ingredientText, theme.cardBody, {opacity: 1.0}]} numberOfLines={2}>
            { ingredient.name }
          </XSmall>
        </View>
        <View style={styles.menu}>
          <Pressable style={[styles.dot, styles.redBg]} onPress={() => {
            addInactiveIngredient(ingredient);
            clearPivotIngredientSlot(slot_idx);
          }}>
            <Cross color={theme.activeIngredient.color}/>
          </Pressable>
          <Pressable style={[styles.dot, styles.greenBg]} onPress={() => {
            addActiveIngredient(ingredient);
            clearPivotIngredientSlot(slot_idx);
          }}>
            <Checkmark color={theme.activeIngredient.color}/>
          </Pressable>
        </View>
      </>
    );
  }

  return (
    <View style={[styles.ingredientBase, theme.card]}>
      <Tile>
        { tile_body }
      </Tile>
    </View>
  );
}

export default WizardIngredient;
