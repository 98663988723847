import { connect } from 'react-redux';

import {
  universalSearch,
  addFollow,
  deleteFollow
} from '../../actions';
import UniversalSearch from '../components/UniversalSearch';

const mapStateToProps = state => ({
  universal_search_results: state.universal_search_results
})

const mapDispatchToProps = {
  universalSearch,
  addFollow,
  deleteFollow
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniversalSearch)
