import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Image,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Switch,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  Camera,
  useCameraDevice,
  useCameraPermission
} from '../../utils/vision-camera';

import { useNavigation, useRoute } from '@react-navigation/native';

import { track } from '@amplitude/analytics-react-native';

import RecipeEditorBase, { IngredientPicker } from '../../recipes/components/RecipeEditorBase';
import RecipeCompact from '../../recipes/components/RecipeCompact';
import {
  PlusTag,
  Tag
} from '../../recipes/components/TagPicker';
import TagPicker from '../../recipes/containers/TagPickerContainer';

import common_styles, {
  borderRadius,
  padding,
  textField
} from '../../common/components/CommonStyles';
import Button from '../../common/components/Button';
import { Picker } from '../../common/components/Picker';
import { Title, TitleWAction } from '../../header/components/Header';

import IngredientsList from '../../common/components/IngredientsList';
import IngredientBase, {
  ING_STATE
} from '../../common/components/Ingredient';

import {
  APP_TABS,
  FEED_SCREENS,
  MODAL_SCREENS
} from '../../common/constants/';

import Cross from '../../assets/Cross';
import Circle from '../../assets/Circle';
import Library from '../../assets/Library';

import {
  Alert,
  Body2,
  H1,
  HidingHeaderScrollView,
  sleep,
  Small,
  VSkipLarge as VSkip
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

const RECIPE_MODE = Object.freeze({"CUSTOM": 0, "EXISTING": 1});
const VISIBILITY_TYPE = Object.freeze({"PUBLIC": 'public', "PRIVATE": 'private'});
const SHARE_TYPE = Object.freeze({"SHARE": 'share', "MAKE": 'make'});
const PAGE = Object.freeze({
  "PERMISSION_SCREEN": -1,
  "CAMERA": 0,
  "MAKE": 1,
  "RECIPE_EDITOR": 2,
  "USED_UP": 3
});
const PERMISSION_STATE = Object.freeze({"NOT_DETERMINED": 0, "GRANTED": 1, "DENIED": 2});

const PostMake = ({household_uuid, postRecipeShare}) => {
  const [ page, setPage ] = useState(PAGE.PERMISSION_SCREEN);
  const [ recipe_mode, setRecipeMode ] = useState(RECIPE_MODE.CUSTOM);
  const [ has_recipe, setHasRecipe ] = useState(false);
  const [ submit_enabled, setSubmitEnabled ] = useState(true);
  const [ recipe_uuid, setRecipeUUID ] = useState('');
  const [ meal_name, setMealName ] = useState('');
  const [ show_empty_meal_name_warning, setShowEmptyMealNameWarning ] = useState(false);
  const [ ingredients, setIngredients ] = useState([]);
  const [ selected, setSelected ] = useState(new Set());
  const [ instructions, setInstructions ] = useState('');
  const [ caption, setCaption ] = useState('');
  const [ tags, setTags ] = useState([]);
  const [ is_visibility_public, setIsVisibilityPublic ] = useState(true);
  const [ is_make, setIsMake ] = useState(true);
  const [ add_to_meal_plan, setAddToMealPlan ] = useState(false);
  const [ images, setImages ] = useState([]);
  const [ camera_permission_state, setCameraPermissionState ] = useState(PERMISSION_STATE.NOT_DETERMINED);
  const [ tags_scroll_position, setTagsScrollPosition ] = useState(0);

  const camera_ref = useRef();
  const make_scroll_ref = useRef();
  const recipe_scroll_ref = useRef();

  const [ show_ingredient_picker, setShowIngredientPicker ] = useState(false);
  const [ show_tags_picker, setShowTagsPicker ] = useState(false);

  const [ show_no_tags_warning, setShowNoTagsWarning ] = useState(false);

  const theme = useTheme();

  const navigation = useNavigation();
  const route = useRoute();

  const { t } = useTranslation(['common']);

  const { bottom, top } = useSafeAreaInsets();

  const { hasPermission, requestPermission } = useCameraPermission();
  const device = useCameraDevice('back');

  useEffect(() => {
    if (route.params) {
      if (route.params.recipes && (route.params.recipes.length > 0)) {
        setRecipeUUID(route.params.recipes[0].uuid);
        setIngredients(route.params.recipes[0].ingredients_with_properties)
        setRecipeMode(RECIPE_MODE.EXISTING);
        setHasRecipe(true);
      } else {
        setRecipeMode(RECIPE_MODE.CUSTOM);
      }
      if (route.params.ingredients && (route.params.ingredients.length > 0)) {
        setIngredients(route.params.ingredients);
      }
      if (route.params.is_visibility_public !== undefined) {
        setIsVisibilityPublic(route.params.is_visibility_public);
      }
      if (route.params.is_make !== undefined) {
        setIsMake(route.params.is_make);
      }
      if (route.params.add_to_meal_plan !== undefined) {
        setAddToMealPlan(route.params.add_to_meal_plan);
      }
    }
  }, [route.params]);

  useEffect(() => {
    if ((recipe_mode === RECIPE_MODE.EXISTING) || meal_name || ingredients.length) {
      setHasRecipe(true);
    } else {
      setHasRecipe(false);
    }
  }, [recipe_mode, meal_name, ingredients]);

  useEffect(() => {
    const handle_permission = async () => {
      if (!hasPermission && !await requestPermission()) {
        console.log('DENIED')
        setCameraPermissionState(PERMISSION_STATE.DENIED);
      } else {
        console.log('GRANTED')
        setCameraPermissionState(PERMISSION_STATE.GRANTED);
      }
    };
    handle_permission();
  }, [hasPermission, requestPermission, setCameraPermissionState]);

  useEffect(() => {
    if ((page === PAGE.PERMISSION_SCREEN) && (camera_permission_state === PERMISSION_STATE.GRANTED)) {
      console.log('GRANTED -> CAMERA')
      setPage(PAGE.CAMERA);
    }
    if (((page === PAGE.PERMISSION_SCREEN) || (page === PAGE.CAMERA)) && ((camera_permission_state === PERMISSION_STATE.DENIED) || !device)) {
      console.log('DENIED or NO DEVICE -> MAKE')
      setPage(PAGE.MAKE);
    }
    if ((page === PAGE.CAMERA) && (Platform.OS === 'web')) {
      console.log('NO CAMERA (WEB) -> MAKE')
      setPage(PAGE.MAKE);
    }
  }, [page, setPage, camera_permission_state]);

  const select = useCallback((ids) => {
    const new_selected = new Set([...selected]);
    ids.forEach(id => new_selected.add(id));
    setSelected(new_selected);
  });

  const deselect = useCallback((ids) => {
    const new_selected = new Set([...selected]);
    ids.forEach(id => new_selected.delete(id));
    setSelected(new_selected);
  });

  const validateRecipe = useCallback(() => {
    if (recipe_mode == RECIPE_MODE.EXISTING) return true;
    if (!meal_name) {
      setShowEmptyMealNameWarning(true);
      recipe_scroll_ref.current?.scrollTo({y: 0/* TODO recipe_title_scroll_position */, animated: (Platform.OS !== 'web')});
      return false;
    } else {
      setShowEmptyMealNameWarning(false);
      return true;
    }
  });
  const validateMake = useCallback(() => {
    if (tags.length === 0) {
      setShowNoTagsWarning(true);
      make_scroll_ref.current?.scrollTo({y: tags_scroll_position, animated: (Platform.OS !== 'web')});
      return false;
    } else {
      setShowNoTagsWarning(false);
      return true;
    }
  });
  const validateAll = useCallback(() => {
    return validateRecipe() && validateMake();
  });

  const addIngredient = useCallback((ingredient) => {
    track('add_ingredient', {
      category: 'post_make',
      name: ingredient.ingredient.name
    });
    setIngredients([...ingredients, ingredient]);
  });
  const deleteIngredient = useCallback((ingredient) => {
    track('delete_ingredient', {
      category: 'post_make',
      name: ingredient.ingredient.name
    });
    setIngredients(ingredients.filter(ing => ing && (ing.ingredient.id !== ingredient.ingredient.id)));
  });

  const addTag = useCallback((tag) => {
    track('add_tag', {
      category: 'post_make',
      name: tag
    });
    setTags([...new Set([...tags, tag])]);
  });
  const deleteTag = useCallback((tag) => {
    track('delete_tag', {
      category: 'post_make',
      name: tag
    });
    setTags(tags.filter(other_tag => other_tag.tag !== tag.tag));
  });

  const submit = useCallback(() => {
    track('submit_recipe', {
      category: 'post_make',
      name: meal_name
    });
    setSubmitEnabled(false);
    if (recipe_mode === RECIPE_MODE.EXISTING) {
      postRecipeShare(
        [
          {
            uuid: recipe_uuid
          }
        ],
        images,
        caption,
        tags,
        /* used_up_ingredients */ingredients.filter(
          ing => (ing.ingredient && ing.ingredient.id && selected.has(ing.ingredient.id))
        ).map(
          ing => ing.ingredient
        ),
        household_uuid,
        is_visibility_public ? VISIBILITY_TYPE.PUBLIC : VISIBILITY_TYPE.PRIVATE,
        is_make ? SHARE_TYPE.MAKE : SHARE_TYPE.SHARE,
        add_to_meal_plan,
        /*success_callback*/(new_entry_image) => {
          navigation.navigate(APP_TABS.feed.name, { screen: FEED_SCREENS.home.name });
          if (new_entry_image) {
            navigation.navigate(
              MODAL_SCREENS.storyShare.name,
              {
                image: new_entry_image
              }
            )
          }
        },
        /*failure_callback*/() => {},
        /*final_callback*/() => setSubmitEnabled(true)
      );
    } else {
      postRecipeShare(
        has_recipe ? [
          {
            ingredients_with_properties: ingredients.filter(ing => (ing.ingredient && ing.ingredient.id)),
            instances: [
              {
                name: meal_name,
                notes: instructions
              }
            ]
          }
        ] : null,
        images,
        caption,
        tags,
        /* used_up_ingredients */ingredients.filter(
          ing => (ing.ingredient && ing.ingredient.id && selected.has(ing.ingredient.id))
        ).map(
          ing => ing.ingredient
        ),
        household_uuid,
        is_visibility_public ? VISIBILITY_TYPE.PUBLIC : VISIBILITY_TYPE.PRIVATE,
        is_make ? SHARE_TYPE.MAKE : SHARE_TYPE.SHARE,
        add_to_meal_plan,
        /*success_callback*/(new_entry_image) => {
          navigation.navigate(APP_TABS.feed.name, { screen: FEED_SCREENS.home.name });
          if (new_entry_image) {
            navigation.navigate(
              MODAL_SCREENS.storyShare.name,
              {
                image: new_entry_image
              }
            )
          }
        },
        /*failure_callback*/() => {},
        /*final_callback*/() => setSubmitEnabled(true)
      );
    }
  });

  const image_picker_options = {
    mediaType: 'photo',
    maxHeight: 2000,
    maxWidth: 2000
  };

  const open_image_picker = useCallback(async () =>
    import('react-native-image-crop-picker').then(
      ImagePicker => ImagePicker.openPicker(image_picker_options).then(
        image => setImages([image])
      ).catch((error) =>
        console.log("Picker error", error)
      )
    ).catch((error) =>
       console.log("Import error", error)
    )
  );

  const custom_recipe_body = (
    <RecipeEditorBase
      meal_name={meal_name}
      setMealName={setMealName}
      show_empty_meal_name_warning={show_empty_meal_name_warning}
      validate={validateRecipe}
      notes={instructions}
      setNotes={setInstructions}
      ingredients={ingredients}
      deleteIngredient={deleteIngredient}
      show_ingredient_picker={show_ingredient_picker}
      setShowIngredientPicker={setShowIngredientPicker}
    />
  );
  const existing_recipe_body = (
    <>
      <Body2 style={[theme.general, styles.label]}>
        { t(`recipeEditor.recipe`) }
      </Body2>
      <RecipeCompact
        recipe={(route.params && route.params.recipes) ? route.params.recipes[0] : null}
        delete_enabled={true}
        deleteEntry={() => {
          setRecipeUUID('');
          setRecipeMode(RECIPE_MODE.CUSTOM);
        }}
        onPress={() => {}}
      />
      <VSkip/>
    </>
  )

  switch (page) {
    case PAGE.PERMISSION_SCREEN:
      return null;
    case PAGE.CAMERA:
      return (
        <Modal
          animationType={Platform.OS === "web" ? "none" : "fade"}
          visible={(page === PAGE.CAMERA) && !!device}
          onRequestClose={() => setPage(page => page + 1)}
        >
          <Camera
            ref={camera_ref}
            style={StyleSheet.absoluteFill}
            device={device}
            isActive={true}
            photo={true}
          />
          <View
            style={[
              styles.cameraButtonRow,
              { top, justifyContent: 'flex-end' }
            ]}
          >
            <TouchableOpacity
              style={styles.cameraButton}
              onPress={() => {
                navigation.goBack();
              }}
            >
              <View
                style={styles.cameraButtonBackground}
              />
              <Cross/>
            </TouchableOpacity>
          </View>
          <View
            style={[
              styles.cameraButtonRow,
              { bottom }
            ]}
          >
            <TouchableOpacity
              style={styles.cameraButtonContainer}
              onPress={async () => {
                await open_image_picker();
                setPage(page => page + 1);
              }}
            >
              <View
                style={styles.cameraButton}
              >
                <View
                  style={styles.cameraButtonBackground}
                />
                <Library/>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cameraButtonContainer}
              onPress={async () => {
                console.log('take photo')
                if (!camera_ref.current) {
                  console.error('camera ref invalid');
                  return;
                }
                console.log('taking photo');
                const photo = await camera_ref.current.takePhoto({
                  qualityPrioritization: 'speed'
                });
                console.log('photo', photo);
                // const result = await fetch(`file://${photo.path}`)
                // console.log('result', result);
                // const data = await result.blob();
                // console.log('data', data);
                setImages([{
                  ...photo,
                  path: `file://${photo.path}`,
                  mime: `mime/${photo.path.substring(photo.path.lastIndexOf('.') + 1).toLowerCase().replace('jpg', 'jpeg')}`
                }]);
                setPage(page => page + 1);
              }}
            >
              <View
                style={styles.cameraMainButton}
              >
                <Circle filled={true}/>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{flex: 1}}
              onPress={() => setPage(page => page + 1)}
            >
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <H1 style={{color: 'white'}}>
                  { t('common.skip') }
                </H1>
              </View>
            </TouchableOpacity>
          </View>
        </Modal>
      );
    case PAGE.RECIPE_EDITOR:
      const get_action_string = () => {
        if (has_recipe) {
          if (ingredients.filter(ing => (ing.ingredient && ing.ingredient.id)).length === 0) {
            return t('postMake.submitButton');
          } else {
            return t('common.next');
          }
        } else {
          return t('common.skipAndSubmit');
        }
      };
      const get_action_disabled = () => {
        if (has_recipe) {
          if (ingredients.filter(ing => (ing.ingredient && ing.ingredient.id)).length === 0) {
            return !submit_enabled;
          } else {
            return false;
          }
        } else {
          return !submit_enabled;
        }
      };
      return (
        <>
          <HidingHeaderScrollView
            ref={recipe_scroll_ref}
            contentContainerStyle={{padding, paddingBottom: 100 /*TODO hack*/}}
            header={(
              <TitleWAction
                goBack={() => setPage(page => page - 1)}
                goForward={() => {
                  if (!has_recipe || (ingredients.filter(ing => (ing.ingredient && ing.ingredient.id)).length === 0)) {
                    submit();
                  } else if (validateRecipe()) {
                    setPage(page => page + 1);
                  }
                }}
                title_string={ t('postMake.titleRecipeEditor') }
                action_string={get_action_string()}
                action_disabled={get_action_disabled()}
              />
            )}
            body={(
              <>
                {
                  (recipe_mode === RECIPE_MODE.CUSTOM) ? custom_recipe_body : null
                }

                <VSkip/>

                <View style={styles.switchContainer}>
                  <Body2 style={theme.general}>
                    { t('postMake.addToMealPlanLabel') }
                  </Body2>
                  <Switch
                    value={add_to_meal_plan}
                    onValueChange={() => setAddToMealPlan(!add_to_meal_plan)}
                  />
                </View>
              </>
            )}
          />
          <IngredientPicker
            ingredients={ingredients}
            show={show_ingredient_picker}
            setShow={setShowIngredientPicker}
            addIngredient={addIngredient}
            deleteIngredient={deleteIngredient}
          />
        </>
      );
    case PAGE.MAKE: {
      return (
        <>
          <HidingHeaderScrollView
            ref={make_scroll_ref}
            contentContainerStyle={{padding, paddingBottom: 100 /*TODO hack*/}}
            header={(
              <TitleWAction
                goBack={() => {
                  if (!device || (camera_permission_state === PERMISSION_STATE.DENIED) || (Platform.OS === 'web')) {
                    navigation.goBack();
                  } else {
                    setPage(page => page - 1);
                  }
                }}
                goForward={() => {
                  if (validateMake()) {
                    if (recipe_mode === RECIPE_MODE.CUSTOM) {
                      setPage(page => page + 1);
                    } else {
                      if (ingredients.filter(ing => (ing.ingredient && ing.ingredient.id)).length === 0) {
                        sumbmit();
                      } else {
                        setPage(page => page + 2);
                      }
                    }
                  }
                }}
                action_string={ ((recipe_mode === RECIPE_MODE.EXISTING) && (ingredients.filter(ing => (ing.ingredient && ing.ingredient.id)).length === 0)) ?  t('postMake.submitButton') : t('common.next') }
                title_string={ t('postMake.title') }
              />
            )}
            body={(
              <>
                {
                  (recipe_mode === RECIPE_MODE.CUSTOM) ? null : existing_recipe_body
                }

                <Body2 style={[theme.general, styles.label]}>
                  { t(`postMake.imageLabel`) }
                </Body2>
                {
                  (Platform.OS === 'web') ? (
                    <Alert text={t('postMake.imagePickerNotAvailable')} />
                  ) : (
                    <TouchableOpacity
                      onPress={async () => {
                        if (!hasPermission && !await requestPermission()) {
                          console.log('TODO raise warning!');
                          setCameraPermissionState(PERMISSION_STATE.DENIED);
                          return;
                        } else {
                          console.log('granted!');
                          setCameraPermissionState(PERMISSION_STATE.GRANTED);
                          sleep(100).then(() => setPage(page => page - 1));
                        }
                      }}
                      style={{
                        width: 100,
                        height: 100
                      }}
                    >
                      {
                        images.length ? (
                          <Image
                            source={{uri: images[0].path}}
                            style={{flex: 1, borderRadius}}
                          />
                        ) : (
                          <View style={[
                            {
                              flex: 1,
                              borderRadius,
                              justifyContent: 'center',
                              alignItems: 'center'
                            },
                            theme.secondary
                          ]}>
                            <Body2 style={theme.secondary}>+</Body2>
                          </View>
                        )
                      }
                    </TouchableOpacity>
                  )
                }

                <VSkip/>

                <Body2 style={[theme.general, styles.label]}>{t(`postMake.captionLabel`)}</Body2>
                <TextInput
                  style={[textField, theme.textField, {height: 100}]}
                  placeholder={t(`postMake.captionPlaceholder`)}
                  placeholderTextColor={theme.textFieldPlaceholder.color}
                  value={caption}
                  onChangeText={value => setCaption(value)}
                  multiline
                />

                <VSkip/>

                <Body2 style={[theme.general, styles.label]}>{t(`postMake.tagsLabel`)}</Body2>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start'
                  }}
                  onLayout={(event) => {
                    const { y } = event.nativeEvent?.layout;
                    setTagsScrollPosition(y);
                  }}
                >
                  {
                    tags ? tags.map((tag, idx) => (
                      <Tag
                        key={idx}
                        tag={tag}
                        onDelete={() => deleteTag(tag)}
                        disabled
                      />
                    )) : null
                  }
                  <PlusTag onPress={() => setShowTagsPicker(true)} />
                </View>

                {
                  show_no_tags_warning ? (
                    <Alert text={t('postMake.noTagsWarning')} />
                  ) : null
                }

                <VSkip/>

                <View style={styles.switchContainer}>
                  <Body2 style={theme.general}>
                    { t('postMake.sharePubliclyLabel') }
                  </Body2>
                  <Switch
                    value={is_visibility_public}
                    onValueChange={() => setIsVisibilityPublic(!is_visibility_public)}
                  />
                </View>

                <VSkip/>

                <View style={styles.switchContainer}>
                  <Body2 style={theme.general}>
                    { t('postMake.isMakeLabel') }
                  </Body2>
                  <Switch
                    value={is_make}
                    onValueChange={() => setIsMake(!is_make)}
                  />
                </View>

                <VSkip/>
              </>
            )}
          />
          <TagPicker
            tags={tags}
            show={show_tags_picker}
            setShow={setShowTagsPicker}
            addTag={addTag}
            deleteTag={deleteTag}
          />
        </>
      );
    }
    case PAGE.USED_UP:
      return (
        <HidingHeaderScrollView
          ref={make_scroll_ref}
          contentContainerStyle={{padding}}
          header={(
            <TitleWAction
              goBack={() => {
                if (recipe_mode === RECIPE_MODE.CUSTOM) {
                  setPage(page => page - 1);
                } else {
                  setPage(page => page - 2);
                }
              }}
              goForward={() => {
                if (validateAll()) submit();
              }}
              action_string={ t('postMake.submitButton')}
              title_string={ t('postMake.titleUsedUpIngredients') }
              action_disabled={!submit_enabled}
            />
          )}
          body={(
            <>
              <Body2 style={[theme.general, styles.label]}>{t('postMake.whichIngredientsUsedUp')}</Body2>
              <Small style={theme.general}>{t('postMake.whichIngredientsUsedUpHint')}</Small>

              <IngredientsList>
                {
                  ingredients.filter(
                    ing => (ing.ingredient && ing.ingredient.id)
                  ).map((ing, index) => (
                    <IngredientBase
                      key={index}
                      locked={false}
                      state={ING_STATE.ACTIVE}
                      ingredient={ing.ingredient}
                      ingredientAction={() => selected.has(ing.ingredient.id) ? deselect([ing.ingredient.id]) : select([ing.ingredient.id])}
                      selectable
                      selected={selected.has(ing.ingredient.id)}
                    />
                  ))
                }
              </IngredientsList>
            </>
          )}
        />
      );
  }
}

const styles = StyleSheet.create({
  cameraButtonRow: {
    position: 'absolute',
    padding,
    width: '100%',
    height: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cameraButtonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cameraButton: {
    width: 63,
    height: 63,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cameraMainButton: {
    width: 90,
    height: 90,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cameraButtonBackground: {
    ...StyleSheet.absoluteFill,
    backgroundColor: 'black',
    width: 63,
    height: 63,
    padding: 10,
    borderRadius: 63,
    opacity: 0.5
  },
  switchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    marginBottom: 0.5 * padding
  },
});

export default PostMake;
