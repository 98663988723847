import { connect } from 'react-redux';

import { clearNotification } from '../../actions';

import Notifications from '../components/Notifications';

const mapStateToProps = state => ({
  notifications: state.in_app_notifications.notifications,
  notification_verbosity: state.in_app_notifications.notification_verbosity
})

const mapDispatchToProps = {
  clearNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
