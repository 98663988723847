import React from 'react';
import { useWindowDimensions, View, Platform, Pressable, StyleSheet } from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  NotificationSeverity
} from '../../common/constants/';
import common_styles, { fullWidth, padding, roundedBorder } from '../../common/components/CommonStyles';
import { Alert, Body2, useAppWidth } from '../../utils/';
import { LightTheme } from '../../utils/Theme';
import Cross from '../../assets/Cross';

const Notifications = ({notifications, notification_verbosity, clearNotification}) => {
  const width = useAppWidth();

  const { top } = useSafeAreaInsets();

  const notification_style = new Map();
  notification_style.set(NotificationSeverity.DEBUG, styles.notificationDebug);
  notification_style.set(NotificationSeverity.INFO, styles.notificationInfo);
  notification_style.set(NotificationSeverity.WARNING, styles.notificationWarning);
  notification_style.set(NotificationSeverity.ERROR, styles.notificationError);
  notification_style.set(NotificationSeverity.FATAL, styles.notificationFatal);

  return (
    <View style={[styles.notificationsContainer, { top }]}>
      <View style={[styles.notifications, { width }]}>
        {
          notifications.filter(
            notification => notification.severity >= notification_verbosity
          ).map(
            (notification, index) => (
              <View
                key={notification.id}
                style={[styles.notificationBase, notification_style.get(notification.severity)]}
              >
                <Body2 style={{...LightTheme.general, flex: 1}}>
                  {notification.message}
                </Body2>
                <Pressable
                  style={styles.crossContainer}
                  onPress={() => clearNotification(notification.id)}
                >
                  <Cross color={LightTheme.general.color}/>
                </Pressable>
              </View>
            )
          )
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  notificationsContainer: {
    position: (Platform.OS === 'web') ? 'fixed' : 'absolute',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  notifications: {
  },
  notificationBase: {
    ...roundedBorder,
    marginHorizontal: padding,
    marginVertical: 0.25 * padding,
    flexDirection: 'row',
    alignItems: 'center'
  },
  notificationDebug: {
    backgroundColor: '#9effc4',
  },
  notificationInfo: {
    backgroundColor: '#9ebfff',
  },
  notificationWarning: {
    backgroundColor: '#fff89e',
  },
  notificationError: {
    backgroundColor: '#ff9eb5',
  },
  notificationFatal: {
    backgroundColor: '#ff9eb5',
  },
  notificationText: {
  },
  crossContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 15,
    height: 15
  }
})

export default Notifications;
