import { connect } from 'react-redux';

import { addActiveIngredient, addInactiveIngredient, clearPivotIngredientSlot } from '../../actions';

import WizardIngredient from '../components/WizardIngredient';

const mapStateToProps1 = state => ({
  ingredient: state.wizard.pivot_ingredients[0],
  slot_idx: 0
});
const mapStateToProps2 = state => ({
  ingredient: state.wizard.pivot_ingredients[1],
  slot_idx: 1
});
const mapStateToProps3 = state => ({
  ingredient: state.wizard.pivot_ingredients[2],
  slot_idx: 2
});

const mapDispatchToProps = {
  addActiveIngredient,
  addInactiveIngredient,
  clearPivotIngredientSlot
}

export const WizardIngredient1 = connect(
  mapStateToProps1,
  mapDispatchToProps
)(WizardIngredient);
export const WizardIngredient2 = connect(
  mapStateToProps2,
  mapDispatchToProps
)(WizardIngredient)
export const WizardIngredient3 = connect(
  mapStateToProps3,
  mapDispatchToProps
)(WizardIngredient)
