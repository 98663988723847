import React, { useState } from 'react';
import { TextInput, View } from 'react-native';

import Button from '../../common/components/Button';
import ScrollableModal from '../../common/components/ScrollableModal';
import common_styles, { fullWidth } from '../../common/components/CommonStyles';
import { Body2, Small, useAppWidth } from '../../utils/';
import { useTheme } from '../../utils/Theme';

const IngredientSearchPhraseEditor = ({ visible, onRequestClose, postIngredientSearchPhrase }) => {
  const [name, setName] = useState('');
  const [keywords, setKeywords] = useState('');
  const [keywords_de, setKeywordsDe] = useState('');

  const theme = useTheme();

  const callPostIngredientSearchPhrase = () => {
    // Filter empty strings.
    const keywords_vec = keywords.split(',').map(keyword => keyword.toLowerCase()).filter(Boolean);
    const keywords_de_vec = keywords_de.split(',').map(keyword => keyword.toLowerCase()).filter(Boolean);
    let ingredient = {name, keywords: keywords_vec, keywords_de: keywords_de_vec};
    postIngredientSearchPhrase(ingredient);
  };

  const simple_text_fields = [
    {
      label: 'Ingredient Name (En)',
      variable: name,
      setVariable: setName
    },
    {
      label: 'Keywords (En)',
      variable: keywords,
      setVariable: setKeywords
    },
    {
      label: 'Keywords (De)',
      variable: keywords_de,
      setVariable: setKeywordsDe
    }
  ];

  const width = useAppWidth();

  return (
    <ScrollableModal
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <View
        style={[
          common_styles.modal,
          theme.general,
          {width, marginTop: '10%', marginBottom: '10%'}
        ]}
      >
        {
          simple_text_fields.map(({label, variable, setVariable}, index) => (
            <View key={index} style={{...fullWidth}}>
              <Small style={theme.general}>{label}</Small>
              <TextInput
                value={variable}
                onChange={event => setVariable(event.target.value)}
                style={[common_styles.searchBar, theme.textField]}
              />
            </View>
          ))
        }
        <View style={common_styles.buttonContainer}>
          <Button
            onPress={() => callPostIngredientSearchPhrase()}
            style={theme.button}
          >
            <Body2 style={theme.button}>
              submit
            </Body2>
          </Button>
          <Button
            onPress={() => onRequestClose()}
            style={theme.button}
          >
            <Body2 style={theme.button}>
              cancel
            </Body2>
          </Button>
        </View>
      </View>
    </ScrollableModal>
  );
}

export default IngredientSearchPhraseEditor
