import React, {
  useEffect,
  useState
} from 'react';
import {
  Image,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import {
  useIsFocused,
  useNavigation
} from '@react-navigation/native';

import common_styles, { padding } from '../../common/components/CommonStyles';
import Button from '../../common/components/Button';
import { Picker } from '../../common/components/Picker';
import { Title } from '../../header/components/Header';

import {
  APP_TABS,
  FEED_SCREENS,
  USER_SCREENS
} from '../../common/constants/';
import {
  MEDIA_PREFIX
} from '../../common/constants/urls';

import {
  Body2,
  ButtonText,
  HidingHeaderScrollView,
  relativeTime,
  XSmall
} from '../../utils/';
import { useTheme } from '../../utils/Theme';

import User from '../../assets/User';

export const Notification = ({
  user,
  date,
  follow,
  reaction,
  deleteFollow
}) => {
  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const theme = useTheme();

  let body = null;
  let action = null;
  if (follow && follow.follower) {
    let avatar_image = '';
    let avatar_image_placeholder_uri = '';

    if (follow.follower.userprofile.image && follow.follower.userprofile.image.image) {
      avatar_image = follow.follower.userprofile.image.image_50;
      const { image_placeholder } = follow.follower.userprofile.image;
      avatar_image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
    }

    return (
      <Pressable
        style={styles.notification}
        onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: follow.follower.username}})}
      >
        {
          avatar_image ? (
            <Image
              style={styles.avatar}
              defaultSource={avatar_image_placeholder_uri}
              source={{uri: `${MEDIA_PREFIX}${avatar_image}`}}
            />
          ) : (
          <View style={[styles.avatar, theme.secondary]}>
            <User color={theme.general.color}/>
          </View>
          )
        }
        <View style={styles.notificationBody}>
          <Body2 style={theme.general}>
            { t('notifications.follow', { username: follow.follower.username}) }
          </Body2>
          <XSmall style={theme.general}>
            { relativeTime(date) }
          </XSmall>
        </View>
      </Pressable>
    );
  } else if (reaction && reaction.user) {
    let avatar_image = '';
    let avatar_image_placeholder_uri = '';

    if (reaction.user.userprofile.image && reaction.user.userprofile.image.image) {
      avatar_image = reaction.user.userprofile.image.image_50;
      const { image_placeholder } = reaction.user.userprofile.image;
      avatar_image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
    }

    let image = '';
    let image_placeholder_uri = '';
    let image_width = 0;
    let image_height = 0;

    if (reaction.feed_entry.recipe_share.images.length !== 0) {
      image = reaction.feed_entry.recipe_share.images[0].image_thumbnail;
      const { image_placeholder } = reaction.feed_entry.recipe_share.images[0];
      image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
      image_width = reaction.feed_entry.recipe_share.images[0].width;
      image_height = reaction.feed_entry.recipe_share.images[0].height;
    }

    return (
      <Pressable
        style={styles.notification}
        onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: reaction.user.username}})}
      >
        {
          avatar_image ? (
            <Image
              style={styles.avatar}
              defaultSource={avatar_image_placeholder_uri}
              source={{uri: `${MEDIA_PREFIX}${avatar_image}`}}
            />
          ) : (
            <View style={[styles.avatar, theme.secondary]}>
              <User color={theme.general.color}/>
            </View>
          )
        }
        <View style={styles.notificationBody}>
          <Body2 style={theme.general}>
            { t('notifications.reaction', { username: reaction.user.username}) }
          </Body2>
          <XSmall style={theme.general}>
            { relativeTime(date) }
          </XSmall>
        </View>
        {
          image ? (
            <Image
              style={styles.makeImage}
              source={{uri: `${MEDIA_PREFIX}${image}`}}
            />
          ) : null
        }
      </Pressable>
    );
  } else {
    return null;
  }
}

const Notifications = ({
  most_recent_notification_date,
  notifications,
  can_load_more,
  loadNotifications,
  setMostRecentNotificationDate
}) => {
  const theme = useTheme();

  const navigation = useNavigation();
  const is_focused = useIsFocused();

  const { t } = useTranslation(['common']);

  const onRefresh = (final_callback) => {
    loadNotifications(
      /*offset*/0,
      final_callback
    );
  }

  useEffect(() => {
    if (is_focused && !notifications.length) {
      onRefresh();
    }
  }, [is_focused]);

  useEffect(() => {
    if (most_recent_notification_date) {
      setMostRecentNotificationDate(most_recent_notification_date);
    }
  }, [most_recent_notification_date]);


  return (
    <HidingHeaderScrollView
      contentContainerStyle={styles.notifications}
      onRefresh={onRefresh}
      header={(
        <Title
          goBack={() => navigation.navigate(APP_TABS.feed.name, {screen: FEED_SCREENS.home.name})}
          title_string={t('notifications.title')}
        />
      )}
      body={(
        <>
          {
            notifications.map((notification, index) => (
              <Notification
                key={index}
                {...notification}
              />
            ))
          }
          {
            can_load_more ? (
              <View style={common_styles.buttonContainer}>
                <Button
                  style={theme.button}
                  onPress={() => loadNotifications(notifications.length)}
                >
                  <ButtonText style={theme.button}>{t('feed.loadMore')}</ButtonText>
                </Button>
              </View>
            ) : null
          }
        </>
      )}
    />
  );
}

const styles = StyleSheet.create({
  notifications: {
    padding
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0.5 * padding
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20
  },
  notificationBody: {
    padding: 0.5 * padding,
    flex: 1
  },
  makeImage: {
    width: 40,
    height: 40
  },
  followButton: {
    flex: 1
  }
})

export default Notifications;
