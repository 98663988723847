import React, {
  useEffect,
  useState
} from 'react';
import {
  Image,
  Linking,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import {
  useNavigation,
  useRoute
} from '@react-navigation/native';

import { TitleWBurger } from '../../header/components/Header';
import Button from '../../common/components/Button';
import ProfileFeed from '../containers/ProfileFeedContainer';
import common_styles, { padding } from '../../common/components/CommonStyles';

import { useTheme } from '../../utils/Theme';
import {
  Body1,
  Body2,
  ButtonText,
  FollowInviteButton,
  H1,
  H3,
  HidingHeaderScrollView,
  Small,
  VSkipMedium,
  XSmall,
} from '../../utils/';

import {
  AuthState,
  APP_TABS,
  PROFILE_SCREENS,
  USER_SCREENS
} from '../../common/constants/';
import {
  MEDIA_PREFIX
} from '../../common/constants/urls';

import User from '../../assets/User';

const MODE = ({"FEED": 0, "INSIGHTS": 1});

const Profile = ({
  auth_state,
  profile,
  own_profile,
  addFollow,
  deleteFollow,
  loadOtherProfile
}) => {
  const [ follow_button_enabled, setFollowButtonEnabled ] = useState(true);
  const [ is_followee_state, setIsFollowee ] = useState(profile.is_followee);
  const [ mode, setMode ] = useState(MODE.FEED);

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const theme = useTheme();

  const route = useRoute();

  const navigate_to_own_profile = () => {
    navigation.navigate(APP_TABS.profile.name, {screen: USER_SCREENS.account.name, params: {username: own_profile.username}})
  };

  useEffect(() => {
    if (route.params && route.params.username) {
      loadOtherProfile(
        route.params.username,
        /*success_callback*/() => {},
        /*failure_callback*/navigate_to_own_profile,
        /*final_callback*/() => {}/* setRefreshing(false)*/);
    } else if (auth_state === AuthState.LOGGED_IN) {
      navigate_to_own_profile();
    }
  }, [route.params]);

  useEffect(() => {
    if (profile.is_followee !== is_followee_state) {
      setIsFollowee(profile.is_followee);
    }
  }, [profile]);

  const onRefresh = (final_callback) => {
    if (route.params && route.params.username) {
      loadOtherProfile(
        route.params.username,
        /*success_callback*/() => {},
        /*failure_callback*/navigate_to_own_profile,
        final_callback
      )
    } else if (auth_state === AuthState.LOGGED_IN) {
      navigate_to_own_profile();
    }
  };

  const own_profile_options = (
    <View style={[common_styles.buttonContainer, {paddingHorizontal: padding}]}>
      <Button style={theme.secondary} onPress={() => navigation.navigate(APP_TABS.profile.name, {screen: PROFILE_SCREENS.search.name})}>
        <ButtonText style={theme.secondary}>
          { t('profile.findFriends') }
        </ButtonText>
      </Button>
      <FollowInviteButton
        style={theme.secondary}
        username={own_profile.username}
      />
    </View>
  );

  const follow_button = (
    <Button
      style={theme.button}
      onPress={() => {
        setFollowButtonEnabled(false);
        addFollow(
          profile.userprofile.uuid,
          /*success_callback*/() => setIsFollowee(true),
          /*failure_callback*/() => {},
          /*final_callback*/() => setFollowButtonEnabled(true)
        );
      }}
      disabled={!follow_button_enabled}
    >
      <ButtonText style={theme.button}>
        { t('profile.follow') }
      </ButtonText>
    </Button>
  );
  const unfollow_button = (
    <Button
      style={theme.button}
      onPress={() => {
        setFollowButtonEnabled(false);
        deleteFollow(
          profile.userprofile.uuid,
          /*success_callback*/() => setIsFollowee(false),
          /*failure_callback*/() => {},
          /*final_callback*/() => setFollowButtonEnabled(true)
        );
      }}
      disabled={!follow_button_enabled}
    >
      <ButtonText style={theme.button}>
        { t('profile.following') }
      </ButtonText>
    </Button>
  );

  const other_profile_options = (
    <View style={[common_styles.buttonContainer, {padding}]}>
      {
        is_followee_state ? unfollow_button : follow_button
      }
    </View>
  )

  const is_own_profile = (profile.userprofile.uuid === own_profile.userprofile.uuid);
  const title_string = is_own_profile ? t('profile.ownProfileTitle') : profile.username;


  let image = '';
  let image_placeholder_uri = '';

  if (profile.userprofile.image && profile.userprofile.image.image) {
    image = profile.userprofile.image.image;
    const { image_placeholder } = profile.userprofile.image;
    image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};
  }

  return (
    <HidingHeaderScrollView
      keyboardShouldPersistTaps='handled'
      onRefresh={onRefresh}
      header={(
        <TitleWBurger
          title_string={title_string}
          goBack={() => navigation.goBack()}
          onPressBurger={() => navigation.navigate(
            APP_TABS.profile.name,
            { screen: PROFILE_SCREENS.settings.name}
          )}
        />
      )}
      body={(
        <>
        <View style={styles.header}>
          <View style={{padding}}>
            {
              image ? (
                <Image
                  style={styles.avatar}
                  defaultSource={image_placeholder_uri}
                  source={{uri: `${MEDIA_PREFIX}${image}`}}
                />
              ) : (
                <View style={[styles.avatar, theme.secondary]}>
                  <User color={theme.general.color}/>
                </View>
              )
            }
          </View>
          <View style={styles.headerRight}>
            <View style={styles.headerName}>
              {
                profile.userprofile.full_name ? (
                  <>
                    <H3 style={theme.general}>{profile.userprofile.full_name}</H3>
                    <XSmall style={theme.general}>{`@${profile.username}`}</XSmall>
                  </>
                ) : (
                  <H3 style={theme.general}>{`@${profile.username}`}</H3>
                )
              }
            </View>
            <View style={styles.headerStats}>
              <View style={styles.profileStat}>
                <H1 style={theme.general}>
                  { profile.userprofile.num_followees }
                </H1>
                <Small style={theme.general}>
                  { t('profile.numFollowees') }
                </Small>
              </View>
              <View style={styles.profileStat}>
                <H1 style={theme.general}>
                  { profile.userprofile.num_followers }
                </H1>
                <Small style={theme.general}>
                  { t('profile.numFollowers') }
                </Small>
              </View>
              <View style={styles.profileStat}>
                <H1 style={theme.general}>
                  { profile.userprofile.num_shares }
                </H1>
                <Small style={theme.general}>
                  { t('profile.numPosts') }
                </Small>
              </View>
            </View>
          </View>
        </View>
        {
          (profile.userprofile.bio && profile.userprofile.url) ? (
            <View style={{padding}}>
              {
                profile.userprofile.bio ? (
                  <Body1 style={theme.general}>
                    { profile.userprofile.bio }
                  </Body1>
                ) : null
              }
              {
                profile.userprofile.url ? (
                  <Body2 style={theme.general} onPress={() => Linking.openURL(profile.userprofile.url)}>
                    { profile.userprofile.url }
                  </Body2>
                ) : null
              }
            </View>
          ) : null
        }
        {
          is_own_profile ? own_profile_options : other_profile_options
        }
        <VSkipMedium/>
        <View style={styles.modeSwitcher}>
          <Pressable
            style={[styles.modeButton, {borderColor: theme.general.color, opacity: (mode === MODE.FEED) ? 1.0 : 0.3}]}
            onPress={() => setMode(MODE.FEED)}
          >
            <H3 style={theme.general}>{t('profile.makes')}</H3>
          </Pressable>
          <Pressable
            style={[styles.modeButton, {borderColor: theme.general.color, opacity: (mode === MODE.INSIGHTS) ? 1.0 : 0.3}]}
            onPress={() => setMode(MODE.FEED/* TODO MODE.INSIGHTS */)}
          >
            <H3 style={theme.general}>{t('profile.insights')}</H3>
          </Pressable>
        </View>
        <>
          {/* TODO handle insights */}
          <ProfileFeed/>
        </>
        </>
      )}
    />
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerRight: {
    flex: 1
  },
  headerName: {
    padding
  },
  headerStats: {
    padding,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  profileStat: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: 110,
    height: 110,
    borderRadius: 24
  },
  modeSwitcher: {
    flexDirection: 'row'
  },
  modeButton: {
    paddingVertical: padding,
    flex: 1,
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default Profile;

