import React, { useRef, Suspense } from 'react';
import {
  StyleSheet
} from 'react-native';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import { SafeAreaProvider } from 'react-native-safe-area-context';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { init } from '@amplitude/analytics-react-native';

import App from '../containers/AppContainer';
import { BackgroundPrimary } from '../components/App';

import { initialize } from '../../actions/';
import rootReducer from '../../reducers/';
import rootSaga from '../../common/sagas/';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
store.dispatch(initialize());

init('2e6e17401f4358e029ef91258071003');

const AppRoot = () => {
  const { t } = useTranslation(['common']);

  return (
    <HelmetProvider>
      <Provider store={store}>
        <Suspense fallback={<BackgroundPrimary/>}>
          <SafeAreaProvider style={styles.container}>
            <Helmet>
              <title>{ t('common:app.metaTags.default.title') }</title>
              <meta property="og:title" content={ t('common:app.metaTags.default.title') } />
              <meta property="description" content={ t('common:app.metaTags.default.description') } />
              <meta property="og:description" content={ t('common:app.metaTags.default.description') } />
              <meta property="keywords" content={ t('common:app.metaTags.default.keywords') } />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content={i18n.language} />
            </Helmet>
            <App />
          </SafeAreaProvider>
        </Suspense>
      </Provider>
    </HelmetProvider>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%'
  }
});

export default AppRoot;
