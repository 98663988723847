import {
  Platform,
  StyleSheet
} from 'react-native';

import {
  COLORS,
  BIG_BORDER_RADIUS,
  BORDER_RADIUS,
  MARGIN,
  PADDING
} from '../../common/constants/';

export const fonts = {
  swearDisplay: {
    fontFamily: 'SwearDisplay-BoldCilati'
  },
  swearText: {
    fontFamily: 'SwearText-Medium'
  },
  interMedium: {
    fontFamily: 'Inter',
    fontWeight: '500'
  },
  interSemiBold: {
    fontFamily: 'Inter',
    fontWeight: '600'
  },
  interBold: {
    fontFamily: 'Inter',
    fontWeight: '700'
  },
  handWritten: {
    fontFamily: (Platform.OS === 'android') ? 'Pangolin-Regular' : 'Pangolin'
  }
};
export const text_styles_raw = {
  title: {
    ...fonts.swearDisplay,
    fontSize: 30,
    lineHeight: 1.1 * 30
  },
  h1: {
    ...fonts.swearText,
    fontSize: 24,
    lineHeight: 1.3 * 24
  },
  h2Sans: {
    ...fonts.interSemiBold,
    fontSize: 18,
    lineHeight: 1.3 * 18
  },
  h2Serif: {
    ...fonts.swearText,
    fontSize: 20,
    lineHeight: 1.3 * 20
  },
  h3: {
    ...fonts.interSemiBold,
    fontSize: 14,
    lineHeight: 1.3 * 14
  },
  body1: {
    ...fonts.swearText,
    fontSize: 16,
    lineHeight: 1.3 * 16
  },
  body2: {
    ...fonts.interMedium,
    fontSize: 14,
    lineHeight: 1.3 * 14
  },
  smallUppercase: {
    ...fonts.interBold,
    fontSize: 12,
    lineHeight: 1.3 * 12,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    opacity: 0.5
  },
  small: {
    ...fonts.interMedium,
    fontSize: 12,
    lineHeight: 1.3 * 12,
    letterSpacing: 0.5
  },
  xSmall: {
    ...fonts.interRegular,
    fontSize: 11,
    letterSpacing: 0.5,
    opacity: 0.5
  },
  button: {
    ...fonts.swearText,
    fontSize: 20,
    lineHeight: 1.3 * 20
  },
  handWritten: {
    ...fonts.handWritten,
    fontSize: 20,
    lineHeight: 1.3 * 20
  },
  noBackgroundColor: {
    backgroundColor: null,
    borderWidth: 0
  }
};
export const text_styles = StyleSheet.create(text_styles_raw);

export const borderRadius = BORDER_RADIUS;
export const bigBorderRadius = BIG_BORDER_RADIUS;
export const padding = PADDING;
export const margin = MARGIN;
export const roundedBorder = {
  borderRadius,
  padding
};
export const fullWidth = {
  width: '100%'
};
export const textField = {
  ...text_styles_raw.body2,
  borderRadius,
  padding: 1.5 * padding
};
export const longTextField = {
  ...textField,
  height: 100
};
export const castShadow = {
  shadowOffset: {
    width: 0, height: 2
  },
  shadowOpacity: 0.3,
  shadowRadius: 5
};
export const noShadow = {
  shadowOffset: {
    width: 0, height: 0
  },
  shadowOpacity: 0,
  shadowRadius: 0
};

export const common_styles_raw = {
  centeredText: {
    textAlign: 'center'
  },
  modalContainer: {
    ...fullWidth,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBackground: {
    ...StyleSheet.absoluteFill,
    position: (Platform.OS === 'web') ? 'fixed' : 'absolute',
    ...fullWidth,
    height: '100%',
    backgroundColor: COLORS.PRIMARY_LIGHT,
    opacity: 0.5
  },
  modal: {
    //position: (Platform.OS === 'web') ? 'fixed' : null,
    borderRadius: BIG_BORDER_RADIUS,
    padding,
    overflow: 'hidden',
    opacity: 1
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  button: {
    padding,
    borderRadius,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin
  },
  smallButton: {
    padding: 0.5 * padding,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  roundButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
  squareButton: {
    width: 30,
    height: 30,
    borderRadius: 12,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center'
  },
  roundButtonIcon: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center'
  },
  inlineIcon: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    ...roundedBorder
  },
  searchBar: {
    ...textField,
    ...castShadow,
    flexDirection: 'row',
    alignItems: 'center'
  },
  smallIng: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0.5 * padding,
    borderRadius,
    margin: 1
  },
  borderBottom: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: COLORS.LIGHT_GRAY
  }
};
const styles = StyleSheet.create(common_styles_raw);

export default styles;
