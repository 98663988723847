import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useAppWidth } from '../../utils/';
import { margin } from './CommonStyles';

const Masonry = ({
  children,
  min_width,
  min_num_cols,
  ...rest
}) => {
  const width = useAppWidth();

  const [numCols, setNumCols] = useState(
    min_num_cols ?
      Math.max(min_num_cols, Math.floor(width / min_width)) :
      Math.floor(width / min_width)
  );

  useEffect(() => {
    setNumCols(
      min_num_cols ?
        Math.max(min_num_cols, Math.floor(width / min_width)) :
        Math.floor(width / min_width)
      );
  }, [width]);

  const Cols = [];
  for (let col_idx = 0; col_idx < numCols; ++col_idx) {
    Cols.push(
      <View
        style={[
          styles.masonryCol,
          {
            marginLeft: (col_idx === 0) ? 0 : 2 * margin,
            marginRight: (col_idx === numCols - 1) ? 0 : 2 * margin
          }
        ]}
        key={col_idx}
      >
        { children.filter((_, child_idx) => child_idx % numCols === col_idx) }
      </View>
    );
  }

  return (
    <View style={styles.masonry} {...rest}>
      { Cols }
    </View>
  );
}

const styles = StyleSheet.create({
  masonry: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  masonryCol: {
    flex: 1
  }
});

export default Masonry
