import { StyleSheet } from 'react-native';

import { padding, margin } from '../../common/components/CommonStyles';

const styles = StyleSheet.create({
  auth: {
    padding
  },
  switchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin
  },
  label: {
    marginBottom: 0.5 * padding
  }
});

export default styles;
