import React from 'react';
import {
  StyleSheet
} from 'react-native';

import Svg, {
  Defs,
  Mask,
  Image,
  Rect
} from 'react-native-svg';

import CurvyMask from '../assets/CurvyMask';

const MaskView = ({
  mask,
  image_src,
  ...props
}) => (
  <Svg
    reserveAspectRatio="xMidYMid meet"
    viewBox="0 0 400 400"
    height="400"
    width="400"
    version="1.1"
  >
    <Defs>
      <Mask
        id="Mask"
        //maskUnits="userSpaceOnUse"
        height="400"
        width="400"
      >
        { mask }
      </Mask>
    </Defs>
    <Image
      preserveAspectRatio="xMidYMid slice"
      height="400"
      width="400"
      href={image_src}
      mask="url(#Mask)"
    />
  </Svg>
);

export default MaskView;
