import AsyncStorage from '@react-native-async-storage/async-storage';

import * as types from '../actions/types';

import {
  ApiState,
  AppState,
  AuthState,
  ConnectionState,
  NotificationSeverity,
  REFRESH_TOKEN_ID,
} from '../common/constants/';

const initial_state = {
  api_state: {
    state: ApiState.OK,
    message: '',
    dismissable: true
  },
  app_state: AppState.INITIALIZING,
  auth_state: AuthState.NOT_LOGGED_IN,
  connection_state: {
    state: ConnectionState.OK,
    message: '',
  },
  auth: {
    access: undefined,
    refresh: undefined
  },
  heart_beat: {
    recipe_share_date: undefined,
    notification_date: undefined,
    households: {}
  },
  update_flags: {
    feed: false,
    notifications: false,
    households: {}
  },
  profile: {
    username: undefined,
    email: undefined,
    userprofile: {
      uuid: undefined,
      subscribed: undefined,
      language: undefined,
      diet: 'vegetarian',
      recipe_languages: ['en'],
      num_followees: 0,
      num_followers: 0,
      meal_plan_reminder_enabled: false,
      post_make_reminder_enabled: false
    },
    onboarding_answers: undefined,
    is_superuser: false,
    households: []
  },
  other_profile: {
    username: '',
    userprofile: {
      uuid: undefined
    }
  },
  account_search_results: {
    accounts: [],
    can_load_more: false
  },
  universal_search_results: {
    ingredients: [],
    accounts: [],
    recipes : [],
    suggestions: []
  },
  active_household_uuid: undefined,
  feed: {
    entries: [],
    hidden_entries: [],
    can_load_more: false
  },
  notifications: {
    notifications: [],
    can_load_more: false
  },
  ingredients: {
    suggested: [],
    search: {
      current_search_phrase: '',
      results: {
        search_phrase: '',
        ingredients: []
      }
    },
    always_active: [],
    active_ingredients: [],
    inactive_ingredients: [],
  },
  household: {
    meal_plan: {
      entries: [],
      can_load_more: false,
      total_count: 0
    },
    recipes: {
      recipes: [],
      can_load_more: false,
      total_count: 0
    }
  },
  wizard: {
    pivot_ingredients: [
      undefined, undefined, undefined
    ],
    query: {
    //  diet: 'vegetarian',
      required_ingredients: [],
    //  active_ingredients: [],
    //  inactive_ingredients: []
    },
    result: {
      query: {
        diet: 'vegetarian',
        required_ingredients: [],
        active_ingredients: [],
        inactive_ingredients: [],
        offset: 0
      },
      ingredients: {
        pivot_ingredients: [],
        can_load_more: false
      },
      recipes: {
        recipes: [],
        can_load_more: false
      }
    }
  },
  recipes: {
    search: {
      results: {
      }
    },
    recipe_detail: {
      recipe: undefined,
      query: {
        active_ingredients: [],
        inactive_ingredients: []
      }
    }
  },
  'in_app_notifications': {
    notifications: [],
    notification_verbosity: NotificationSeverity.INFO
  },
  admin: {
    labelling_ingredient: undefined
  }
};

const rootReducer = (state = initial_state, action) => {
  switch (action.type) {
    case types.SET_TOKEN:
      return {...state, auth: {...state.auth, refresh: action.token}};
    case types.SET_API_STATE:
      return {...state, api_state: {state: action.state, message: action.message, dismissable: action.dismissable}};
    case types.SET_APP_STATE:
      return {...state, app_state: action.state};
    case types.SET_AUTH_STATE:
      return {...state, auth_state: action.state};
    case types.SET_CONNECTION_STATE:
      return {...state, connection_state: {state: action.state, message: action.message}};
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        auth: {
          access: action.payload.access,
          refresh: action.payload.refresh
        }
      };
    case types.CLEAR_AUTH:
      return {
        ...state,
        auth: initial_state.auth
      };
    case types.TOKEN_SUCCESS:
      return {...state, auth: {
        ...state.auth,
        access: action.payload.access
      }};
    case types.LOG_OUT:
      return {
        ...state,
        auth_state: AuthState.NOT_LOGGED_IN,
        auth: initial_state.auth,
        profile: initial_state.profile,
        active_household_uuid: initial_state.active_household_uuid
      };
    case types.SET_DIET:
      return {...state,
        profile: {
          ...state.profile,
          userprofile: {
            ...state.profile.userprofile,
            diet: action.diet
          }
        }
      };
    case types.ADD_REQUIRED_INGREDIENT:
      if (!action.ingredient.id) {  // Make sure only valid ingredients can be added.
        return state;
      }
      return {...state,
        wizard: {
          ...state.wizard,
          query: {
            ...state.wizard.query,
            required_ingredients: [...new Set([...state.wizard.query.required_ingredients, action.ingredient])],
            //active_ingredients: state.wizard.query.active_ingredients.filter(ing => ing.id !== action.ingredient.id),
            //inactive_ingredients: state.wizard.query.inactive_ingredients.filter(ing => ing.id !== action.ingredient.id)
          }
        }
      };
    case types.DELETE_REQUIRED_INGREDIENT:
      return {...state,
        wizard: {
          ...state.wizard,
          query: {
            ...state.wizard.query,
            required_ingredients: state.wizard.query.required_ingredients.filter(ing => ing.id !== action.ingredient.id)
          }
        }
      };
    case types.ADD_ACTIVE_INGREDIENT:
      if (!action.ingredient || !action.ingredient.id) {  // Make sure only valid ingredients can be added.
        return state;
      }
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          active_ingredients: [...new Set([...state.ingredients.active_ingredients, action.ingredient])],
          inactive_ingredients: state.ingredients.inactive_ingredients.filter(ing => ing.id !== action.ingredient.id)
        }
      };
    case types.ADD_INACTIVE_INGREDIENT:
      if (!action.ingredient || !action.ingredient.id) {  // Make sure only valid ingredients can be added.
        return state;
      }
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          active_ingredients: state.ingredients.active_ingredients.filter(ing => ing.id !== action.ingredient.id),
          inactive_ingredients: [...new Set([...state.ingredients.inactive_ingredients, action.ingredient])]
        },
        wizard: {
          ...state.wizard,
          query: {
            ...state.wizard.query,
            required_ingredients: state.wizard.query.required_ingredients.filter(ing => ing.id !== action.ingredient.id)
          }
        }
      };
    case types.SET_SUGGESTED_INGREDIENTS: {
      return {...state, ingredients: {...state.ingredients, suggested: action.suggested_ingredients}};
    }
    case types.SET_ALWAYS_ACTIVE_INGREDIENTS:
      return {...state, ingredients: {...state.ingredients, always_active: action.active_ingredients}};
    case types.SET_ACTIVE_INGREDIENTS:
      return {...state, ingredients: {...state.ingredients, active_ingredients: action.active_ingredients}};
    case types.SET_HOUSEHOLD_RECIPES:
      return {
        ...state,
        household: {
          ...state.household,
          recipes: {
            ...state.household.recipes,
            recipes: [
              ...state.household.recipes.recipes.slice(0, action.offset),
              ...action.recipes
            ],
            can_load_more: action.can_load_more,
            total_count: action.total_count
          }
        }
      };
    case types.SET_HOUSEHOLD_MEAL_PLAN:
      return {
        ...state,
        household: {
          ...state.household,
          meal_plan: {
            ...state.meal_plan,
            entries: [
              ...state.household.meal_plan.entries.slice(0, action.offset),
              ...action.entries
            ],
            can_load_more: action.can_load_more,
            total_count: action.total_count
          }}};
    case types.SET_PROFILE: {
      return {...state, profile: action.profile};
    }
    case types.SET_OTHER_PROFILE: {
      return {...state, other_profile: action.profile};
    }
    case types.SET_ACCOUNT_SEARCH_RESULTS: {
      return {
        ...state,
        account_search_results: {
          accounts: [
            ...state.account_search_results.accounts.slice(0, action.offset),
            ...action.accounts
          ],
          can_load_more: action.can_load_more
        }
      };
    }
    case types.SET_UNIVERSAL_SEARCH_RESULTS: {
      return {
        ...state,
        universal_search_results: {
          ingredients: action.ingredients,
          accounts: action.accounts,
          recipes: action.recipes,
          suggestions: action.suggestions
        }
      };
    }
    case types.SET_ACTIVE_HOUSEHOLD:
      return {...state, active_household_uuid: action.active_household_uuid};
    case types.SET_HIDDEN_ENTRIES:
      return {
        ...state,
        feed: {
          ...state.feed,
          hidden_entries: action.recipe_share_uuids
        }
      };
    case types.SET_HEART_BEAT:
      return {
        ...state,
        heart_beat: action.heart_beat
      };
    case types.SET_MOST_RECENT_RECIPE_SHARE_DATE:
      return {
        ...state,
        heart_beat: {
          ...state.heart_beat,
          recipe_share_date: action.date
        }
      };
    case types.SET_MOST_RECENT_NOTIFICATION_DATE:
      return {
        ...state,
        heart_beat: {
          ...state.heart_beat,
          notification_date: action.date
        }
      };
    case types.SET_MOST_RECENT_INGREDIENT_DATE: {
      const households = state.heart_beat.households;
      households[action.active_household_uuid] = {
        ...households[action.active_household_uuid],
        ingredient_date: action.date
      };
      return {
        ...state,
        heart_beat: {
          ...state.heart_beat,
          households
        }
      };
    }
    case types.SET_MOST_RECENT_MEAL_PLAN_ENTRY_DATE: {
      const households = state.heart_beat.households;
      households[action.active_household_uuid] = {
        ...households[action.active_household_uuid],
        meal_plan_date: action.date
      };
      return {
        ...state,
        heart_beat: {
          ...state.heart_beat,
          households
        }
      };
    }
    case types.SET_UPDATE_FLAGS:
      return {
        ...state,
        update_flags: action.update_flags
      };
    case types.SET_INGREDIENTS_SEARCH_PHRASE:
      return {...state, ingredients: {...state.ingredients, search: {...state.ingredients.search, current_search_phrase: action.search_phrase}}};
    case types.SET_INGREDIENT_SEARCH_RESULTS:
      return {...state, ingredients: {...state.ingredients, search: {...state.ingredients.search, results: {search_phrase: action.search_phrase, ingredients: action.ingredients}}}};
    case types.SET_RECIPE_WIZARD_INTERMEDIATE_RESULT: {
      // Get the current pivot ingredients (slots may be empty).
      const pivot_ingredients = state.wizard.pivot_ingredients;
      // Get only the occupied ingredient slots, keep ids.
      const pivot_ingredient_ids = pivot_ingredients.filter(ingredient => ingredient).map(ingredient => ingredient.id);
      const active_ids = state.ingredients.active_ingredients.map(ing => ing.id);
      const inactive_ids = state.ingredients.inactive_ingredients.map(ing => ing.id);
      // New potential pivot ingredients.
      const pivot_ingredient_candidates = action.result.ingredients.pivot_ingredients;
      const free_slots = [];
      pivot_ingredients.forEach((ingredient, slot_index) => {
        if (!ingredient) {
          free_slots.push(slot_index)
        }
      });
      free_slots.forEach((slot_idx, index) => {
        for (let candidate_idx = 0; candidate_idx < pivot_ingredient_candidates.length; ++candidate_idx) {
          const candidate_id = pivot_ingredient_candidates[candidate_idx].id;
          if (pivot_ingredient_ids.includes(candidate_id) ||
            active_ids.includes(candidate_id) ||
            inactive_ids.includes(candidate_id)) {
            continue;
          } else {
            // Fill the slot
            pivot_ingredients[slot_idx] = pivot_ingredient_candidates[candidate_idx];
            pivot_ingredient_ids.push(candidate_id);
            // Pop candidate
            pivot_ingredient_candidates.splice(candidate_idx, 1);
            return;
          }
        }
      });
      return {
        ...state,
        wizard: {
          ...state.wizard,
          pivot_ingredients,
          result: {
            ...state.wizard.result,
            recipes: {
              ...state.wizard.result.recipes,
              recipes: [
                ...state.wizard.result.recipes.recipes.slice(0, action.offset),
                ...action.result.recipes.recipes
              ],
              can_load_more: action.result.recipes.can_load_more
            },
            ingredients: {
              ...state.wizard.result.ingredients,
              pivot_ingredients: pivot_ingredient_candidates,
              can_load_more: action.result.ingredients.can_load_more
            },
            query: action.result.query
          }
        }
      };
    }
    case types.CLEAR_PIVOT_INGREDIENT_SLOT: {
      const pivot_ingredients = state.wizard.pivot_ingredients;
      const pivot_ingredient_ids = pivot_ingredients.filter(ingredient => ingredient).map(ingredient => ingredient.id);
      const active_ids = state.ingredients.active_ingredients.map(ing => ing.id);
      const inactive_ids = state.ingredients.inactive_ingredients.map(ing => ing.id);
      const pivot_ingredient_candidates = state.wizard.result.ingredients.pivot_ingredients;
      pivot_ingredients[action.slot_idx] = undefined;
      for (let candidate_idx = 0; candidate_idx < pivot_ingredient_candidates.length; ++candidate_idx) {
        const candidate_id = pivot_ingredient_candidates[candidate_idx].id;
        if (pivot_ingredient_ids.includes(candidate_id) ||
            active_ids.includes(candidate_id) ||
            inactive_ids.includes(candidate_id)) {
          continue;
        } else {
          // Fill the slot
          pivot_ingredients[action.slot_idx] = pivot_ingredient_candidates[candidate_idx];
          pivot_ingredient_ids.push(candidate_id);
          // Pop candidate
          pivot_ingredient_candidates.splice(candidate_idx, 1);
          break;
        }
      }
      return {
        ...state,
        wizard: {
          ...state.wizard,
          pivot_ingredients,
          result: {
            ...state.wizard.result,
            ingredients: {
              ...state.wizard.result.ingredients,
              pivot_ingredients: pivot_ingredient_candidates
            }
          }
        }
      };
    }
    case types.SET_RECIPES:
      return {
        ...state,
        recipes: {
          ...state.recipes,
          search: {
            results: {
              query: action.query,
              recipes: action.recipes
            }
          }
        }
      };
    case types.SET_RECIPE:
      return {
        ...state,
        recipes: {
          ...state.recipes,
          recipe_detail: {
            recipe: action.recipe,
            query: action.query
          }
        }
      };
    case types.CLOSE_RECIPE:
      return {
        ...state,
        recipes: {
          ...state.recipes,
          recipe_detail: {
            recipe: undefined,
            query: {
              active_ingredients: [],
              inactive_ingredients: []
            }
          }
        }
      };
    case types.SET_FEED:
      return {
        ...state,
        feed: {
          ...state.feed,
          entries: [
            ...state.feed.entries.slice(0, action.offset),
            ...action.entries
          ],
          can_load_more: action.can_load_more
        }
      }
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notifications: [
            ...state.notifications.notifications.slice(0, action.offset),
            ...action.notifications
          ],
          can_load_more: action.can_load_more
        }
      }
    case types.QUEUE_NOTIFICATION: {
      const { id, severity, message } = action;
      const notification = { id, severity, message };
      return {
        ...state,
        in_app_notifications: {
          ...state.in_app_notifications,
          notifications: [
            ...state.in_app_notifications.notifications,
            notification
          ]
        }
      }
    }
    case types.CLEAR_NOTIFICATION: {
      const { id } = action;
      return {
        ...state,
        in_app_notifications: {
          ...state.in_app_notifications,
          notifications: state.in_app_notifications.notifications.filter(notification => (notification.id !== action.id))
        }
      }
    }
    case types.SET_LABELLING_INGREDIENT:
      return {...state, admin: {...state.admin, labelling_ingredient: action.ingredient}};
    default:
      return state
  }
}

export default rootReducer
