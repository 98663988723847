import React from 'react';

import Svg, { G, Path } from 'react-native-svg';

const Maintenance = ({color = 'white', stroke_width=5}) => (
    <Svg width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{fill: 'none', strokeLinecap: 'round'}}>
        <G>
            <G>
                <G transform="matrix(-0.999993,0.00369513,-0.00369513,-0.999993,197.893,195.289)">
                    <Path d="M94.202,84.952L128.024,51.171C128.024,51.171 118.861,33.978 130.651,22.959C142.183,12.181 156.128,19.425 155.866,19.229C155.604,19.032 142.644,32.096 142.644,32.096L148.612,48.336L164.507,53.641L177.484,41.25C177.484,41.25 182.958,57.321 174.974,65.572C163.105,77.838 144.942,69.287 144.942,69.287L111.809,102.352" style={{stroke: color, strokeWidth: stroke_width}}/>
                </G>
                <G transform="matrix(1,0,0,1,-0.310028,0.107074)">
                    <Path d="M94.072,85.077L128.024,51.171C128.024,51.171 118.861,33.978 130.651,22.959C142.183,12.181 156.128,19.425 155.866,19.229C155.604,19.032 142.644,32.096 142.644,32.096L148.612,48.336L164.507,53.641L177.484,41.25C177.484,41.25 182.958,57.321 174.974,65.572C163.105,77.838 144.942,69.287 144.942,69.287L111.809,102.352" style={{stroke:color, strokeWidth: stroke_width}}/>
                </G>
            </G>
            <G>
                <G transform="matrix(0.906718,0,0,0.906718,16.3881,16.284)">
                    <Path d="M133.264,125.15L170.328,161.602" style={{stroke: color, strokeWidth: stroke_width}}/>
                    <Path d="M134.833,110.382L171.827,142.763C171.827,142.763 176.087,147.388 175.652,151.192C175.216,154.995 172.785,160.198 167.384,165.218C161.982,170.239 158.453,174.696 152.359,174.565C146.266,174.433 140.914,168.025 140.914,168.025L111.087,133.966L134.833,110.382Z" style={{stroke: color, strokeWidth: stroke_width}}/>
                    <Path d="M125.867,132.614L162.963,169.264" style={{stroke: color, strokeWidth: stroke_width}}/>
                    <Path d="M92.214,121.88L122.463,91.765L135.933,104.101C135.933,104.101 137.534,105.684 137.273,106.822C137.013,107.96 134.974,110.093 134.974,110.093L110.627,134.386C110.627,134.386 108.521,136.401 106.783,136.297C105.045,136.193 103.49,134.549 103.49,134.549L92.214,121.88Z" style={{stroke: color, strokeWidth: stroke_width}}/>
                </G>
                <Path d="M109.917,116.985L43.105,50.279L40.206,53.074L25.568,33.802L34.049,25.241L53.665,39.708L50.866,42.606L117.608,109.352L109.917,116.985Z" style={{stroke: color, strokeWidth: stroke_width}}/>
            </G>
        </G>
    </Svg>
);

export default Maintenance;
