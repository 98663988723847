// Adapted from https://react-native-vision-camera.com/docs/guides/mocking#create-proxy-for-original-and-mocked-modules

import React from 'react';
import { View, ImageBackground } from 'react-native';

import Breakfast from '../assets/breakfast.webp';

console.log('[DETOX] Using mocked react-native-vision-camera');

export const useCameraDevice = () => ({
  position: 'back',
});

export const useCameraPermission = () => ({
  hasPermission: true,
  requestPermission: async () => true
});

export class Camera extends React.PureComponent {
  static getAvailableCameraDevices() {
    return (
      [
        {
          position: 'back',
        },
      ]
    )
  };

  static async getCameraPermissionStatus() {
    return 'granted'
  };

  static async requestCameraPermission() {
    return 'granted'
  };

  async takePhoto() {
    const writePath = 'file:///Users/otim/Library/Developer/CoreSimulator/Devices/48224FF5-5EF1-4C09-8B5F-AC2DAB6E48D4/data/Media/DCIM/100APPLE/IMG_0006.HEIC';

    return { path: writePath }
  };

  render() {
    return <ImageBackground style={{flex: 1}} source={Breakfast}/>
  };
}
