import { connect } from 'react-redux';

import { postOnboardingAnswers, setDiet } from '../../actions/';

import Onboarding, { Mode } from '../components/Onboarding';
import { AuthState } from '../../common/constants/';

const mapStateToProps = state => ({
  mode: Mode.EXISTING_USER,
  visible: (state.auth_state === AuthState.LOGGED_IN) && !state.profile.onboarding_answers
});

const mapDispatchToProps = {
  postOnboardingAnswers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding)
