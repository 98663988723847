import React from 'react';

import Svg, { G, Path } from 'react-native-svg';

const CurvyMask = (props) => (
  <G transform="matrix(1,0,0,1.32681,0,6.46529)">
    <Path
      d="M-7.596,275.143C-7.596,275.143 54.306,255.065 96.949,256.749C171.651,259.7 183.089,286.578 261.691,286.946C299.152,287.121 406.208,268.708 406.208,268.708L405.964,-7.32L-6.896,-8.587L-7.596,275.143Z"
      style={{fill:'white'}}
    />
  </G>
);

export default CurvyMask;
