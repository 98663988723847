import React, { useState, useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { useTranslation } from 'react-i18next';

import Button from '../../common/components/Button';
import common_styles, { padding } from '../../common/components/CommonStyles';
import { Alert, Body2, ButtonText } from '../../utils/';
import { useTheme } from '../../utils/Theme';

import IngredientEditorContainer from '../containers/IngredientEditorContainer';
import IngredientSearchPhraseEditorContainer from '../containers/IngredientSearchPhraseEditorContainer';

const Table = ({ children, ...props}) => (
  <View style={[styles.table, props.style]}>
    { children }
  </View>
)
const Row = ({ children, ...props }) => (
  <View style={[styles.row, props.style]}>
    { children }
  </View>
)
const Cell = ({colSpan = 1, children, ...props}) => (
  <View style={[styles.cell, {flex: colSpan}, props.style]}>
    { children }
  </View>
)

const Uploader = ({ labelling_ingredient, loadLabellingIngredient, confirmLabellingIngredient, reparseLabellingIngredient, deleteLabellingIngredient }) => {
  const [ deleteConfirmed, setDeleteConfirmed ] = useState(false);
  const [ ingredient_modal_show, setIngredientModalShow ] = useState(false);
  const [ search_phrase_modal_show, setSearchPhraseModalShow ] = useState(false);

  const { t } = useTranslation('common');

  const theme = useTheme();

  useEffect(() => {
    setDeleteConfirmed(false);
  }, [labelling_ingredient])

  const callLoadLabellingIngredient = () => {
    loadLabellingIngredient();
  };

  let body = null;
  if (labelling_ingredient) {
    const {original_string_wo_quantity, language_code} = labelling_ingredient;

    let match_result = null;
    if (!labelling_ingredient.matched_with_ingredients.length) {
      match_result = (
        <Row>
          <Cell colSpan={3}>
            <Alert text={t('unmatched')}/>
          </Cell>
        </Row>
      );
    } else {
      match_result = labelling_ingredient.matched_with_ingredients.map((matched_ingredient, idx) => (
        <Row key={idx}>
          <Cell colSpan={2}>
            <Body2>
              { matched_ingredient.name }
            </Body2>
          </Cell>
          <Cell>
            <Button style={theme.button} onPress={() => confirmLabellingIngredient(original_string_wo_quantity, language_code, matched_ingredient.id)}>
              <Body2 style={theme.button}>
                {t('labelling.confirm')}
              </Body2>
            </Button>
          </Cell>
        </Row>
      ));
    }

    body = (
      <>
        <Table>
          <Row style={theme.general}>
            <Cell><Body2 style={[styles.textWrap, theme.general]}>{t('labelling.originalStringWithoutQty')}</Body2></Cell>
            <Cell><Body2 style={[styles.textWrap, theme.general]}>{t('language.language')}</Body2></Cell>
            <Cell><Body2 style={[styles.textWrap, theme.general]}>{t('labelling.action')}</Body2></Cell>
          </Row>
          <Row>
            <Cell><Body2 style={[styles.textWrap, theme.general]}>{original_string_wo_quantity}</Body2></Cell>
            <Cell><Body2 style={theme.general}>{language_code}</Body2></Cell>
            <Cell style={{'textAlign': 'right'}}>
              <View style={common_styles.buttonContainer}>
                {
                  deleteConfirmed ? (
                    <Button style={theme.button} onPress={() => deleteLabellingIngredient(original_string_wo_quantity, language_code)}>
                      <Body2 style={theme.button}>
                        {t('labelling.reallyDelete')}
                      </Body2>
                    </Button>
                  ) : (
                    <Button style={theme.button} onPress={() => setDeleteConfirmed(true)}>
                      <Body2 style={theme.button}>
                        {t('labelling.delete')}
                      </Body2>
                    </Button>
                  )
                }
              </View>
            </Cell>
          </Row>
          <Row>
            <Cell colSpan={3} style={{'textAlign': 'right'}}>
              <Button style={theme.button} onPress={() => setIngredientModalShow(true)}>
                <Body2 style={theme.button}>
                  {t('labelling.addNewIngredient')}
                </Body2>
              </Button>
            </Cell>
          </Row>
          <Row>
            <Cell colSpan={3} style={{'textAlign': 'right'}}>
              <Button style={theme.button} onPress={() => setSearchPhraseModalShow(true)}>
                <Body2 style={theme.button}>
                  {t('labelling.addNewKeywords')}
                </Body2>
              </Button>
            </Cell>
          </Row>
          <Row>
            <Cell colSpan={3} style={{'textAlign': 'right'}}>
              <Button style={theme.button} onPress={() => reparseLabellingIngredient(original_string_wo_quantity, language_code)}>
                <Body2 style={theme.button}>
                {t('labelling.reparse')}
                </Body2>
              </Button>
            </Cell>
          </Row>
          { match_result }
        </Table>
        <IngredientEditorContainer visible={ingredient_modal_show} onRequestClose={() => setIngredientModalShow(false)} />
        <IngredientSearchPhraseEditorContainer visible={search_phrase_modal_show} onRequestClose={() => setSearchPhraseModalShow(false)} />
      </>
    );
  }


  return (
    <ScrollView>
      <Button style={theme.button} onPress={() => callLoadLabellingIngredient()}>
        <ButtonText style={theme.button}>
          { t('labelling.getLabellingIngredient') }
        </ButtonText>
      </Button>
      { body }
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  textWrap: {
    flexWrap: 'wrap'
  },
  table: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  row: {
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  cell: {
    padding
  }
})

export default Uploader;
