import React from 'react';

import { HiddenEmail } from '../../utils/';

import { A, H1, H2, H3, Li, P, Span, Strong, Ul } from './PrivacyPolicyUtils';

const PrivacyPolicyDe = () => (
<>
<H1>Datenschutzbestimmungen</H1>
<P>Letzte Aktualisierung: Januar 13, 2021</P>
<P>Diese Datenschutzrichtlinie beschreibt unsere Richtlinien und Verfahren zur Erfassung, Verwendung und Offenlegung Ihrer Daten, wenn Sie den Dienst nutzen, und informiert Sie über Ihre Datenschutzrechte und wie das Gesetz Sie schützt.</P>
<P>Wir verwenden Ihre personenbezogenen Daten, um den Dienst bereitzustellen und zu verbessern. Durch die Nutzung des Dienstes erklären Sie sich mit der Erfassung und Verwendung von Informationen in Übereinstimmung mit dieser Datenschutzrichtlinie einverstanden.</P>
<H1>Auslegung und Definitionen</H1>
<H2>Auslegung</H2>
<P>Die Wörter, deren Anfangsbuchstaben groß geschrieben sind, haben die im Folgenden definierten Bedeutungen. Die folgenden Definitionen haben dieselbe Bedeutung, unabhängig davon, ob sie im Singular oder im Plural stehen.</P>
<H2>Definitionen</H2>
<P>Für die Zwecke dieser Datenschutzrichtlinie:</P>
<Ul>
<Li>
<P><Strong>Konto</Strong> bezeichnet ein eindeutiges Konto, das für Sie erstellt wird, um auf unseren Service oder Teile unseres Service zuzugreifen.</P>
</Li>
<Li>
<P><Strong>Verbundenes Unternehmen</Strong> bedeutet ein Unternehmen, das eine Partei kontrolliert, von ihr kontrolliert wird oder mit ihr unter gemeinsamer Kontrolle steht, wobei &quot;Kontrolle&quot; den Besitz von 50 % oder mehr der Aktien, Anteile oder anderer Wertpapiere bedeutet, die zur Wahl von Direktoren oder anderen leitenden Angestellten stimmberechtigt sind.</P>
</Li>
<Li>
<P><Strong>Anwendung</Strong> bezeichnet das von der Gesellschaft bereitgestellte Softwareprogramm, das Sie auf ein elektronisches Gerät herunterladen, namens savori</P>
</Li>
<Li>
<P><Strong>Unternehmen</Strong> bezeichnet für die Zwecke des CCPA (California Consumer Privacy Act) die Gesellschaft als die juristische Person, die personenbezogene Daten der Verbraucher sammelt und die Zwecke und Mittel der Verarbeitung der personenbezogenen Daten der Verbraucher bestimmt oder in deren Namen solche Daten gesammelt werden und die allein oder gemeinsam mit anderen die Zwecke und Mittel der Verarbeitung der personenbezogenen Daten der Verbraucher bestimmt, die im Bundesstaat Kalifornien geschäftlich tätig ist.</P>
</Li>
<Li>
<P><Strong>Unternehmen</Strong> (bezeichnet entweder als &quot;das Unternehmen&quot;, &quot;Wir&quot;, &quot;Uns&quot; oder &quot;Unser&quot; in dieser Vereinbarung) bezieht sich auf savori.</P>
<P>Für die Zwecke der GDPR ist das Unternehmen der Data Controller.</P>
</Li>
<Li>
<P><Strong>Verbraucher</Strong> bedeutet für die Zwecke des CCPA (California Consumer Privacy Act) eine natürliche Person, die in Kalifornien ansässig ist. Ein Einwohner im Sinne des Gesetzes ist (1) jede natürliche Person, die sich zu einem anderen als einem vorübergehenden oder transitorischen Zweck in den USA aufhält, und (2) jede natürliche Person, die ihren Wohnsitz in den USA hat und sich zu einem vorübergehenden oder transitorischen Zweck außerhalb der USA befindet.</P>
</Li>
<Li>
<P><Strong>Land</Strong> bezieht sich auf: Schweiz</P>
</Li>
<Li>
<P><Strong>Datenverantwortlicher</Strong> im Sinne der GDPR (General Data Protection Regulation) ist das Unternehmen als diejenige juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</P>
</Li>
<Li>
<P><Strong>Gerät</Strong> bezeichnet jedes Gerät, das auf den Service zugreifen kann, wie z.B. ein Computer, ein Mobiltelefon oder ein digitales Tablet.</P>
</Li>
<Li>
<P><Strong>Do Not Track</Strong> (DNT) ist ein Konzept, das von US-amerikanischen Regulierungsbehörden, insbesondere der U.S. Federal Trade Commission (FTC), für die Internetindustrie gefördert wurde, um einen Mechanismus zu entwickeln und zu implementieren, der es Internetnutzern ermöglicht, die Verfolgung ihrer Online-Aktivitäten über Websites hinweg zu kontrollieren.</P>
</Li>
<Li>
<P><Strong>Personenbezogene Daten</Strong> sind alle Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen.</P>
<P>Im Sinne der GDPR sind personenbezogene Daten alle Informationen, die sich auf Sie beziehen, wie z. B. ein Name, eine Kennnummer, Standortdaten, Online-Kennungen oder ein oder mehrere Faktoren, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität sind.</P>
<P>Personenbezogene Daten im Sinne des CCPA sind alle Informationen, die Sie identifizieren, sich auf Sie beziehen, Sie beschreiben oder mit Ihnen in Verbindung gebracht werden können oder vernünftigerweise direkt oder indirekt mit Ihnen in Verbindung gebracht werden könnten.</P>
</Li>
<Li>
<P><Strong>Verkauf</Strong> bedeutet im Sinne des CCPA (California Consumer Privacy Act) den Verkauf, die Vermietung, die Freigabe, die Offenlegung, die Verbreitung, die Zurverfügungstellung, die Übertragung oder die anderweitige mündliche, schriftliche, elektronische oder sonstige Übermittlung der personenbezogenen Daten eines Verbrauchers an ein anderes Unternehmen oder einen Dritten gegen Entgelt oder eine andere entgeltliche Gegenleistung.</P>
</Li>
<Li>
<P><Strong>Service</Strong> bezieht sich auf die Anwendung.</P>
</Li>
<Li>
<P><Strong>Dienstleister</Strong> bezeichnet jede natürliche oder juristische Person, die die Daten im Auftrag des Unternehmens verarbeitet. Er bezieht sich auf Drittunternehmen oder Einzelpersonen, die vom Unternehmen beauftragt werden, den Service zu ermöglichen, den Service im Namen des Unternehmens bereitzustellen, Dienstleistungen im Zusammenhang mit dem Service zu erbringen oder das Unternehmen bei der Analyse der Nutzung des Service zu unterstützen.
Für die Zwecke der GDPR werden Dienstanbieter als Datenverarbeiter betrachtet.</P>
</Li>
<Li>
<P><Strong>Sozialer Medienservice eines Drittanbieters</Strong> bezieht sich auf jede Website oder jede Website eines sozialen Netzwerks, über die ein Nutzer sich anmelden oder ein Konto erstellen kann, um den Service zu nutzen.</P>
</Li>
<Li>
<P><Strong>Nutzungsdaten</Strong> bezieht sich auf automatisch erhobene Daten, die entweder durch die Nutzung des Service oder von der Infrastruktur des Service selbst generiert werden (z.B. die Dauer eines Seitenbesuchs).</P>
</Li>
<Li>
<P><Strong>Sie</Strong> bezeichnet die natürliche Person, die auf den Service zugreift oder ihn nutzt, bzw. das Unternehmen oder eine andere juristische Person, in deren Namen eine solche natürliche Person auf den Service zugreift oder ihn nutzt.</P>
<P>Nach der GDPR (General Data Protection Regulation) können Sie als die betroffene Person oder als der Nutzer bezeichnet werden, da Sie die natürliche Person sind, die den Service nutzt.</P>
</Li>
</Ul>
<H1>Erhebung und Verwendung Ihrer personenbezogenen Daten</H1>
<H2>Arten der gesammelten Daten</H2>
<H3>Personenbezogene Daten</H3>
<P>Während der Nutzung unseres Dienstes können wir Sie bitten, uns bestimmte personenbezogene Daten zur Verfügung zu stellen, die dazu verwendet werden können, Sie zu kontaktieren oder zu identifizieren. Persönlich identifizierbare Informationen können unter anderem sein:</P>
<Ul>
<Li>
<P>E-Mail-Adresse</P>
</Li>
<Li>
<P>Vorname und Nachname</P>
</Li>
<Li>
<P>Adresse, Bundesland, Provinz, Postleitzahl, Ort</P>
</Li>
<Li>
<P>Nutzungsdaten</P>
</Li>
</Ul>
<H3>Nutzungsdaten</H3>
<P>Nutzungsdaten werden bei der Nutzung des Dienstes automatisch erfasst.</P>
<P>Nutzungsdaten können Informationen wie die Internetprotokolladresse Ihres Geräts (z. B. IP-Adresse), den Browsertyp, die Browserversion, die von Ihnen besuchten Seiten unseres Dienstes, die Uhrzeit und das Datum Ihres Besuchs, die auf diesen Seiten verbrachte Zeit, eindeutige Gerätekennungen und andere Diagnosedaten umfassen.</P>
<P>Wenn Sie mit oder über ein Mobilgerät auf den Dienst zugreifen, können wir bestimmte Informationen automatisch erfassen, einschließlich, aber nicht beschränkt auf die Art des von Ihnen verwendeten Mobilgeräts, die eindeutige ID Ihres Mobilgeräts, die IP-Adresse Ihres Mobilgeräts, Ihr mobiles Betriebssystem, die Art des von Ihnen verwendeten mobilen Internetbrowsers, eindeutige Gerätekennungen und andere Diagnosedaten.</P>
<P>Wir können auch Informationen sammeln, die Ihr Browser sendet, wenn Sie unseren Dienst besuchen oder wenn Sie auf den Dienst von oder über ein mobiles Gerät zugreifen.</P>
<H3>Informationen, die während der Nutzung der Anwendung gesammelt werden</H3>
<P>Während der Nutzung unserer Anwendung, um Funktionen unserer Anwendung bereitzustellen, können wir mit Ihrer vorherigen Erlaubnis folgende Daten erfassen:</P>
<Ul>
<Li>Informationen zu Ihrem Standort</Li>
</Ul>
<P>Wir verwenden diese Informationen, um Funktionen Unseres Dienstes bereitzustellen und um Unseren Dienst zu verbessern und anzupassen. Die Informationen können auf die Server des Unternehmens und/oder eines Dienstanbieters hochgeladen werden oder sie werden einfach auf Ihrem Gerät gespeichert.</P>
<P>Sie können den Zugriff auf diese Informationen jederzeit über die Einstellungen Ihres Geräts aktivieren oder deaktivieren.</P>
<H2>Verwendung Ihrer persönlichen Daten</H2>
<P>Das Unternehmen kann personenbezogene Daten für die folgenden Zwecke verwenden:</P>
<Ul>
<Li>
<P><Strong>Zur Bereitstellung und Aufrechterhaltung unseres Dienstes</Strong>, einschließlich zur Überwachung der Nutzung unseres Dienstes.</P>
</Li>
<Li>
<P><Strong>Um Ihr Konto zu verwalten:</Strong> um Ihre Registrierung als Nutzer des Service zu verwalten. Die von Ihnen bereitgestellten personenbezogenen Daten können Ihnen Zugang zu verschiedenen Funktionalitäten des Service geben, die Ihnen als registriertem Nutzer zur Verfügung stehen.</P>
</Li>
<Li>
<P><Strong>Zur Vertragserfüllung:</Strong> die Abwicklung, Einhaltung und Durchführung des Kaufvertrags für die von Ihnen erworbenen Produkte, Artikel oder Dienstleistungen oder eines anderen Vertrags mit uns über den Service.</P>
</Li>
<Li>
<P><Strong>Um mit Ihnen in Kontakt zu treten:</Strong> Um Sie per E-Mail, Telefonanrufe, SMS oder andere gleichwertige Formen der elektronischen Kommunikation, wie z.B. Push-Benachrichtigungen einer mobilen Anwendung, bezüglich Updates oder informativer Mitteilungen in Bezug auf die Funktionalitäten, Produkte oder vertraglich vereinbarten Dienstleistungen, einschließlich der Sicherheitsupdates, zu kontaktieren, wenn dies für deren Durchführung notwendig oder angemessen ist.</P>
</Li>
<Li>
<P><Strong>Um Ihnen</Strong> Nachrichten, Sonderangebote und allgemeine Informationen über andere Waren, Dienstleistungen und Veranstaltungen zukommen zu lassen, die wir anbieten und die denen ähnlich sind, die Sie bereits gekauft oder angefragt haben, es sei denn, Sie haben sich gegen den Erhalt solcher Informationen entschieden.</P>
</Li>
<Li>
<P><Strong>Um Ihre Anfragen zu verwalten:</Strong>Um Ihre Anfragen an uns zu bearbeiten und zu verwalten.</P>
</Li>
<Li>
<P><Strong>Für Geschäftsübertragungen:</Strong> Wir können Ihre Daten verwenden, um eine Fusion, Veräußerung, Umstrukturierung, Reorganisation, Auflösung oder einen anderen Verkauf oder eine Übertragung eines Teils oder aller unserer Vermögenswerte zu bewerten oder durchzuführen, sei es als laufender Betrieb oder als Teil eines Konkurses, einer Liquidation oder eines ähnlichen Verfahrens, bei dem die von uns gespeicherten personenbezogenen Daten unserer Service-Nutzer zu den übertragenen Vermögenswerten gehören.</P>
</Li>
<Li>
<P><Strong>Für andere Zwecke</Strong>: Wir können Ihre Daten für andere Zwecke verwenden, wie z.B. zur Datenanalyse, zur Ermittlung von Nutzungstrends, zur Bestimmung der Effektivität unserer Werbekampagnen und zur Bewertung und Verbesserung unseres Service, unserer Produkte, Dienstleistungen, unseres Marketings und Ihrer Erfahrung.</P>
</Li>
</Ul>
<P>Wir können Ihre persönlichen Daten in den folgenden Situationen weitergeben:</P>
<Ul>
<Li><Strong>Mit Dienstanbietern:</Strong> Wir können Ihre personenbezogenen Daten mit Dienstanbietern teilen, um die Nutzung unseres Service zu überwachen und zu analysieren und um Sie zu kontaktieren.</Li>
<Li><Strong>Für Geschäftsübertragungen:</Strong> Wir können Ihre personenbezogenen Daten in Verbindung mit oder während der Verhandlungen über eine Fusion, den Verkauf von Unternehmensvermögen, eine Finanzierung oder die Übernahme unseres gesamten oder eines Teils unseres Unternehmens durch ein anderes Unternehmen weitergeben.</Li>
<Li><Strong>Mit verbundenen Unternehmen:</Strong> Wir können Ihre Daten an unsere verbundenen Unternehmen weitergeben; in diesem Fall verpflichten wir diese verbundenen Unternehmen, diese Datenschutzrichtlinie einzuhalten. Zu den verbundenen Unternehmen gehören unsere Muttergesellschaft und alle anderen Tochtergesellschaften, Joint-Venture-Partner oder andere Unternehmen, die wir kontrollieren oder die unter gemeinsamer Kontrolle mit uns stehen.</Li>
<Li><Strong>Mit Geschäftspartnern:</Strong> Wir können Ihre Informationen mit unseren Geschäftspartnern teilen, um Ihnen bestimmte Produkte, Dienstleistungen oder Werbeaktionen anzubieten.</Li>
<Li><Strong>Mit anderen Nutzern:</Strong> Wenn Sie persönliche Informationen teilen oder anderweitig in den öffentlichen Bereichen mit anderen Nutzern interagieren, können diese Informationen von allen Nutzern eingesehen werden und können öffentlich nach außen verbreitet werden. Wenn Sie mit anderen Nutzern interagieren oder sich über einen Social-Media-Dienst eines Drittanbieters registrieren, können Ihre Kontakte auf dem Social-Media-Dienst des Drittanbieters Ihren Namen, Ihr Profil, Ihre Bilder und die Beschreibung Ihrer Aktivitäten sehen. In ähnlicher Weise können andere Nutzer Beschreibungen Ihrer Aktivitäten einsehen, mit Ihnen kommunizieren und Ihr Profil einsehen.</Li>
<Li><Strong>Mit Ihrer Zustimmung</Strong>: Wir können Ihre persönlichen Daten für jeden anderen Zweck mit Ihrer Zustimmung offenlegen.</Li>
</Ul>
<H2>Aufbewahrung Ihrer persönlichen Daten</H2>
<P>Das Unternehmen wird Ihre personenbezogenen Daten nur so lange aufbewahren, wie es für die in dieser Datenschutzrichtlinie genannten Zwecke erforderlich ist. Wir werden Ihre personenbezogenen Daten in dem Umfang aufbewahren und verwenden, der erforderlich ist, um unseren rechtlichen Verpflichtungen nachzukommen (z. B. wenn wir Ihre Daten aufbewahren müssen, um geltende Gesetze einzuhalten), Streitigkeiten beizulegen und unsere rechtlichen Vereinbarungen und Richtlinien durchzusetzen.</P>
<P>Das Unternehmen wird Nutzungsdaten auch für interne Analysezwecke aufbewahren. Nutzungsdaten werden im Allgemeinen für einen kürzeren Zeitraum aufbewahrt, es sei denn, diese Daten werden verwendet, um die Sicherheit oder die Funktionalität unseres Dienstes zu verbessern, oder wir sind gesetzlich verpflichtet, diese Daten für längere Zeiträume aufzubewahren.</P>
<H2>Weitergabe Ihrer persönlichen Daten</H2>
<P>Ihre Informationen, einschließlich personenbezogener Daten, werden in den Betriebsbüros des Unternehmens und an anderen Orten, an denen sich die an der Verarbeitung beteiligten Parteien befinden, verarbeitet. Das bedeutet, dass diese Informationen an Computer außerhalb Ihres Staates, Ihrer Provinz, Ihres Landes oder einer anderen staatlichen Gerichtsbarkeit, in der andere Datenschutzgesetze gelten als in Ihrer Gerichtsbarkeit, übertragen und dort gespeichert werden können.</P>
<P>Ihre Zustimmung zu dieser Datenschutzrichtlinie, gefolgt von der Übermittlung dieser Informationen, stellt Ihre Zustimmung zu dieser Übertragung dar.</P>
<P>Das Unternehmen wird alle angemessenen Schritte unternehmen, um sicherzustellen, dass Ihre Daten sicher und in Übereinstimmung mit dieser Datenschutzrichtlinie behandelt werden, und es wird keine Übertragung Ihrer persönlichen Daten an eine Organisation oder ein Land stattfinden, wenn nicht angemessene Kontrollen vorhanden sind, die die Sicherheit Ihrer Daten und anderer persönlicher Informationen einschließen.</P>
<H2>Weitergabe Ihrer persönlichen Daten</H2>
<H3>Geschäftstransaktionen</H3>
<P>Wenn das Unternehmen an einer Fusion, Übernahme oder einem Verkauf von Vermögenswerten beteiligt ist, können Ihre personenbezogenen Daten übertragen werden. Wir werden Sie darüber informieren, bevor Ihre personenbezogenen Daten übertragen werden und einer anderen Datenschutzrichtlinie unterliegen.</P>
<H3>Gesetzesdurchsetzung</H3>
<P>Unter bestimmten Umständen kann das Unternehmen verpflichtet sein, Ihre personenbezogenen Daten offenzulegen, wenn dies gesetzlich vorgeschrieben ist oder als Reaktion auf berechtigte Anfragen von Behörden (z.B. einem Gericht oder einer Regierungsbehörde).</P>
<H3>Sonstige gesetzliche Anforderungen</H3>
<P>Das Unternehmen kann Ihre personenbezogenen Daten in dem guten Glauben offenlegen, dass eine solche Handlung notwendig ist, um:</P>
<Ul>
<Li>Eine gesetzliche Verpflichtung zu erfüllen</Li>
<Li>Schutz und Verteidigung der Rechte oder des Eigentums des Unternehmens</Li>
<Li>Vorbeugung oder Untersuchung möglichen Fehlverhaltens in Verbindung mit dem Dienst</Li>
<Li>Schutz der persönlichen Sicherheit von Benutzern des Dienstes oder der Öffentlichkeit</Li>
<Li>Schutz vor rechtlicher Haftung</Li>
</Ul>
<H2>Sicherheit Ihrer persönlichen Daten</H2>
<P>Die Sicherheit Ihrer persönlichen Daten ist uns wichtig, aber bedenken Sie, dass keine Methode der Übertragung über das Internet oder eine Methode der elektronischen Speicherung 100% sicher ist. Obwohl wir uns bemühen, kommerziell annehmbare Mittel zu verwenden, um Ihre persönlichen Daten zu schützen, können wir deren absolute Sicherheit nicht garantieren.</P>
<H1>Detaillierte Informationen über die Verarbeitung Ihrer persönlichen Daten</H1>
<P>Die von uns eingesetzten Dienstleister können Zugriff auf Ihre personenbezogenen Daten haben. Diese Drittanbieter erheben, speichern, nutzen, verarbeiten und übertragen Informationen über Ihre Aktivitäten auf unserem Dienst in Übereinstimmung mit ihren Datenschutzrichtlinien.</P>
<H2>Analytik</H2>
<P>Wir können Drittanbieter einsetzen, um die Nutzung unseres Dienstes zu überwachen und zu analysieren.</P>
<Ul>
<Li>
<P><Strong>Google Analytics</Strong></P>
<P>Google Analytics ist ein von Google angebotener Webanalysedienst, der den Website-Traffic verfolgt und berichtet. Google verwendet die erfassten Daten, um die Nutzung unseres Dienstes zu verfolgen und zu überwachen. Diese Daten werden mit anderen Google-Diensten geteilt. Google kann die gesammelten Daten verwenden, um die Anzeigen des eigenen Werbenetzwerks zu kontextualisieren und zu personalisieren.</P>
<P>Sie können bestimmte Funktionen von Google Analytics über die Einstellungen Ihres mobilen Geräts deaktivieren, z. B. über die Werbeeinstellungen Ihres Geräts oder indem Sie die Anweisungen von Google in den Datenschutzbestimmungen befolgen: <A href="https://policies.google.com/privacy" rel="external nofollow noopener noreferrer" target="_blank">https://policies.google.com/privacy</A></P>
<P>Weitere Informationen zu den Datenschutzpraktiken von Google finden Sie auf der Webseite von Google Privacy &amp; Terms: <A href="https://policies.google.com/privacy" rel="external nofollow noopener noreferrer" target="_blank">https://policies.google.com/privacy</A></P>
</Li>
</Ul>
<H2>E-Mail-Marketing</H2>
<P>Wir können Ihre personenbezogenen Daten verwenden, um Sie mit Newslettern, Marketing- oder Werbematerialien und anderen Informationen zu kontaktieren, die für Sie von Interesse sein könnten. Sie können den Erhalt von einzelnen oder allen dieser Mitteilungen von uns abbestellen, indem Sie dem Abmeldelink oder den Anweisungen folgen, die in jeder von uns gesendeten E-Mail enthalten sind, oder indem Sie sich mit uns in Verbindung setzen.</P>
<P>Wir können E-Mail-Marketing-Dienstleister nutzen, um E-Mails zu verwalten und an Sie zu senden.</P>
<Ul>
<Li>
<P><Strong>Mailchimp</Strong></P>
<P>Mailchimp ist ein E-Mail-Marketing-Versanddienst, der von The Rocket Science Group LLC bereitgestellt wird.</P>
<P>Weitere Informationen zu den Datenschutzpraktiken von Mailchimp finden Sie in deren Datenschutzrichtlinie: <A href="https://mailchimp.com/legal/privacy/" rel="external nofollow noopener noreferrer" target="_blank">https://mailchimp.com/legal/privacy/</A></P>
</Li>
</Ul>
<H1>GDPR-Datenschutz</H1>
<H2>Rechtsgrundlage für die Verarbeitung personenbezogener Daten nach GDPR</H2>
<P>Wir dürfen personenbezogene Daten unter den folgenden Bedingungen verarbeiten:</P>
<Ul>
<Li><Strong>Zustimmung:</Strong> Sie haben Ihre Zustimmung zur Verarbeitung personenbezogener Daten für einen oder mehrere bestimmte Zwecke gegeben.</Li>
<Li><Strong>Erfüllung eines Vertrages:</Strong> Die Bereitstellung der personenbezogenen Daten ist für die Erfüllung eines Vertrages mit Ihnen und/oder für vorvertragliche Pflichten erforderlich.</Li>
<Li><Strong>Gesetzliche Verpflichtungen:</Strong> Die Verarbeitung personenbezogener Daten ist für die Erfüllung einer gesetzlichen Verpflichtung erforderlich, der das Unternehmen unterliegt.</Li>
<Li><Strong>Wichtige Interessen:</Strong> Die Verarbeitung personenbezogener Daten ist erforderlich, um Ihre lebenswichtigen Interessen oder die einer anderen natürlichen Person zu schützen.</Li>
<Li><Strong>Öffentliche Interessen:</Strong> Die Verarbeitung personenbezogener Daten ist mit einer Aufgabe verbunden, die im öffentlichen Interesse oder in Ausübung öffentlicher Gewalt erfolgt, die dem Unternehmen übertragen wurde.</Li>
<Li><Strong>Legitime Interessen:</Strong> Die Verarbeitung personenbezogener Daten ist für die Zwecke der vom Unternehmen verfolgten legitimen Interessen erforderlich.</Li>
</Ul>
<P>In jedem Fall ist das Unternehmen gerne bereit, bei der Klärung der konkreten Rechtsgrundlage für die Verarbeitung behilflich zu sein, insbesondere ob die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den Abschluss eines Vertrages erforderlich ist.</P>
<H2>Ihre Rechte nach der GDPR</H2>
<P>Das Unternehmen verpflichtet sich, die Vertraulichkeit Ihrer personenbezogenen Daten zu wahren und zu gewährleisten, dass Sie Ihre Rechte ausüben können.</P>
<P>Sie haben gemäß dieser Datenschutzrichtlinie und per Gesetz, wenn Sie sich innerhalb der EU befinden, das Recht auf:</P>
<Ul>
<Li><Strong>Zugang zu Ihren persönlichen Daten zu verlangen.</Strong> Das Recht, auf die Informationen, die wir über Sie haben, zuzugreifen, sie zu aktualisieren oder zu löschen. Wann immer es möglich ist, können Sie direkt im Bereich Ihrer Kontoeinstellungen auf Ihre personenbezogenen Daten zugreifen, diese aktualisieren oder deren Löschung beantragen. Wenn Sie nicht in der Lage sind, diese Aktionen selbst durchzuführen, kontaktieren Sie uns bitte, um Ihnen zu helfen. Auf diese Weise können Sie auch eine Kopie der persönlichen Daten erhalten, die wir über Sie gespeichert haben.</Li>
<Li><Strong>Bitte um Korrektur der persönlichen Daten, die wir über Sie gespeichert haben.</Strong> Sie haben das Recht, unvollständige oder ungenaue Informationen, die wir über Sie gespeichert haben, korrigieren zu lassen.</Li>
<Li><Strong>Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten.</Strong> Dieses Recht besteht, wenn wir uns auf ein berechtigtes Interesse als Rechtsgrundlage für unsere Verarbeitung berufen und es etwas an Ihrer besonderen Situation gibt, das Sie dazu veranlasst, gegen unsere Verarbeitung Ihrer personenbezogenen Daten aus diesem Grund Widerspruch einzulegen. Sie haben auch das Recht, Widerspruch einzulegen, wenn wir Ihre personenbezogenen Daten für Direktmarketingzwecke verarbeiten.</Li>
<Li><Strong>Löschung Ihrer personenbezogenen Daten verlangen.</Strong> Sie haben das Recht, von uns die Löschung oder Entfernung Ihrer personenbezogenen Daten zu verlangen, wenn es für uns keinen triftigen Grund gibt, diese weiterhin zu verarbeiten.</Li>
<Li><Strong>Verlangen Sie die Übermittlung Ihrer personenbezogenen Daten.</Strong> Wir werden Ihnen oder einem von Ihnen gewählten Dritten Ihre personenbezogenen Daten in einem strukturierten, allgemein gebräuchlichen und maschinenlesbaren Format zur Verfügung stellen. Bitte beachten Sie, dass dieses Recht nur für automatisierte Daten gilt, deren Verwendung Sie ursprünglich zugestimmt haben, oder wenn wir die Daten zur Erfüllung eines Vertrags mit Ihnen verwendet haben.</Li>
<Li><Strong>Widerruf Ihrer Einwilligung.</Strong> Sie haben das Recht, Ihre Einwilligung zur Verwendung Ihrer personenbezogenen Daten zu widerrufen. Wenn Sie Ihre Einwilligung zurückziehen, können wir Ihnen möglicherweise keinen Zugang zu bestimmten Funktionen des Service gewähren.</Li>
</Ul>
<H2>Ausübung Ihrer GDPR-Datenschutzrechte</H2>
<P>Sie können Ihre Rechte auf Zugriff, Berichtigung, Löschung und Widerspruch ausüben, indem Sie sich mit uns in Verbindung setzen. Bitte beachten Sie, dass wir Sie möglicherweise bitten, Ihre Identität zu überprüfen, bevor wir auf solche Anfragen reagieren. Wenn Sie eine Anfrage stellen, werden wir unser Bestes tun, um Ihnen so schnell wie möglich zu antworten.</P>
<P>Sie haben das Recht, sich bei einer Datenschutzbehörde über die Erhebung und Verwendung Ihrer personenbezogenen Daten durch uns zu beschweren. Wenn Sie sich im Europäischen Wirtschaftsraum (EWR) befinden, wenden Sie sich für weitere Informationen bitte an Ihre lokale Datenschutzbehörde im EWR.</P>
<H1>CCPA-Datenschutz</H1>
<P>Dieser Abschnitt über den Datenschutz für Einwohner von Kalifornien ergänzt die Informationen, die in unserer Datenschutzrichtlinie enthalten sind, und gilt ausschließlich für alle Besucher, Nutzer und andere Personen, die im Bundesstaat Kalifornien ansässig sind.</P>
<H2>Kategorien der gesammelten persönlichen Daten</H2>
<P>Wir sammeln Informationen, die einen bestimmten Verbraucher oder ein bestimmtes Gerät identifizieren, sich auf ihn beziehen, ihn beschreiben, auf ihn verweisen, mit ihm in Verbindung gebracht werden können oder vernünftigerweise direkt oder indirekt mit ihm in Verbindung gebracht werden könnten. Im Folgenden finden Sie eine Liste von Kategorien personenbezogener Daten, die wir von in Kalifornien ansässigen Personen erheben können oder in den letzten zwölf (12) Monaten erhoben haben.</P>
<P>Bitte beachten Sie, dass die Kategorien und Beispiele in der folgenden Liste diejenigen sind, die im CCPA definiert sind. Dies bedeutet nicht, dass alle Beispiele dieser Kategorie personenbezogener Daten tatsächlich von uns erfasst wurden, sondern spiegelt unsere gutgläubige Annahme nach bestem Wissen und Gewissen wider, dass einige dieser Daten aus der zutreffenden Kategorie erfasst werden können und möglicherweise erfasst wurden. Bestimmte Kategorien personenbezogener Daten würden beispielsweise nur dann erfasst werden, wenn Sie uns diese personenbezogenen Daten direkt zur Verfügung stellen.</P>
<Ul>
<Li>
<P><Strong>Kategorie A: Identifikatoren.</Strong></P>
<P>Beispiele: Ein echter Name, ein Alias, eine Postadresse, eine eindeutige persönliche Kennung, eine Online-Kennung, eine Internet-Protokoll-Adresse, eine E-Mail-Adresse, ein Kontoname, eine Führerscheinnummer, eine Reisepassnummer oder andere ähnliche Kennungen.</P>
<P>Gesammelt: Ja.</P>
</Li>
<Li>
<P><Strong>Kategorie B: Persönliche Datenkategorien, die im kalifornischen Gesetz über Kundendaten (Cal. Civ. Code § 1798.80(e)).</Strong></P>
<P>Beispiele: Name, Unterschrift, Sozialversicherungsnummer, körperliche Merkmale oder Beschreibung, Adresse, Telefonnummer, Reisepassnummer, Führerschein- oder staatliche Ausweisnummer, Nummer der Versicherungspolice, Ausbildung, Beschäftigung, Beschäftigungsgeschichte, Bankkontonummer, Kreditkartennummer, Debitkartennummer oder andere finanzielle Informationen, medizinische Informationen oder Informationen zur Krankenversicherung. Einige persönliche Informationen, die in dieser Kategorie enthalten sind, können sich mit anderen Kategorien überschneiden.</P>
<P>Sammeln: Ja.</P>
</Li>
<Li>
<P><Strong>Kategorie C: Geschützte Klassifizierungsmerkmale nach kalifornischem oder Bundesrecht.</Strong></P>
<P>Beispiele: Alter (40 Jahre oder älter), Rasse, Hautfarbe, Abstammung, nationale Herkunft, Staatsbürgerschaft, Religion oder Weltanschauung, Familienstand, medizinischer Zustand, körperliche oder geistige Behinderung, Geschlecht (einschließlich Geschlecht, Geschlechtsidentität, Geschlechtsausdruck, Schwangerschaft oder Geburt und damit verbundene medizinische Zustände), sexuelle Orientierung, Veteranen- oder Militärstatus, genetische Informationen (einschließlich familiärer genetischer Informationen).</P>
<P>Sammeln: Nein.</P>
</Li>
<Li>
<P><Strong>Kategorie D: Kommerzielle Informationen.</Strong></P>
<P>Beispiele: Aufzeichnungen und Geschichte von Produkten oder Dienstleistungen, die gekauft oder in Betracht gezogen wurden.</P>
<P>Gesammelt: Nein.</P>
</Li>
<Li>
<P><Strong>Kategorie E: Biometrische Informationen.</Strong></P>
<P>Beispiele: Genetische, physiologische, Verhaltens- und biologische Merkmale oder Aktivitätsmuster, die verwendet werden, um eine Schablone oder einen anderen Identifikator oder identifizierende Informationen zu extrahieren, wie z.B. Fingerabdrücke, Gesichts- und Stimmabdrücke, Iris- oder Retina-Scans, Tastenanschlag-, Gang- oder andere physische Muster sowie Schlaf-, Gesundheits- oder Bewegungsdaten.</P>
<P>Gesammelt: Nein.</P>
</Li>
<Li>
<P><Strong>Kategorie F: Internet- oder andere ähnliche Netzwerkaktivitäten.</Strong></P>
<P>Beispiele: Interaktion mit unserem Service oder Werbung.</P>
<P>Sammeln: Ja.</P>
</Li>
<Li>
<P><Strong>Kategorie G: Geolokalisierungsdaten.</Strong></P>
<P>Beispiele: Ungefährer physischer Standort.</P>
<P>Gesammelt: Ja.</P>
</Li>
<Li>
<P><Strong>Kategorie H: Sensorische Daten.</Strong></P>
<P>Beispiele: Audio-, elektronische, visuelle, thermische, olfaktorische oder ähnliche Informationen.</P>
<P>Sammeln: Nein.</P>
</Li>
<Li>
<P><Strong>Kategorie I: Berufs- oder beschäftigungsbezogene Informationen.</Strong></P>
<P>Beispiele: Aktueller oder früherer beruflicher Werdegang oder Leistungsbewertungen.</P>
<P>Sammeln: Nein.</P>
</Li>
<Li>
<P><Strong>Kategorie J: Nicht-öffentliche Bildungsinformationen (gemäß dem Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</Strong></P>
<P>Beispiele: Bildungsaufzeichnungen, die sich direkt auf einen Schüler beziehen und von einer Bildungseinrichtung oder einer Partei, die in ihrem Namen handelt, aufbewahrt werden, wie z. B. Noten, Abschriften, Klassenlisten, Stundenpläne von Schülern, Schüleridentifikationscodes, finanzielle Informationen von Schülern oder disziplinarische Aufzeichnungen von Schülern.</P>
<P>Sammeln: Nein.</P>
</Li>
<Li>
<P><Strong>Kategorie K: Rückschlüsse aus anderen persönlichen Informationen.</Strong></P>
<P>Beispiele: Profil, das die Vorlieben, Eigenschaften, psychologischen Tendenzen, Prädispositionen, Verhalten, Einstellungen, Intelligenz, Fähigkeiten und Neigungen einer Person widerspiegelt.</P>
<P>Sammeln: Nein.</P>
</Li>
</Ul>
<P>Nach dem CCPA gehören zu den persönlichen Informationen nicht:</P>
<Ul>
<Li>Öffentlich verfügbare Informationen aus staatlichen Aufzeichnungen</Li>
<Li>Deidentifizierte oder aggregierte Verbraucherinformationen</Li>
<Li>Informationen, die vom Anwendungsbereich des CCPA ausgeschlossen sind, wie z.B.:
<Ul>
<Li>Gesundheitsdaten oder medizinische Informationen, die unter den Health Insurance Portability and Accountability Act von 1996 (HIPAA) und den California Confidentiality of Medical Information Act (CMIA) fallen, oder Daten aus klinischen Studien</Li>
<Li>Personenbezogene Informationen, die unter bestimmte branchenspezifische Datenschutzgesetze fallen, einschließlich des Fair Credit Reporting Act (FRCA), des Gramm-Leach-Bliley Act (GLBA) oder des California Financial Information Privacy Act (FIPA) und des Driver's Privacy Protection Act von 1994</Li>
</Ul>
</Li>
</Ul>
<H2>Quellen der persönlichen Informationen</H2>
<P>Wir erhalten die oben aufgeführten Kategorien personenbezogener Daten aus den folgenden Kategorien von Quellen:</P>
<Ul>
<Li><Strong>Direkt von Ihnen</Strong>. Zum Beispiel aus den Formularen, die Sie in unserem Service ausfüllen, aus Präferenzen, die Sie äußern oder über unseren Service bereitstellen.</Li>
<Li><Strong>Indirekt von Ihnen</Strong>. Zum Beispiel durch die Beobachtung Ihrer Aktivitäten in unserem Service.</Li>
<Li><Strong>Automatisch von Ihnen</Strong>. Zum Beispiel durch Cookies, die wir oder unsere Service-Provider auf Ihrem Gerät setzen, während Sie durch unseren Service navigieren.</Li>
<Li><Strong>Von Dienstanbietern</Strong>. Zum Beispiel von Drittanbietern, um die Nutzung unseres Dienstes zu überwachen und zu analysieren, oder von anderen Drittanbietern, die wir nutzen, um Ihnen den Dienst zur Verfügung zu stellen.</Li>
</Ul>
<H2>Verwendung von personenbezogenen Daten für geschäftliche oder kommerzielle Zwecke</H2>
<P>Wir können die von uns erfassten personenbezogenen Daten für &quot;geschäftliche Zwecke&quot; oder &quot;kommerzielle Zwecke&quot; (wie im CCPA definiert) verwenden oder offenlegen, was die folgenden Beispiele umfassen kann:</P>
<Ul>
<Li>Um unseren Dienst zu betreiben und Ihnen unseren Dienst zur Verfügung zu stellen.</Li>
<Li>Um Ihnen Support zu bieten und Ihre Anfragen zu beantworten, einschließlich der Untersuchung und Behandlung Ihrer Anliegen und der Überwachung und Verbesserung unseres Dienstes.</Li>
<Li>Um den Grund zu erfüllen, zu dem Sie die Informationen zur Verfügung gestellt haben. Wenn Sie uns beispielsweise Ihre Kontaktinformationen mitteilen, um eine Frage zu unserem Dienst zu stellen, werden wir diese persönlichen Informationen verwenden, um Ihre Anfrage zu beantworten.</Li>
<Li>Zur Beantwortung von Anfragen der Strafverfolgungsbehörden und soweit dies nach geltendem Recht, Gerichtsbeschluss oder behördlichen Vorschriften erforderlich ist.</Li>
<Li>Wie Ihnen bei der Erfassung Ihrer persönlichen Daten beschrieben oder wie anderweitig in der CCPA festgelegt.</Li>
<Li>Für interne Verwaltungs- und Prüfungszwecke.</Li>
<Li>Zur Aufdeckung von Sicherheitsvorfällen und zum Schutz vor böswilligen, betrügerischen oder illegalen Aktivitäten, einschließlich, falls erforderlich, zur strafrechtlichen Verfolgung der für solche Aktivitäten Verantwortlichen.</Li>
</Ul>
<P>Bitte beachten Sie, dass die oben angeführten Beispiele illustrativ sind und keinen Anspruch auf Vollständigkeit erheben. Weitere Einzelheiten darüber, wie wir diese Informationen verwenden, finden Sie im Abschnitt &quot;Verwendung Ihrer personenbezogenen Daten&quot;.</P>
<P>Wenn wir beschließen, weitere Kategorien personenbezogener Daten zu erfassen oder die von uns erfassten personenbezogenen Daten für wesentlich andere, nicht verwandte oder unvereinbare Zwecke zu verwenden, werden wir diese Datenschutzrichtlinie aktualisieren.</P>
<H2>Weitergabe von personenbezogenen Daten für geschäftliche oder kommerzielle Zwecke</H2>
<P>Wir können die folgenden Kategorien personenbezogener Daten für geschäftliche oder kommerzielle Zwecke verwenden oder offenlegen und haben sie in den letzten zwölf (12) Monaten verwendet oder offengelegt:</P>
<Ul>
<Li>
<P>Kategorie A: Identifikatoren</P>
</Li>
<Li>
<P>Kategorie B: Persönliche Datenkategorien, die im kalifornischen Gesetz über Kundendaten (Cal. Civ. Code § 1798.80(e))</P>
</Li>
<Li>
<P>Kategorie F: Internet oder andere ähnliche Netzwerkaktivitäten</P>
</Li>
<Li>
<P>Kategorie G: Geolokalisierungsdaten</P>
</Li>
</Ul>
<P>Bitte beachten Sie, dass die oben aufgeführten Kategorien diejenigen sind, die im CCPA definiert sind. Dies bedeutet nicht, dass alle Beispiele dieser Kategorie personenbezogener Daten tatsächlich offengelegt wurden, sondern spiegelt unsere gutgläubige Annahme nach bestem Wissen und Gewissen wider, dass einige dieser Daten aus der entsprechenden Kategorie offengelegt werden können und wurden.</P>
<P>Wenn wir personenbezogene Daten für einen geschäftlichen oder kommerziellen Zweck offenlegen, schließen wir einen Vertrag ab, der den Zweck beschreibt und den Empfänger verpflichtet, diese personenbezogenen Daten sowohl vertraulich zu behandeln als auch nicht für andere Zwecke als die Erfüllung des Vertrags zu verwenden.</P>
<H2>Verkauf von personenbezogenen Daten</H2>
<P>Wie im CCPA definiert, bedeuten &quot;verkaufen&quot; und &quot;Verkauf&quot; den Verkauf, die Vermietung, die Freigabe, die Offenlegung, die Verbreitung, die Zurverfügungstellung, die Übertragung oder die anderweitige mündliche, schriftliche, elektronische oder sonstige Übermittlung der personenbezogenen Daten eines Verbrauchers durch das Unternehmen an einen Dritten gegen eine Gegenleistung. Das bedeutet, dass Wir eine Art von Vorteil als Gegenleistung für die Weitergabe von persönlichen I nformationen erhalten haben können, aber nicht notwendigerweise einen finanziellen Vorteil.</P>
<P>Bitte beachten Sie, dass die unten aufgeführten Kategorien diejenigen sind, die im CCPA definiert sind. Dies bedeutet nicht, dass alle Beispiele dieser Kategorie personenbezogener Daten tatsächlich verkauft wurden, sondern spiegelt unsere gutgläubige Annahme nach bestem Wissen und Gewissen wider, dass einige dieser Daten aus der entsprechenden Kategorie gegen einen Gegenwert weitergegeben werden können und wurden.</P>
<P>Wir können die folgenden Kategorien personenbezogener Daten verkaufen und haben sie möglicherweise in den letzten zwölf (12) Monaten verkauft:</P>
<Ul>
<Li>
<P>Kategorie A: Identifikatoren</P>
</Li>
<Li>
<P>Kategorie B: Persönliche Datenkategorien, die im kalifornischen Gesetz über Kundendaten (Cal. Civ. Code § 1798.80(e))</P>
</Li>
<Li>
<P>Kategorie F: Internet oder andere ähnliche Netzwerkaktivitäten</P>
</Li>
<Li>
<P>Kategorie G: Geolokalisierungsdaten</P>
</Li>
</Ul>
<H2>Weitergabe von personenbezogenen Daten</H2>
<P>Wir können Ihre persönlichen Informationen, die in den oben genannten Kategorien identifiziert wurden, mit den folgenden Kategorien von Dritten teilen:</P>
<Ul>
<Li>
<P>Dienstleister</P>
</Li>
<Li>
<P>Unsere Partner</P>
</Li>
<Li>
<P>Unsere Geschäftspartner</P>
</Li>
<Li>
<P>Drittanbieter, an die Sie oder Ihre Vertreter uns ermächtigen, Ihre personenbezogenen Daten in Verbindung mit Produkten oder Dienstleistungen, die wir Ihnen zur Verfügung stellen, weiterzugeben</P>
</Li>
</Ul>
<H2>Verkauf von personenbezogenen Daten von Minderjährigen unter 16 Jahren</H2>
<P>Wir verkaufen keine personenbezogenen Daten von Verbrauchern, von denen wir wissen, dass sie unter 16 Jahre alt sind, es sei denn, wir erhalten eine ausdrückliche Genehmigung (das &quot;Recht auf Opt-in&quot;) entweder von dem Verbraucher, der zwischen 13 und 16 Jahre alt ist, oder von den Eltern oder dem Vormund eines Verbrauchers, der unter 13 Jahre alt ist. Verbraucher, die dem Verkauf personenbezogener Daten zugestimmt haben, können sich jederzeit von zukünftigen Verkäufen abmelden. Um das Recht zum Opt-out auszuüben, können Sie (oder Ihr bevollmächtigter Vertreter) eine Anfrage an uns richten, indem Sie uns kontaktieren.</P>
<P>Wenn Sie Grund zu der Annahme haben, dass ein Kind unter 13 (oder 16) Jahren uns personenbezogene Daten zur Verfügung gestellt hat, kontaktieren Sie uns bitte mit ausreichenden Details, damit wir diese Daten löschen können.</P>
<H2>Ihre Rechte unter dem CCPA</H2>
<P>Der CCPA gewährt den in Kalifornien ansässigen Personen bestimmte Rechte in Bezug auf ihre personenbezogenen Daten. Wenn Sie Einwohner von Kalifornien sind, haben Sie die folgenden Rechte:</P>
<Ul>
<Li><Strong>Das Recht auf Benachrichtigung.</Strong> Sie haben das Recht, darüber informiert zu werden, welche Kategorien personenbezogener Daten gesammelt werden und für welche Zwecke die personenbezogenen Daten verwendet werden.</Li>
<Li><Strong>Das Recht auf Anfrage.</Strong> Gemäß CCPA haben Sie das Recht, von uns zu verlangen, dass wir Ihnen Informationen über die Erhebung, die Verwendung, den Verkauf, die Weitergabe zu Geschäftszwecken und die Weitergabe von personenbezogenen Daten offenlegen. Sobald Wir Ihre Anfrage erhalten und bestätigt haben, werden Wir Ihnen diese offenlegen:
<Ul>
<Li>Die Kategorien der persönlichen Daten, die wir über Sie gesammelt haben</Li>
<Li>Die Kategorien der Quellen für die persönlichen Daten, die wir über Sie gesammelt haben</Li>
<Li>Unser geschäftlicher oder kommerzieller Zweck für die Sammlung oder den Verkauf dieser personenbezogenen Daten</Li>
<Li>Die Kategorien von Dritten, mit denen wir diese personenbezogenen Daten teilen</Li>
<Li>Die spezifischen persönlichen Daten, die wir über Sie gesammelt haben</Li>
<Li>Wenn wir Ihre personenbezogenen Daten verkauft haben oder Ihre personenbezogenen Daten für einen geschäftlichen Zweck weitergegeben haben, werden wir Sie darüber informieren:
<Ul>
<Li>Die Kategorien der verkauften personenbezogenen Daten</Li>
<Li>Die Kategorien der personenbezogenen Daten, die offengelegt wurden</Li>
</Ul>
</Li>
</Ul>
</Li>
<Li><Strong>Das Recht, Nein zum Verkauf personenbezogener Daten zu sagen (Opt-out).</Strong> Sie haben das Recht, uns anzuweisen, Ihre personenbezogenen Daten nicht zu verkaufen. Um eine Opt-out-Anfrage zu stellen, kontaktieren Sie uns bitte.</Li>
<Li><Strong>Das Recht auf Löschung personenbezogener Daten.</Strong> Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu verlangen, vorbehaltlich bestimmter Ausnahmen. Sobald wir Ihren Antrag erhalten und bestätigt haben, werden wir Ihre personenbezogenen Daten aus unseren Aufzeichnungen löschen (und unsere Dienstleister anweisen, diese zu löschen), es sei denn, es liegt eine Ausnahme vor. Wir können Ihren Antrag auf Löschung ablehnen, wenn die Beibehaltung der Informationen für uns oder unsere Dienstanbieter notwendig ist, um:
<Ul>
<Li>Die Transaktion abzuschließen, für die wir die personenbezogenen Daten erfasst haben, eine Ware oder Dienstleistung bereitzustellen, die Sie angefordert haben, Maßnahmen zu ergreifen, die im Rahmen unserer laufenden Geschäftsbeziehung mit Ihnen vernünftigerweise erwartet werden, oder unseren Vertrag mit Ihnen anderweitig zu erfüllen.</Li>
<Li>Sicherheitsvorfälle aufzuspüren, vor böswilligen, betrügerischen oder illegalen Aktivitäten zu schützen oder die für solche Aktivitäten Verantwortlichen strafrechtlich zu verfolgen.</Li>
<Li>Produkte zu debuggen, um Fehler zu identifizieren und zu beheben, die die bestehende beabsichtigte Funktionalität beeinträchtigen.</Li>
<Li>Die freie Meinungsäußerung ausüben, das Recht eines anderen Verbrauchers auf freie Meinungsäußerung sicherstellen oder ein anderes gesetzlich vorgesehenes Recht ausüben.</Li>
<Li>Mit dem California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.) übereinstimmen.</Li>
<Li>Zur Durchführung öffentlicher oder begutachteter wissenschaftlicher, historischer oder statistischer Forschungen im öffentlichen Interesse, die alle anderen anwendbaren Ethik- und Datenschutzgesetze einhalten, wenn die Löschung der Daten das Erreichen der Forschungsziele wahrscheinlich unmöglich machen oder ernsthaft beeinträchtigen würde, sofern Sie zuvor eine informierte Zustimmung erteilt haben.</Li>
<Li>Ausschließlich interne Verwendungszwecke ermöglichen, die vernünftigerweise mit den Erwartungen der Verbraucher auf der Grundlage Ihrer Beziehung zu uns übereinstimmen.</Li>
<Li>Erfüllen Sie eine gesetzliche Verpflichtung.</Li>
<Li>Sonstige interne und rechtmäßige Verwendungen dieser Informationen, die mit dem Kontext, in dem Sie sie bereitgestellt haben, vereinbar sind.</Li>
</Ul>
</Li>
<Li><Strong>Das Recht, nicht diskriminiert zu werden.</Strong> Sie haben das Recht, nicht diskriminiert zu werden, weil Sie eines Ihrer Verbraucherrechte ausüben, einschließlich durch:
<Ul>
<Li>Die Verweigerung von Waren oder Dienstleistungen für Sie</Li>
<Li>Unterschiedliche Preise oder Tarife für Waren oder Dienstleistungen berechnen, einschließlich der Verwendung von Rabatten oder anderen Vorteilen oder der Verhängung von Strafen</Li>
<Li>Das Anbieten eines anderen Niveaus oder einer anderen Qualität von Waren oder Dienstleistungen für Sie</Li>
<Li>Vorschlagen, dass Sie einen anderen Preis oder Tarif für Waren oder Dienstleistungen oder ein anderes Niveau oder eine andere Qualität von Waren oder Dienstleistungen erhalten werden</Li>
</Ul>
</Li>
</Ul>
<H2>Ausübung Ihrer CCPA-Datenschutzrechte</H2>
<P>Um Ihre Rechte nach dem CCPA auszuüben, können Sie sich, wenn Sie in Kalifornien ansässig sind, an uns wenden:</P>
<Ul>
<Li>
<Span>Durch E-Mail: </Span><HiddenEmail email="info@savori.app"/>
</Li>
<Li>
<P>Bei einem Besuch dieser Seite auf unserer Website: <A href="https://eat.savori.app/privacy_policy" rel="external nofollow noopener noreferrer" target="_blank">https://eat.savori.app/privacy_policy</A></P>
</Li>
</Ul>
<P>Nur Sie oder eine beim California Secretary of State registrierte Person, die Sie ermächtigt haben, in Ihrem Namen zu handeln, können eine überprüfbare Anfrage bezüglich Ihrer persönlichen Daten stellen.</P>
<P>Ihre Anfrage an uns muss:</P>
<Ul>
<Li>Ausreichende Informationen bereitstellen, die es uns ermöglichen, in angemessener Weise zu überprüfen, ob Sie die Person sind, über die wir personenbezogene Daten gesammelt haben, oder ein autorisierter Vertreter</Li>
<Li>Beschreiben Sie Ihre Anfrage so detailliert, dass wir sie richtig verstehen, bewerten und beantworten können</Li>
</Ul>
<P>Wir können nicht auf Ihre Anfrage antworten oder Ihnen die erforderlichen Informationen zur Verfügung stellen, wenn wir nicht in der Lage sind:</P>
<Ul>
<Li>Ihre Identität oder Befugnis, die Anfrage zu stellen, überprüfen können</Li>
<Li>Und bestätigen, dass sich die persönlichen Informationen auf Sie beziehen</Li>
</Ul>
<P>Wir werden die erforderlichen Informationen innerhalb von 45 Tagen nach Erhalt Ihrer nachweisbaren Anfrage kostenlos offenlegen und liefern. Die Frist für die Bereitstellung der erforderlichen Informationen kann einmalig um weitere 45 Tage verlängert werden, wenn dies in angemessener Weise erforderlich ist und mit vorheriger Ankündigung.</P>
<P>Die von uns zur Verfügung gestellten Informationen beziehen sich nur auf den Zeitraum von 12 Monaten vor dem Eingang der überprüfbaren Anfrage.</P>
<P>Bei Anfragen zur Datenübertragbarkeit werden wir ein Format für die Bereitstellung Ihrer personenbezogenen Daten wählen, das leicht verwendbar ist und Ihnen die ungehinderte Übertragung der Daten von einer Stelle zu einer anderen Stelle ermöglichen sollte.</P>
<H2>Verkaufen Sie meine persönlichen Daten nicht</H2>
<P>Sie haben das Recht, dem Verkauf Ihrer personenbezogenen Daten zu widersprechen. Sobald wir eine nachweisbare Verbraucheranfrage von Ihnen erhalten und bestätigen, werden wir den Verkauf Ihrer personenbezogenen Daten einstellen. Um Ihr Recht auf Ablehnung auszuüben, kontaktieren Sie uns bitte.</P>
<P>Die Dienstanbieter, mit denen wir zusammenarbeiten (z. B. unsere Analyse- oder Werbepartner), können im Rahmen des Dienstes Technologien verwenden, die personenbezogene Daten im Sinne des CCPA-Gesetzes verkaufen. Wenn Sie der Verwendung Ihrer personenbezogenen Daten für interessenbasierte Werbung und diesen potenziellen Verkäufen im Sinne des CCPA-Gesetzes widersprechen möchten, können Sie dies tun, indem Sie die nachstehenden Anweisungen befolgen.</P>
<P>Bitte beachten Sie, dass jedes Opt-Out spezifisch für den von Ihnen verwendeten Browser ist. Es kann sein, dass Sie das Opt-Out in jedem Browser, den Sie verwenden, durchführen müssen.</P>
<H3>Website</H3>
<P>Sie können den Empfang von personalisierter Werbung, wie sie von unseren Dienstanbietern bereitgestellt wird, deaktivieren, indem Sie unsere Anweisungen auf dem Dienst befolgen:</P>
<Ul>
<Li>Die Opt-Out-Plattform der NAI: <A href="http://www.networkadvertising.org/choices/" rel="external nofollow noopener noreferrer" target="_blank">http://www.networkadvertising.org/choices/</A></Li>
<Li>Die Opt-Out-Plattform der EDAA <A href="http://www.youronlinechoices.com/" rel="external nofollow noopener noreferrer" target="_blank">http://www.youronlinechoices.com/</A></Li>
<Li>Die Opt-Out-Plattform der DAA: <A href="http://optout.aboutads.info/?c=2&amp;lang=DE" rel="external nofollow noopener noreferrer" target="_blank">http://optout.aboutads.info/?c=2&amp;lang=DE</A></Li>
</Ul>
<P>Das Opt-Out platziert ein Cookie auf Ihrem Computer, das eindeutig dem Browser zugeordnet ist, den Sie zum Opt-Out verwenden. Wenn Sie den Browser wechseln oder die von Ihrem Browser gespeicherten Cookies löschen, müssen Sie sich erneut abmelden.</P>
<H3>Mobile Geräte</H3>
<P>Ihr mobiles Gerät kann Ihnen die Möglichkeit geben, die Verwendung von Informationen über die von Ihnen verwendeten Apps abzulehnen, um Ihnen auf Ihre Interessen abgestimmte Werbung zu zeigen:</P>
<Ul>
<Li>&quot;Ablehnung von interessenbezogener Werbung&quot; oder &quot;Ablehnung von Anzeigenpersonalisierung&quot; auf Android-Geräten</Li>
<Li>&quot;Anzeigenverfolgung einschränken&quot; auf iOS-Geräten</Li>
</Ul>
<P>Sie können auch die Sammlung von Standortinformationen von Ihrem mobilen Gerät stoppen, indem Sie die Einstellungen auf Ihrem mobilen Gerät ändern.</P>
<H1>&quot;Do Not Track&quot; Richtlinie gemäß dem California Online Privacy Protection Act (CalOPPA)</H1>
<P>Unser Dienst reagiert nicht auf "Do Not Track"-Signale.</P>
<P>Es gibt jedoch einige Websites von Drittanbietern, die Ihre Browsing-Aktivitäten verfolgen. Wenn Sie solche Websites besuchen, können Sie in Ihrem Webbrowser einstellen, dass Sie den Websites mitteilen, dass Sie nicht verfolgt werden möchten. Sie können DNT aktivieren oder deaktivieren, indem Sie die Voreinstellungen oder die Einstellungsseite Ihres Webbrowsers besuchen.</P>
<H1>Ihre kalifornischen Datenschutzrechte (das kalifornische Gesetz "Shine the Light")</H1>
<P>Nach dem California Civil Code Section 1798 (California's Shine the Light law) können Einwohner Kaliforniens, die mit uns in einer Geschäftsbeziehung stehen, einmal im Jahr Informationen über die Weitergabe ihrer persönlichen Daten an Dritte für deren Direktmarketingzwecke anfordern.</P>
<P>Wenn Sie weitere Informationen im Rahmen des kalifornischen Shine the Light-Gesetzes anfordern möchten und wenn Sie in Kalifornien ansässig sind, können Sie uns unter den unten angegebenen Kontaktdaten kontaktieren.</P>
<H1>Kalifornische Datenschutzrechte für minderjährige Nutzer (California Business and Professions Code Section 22581)</H1>
<P>California Business and Professions Code section 22581 erlaubt es kalifornischen Einwohnern unter 18 Jahren, die registrierte Benutzer von Online-Seiten, -Diensten oder -Anwendungen sind, die Entfernung von Inhalten oder Informationen, die sie öffentlich veröffentlicht haben, zu verlangen und zu erhalten.</P>
<P>Um die Entfernung solcher Daten anzufordern, können Sie uns, wenn Sie in Kalifornien ansässig sind, über die unten angegebenen Kontaktinformationen kontaktieren und die mit Ihrem Konto verknüpfte E-Mail-Adresse angeben.</P>
<P>Bitte beachten Sie, dass Ihre Anfrage keine vollständige oder umfassende Entfernung von online gestellten Inhalten oder Informationen garantiert und dass das Gesetz unter bestimmten Umständen eine Entfernung nicht zulässt oder verlangt.</P>
<H1>Links zu anderen Websites</H1>
<P>Unser Service kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Link eines Dritten klicken, werden Sie auf die Seite dieses Dritten weitergeleitet. Wir raten Ihnen dringend, die Datenschutzrichtlinien jeder Seite, die Sie besuchen, zu überprüfen.</P>
<P>Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Datenschutzrichtlinien oder Praktiken von Websites oder Diensten Dritter.</P>
<H1>Änderungen an dieser Datenschutzerklärung</H1>
<P>Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über alle Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.</P>
<P>Wir werden Sie per E-Mail und/oder durch einen auffälligen Hinweis in unserem Service informieren, bevor die Änderung in Kraft tritt, und das &quot;Zuletzt aktualisiert&quot; Datum am Anfang dieser Datenschutzrichtlinie aktualisieren.</P>
<P>Sie werden gebeten, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen. Änderungen an dieser Datenschutzrichtlinie werden wirksam, wenn sie auf dieser Seite veröffentlicht werden.</P>
<H1>Kontaktieren Sie uns</H1>
<P>Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie uns kontaktieren:</P>
<Ul>
<Li>
<Span>Durch E-Mail: </Span><HiddenEmail email="info@savori.app"/>
</Li>
<Li>
<P>Bei einem Besuch dieser Seite auf unserer Website: <A href="https://eat.savori.app/privacy_policy" rel="external nofollow noopener noreferrer" target="_blank">https://eat.savori.app/privacy_policy</A></P>
</Li>
</Ul>
</>
);

export default PrivacyPolicyDe;
