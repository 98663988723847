import { connect } from 'react-redux';

import { setDiet } from '../../actions';

import DietPicker from '../components/DietPicker';

const mapStateToProps = state => ({
  diet: state.profile.userprofile.diet,
});

const mapDispatchToProps = {
  setDiet
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DietPicker)
