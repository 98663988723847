import { connect } from 'react-redux';

import { verifyEMailRequest } from '../../actions';
import VerifyEMail from '../components/VerifyEMail';

const mapDispatchToProps = {
  verifyEMailRequest
}

export default connect(
  null,
  mapDispatchToProps
)(VerifyEMail)
