import { connect } from 'react-redux';

import {
  addBookmark,
  deleteBookmark,
  deleteRecipeShare,
  addReaction,
  deleteReaction,
  hideEntry,
  loadFeed,
  loadRecipe,
  pushNotification,
  submitFeedEntryReport
} from '../../actions';

import Feed from '../components/Feed';

const mapStateToProps = (state) => ({
  is_superuser: state.profile.is_superuser,
  username: state.profile.username,
  user_uuid: state.profile.userprofile.uuid,
  entries: state.feed.entries,
  hidden_entries: state.feed.hidden_entries,
  can_load_more: state.feed.can_load_more
})

const mapDispatchToProps = {
  addBookmark,
  deleteBookmark,
  deleteRecipeShare,
  addReaction,
  deleteReaction,
  hideEntry,
  loadFeed,
  loadRecipe,
  pushNotification,
  submitReport: submitFeedEntryReport
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feed)
