import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useTranslation } from 'react-i18next';

import RecipeContainer from '../containers/RecipeContainer';
import Button from '../../common/components/Button';
import Masonry from '../../common/components/Masonry';
import {
  MIN_RECIPE_WIDTH,
  MIN_RECIPE_COLS
} from '../../common/constants/';

import common_styles, { padding } from '../../common/components/CommonStyles';

import { arraysEqual, Body2, ButtonText } from '../../utils';
import { useTheme } from '../../utils/Theme';

const RecipesList = ({ result }) => {
  const { t } = useTranslation(['common', 'recipes']);

  const theme = useTheme();

  if (!result.recipes.recipes.length) {
    return (
      <View style={[
        styles.recipesList,
        theme.general
      ]}>
        <Body2 style={theme.general}>
          { t('recipes:noRecipesFound1') }
          { '\n' }
          { t('recipes:noRecipesFound2') }
        </Body2>
      </View>
    );
  } else {
    return (
      <View style={[
        styles.recipesList,
        theme.general
      ]}>
        <Masonry
          min_width={MIN_RECIPE_WIDTH}
          min_num_cols={MIN_RECIPE_COLS}
        >
          {
            result.recipes.recipes.map(recipe => (<RecipeContainer key={recipe.uuid} {...recipe}/>))
          }
        </Masonry>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  recipesList: {
    width: '100%',
    flex: 1,
    padding: 0.5 * padding
  },
  loadingIcon: {
    maxWidth: 200
  }
});

export default RecipesList
