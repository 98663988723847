import React, { useState } from 'react';
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  Pressable,
  View
} from 'react-native';

import { useNavigation } from '@react-navigation/native';

import i18n from 'i18next';

import FridgeCheck from '../../assets/FridgeCheck';
import MaybeShop from '../../assets/MaybeShop';
import NeedShop from '../../assets/NeedShop';
import NoShop from '../../assets/NoShop';
import RecipeImagePlaceholder from '../../assets/RecipeImagePlaceholder';
import User from '../../assets/User';

import {
  Body2,
  getEmojisFromRecipe,
  Small,
  truncateString,
  useAppWidth
} from '../../utils/';
import { useTheme } from '../../utils/Theme';
import {
  APP_TABS,
  COLORS,
  MARGIN,
  PADDING,
  RECIPE_NAME_MAX_LENGTH,
  MODAL_SCREENS,
  SEARCH_SCREENS
} from '../../common/constants/';
import { MEDIA_PREFIX } from '../../common/constants/urls';
import common_styles, { borderRadius, castShadow, roundedBorder } from '../../common/components/CommonStyles';

import { useTranslation } from 'react-i18next';

const Badge = ({source, author}) => {
  const theme = useTheme();

  let avatar_image = '';
  let avatar_image_placeholder_uri = '';

  let body = null;

  if (author) {
    if (author.userprofile.image && author.userprofile.image) {
      avatar_image = author.userprofile.image.image_50;
      const avatar_image_placeholder = author.userprofile.image.image_placeholder;
      avatar_image_placeholder_uri = {uri: `data:image/png;base64,${avatar_image_placeholder}`};
    } else {
      body = (
        <User color={theme.button.color}/>
      );
    }
  } else if (source) {
    if (source.logo) {
      avatar_image = source.logo;
    } else {
      let initials = null;

      if (source.name) {
        const words = source.name.split(' ');
        if (words.length > 1) {
          initials = `${words[0].substring(0, 1)}${words.pop().substring(0, 1)}`;
        } else {
          initials = source.name.substring(0, 1);
        }
      } else {
        initials = '?'
      }

      body = (
        <Body2 style={theme.button}>
          { initials.toUpperCase() }
        </Body2>
      );

    }
  }

  if (avatar_image) {
    body = (
      <Image
        source={{uri: `${MEDIA_PREFIX}${avatar_image}`}}
        defaultSource={avatar_image_placeholder_uri}
        style={[styles.recipeSourceBadge, {alignSelf: 'center'}]}
      />
    );
  }

  return (
    <View style={[styles.recipeSourceBadge, theme.button]}>
      { body }
    </View>
  );
}

const Recipe = (recipe) => {
  const screen_width = useAppWidth();

  const [card_width, setCardWidth] = useState(0.5 * screen_width);

  const { t } = useTranslation(['common', 'recipes']);
  const preferred_lang = i18n.language;

  const theme = useTheme();

  const navigation = useNavigation();

  let have_image = false;
  let image_thumbnail = null;
  let image_placeholder = null;
  let image_width = 0;
  let image_height = 0;
  if (recipe.images && recipe.images.length) {
    have_image = true;
    image_thumbnail = recipe.images[0].image_thumbnail;
    image_placeholder = recipe.images[0].image_placeholder;
    image_width = recipe.images[0].width;
    image_height = recipe.images[0].height;
  }

  const image_placeholder_uri = {uri: `data:image/png;base64,${image_placeholder}`};

  const emojis = getEmojisFromRecipe(recipe)

  const PantryIndicator = () => {
    if ((recipe.num_inactive_ingredients === 0) && (recipe.num_unknown_ingredients === 0)) {
      return (
        <View style={[styles.iconListItemBase, styles.greenBg]}>
          <View style={styles.iconListIcon}>
            <View style={styles.iconListIconImage}>
              <FridgeCheck color='white' />
            </View>
          </View>
          <View style={styles.iconListText}>
            <Small style={{color: 'white'}}>
              { recipe.num_active_ingredients }
            </Small>
          </View>
        </View>
      );
    } else if (recipe.num_inactive_ingredients === 0) {
      return (
        <View style={[styles.iconListItemBase, styles.purpleBg]}>
          <View style={styles.iconListIcon}>
            <View style={styles.iconListIconImage}>
              <MaybeShop color='white'/>
            </View>
          </View>
          <View style={styles.iconListText}>
            <Small style={{color: 'white'}}>
              { recipe.num_unknown_ingredients }
            </Small>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[styles.iconListItemBase, styles.redBg]}>
          <View style={styles.iconListIcon}>
            <View style={styles.iconListIconImage}>
              <NeedShop color='white'/>
            </View>
          </View>
          <View style={styles.iconListText}>
            <Small style={{color: 'white'}}>
              { `${recipe.num_inactive_ingredients + recipe.num_unknown_ingredients}` }
            </Small>
          </View>
        </View>
      );
    }
  }

  const recipe_card_body = (
    <View style={styles.recipeCardBody}>
      <View style={styles.recipeCardBodyColumn}>
        <PantryIndicator/>
      </View>
      <View>
        <Badge source={recipe.source} author={recipe.author} />
      </View>
    </View>
  );

  return (
    <View style={styles.recipeCardContainer}>
      <Pressable
        style={[theme.general, styles.recipeCard]}
        onPress={() => navigation.navigate(
          MODAL_SCREENS.recipeDetail.name,
          {
            uuid: recipe.uuid,
            nav_origin: { name: SEARCH_SCREENS.recipeSearch.name }
          }
        )}
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setCardWidth(width);
        }}
      >
        {
          have_image ? (
            <ImageBackground
              defaultSource={image_placeholder_uri}
              source={{uri: `${MEDIA_PREFIX}${image_thumbnail}`}}
              style={[
                {
                  width: card_width + 1,
                  height: image_height ? (image_height / image_width * (card_width + 1)) : 0,
                },
                theme.general
              ]}
            >
              { recipe_card_body }
            </ImageBackground>
          ) : (
            <View style={{width: card_width + 1, height: card_width + 1}}>
              <RecipeImagePlaceholder
                emoji={emojis[0]}
              />
              <View style={{...StyleSheet.absoluteFill, top: 0, bottom: 0}}>
                { recipe_card_body }
              </View>
            </View>
          )
        }
      </Pressable>
      <Small style={{...theme.general, backgroundColor: undefined}} numberOfLines={2}>
        { recipe.instance.name }
      </Small>
    </View>
  )
}

const styles = StyleSheet.create({
  recipeCardContainer: {
    width: '96%',
    margin: '2%'
  },
  recipeCard: {
    ...roundedBorder,
    overflow: 'hidden',
    padding: 0
  },
  recipeCardBody: {
    padding: 2,
    flexDirection: 'row',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'space-between'
  },
  recipeCardBodyColumn: {
    flexWrap: 'wrap',
    height: '100%',
    alignContent: 'flex-start'
  },
  recipeSourceBadge: {
    margin: 2,
    height: 30,
    width: 30,
    borderRadius: 15,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  iconListItemBase: {
    flexDirection: 'row',
    borderRadius,
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 2,
    padding: 2
  },
  greenBg: {
    backgroundColor: COLORS.GREEN
  },
  purpleBg: {
    backgroundColor: COLORS.PURPLE
  },
  redBg: {
    backgroundColor: COLORS.RED
  },
  iconListIcon: {
    width: 30,
    height: 30
  },
  iconListIconImage: {
    padding: 4,
    width: 30,
    height: 30
  },
  iconListText: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 4
  }
});

export default Recipe
