import React from 'react';
import { StyleSheet } from 'react-native';

import { Title } from '../../utils/';

const styles = StyleSheet.create({
  bigLetter: {
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 27
  }
});

export const BigLetter = (props) => (
  <Title style={[styles.bigLetter, props.style]}>
    { props.name.substring(0, 1) }
  </Title>
)
export const BigEmoji = (props) => (
  <Title style={[styles.bigLetter, props.style]} aria-label={props.label} role="img" aria-hidden="true">
    { props.emoji }
  </Title>
)
