import React, {
  useEffect,
  useState
} from 'react';
import {
  ImageBackground,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import { useTranslation } from 'react-i18next';

import {
  useNavigation
} from '@react-navigation/native';

import Masonry from '../../common/components/Masonry';
import common_styles, { padding } from '../../common/components/CommonStyles';

import LinearGradient from '../../assets/LinearGradient';

import { useTheme } from '../../utils/Theme';
import {
  Body2,
  parseDuration,
  HidingHeaderScrollView,
  SearchBar,
  Small,
  Title
} from '../../utils/';

import {
  APP_TABS,
  COLORS,
  MIN_RECIPE_WIDTH,
  MIN_RECIPE_COLS,
  MODAL_SCREENS,
  CHALLENGES_SCREENS
} from '../../common/constants/';
import { MEDIA_PREFIX } from '../../common/constants/urls';

const Challenge = ({
  title,
  short_description,
  is_open_end,
  duration,
  has_completed,
  is_subscribed,
  images,
  onPress
}) => {
  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const theme = useTheme();

  console.log(images)

  return (
    <TouchableOpacity
      style={[common_styles.card, styles.challengeOuter]}
      onPress={onPress}
    >
      <ImageBackground
        style={{height: '100%'}}
        source={images.length ? {uri: `${MEDIA_PREFIX}${images[0].image}`} : null}
      >
        <View style={{
          ...StyleSheet.absoluteFill
        }}>
          <LinearGradient/>
          <View
            style={{
              ...StyleSheet.absoluteFill,
              backgroundColor: 'black',
              opacity: 0.37
            }}
          />
          <View
            style={styles.challengeInner}
          >
            <View style={styles.stateContainer}>
              {
                has_completed ? (
                  <View style={[common_styles.smallIng, theme.greenBg]}>
                    <Small style={theme.greenBg}>
                      { t('challenges.completedLabel') }
                    </Small>
                  </View>
                ) : null
              }
              {
                (is_subscribed && !has_completed) ? (
                  <View style={[common_styles.smallIng, theme.purpleBg]}>
                    <Small style={theme.purpleBg}>
                      { t('challenges.signedUpLabel') }
                    </Small>
                  </View>
                ) : null
              }
            </View>
            <Title
              style={{
                color: COLORS.YELLOW,
                textAlign: 'center'
              }}
              numberOfLines={3}
            >
              { title }
            </Title>
            <Small
              style={{
                color: COLORS.YELLOW,
                textAlign: 'center'
              }}
              numberOfLines={5}
            >
              { short_description }
            </Small>
            {/*
              <Small
                style={{
                  color: COLORS.YELLOW,
                  textAlign: 'center'
                }}
                numberOfLines={2}
              >
                {
                  (!is_open_end && duration) ?
                    t('challenges.durationChallenge', { duration: parseDuration(duration, i18n prefix'challenges') }) :
                    t('challenges.durationOpenEnd')
                }
              </Small>
            */}
          </View>
        </View>

      </ImageBackground>
    </TouchableOpacity>
  );
};

const ChallengesOverview = ({
  challengeSearch
}) => {
  const [ search_phrase, setSearchPhrase ] = useState('');
  const [ challenges, setChallenges ] = useState([]);

  const { t } = useTranslation('common');

  const navigation = useNavigation();
  const theme = useTheme();

  useEffect(() => {
    if (!search_phrase) {
      challengeSearch(
        search_phrase,
        (new_challenges) => setChallenges(new_challenges)
      );
    }
  }, [search_phrase, challengeSearch]);

  return (
    <HidingHeaderScrollView
      keyboardShouldPersistTaps='handled'
      header={(
        <View style={{padding}}>
          <SearchBar
            search_phrase={search_phrase}
            setSearchPhrase={setSearchPhrase}
            doSearch={search_phrase => challengeSearch(
              search_phrase,
              (new_challenges) => setChallenges(new_challenges)
            )}
            placeholder={t('challenges.searchPlaceholder')}
          />
        </View>
      )}
      body={(
        <View style={{padding}}>
          <View
            style={{
              flex: 1,
              padding: 0.5 * padding
            }}
          >
            <Masonry
              min_width={MIN_RECIPE_WIDTH}
              min_num_cols={MIN_RECIPE_COLS}
            >
              {
                challenges.map((challenge, index) => (
                  <Challenge
                    key={index}
                    {...challenge}
                    onPress={() => navigation.navigate(
                      MODAL_SCREENS.challengeDetail.name,
                      {
                        uuid: challenge.uuid
                      })}
                  />
                ))
              }
            </Masonry>
          </View>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  challengeOuter: {
    overflow: 'hidden',
    height: 190,
    padding: 0,
    margin: 0,
    marginVertical: 0.5 * padding
  },
  challengeInner: {
    ...StyleSheet.absoluteFill,
    padding,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stateContainer: {
    position: 'absolute',
    right: 0.5 * padding,
    top: 0.5 * padding
  }
})

export default ChallengesOverview;
