import { connect } from 'react-redux';

import { loadLabellingIngredient, confirmLabellingIngredient, reparseLabellingIngredient, deleteLabellingIngredient } from '../../actions';
import LabellingTool from '../components/LabellingTool';

const mapStateToProps = state => ({
  labelling_ingredient: state.admin.labelling_ingredient,
})

const mapDispatchToProps = ({
  loadLabellingIngredient,
  confirmLabellingIngredient,
  reparseLabellingIngredient,
  deleteLabellingIngredient
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabellingTool)
