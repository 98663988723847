import { connect } from 'react-redux';

import {
  addFollow,
  deleteFollow,
  loadOtherProfile
} from '../../actions';
import Profile from '../components/Profile';

const mapStateToProps = state => ({
  auth_state: state.auth_state,
  profile: state.other_profile,
  own_profile: state.profile
})

const mapDispatchToProps = {
  addFollow,
  deleteFollow,
  loadOtherProfile
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
